a {
    color: $link-color-impaired;
    text-decoration: underline;
}

&:not(.agency) {
    background: $bg-color-impaired;
}

.bltd--page--route .bltd--workspace, .bltd--print--invoice, #bltd--layout, .bltd--booker--page .bltd--bg {
    background: $bg-color-impaired;
}

.bltd--heading .bltd--heading--breadcrumb .bltd--title {
    color: $font-color-impaired;
}

@include media('>tablet') {
    .h2x .bltd--main_content {
        padding-top: 8.625em;
    }

    .bltd--main_content {
        padding-top: 3.875em;
    }

    .bltd--heading .bltd--heading--wrapper.bltd--heading--breadcrumb {
        padding-top: 1.375em;
        padding-bottom: 1.375em;
    }
}

.bltd--layout--sidebar {
    background-color: $font-color-impaired;

    .bltd--layout--menu .bltd--item a {
        color: $bg-color-impaired;
    }

    .bltd--layout--menu .bltd--item.bltd--pre .bltd--text {
        color: $font-color-impaired;
        font-weight: bold;
    }

    .bltd--copyrights p {
        color: $bg-color-impaired;
    }
}

h1, .bltd--h1, h2, .h2, h3, .h3, h4, .bltd--h4, .confirm-popup h3.bltd--error {
    color: $font-color-impaired;
}

@include media('>tablet') {
    .bltd--heading .bltd--heading--wrapper, .bltd--page--route .bltd--wrap,
    .bltd--page--ticket .bltd--wrap, .bltd--heading--menu .bltd--heading--menu-wrapper,
    .bltd--heading .bltd--heading--wrapper.bltd--heading--breadcrumb {
        padding-left: 0;
        padding-right: 0;
    }
}

.main-text {
    font-size: inherit;
}

.bltd--rlist .bltd--btn.bltd--btn--red {
    text-decoration: none;
}

.bltd--rlist .bltd--btn.bltd--btn--link {
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}

.bltd--ui.bltd--buttons .bltd--btn {
    margin-right: .1em;
}

.bltd--btn--primary.bltd--btn--gray {
    background-color: $bg-color-impaired;
    color: $font-color-impaired !important;
    box-shadow: 0 0 0 0.125em $font-color-impaired inset;
}

.bltd--btn--stroke.bltd--btn--green.bltd--btn--s, .bltd--btn--stroke.bltd--btn--green.bltd--btn--m,
.bltd--btn--stroke.bltd--btn--red.bltd--btn--s, .bltd--btn--stroke.bltd--btn--red.bltd--btn--m,
.bltd--btn--stroke.bltd--btn--red.bltd--btn--s:active, .bltd--btn--stroke.bltd--btn--red.bltd--btn--m:active,
.bltd--btn--stroke.bltd--btn--blue.bltd--btn--m, .bltd--btn--stroke.bltd--btn--blue.bltd--btn--m:active {
    box-shadow: 0 0 0 0.125em $font-color-impaired inset;
    text-decoration: none;
}

.bltd--btn--stroke.bltd--btn--red:hover, .bltd--btn--stroke.bltd--btn--red:active,
.bltd--btn--stroke.bltd--btn--blue:hover, .bltd--btn--stroke.bltd--btn--blue:active,
.bltd--btn--primary.bltd--btn--gray:hover, .bltd--btn--primary.bltd--btn--gray:active,
.bltd--btn--stroke.bltd--btn--green:hover, .bltd--btn--stroke.bltd--btn--green:active {
    background-color: $bg-color-impaired;
    color: $font-color-impaired !important;
    text-decoration: underline;
}

.bltd--btn--primary.bltd--btn--green, .bltd--btn--primary.bltd--btn--blue {
    background-color: $font-color-impaired;
    color: $bg-color-impaired;

    &:hover {
        text-decoration: underline;
    }
}

.bltd--btn.bltd--btn--green, .bltd--btn.bltd--btn--red,
.bltd--btn.bltd--btn--blue:active, .bltd--btn.bltd--btn--blue:hover,
.bltd--btn--stroke.bltd--btn--blue, .bltd--btn.bltd--btn--blue,
.bltd--btn--stroke.bltd--btn--blue:hover, .bltd--btn--stroke.bltd--btn--blue:active {
    color: $font-color-impaired;
}

.bltd--btn--primary.bltd--btn--blue:hover {
    background-color: $font-color-impaired;
    text-decoration: underline;
}

.bltd--btn--stroke.bltd--btn--green:hover, .bltd--btn--stroke.bltd--btn--green:active  {
    span:after {
        filter: brightness(0) saturate(100%);
    }
}

.bltd--page--profile .bltd--btn.bltd--btn--red:hover {
    text-decoration: underline;
}

.bltd--print--invoice .bltd--btn--blue {
    background-color: $font-color-impaired;
    color: $bg-color-impaired;

    &:hover, &:active {
        background-color: $font-color-impaired;
        color: $bg-color-impaired !important;
    }
}

.bltd--ui.bltd--form {
    input:not([type]), input[type="date"], input[type="datetime-local"], input[type="email"],
    input[type="number"], input[type="password"], input[type="search"], input[type="tel"],
    input[type="time"], input[type="text"], input[type="url"] {
        border-color: $font-color-impaired;
        box-shadow: none;

        &:focus {
            border-color: $font-color-impaired;
            box-shadow: none;
        }
    }

    .bltd--field.bltd--field input:-webkit-autofill {
        box-shadow: 0 0 0 100px $bg-color-impaired inset !important;
        border-color: $font-color-impaired !important;
    }

    .bltd--field.bltd--field input:-webkit-autofill:focus {
        box-shadow: 0 0 0 100px $bg-color-impaired inset !important;
        border-color: $font-color-impaired !important;
    }

    .bltd--error.bltd--error input:-webkit-autofill {
        box-shadow: 0 0 0 100px $bg-color-impaired inset !important;
        border-color: $font-color-impaired !important;
    }

    .message-error, .bltd--error.bltd--msg,
    .bltd--fields .bltd--field .message-error {
        color: $font-color-impaired !important;
    }
}

input:not([type]).is-invalid, .bltd--ui.bltd--form input[type="date"].is-invalid,
.bltd--ui.bltd--form input[type="datetime-local"].is-invalid, .bltd--ui.bltd--form input[type="email"].is-invalid,
.bltd--ui.bltd--form input[type="number"].is-invalid, .bltd--ui.bltd--form input[type="password"].is-invalid,
.bltd--ui.bltd--form input[type="search"].is-invalid, .bltd--ui.bltd--form input[type="tel"].is-invalid,
.bltd--ui.bltd--form input[type="time"].is-invalid, .bltd--ui.bltd--form input[type="text"].is-invalid,
.bltd--ui.bltd--form input[type="url"].is-invalid, .bltd--ui.selection.bltd--dropdown.is-invalid,
.bltd--ui.bltd--form .bltd--form-dropdown.is-invalid, .ng-submitted .bltd--aside-wrapper input.ng-invalid {
    border-color: $font-color-impaired !important;
    box-shadow: none !important;
    border-width: 0.125em;
}

// icons
.bltd--with-icon_swap:after, .bltd--with-icon_calendar:after, .bltd--btn.bltd--btn--printer i:after,
.bltd--rlist .bltd--icon--expand:after, .bltd--page--account-list .bltd--notice:before, .bltd--with-icon_time:after,
.bltd--btn--is-rarr.bltd--btn--green span:after, .bltd--page--checkout .bltd--icon--expand:after,
.bltd--btn.bltd--btn--green .bltd--icon.bltd--plus:before, .bltd--with-icon_larr:after, .zoom.bltd--icon:before,
.bltd--flex--ticket .bltd--labeled--field.bltd--labeled--field-account .bltd--labeled--field-field .bltd--input-container .bltd--input--toggle:after,
.bltd--page--checkout aside .bltd--waypoint .bltd--point.bltd--point--date:after,
.bltd--page--checkout aside .bltd--waypoint .bltd--point.bltd--point--date:before,
.bltd--rlist .bltd--tr .bltd--td.bltd--route--name .bltd--main-line:after, .expand--filter span:after,
.bltd--booker__params .bltd--dropdown.bltd--icon:before, #bltd--full-clndr .bltd--clndr-controls .bltd--clndr-next-button
{
    filter: brightness(0) saturate(100%);
}

.bltd--account .bltd--btn .bltd--text .bltd--i {
    background-color: $bg-color-impaired;
}

@include media('<=tablet') {
    .bltd--rlist .bltd--tr .bltd--td.bltd--route--timestamp .bltd--inner.bltd--with-date--right:after {
        filter: brightness(0) saturate(100%);
    }

    .login-popup .bltd--modal-dialog {
        width: 100%;
    }

    .bltd--page--ticket .bltd--rlist .bltd--expand .bltd--tr--expand .bltd--tr--expand-heading .bltd--expand--back .bltd--expand--nav {
        color: $font-color-impaired;
    }

    .bltd--layout--sidebar {
        top: 74px !important;

        .bltd--push {
            padding-top: 1.75em;
            padding-bottom: 1em;
        }
    }
}

// Spinners
.bltd--spinner i {
    background: $font-color-impaired;
}

@keyframes load5-impaired {
    0%,
    100% {
        box-shadow: 0em -2.5em 0 0 #000000, 1.75em -1.55em 0 0 rgba(0, 0, 0, 0.2), 2.25em 0.3em 0 0 rgba(0, 0, 0, 0.2), 1em 1.8em 0 0 rgba(0, 0, 0, 0.2), -1em 1.8em 0 0 rgba(0, 0, 0, 0.2), -2.25em 0.3em 0 0 rgba(0, 0, 0, 0.5), -1.75em -1.55em 0 0 rgba(0, 0, 0, 0.7); }
    12.5% {
        box-shadow: 0 -2.5em 0 0 rgba(0, 0, 0, 0.7), 1.75em -1.55em 0 0 #000, 2.25em 0.3em 0 0 rgba(0, 0, 0, 0.2), 1em 1.8em 0 0 rgba(0, 0, 0, 0.2), -1em 1.8em 0 0 rgba(0, 0, 0, 0.2), -2.25em 0.3em 0 0 rgba(0, 0, 0, 0.2), -1.75em -1.55em 0 0 rgba(0, 0, 0, 0.5); }
    25% {
        box-shadow: 0 -2.5em 0 0 rgba(0, 0, 0, 0.5), 1.75em -1.55em 0 0 rgba(0, 0, 0, 0.7), 2.25em 0.3em 0 0 #000000, 1em 1.8em 0 0 rgba(0, 0, 0, 0.2), -1em 1.8em 0 0 rgba(0, 0, 0, 0.2), -2.25em 0.3em 0 0 rgba(0, 0, 0, 0.2), -1.75em -1.55em 0 0 rgba(0, 0, 0, 0.2); }
    37.5% {
        box-shadow: 0 -2.5em 0 0 rgba(0, 0, 0, 0.2), 1.75em -1.55em 0 0 rgba(0, 0, 0, 0.5), 2.25em 0.3em 0 0 rgba(0, 0, 0, 0.7), 1em 1.8em 0 0 #000000, -1em 1.8em 0 0 rgba(0, 0, 0, 0.2), -2.25em 0.3em 0 0 rgba(0, 0, 0, 0.2), -1.75em -1.55em 0 0 rgba(0, 0, 0, 0.2); }
    62.5% {
        box-shadow: 0 -2.5em 0 0 rgba(0, 0, 0, 0.2), 1.75em -1.55em 0 0 rgba(0, 0, 0, 0.2), 2.25em 0.3em 0 0 rgba(0, 0, 0, 0.5), 1em 1.8em 0 0 rgba(0, 0, 0, 0.7), -1em 1.8em 0 0 #000000, -2.25em 0.3em 0 0 rgba(0, 0, 0, 0.2), -1.75em -1.55em 0 0 rgba(0, 0, 0, 0.2); }
    75% {
        box-shadow: 0 -2.5em 0 0 rgba(0, 0, 0, 0.2), 1.75em -1.55em 0 0 rgba(0, 0, 0, 0.2), 2.25em 0.3em 0 0 rgba(0, 0, 0, 0.2), 1em 1.8em 0 0 rgba(0, 0, 0, 0.5), -1em 1.8em 0 0 rgba(0, 0, 0, 0.7), -2.25em 0.3em 0 0 #000000, -1.75em -1.55em 0 0 rgba(0, 0, 0, 0.2); }
    87.5% {
        box-shadow: 0 -2.5em 0 0 rgba(0, 0, 0, 0.2), 1.75em -1.55em 0 0 rgba(0, 0, 0, 0.2), 2.25em 0.3em 0 0 rgba(0, 0, 0, 0.2), 1em 1.8em 0 0 rgba(0, 0, 0, 0.2), -1em 1.8em 0 0 rgba(0, 0, 0, 0.5), -2.25em 0.3em 0 0 rgba(0, 0, 0, 0.7), -1.75em -1.55em 0 0 #000000; }
}

.bltd--loader {
    animation-name: load5-impaired;
}

// Footer
.bltd--footer {
    box-shadow: 0 -0.125em 0 $font-color-impaired;
    margin-top: -4em;

    .bltd--wrapper, .bltd--menu .bltd--link {
        color: $font-color-impaired;
    }

    .bltd--wrapper {
        padding-bottom: 1em;
        padding-top: 1em;
    }
}

footer.bltd--index {
    box-shadow: 0 -0.125emx 0 $font-color-impaired;
}

a.bltd--top-btn {
    background-color: $font-color-impaired;
}

.bltd--ui.bltd--menu .bltd--item {
    text-decoration: underline;
}
