
@function emtopx($em) {
  @return $em + em ;
}

.bltd--loader {
  margin: 2.5em auto;
  font-size: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  &.bltd--big {
    width: 12px;
    height: 12px;
    position: absolute;
    left: 50%;
    top: 25%;
    transform: translate(-50%, 0);
    font-size: 0.75em;
  }
}

@keyframes load5 {
  0%,
  100% {
    box-shadow: emtopx(0) emtopx(-2.5) 0 0 var(--loader-color),
    emtopx(1.75) emtopx(-1.55) 0 0 var(--loader-color-02),
    emtopx(2.25) emtopx(.3) 0 0 var(--loader-color-02),
    emtopx(1) emtopx(1.8) 0 0 var(--loader-color-02),
    emtopx(-1) emtopx(1.8) 0 0 var(--loader-color-02),
    emtopx(-2.25) emtopx(.3) 0 0 var(--loader-color-05),
    emtopx(-1.75) emtopx(-1.55) 0 0 var(--loader-color-07);
  }
  12.5% {
    box-shadow: 0 emtopx(-2.5) 0 0 var(--loader-color-07),
    emtopx(1.75) emtopx(-1.55) 0 0 var(--loader-color),
    emtopx(2.25) emtopx(.3) 0 0 var(--loader-color-02),
    emtopx(1) emtopx(1.8) 0 0 var(--loader-color-02),
    emtopx(-1) emtopx(1.8) 0 0 var(--loader-color-02),
    emtopx(-2.25) emtopx(.3) 0 0 var(--loader-color-02),
    emtopx(-1.75) emtopx(-1.55) 0 0 var(--loader-color-05);
  }
  25% {
    box-shadow: 0 emtopx(-2.5) 0 0 var(--loader-color-05),
    emtopx(1.75) emtopx(-1.55) 0 0 var(--loader-color-07),
    emtopx(2.25) emtopx(.3) 0 0 var(--loader-color),
    emtopx(1) emtopx(1.8) 0 0 var(--loader-color-02),
    emtopx(-1) emtopx(1.8) 0 0 var(--loader-color-02),
    emtopx(-2.25) emtopx(.3) 0 0 var(--loader-color-02),
    emtopx(-1.75) emtopx(-1.55) 0 0 var(--loader-color-02);
  }
  37.5% {
    box-shadow: 0 emtopx(-2.5) 0 0 var(--loader-color-02),
    emtopx(1.75) emtopx(-1.55) 0 0 var(--loader-color-05),
    emtopx(2.25) emtopx(.3) 0 0 var(--loader-color-07),
    emtopx(1) emtopx(1.8) 0 0 var(--loader-color),
    emtopx(-1) emtopx(1.8) 0 0 var(--loader-color-02),
    emtopx(-2.25) emtopx(.3) 0 0 var(--loader-color-02),
    emtopx(-1.75) emtopx(-1.55) 0 0 var(--loader-color-02);
  }
  62.5% {
    box-shadow: 0 emtopx(-2.5) 0 0 var(--loader-color-02),
    emtopx(1.75) emtopx(-1.55) 0 0 var(--loader-color-02),
    emtopx(2.25) emtopx(.3) 0 0 var(--loader-color-05),
    emtopx(1) emtopx(1.8) 0 0 var(--loader-color-07),
    emtopx(-1) emtopx(1.8) 0 0 var(--loader-color),
    emtopx(-2.25) emtopx(.3) 0 0 var(--loader-color-02),
    emtopx(-1.75) emtopx(-1.55) 0 0 var(--loader-color-02);
  }
  75% {
    box-shadow: 0 emtopx(-2.5) 0 0 var(--loader-color-02),
    emtopx(1.75) emtopx(-1.55) 0 0 var(--loader-color-02),
    emtopx(2.25) emtopx(.3) 0 0 var(--loader-color-02),
    emtopx(1) emtopx(1.8) 0 0 var(--loader-color-05),
    emtopx(-1) emtopx(1.8) 0 0 var(--loader-color-07),
    emtopx(-2.25) emtopx(.3) 0 0 var(--loader-color),
    emtopx(-1.75) emtopx(-1.55) 0 0 var(--loader-color-02);
  }
  87.5% {
    box-shadow: 0 emtopx(-2.5) 0 0 var(--loader-color-02),
    emtopx(1.75) emtopx(-1.55) 0 0 var(--loader-color-02),
    emtopx(2.25) emtopx(.3) 0 0 var(--loader-color-02),
    emtopx(1) emtopx(1.8) 0 0 var(--loader-color-02),
    emtopx(-1) emtopx(1.8) 0 0 var(--loader-color-05),
    emtopx(-2.25) emtopx(.3) 0 0 var(--loader-color-07),
    emtopx(-1.75) emtopx(-1.55) 0 0 var(--loader-color);
  }
}

$loader-color: var(--loader-color);
$loader-size: 6px;

@mixin prefixer($property, $value) {
  @each $prefix in "-webkit-", "-moz-", "-ms-", "-o-", "" {
    #{unquote($prefix)}#{$property}: #{$value};
  }
}

@mixin loader-circle($size, $color) {
  display: inline-block;
  width: $size;
  height: $size;
  background: $color;
  @include border-radius(50%);
}

.bltd--line-loader {
  margin:  auto $loader-size + 6px;
  position: relative;
  @include loader-circle($loader-size, $loader-color);
  @include prefixer(animation, "loader-fade 0.5s 0.5s infinite alternate");
  &:before {
    content: '';
    @include loader-circle($loader-size, $loader-color);
    position: absolute;
    right: $loader-size + 6px;
    @include prefixer(animation, "loader-fade 1s infinite alternate");
  }

  &:after {
    content: '';
    @include loader-circle($loader-size, $loader-color);
    position: absolute;
    left: $loader-size + 6px;
    @include prefixer(animation, "loader-fade 1s 1s infinite alternate");
  }
}


@keyframes loader-fade {
  from { background-color: var(--loader-color); }
  to { background-color: var(--loader-color-02); }
}
@keyframes spin {
 from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
.bltd--spinner {
  height: 80px;
  width: 80px;
  display: inline-block;
  margin: -40px auto 0;
  -webkit-animation: spin 1s steps(10, end) infinite;
  animation: spin 1s steps(10, end) infinite;
  i {
    height: 10px;
    width: 10px;
    margin-left: -5px;
    display: block;
    -webkit-transition: height 1s;
    transition: height 1s;
    position: absolute;
    left: 50%;
    -webkit-transform-origin: center 40px;
    transform-origin: center 40px;
    background: var(--base-green-color);
    border-radius: 5px;
  }

  &:nth-child(2) i {
    height: 10px;
  }
}
.bltd--spinner-bg {
  background: #fff;
  opacity: .5;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.bltd--spinner-wrap {
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  margin-top: -80px;
  text-align: center;
  .bltd--text {
    font-size: 2.2rem;
    line-height: 2.8rem;
    color: #4A4A4A;
    font-weight: 600;
    margin-bottom: 80px;
  }
}
.bltd--spinner i:nth-child(1) { opacity:.08 }
.bltd--spinner i:nth-child(2) { transform:rotate(36deg);opacity:.167 }
.bltd--spinner i:nth-child(3) { transform:rotate(72deg);opacity:.25 }
.bltd--spinner i:nth-child(4) { transform:rotate(108deg);opacity:.33 }
.bltd--spinner i:nth-child(5) { transform:rotate(144deg);opacity:.4167 }
.bltd--spinner i:nth-child(6) { transform:rotate(180deg);opacity:.5 }
.bltd--spinner i:nth-child(7) { transform:rotate(216deg);opacity:.583 }
.bltd--spinner i:nth-child(8) { transform:rotate(252deg);opacity:.67 }
.bltd--spinner i:nth-child(9) { transform:rotate(288deg);opacity:.75 }
.bltd--spinner i:nth-child(10) { transform:rotate(324deg);opacity:.833 }