.bltd--ui.bltd--menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

}
.bltd--ui.bltd--menu:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/*--------------
    Sub-Menu
---------------*/

.bltd--ui.bltd--menu .bltd--menu {
  margin: 0;
}
.bltd--ui.bltd--menu > .bltd--menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.bltd--ui.bltd--menu .bltd--item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bltd--ui.bltd--menu .bltd--item {
  position: relative;
  vertical-align: middle;
  line-height: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  padding: .75em 1em;
  text-transform: none;
  font-weight: normal;
  -webkit-transition: background 0.1s ease, box-shadow 0.1s ease, color 0.1s ease;
  transition: background 0.1s ease, box-shadow 0.1s ease, color 0.1s ease;
}
.bltd--ui.bltd--menu .bltd--item.bltd--dropdown {
  padding: .75em 1.5em .75em 1em;
  position: relative;

}

/*--------------
  Text Content
---------------*/

.bltd--ui.bltd--menu .bltd--text.bltd--item > *,
.bltd--ui.bltd--menu .bltd--item > a:not(.bltd--ui),
.bltd--ui.bltd--menu .bltd--item > p:only-child {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;

}
.bltd--ui.bltd--menu .bltd--item > p:first-child {
  margin-top: 0;
}
.bltd--ui.bltd--menu .bltd--item > p:last-child {
  margin-bottom: 0;
}


/* Dropdown Icon */
.bltd--ui.bltd--menu .bltd--item > i.bltd--dropdown.bltd--icon {
  padding: 0;
  float: right;
  margin: 0 0 0 .75em;
}

/* Menu */
.bltd--ui.bltd--menu .bltd--dropdown.bltd--item .bltd--menu {
  left: 0;
  border-radius: 0;
  background: #FFFFFF;
  margin: 0 0 0;
  box-shadow: 0 0.625em 0.625em rgba(0, 0, 0, 0.1);
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  border: 1px solid #e4e4e4;
  max-width: 17.85714em;
  white-space: normal;

  @include media("<460px") {
    max-width: 100%;
    width: 100%;
  }
}

/* Menu Items */
.bltd--ui.bltd--menu .bltd--ui.bltd--dropdown .bltd--menu > .bltd--item {
  margin: 0;
  text-align: left;
  /* padding: .5em 1em !important; */
  padding: 0em 0em 1em 0em !important;
  background: transparent !important;
  text-transform: none !important;
  font-weight: normal !important;
  box-shadow: none !important;
  -webkit-transition: none !important;
  transition: none !important;
  white-space: normal;
  width: 100%;
  line-height: 1.5;
  a {
    text-decoration: none;
  }
}

.bltd--ui.bltd--menu .bltd--ui.bltd--dropdown.bltd--item .bltd--menu .bltd--item {
  display: block;
}
.bltd--ui.bltd--menu .bltd--ui.bltd--dropdown.bltd--item .bltd--menu .bltd--item:last-child {
  padding-bottom: .25em !important;
}
.bltd--ui.bltd--menu .bltd--ui.bltd--dropdown .bltd--menu > .bltd--item .bltd--icon {
  display: inline-block;
  font-size: 1em !important;
  float: none;
  margin: 0 0.75em 0 0;
}

/* Secondary */

.bltd--ui.bltd--secondary.bltd--menu .bltd--dropdown.bltd--item > .bltd--menu,
.bltd--ui.bltd--text.bltd--menu .bltd--dropdown.bltd--item > .bltd--menu {
  /*border-radius: $border-radius;*/

}

/* Evenly Divided */
.bltd--ui.bltd--item.bltd--menu .bltd--dropdown .bltd--menu .bltd--item {
  width: 100%;
}


/*--------------
     Images
---------------*/

.bltd--ui.bltd--menu .bltd--item > img:not(.bltd--ui) {
  display: inline-block;
  vertical-align: middle;
  margin: -0.3em 0;
  width: 2.5em;
}

/*--------------
      Hover
---------------*/

.bltd--ui.bltd--link.bltd--menu .bltd--item:hover,
.bltd--ui.bltd--menu .bltd--dropdown.bltd--item:hover,
.bltd--ui.bltd--menu .bltd--link.bltd--item:hover,
.bltd--ui.bltd--menu a.bltd--item:hover {
  cursor: pointer;
}

/*--------------
     Pressed
---------------*/

.bltd--ui.bltd--link.bltd--menu .bltd--item:active,
.bltd--ui.bltd--menu .bltd--link.bltd--item:active,
.bltd--ui.bltd--menu a.bltd--item:active {
  background: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
     Active
---------------*/

.bltd--ui.bltd--menu .active.bltd--item {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
  font-weight: normal;
  box-shadow: none;
}
.bltd--ui.bltd--menu .active.bltd--item > i.bltd--icon {
  opacity: 1;
}

/*--------------
  Active Hover
---------------*/

.bltd--ui.bltd--menu .active.bltd--item:hover,
.bltd--ui.vertical.bltd--menu .active.bltd--item:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
     Disabled
---------------*/

.bltd--ui.bltd--menu .bltd--item.disabled,
.bltd--ui.bltd--menu .bltd--item.disabled:hover {
  cursor: default;
  background-color: transparent !important;
  color: rgba(40, 40, 40, 0.3);
}


/*******************************
             Types
*******************************/



/* Left Floated */
.bltd--ui.bltd--menu:not(.vertical) .left.bltd--item,
.bltd--ui.bltd--menu:not(.vertical) .left.bltd--menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto !important;
}

/* Right Floated */
.bltd--ui.bltd--menu:not(.vertical) .right.bltd--item,
.bltd--ui.bltd--menu:not(.vertical) .right.bltd--menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto !important;
}
.bltd--ui.bltd--menu:not(.vertical) .right.bltd--menu {
  right: 0;
  left: auto;
}
/* Swapped Borders */
.bltd--ui.bltd--menu .right.bltd--item::before,
.bltd--ui.bltd--menu .right.bltd--menu > .bltd--item::before {
  right: auto;
  left: 0;
}


/*--------------
   Secondary
---------------*/

.bltd--ui.bltd--secondary.bltd--menu {

}

/* Item */
.bltd--ui.bltd--secondary.bltd--menu .bltd--item {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  box-shadow: none;
  border: none;
  padding: .75em 0.9375em;

  background: none;
  -webkit-transition: color 0.1s ease;
  transition: color 0.1s ease;
  border-radius: $border-radius;

}

.bltd--ui.bltd--secondary.bltd--menu .bltd--item.bltd--dropdown {
  padding: .75em 1.5em .75em 1em;
  position: relative;
}
/* No Divider */
.bltd--ui.bltd--secondary.bltd--menu .bltd--item:before {
  display: none !important;
}

/* Header */
.bltd--ui.bltd--secondary.bltd--menu .header.bltd--item {
  border-radius: 0;
  border-right: none;
  background: none transparent;
}

/* Image */
.bltd--ui.bltd--secondary.bltd--menu .bltd--item > img:not(.bltd--ui) {
  margin: 0;
}

/* Hover */
.bltd--ui.bltd--secondary.bltd--menu .bltd--dropdown.bltd--item:hover,
.bltd--ui.bltd--secondary.bltd--menu .bltd--link.bltd--item:hover,
.bltd--ui.bltd--secondary.bltd--menu a.bltd--item:hover {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
}

/* Active */
.bltd--ui.bltd--secondary.bltd--menu .active.bltd--item {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
  border-radius: $border-radius;
}

/* Active Hover */
.bltd--ui.bltd--secondary.bltd--menu .active.bltd--item:hover {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
}

/* Fix item margins */
.bltd--ui.bltd--secondary.bltd--item.bltd--menu {
  margin-left: 0;
  margin-right: 0;
}
.bltd--ui.bltd--secondary.bltd--item.bltd--menu .bltd--item:last-child {
  margin-right: 0;
}
.bltd--ui.bltd--secondary.attached.bltd--menu {
  box-shadow: none;
}

/* Sub Menu */
.bltd--ui.vertical.bltd--secondary.bltd--menu .bltd--item:not(.bltd--dropdown) > .bltd--menu {
  margin: 0 -1em;
}
.bltd--ui.vertical.bltd--secondary.bltd--menu .bltd--item:not(.bltd--dropdown) > .bltd--menu > .bltd--item {
  margin: 0;
  padding: 0.5em 1.33333333em;
}

/*--------------
    Text Menu
---------------*/

.bltd--ui.bltd--text.bltd--menu {
  background: none transparent;
  border-radius: 0;
  box-shadow: none;
  border: none;
  /* margin: 1em -0.5em;*/
}
.bltd--ui.bltd--text.bltd--menu .bltd--item {
  border-radius: 0;
  box-shadow: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  margin: 0 0;
  padding: 0.35714286em 0.5em;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
}

/* Border */
.bltd--ui.bltd--text.bltd--menu .bltd--item:before,
.bltd--ui.bltd--text.bltd--menu .bltd--menu .bltd--item:before {
  display: none !important;
}

/*--- fluid text ---*/

.bltd--ui.bltd--text.bltd--item.bltd--menu .bltd--item {
  margin: 0;
}


/*--- hover ---*/

.bltd--ui.bltd--text.bltd--menu .bltd--item:hover {
  opacity: 1;

}

/*--- active ---*/

.bltd--ui.bltd--text.bltd--menu .active.bltd--item {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.95);
}

/*--- active hover ---*/

.bltd--ui.bltd--text.bltd--menu .active.bltd--item:hover {
  background-color: transparent;
}


.bltd--ui.bltd--text.attached.bltd--menu {
  box-shadow: none;
}

/* Fluid */
.bltd--ui.fluid.bltd--text.bltd--menu {
  margin-left: 0;
  margin-right: 0;
}

/*--------------
    Icon Only
---------------*/


/* Vertical Menu */
.bltd--ui.vertical.bltd--icon.bltd--menu {
  display: inline-block;
  width: auto;
}

/* Item */
.bltd--ui.bltd--icon.bltd--menu .bltd--item {
  height: auto;
  text-align: center;
  color: #1B1C1D;
}

/* Icon */
.bltd--ui.bltd--icon.bltd--menu .bltd--item > .bltd--icon:not(.bltd--dropdown) {
  margin: 0;
  opacity: 1;
}

/* Icon Gylph */
.bltd--ui.bltd--icon.bltd--menu .bltd--icon:before {
  opacity: 1;
}

/* (x) Item Icon */
.bltd--ui.bltd--menu .bltd--icon.bltd--item > .bltd--icon {
  width: auto;
  margin: 0 auto;
}

/* Vertical Icon */
.bltd--ui.vertical.bltd--icon.bltd--menu .bltd--item > .bltd--icon:not(.bltd--dropdown) {
  display: block;
  opacity: 1;
  margin: 0 auto;
  float: none;
}


/*--------------
   Labeled Icon
---------------*/


/* Menu */
.bltd--ui.labeled.bltd--icon.bltd--menu {
  text-align: center;
}

/* Item */
.bltd--ui.labeled.bltd--icon.bltd--menu .bltd--item {
  min-width: 6em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

/* Icon */
.bltd--ui.labeled.bltd--icon.bltd--menu .bltd--item > .bltd--icon:not(.bltd--dropdown) {
  height: 1em;
  display: block;
  font-size: 1.71428571em !important;
  margin: 0 auto 0.5rem !important;
}

/* Fluid */
.bltd--ui.fluid.labeled.bltd--icon.bltd--menu > .bltd--item {
  min-width: 0;
}


/*******************************
           Variations
*******************************/




/*--------------
     Floated
---------------*/

.bltd--ui.floated.bltd--menu {
  float: left;
  margin: 0rem 0.5rem 0rem 0rem;
}
.bltd--ui.floated.bltd--menu .bltd--item:last-child:before {
  display: none;
}
.bltd--ui.right.floated.bltd--menu {
  float: right;
  margin: 0rem 0rem 0rem 0.5rem;
}



/*--------------
   Borderless
---------------*/

.bltd--ui.borderless.bltd--menu .bltd--item:before,
.bltd--ui.borderless.bltd--menu .bltd--item .bltd--menu .bltd--item:before,
.bltd--ui.bltd--menu .borderless.bltd--item:before {
  background: none !important;
}

/*-------------------
       Compact
--------------------*/

.bltd--ui.compact.bltd--menu {
  /*display: -webkit-inline-box;*/
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0;
  vertical-align: middle;
}


/*-------------------
      Evenly Sized
--------------------*/

.bltd--ui.bltd--item.bltd--menu,
.bltd--ui.bltd--item.bltd--menu .bltd--item {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  text-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.bltd--ui.bltd--item.bltd--menu .bltd--item:last-child:before {
  display: none;
}
.bltd--ui.bltd--menu.two.bltd--item .bltd--item {
  width: 50%;
}
.bltd--ui.bltd--menu.three.bltd--item .bltd--item {
  width: 33.333%;
}
.bltd--ui.bltd--menu.four.bltd--item .bltd--item {
  width: 25%;
}
.bltd--ui.bltd--menu.five.bltd--item .bltd--item {
  width: 20%;
}
.bltd--ui.bltd--menu.six.bltd--item .bltd--item {
  width: 16.666%;
}
.bltd--ui.bltd--menu.seven.bltd--item .bltd--item {
  width: 14.285%;
}
.bltd--ui.bltd--menu.eight.bltd--item .bltd--item {
  width: 12.500%;
}
.bltd--ui.bltd--menu.nine.bltd--item .bltd--item {
  width: 11.11%;
}
.bltd--ui.bltd--menu.ten.bltd--item .bltd--item {
  width: 10.0%;
}
.bltd--ui.bltd--menu.eleven.bltd--item .bltd--item {
  width: 9.09%;
}
.bltd--ui.bltd--menu.twelve.bltd--item .bltd--item {
  width: 8.333%;
}

.bltd--ui.bltd--secondary.bltd--pointing.bltd--menu {
  margin-left: 0em;
  margin-right: 0em;
  margin-bottom: $px2;

}
.bltd--ui.bltd--secondary.bltd--pointing.bltd--menu .bltd--item {
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-radius: 0em;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin: 0em 2.5em -2px 0;
  padding: 1.25em 0 .875em 0;
  border-bottom-width: 2px;
  -webkit-transition: color 0.1s ease;
  transition: color 0.1s ease;
}

/* Item Types */
.bltd--ui.bltd--secondary.bltd--pointing.bltd--menu .header.bltd--item {
  color: rgba(0, 0, 0, 0.85) !important;
}
.bltd--ui.bltd--secondary.bltd--pointing.bltd--menu .bltd--text.bltd--item {
  box-shadow: none !important;
}
.bltd--ui.bltd--secondary.bltd--pointing.bltd--menu .bltd--item:after {
  display: none;
}

/* Hover */
.bltd--ui.bltd--secondary.bltd--pointing.bltd--menu .bltd--dropdown.bltd--item:hover,
.bltd--ui.bltd--secondary.bltd--pointing.bltd--menu .bltd--link.bltd--item:hover,
.bltd--ui.bltd--secondary.bltd--pointing.bltd--menu a.bltd--item:hover {
  background-color: transparent;
  color: #3f88cc;
}

/* Pressed */
.bltd--ui.bltd--secondary.bltd--pointing.bltd--menu .bltd--dropdown.bltd--item:active,
.bltd--ui.bltd--secondary.bltd--pointing.bltd--menu .bltd--link.bltd--item:active,
.bltd--ui.bltd--secondary.bltd--pointing.bltd--menu a.bltd--item:active {
  background-color: transparent;
  border-color: rgba(34, 36, 38, 0.15);
}

/* Active */
.bltd--ui.bltd--secondary.bltd--pointing.bltd--menu .active.bltd--item {
  background-color: transparent;
  box-shadow: none;
  border-color: #3f88cc;
  font-weight: $font-800;

  > a {
    color: #4A4A4A;
  }
}

/* Active Hover */
.bltd--ui.bltd--secondary.bltd--pointing.bltd--menu .active.bltd--item:hover {
  border-color: #3f88cc;

}

/* Active Dropdown */
.bltd--ui.bltd--secondary.bltd--pointing.bltd--menu .active.bltd--dropdown.bltd--item {
  border-color: transparent;
}



.bltd--ui.vertical.bltd--menu {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #FFF;
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
}
.bltd--ui.vertical.bltd--menu .bltd--item {
  display: block;
  background: 0 0;
  border-top: none;
  border-right: none;
  padding: 0em 0em $px20 0em !important;
}
.bltd--ui.vertical.bltd--menu .pre {
  margin-top: $px30;
  margin-bottom: 0;
}
.bltd--ui.vertical.bltd--menu .pre .bltd--text{

  color: #83949e;
  font-size: $px14;
  line-height: $px20;
}
.bltd--ui.vertical.bltd--menu .bltd--divider {
  border-top: 1px solid rgba(223, 223, 224, 0.5);
  height: 0;
  padding-bottom: 1em;
}
.bltd--ui.bltd--inverted.bltd--menu .bltd--item, .bltd--ui.bltd--inverted.bltd--menu .bltd--item>a:not(.bltd--ui) {
  background: 0 0;
  color: #fff;
}
.bltd--ui.bltd--inverted.bltd--menu {
  border: 0 solid transparent;
  background-color: #0e2e43;
  box-shadow: none;
}