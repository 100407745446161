.bltd--progress {
  height: 0.25em;
  margin-bottom: 0;
  overflow: hidden;
  background-color: #e0dfda;
  @include border-radius(3px 3px 0 0);
}

.bltd--ui-progress {
  pointer-events: none;
  position: relative;
}

.bltd--ui-progress-bar {
  background-color: var(--base-green-color);
  @include border-radius(3px 3px 0 0);
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  /* height: 2px; */
  height: 0.25em;
}

/*
.ui-progress .ui-progress-barShadow {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}*/
