::-ms-clear { display: none; }
::-ms-reveal {
  display: none;
}
.bltd--page--checkout {
  .bltd--agree {
    margin: .625em 0;
  }
  .bltd--list-footer {
    .big {
      width: 100%;
    }
  }

  .bltd--input--bayer {
    margin: 0 0 1.05em 0;
    .bltd--notes {
      font-size: 0.8125em;
      font-weight: normal;
      margin: .5em 0;
    }

  }
  .bltd--ui.bltd--form .bltd--fields {
    justify-content: space-between;
    flex-wrap: wrap;
    &--two {
      justify-content: flex-start;
      display: flex;
      width: 50%;
      > .bltd--field {
        &#rate {
          max-width: 100%;
          flex: 50%;
          width: 100%;
        }
        &#seat {
          max-width: 33.3333%;
          flex: 33.3333%;
          width: 100%;
        }
      }
    }
  }
  .bltd--baggage {
    margin-top: 25px;
    &:first-child {
      margin-top: 15px;
    }
  }
  .bltd--ui.bltd--form .bltd--field.string {
    max-width: 100%;
    width: 100%;
    flex: 100%;
  }
  .bltd--ui.bltd--form .bltd--field.identificationdocument,
  .bltd--ui.bltd--form .bltd--field.contactinformation {
    max-width: 100%;
    width: 100%;
    flex: 100%;
    display: flex;
    > .bltd--field {
      padding-left: 0.5em;
      padding-right: 0.5em;
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
      }
    }
  }
  .bltd--ui.bltd--form .bltd--field.valuelist,
  .bltd--ui.bltd--form .bltd--field.bltd--date {
    max-width: 33.3333%;
    width: 100%;
    flex: 33.3333%;
  }
  .bltd--ui.bltd--form .fields .bltd--field:first-child {
    padding-left: 0.5em;
  }
  .bltd--ui.bltd--form .fields .bltd--field:last-child {
    padding-right: 0.5em;
  }
  #insurance {
    max-width: 30%;
    width: 100% !important;
    -webkit-flex: 33.3333%;
    -ms-flex: 33.3333%;
    flex: 25%;
  }
  #bltd--cost {
    min-width: 20% !important;
    width: unset !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .bltd--icon.bltd--delete {
      margin-right: 22px;
      cursor: pointer;
      background: url('../img/icons/icon_trash_gray.png') center no-repeat;
      width: 15px;
      height: 16px;
      display: block;
    }
    .bltd--ui.bltd--tag.bltd--labels .bltd--label,
    .bltd--ui.bltd--tag.bltd--label {
      margin-right: 0 !important;
      padding-left: 1em !important;
      padding-right: 1em !important;
      margin-bottom: 0.3em;
    }
  }
  .bltd--bag--label {
    min-width: 20% !important;
    width: unset !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .bltd--icon.bltd--delete {
      margin-right: 22px;
      cursor: pointer;
      background: url('../img/icons/icon_trash_gray.png') center no-repeat;
      width: 15px;
      height: 16px;
      display: block;
    }
    .bltd--ui.bltd--tag.bltd--labels .bltd--label,
    .bltd--ui.bltd--tag.label {
      margin-right: 0 !important;
      padding-left: 1em !important;
      padding-right: 1em !important;
    }
  }
  @media only screen and (max-width: 991px) {
    #rate {
      min-width: 50% !important;
    }
    #seat {
      min-width: 50% !important;
      padding-right: 0 !important;
    }
    #insurance {
      min-width: 70% !important;
      width: unset !important;
    }
    #cost {
      min-width: 30% !important;
      width: unset !important;
      .bltd--ui.tag.labels .label,
      .bltd--ui.tag.label {
        margin-right: 0 !important;
      }
    }
    .bltd--ui.bltd--form .fields {
      flex-wrap: wrap;
    }
  }
  @media only screen and (max-width: 767px) {
    #rate,
    #seat,
    #insurance,
    #cost {
      width: 100% !important;
    }
  }
  font-size: 1.6rem;
  .bltd--container {
    position: relative;
  }

  .bltd--major {
    position: relative;
    width: 65%;
    margin-right: 35%;
    margin-bottom: 35px;
  }

  .affix {
    z-index: 990;
  }

  aside {
    position: absolute;
    top: 0;
    right: 0;
    float: left;
    clear: right;
    width: 32%;

    .bltd--aside-wrapper {
      padding: 1.25em;
      margin-bottom: 35px;
      background: #F3F3F3;
      border-radius: $px4;
    }

    .bltd--h4 {
      font-size: 1.125em;
      font-weight: $font-800;
      margin: 0 0 .8125em 0;
    }

    .bltd--waypoint {
      .bltd--point {
        margin-left: 2.5em;
        clear: both;
        margin-bottom: 1.75em;
        &.bltd--point--departure {
          &:before {
            content: ' ';
            /* height: 100%; */
            border-right: 2px dotted #C9C9C9;
            width: 1px;
            display: block;
            position: absolute;
            left: -2.1em;
            margin-top: 18px;
            bottom: -1.5em;
            top: 0.15em;
          }
        }
        &.bltd--point--departure,
        &.bltd--point--destination,
        &.bltd--point--date {
          position: relative;
          &:after {
            background: none;
            width: 16px;
            height: 16px;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            content: ' ';
            top: 0px;
            /* margin-top: -10px; */
            display: block;
            position: absolute;
            left: -2em;
            border: 4px solid var(--route-way-color);
            margin-left: -8px;
          }
        }
        &.bltd--point--date {
          &:after {
            border-width: 2px;
          }
          &:before {
            content: '';
            border: 2px solid var(--route-way-color);
            position: absolute;
            width: 5px;
            height: 5px;
            border-top: 0;
            border-right: 0;
            left: -2.1em;
            top: 0.28em;
          }
        }
        .bltd--value {
          font-weight: $font-800;
        }
        .bltd--subl {
          font-size: .8125em;
          word-break: break-word;
        }
      }
    }
    .bltd--cost {
      .bltd--calculator {
        list-style: none;
        margin: 0 0 1em;
        padding: 0;
        li {
          display: block;
          margin-bottom: 0;
          overflow: hidden;
          font-size: .85em;
          .bltd--add_coupon{
            color: var(--base-link-color);
          }
          .bltd--t {
            float: left;
            clear: both;
            margin-bottom: .5em;
            .bltd--desc {
            }
          }
          .bltd--cost {
            float: right;
          }
          &.bltd--total {
            padding-top: .5em;
            border-top: 1px solid #E8E8E8;
            font-weight: $font-800;
            font-size: 1.1em;
            .bltd--fees {
              clear: both;
              padding-top: 8px;
              font-size: 0.8em;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
  article {
    background: #f8f7f0;
    padding: 1.25em 1.25em;
    border-radius: .125em;
    margin-bottom: 1.25em;
    position: relative;
    .bltd--del-ticket-btn {
      position: absolute;
      top: 17px;
      right: 25px;
      display: block;
      color: #767674;
      width: 15px;
      height: 16px;
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background: #767674;
      }
      &:before {
        transform: translate(50%, 50%) rotate(45deg)
      }
      &:after {
        transform: translate(50%, 50%) rotate(-45deg)
      }
    }
  }

  .bltd--sbpay .checkbox {
    label {
      font-size: 0.8125em;
    }
    .bltd--pseudo-link {
      display: inline;
      font-size: inherit;
    }
  }
}

.str0 {
  stroke: #e5e4de;
  stroke-width: 1
}

.fil0 {
  fill: none
}

.fil1 {
  fill: #e5e4de
}

.fnt0 {
  font-weight: normal;
  color: #e5e4de
}

/*  $BUS PLACES
  ------------------------------------ */

#bltd--bus-scheme {
  position: relative;
  text-align: center;
}

.bltd--bus-model {
  margin-top: 16px;
  text-align: center;
  .bltd--model {
    font-size: .875em;
    font-weight: bold;
  }
}

.bltd--aligner {

  padding: 26px 60px 26px 26px;

  display: flex;
  flex-direction: row;
  width: 100%;

  .bltd--aligner--wrapper {
    margin: auto;
    width: 100%;
    height: 100%;
  }
}
.bltd--page--checkout {

  .bltd--ticket--heading, .bltd--baggage-heading, .bltd--service--heading {
    padding-bottom: 0.875em;
    h4 {
      margin: 0;
      padding: 0;
      font-size: 1.125em;
      font-weight: 600;
    }
    h5 {
      margin: 0;
      padding: 0;
      font-size: 1em;
      font-weight: 600;
    }
  }
  .bltd--tag--label {
    margin-top: 24px;
    width: 33%;
    overflow: hidden;
    margin-left: auto;
    .bltd--tag--label-val {
      float: right;
    }
  }
  .bltd--wrap {
    padding-top: 0;
  }
  .bltd--list-service{
    background: #f8f8f8;
    padding: 1.25em 1.25em;
    border-radius: .125em;
    margin-bottom: 1.25em;
    position: relative;
  }

  .bltd--expand-link {
    &.bltd--expand--decorator {
      display: block;
    }
    color: var(--base-link-color);
    cursor: pointer;
    position: relative;
    overflow: hidden;

    display: inline-block;
    text-overflow: ellipsis;
    white-space: normal;
    /*line-height: 1;*/

    .bltd--txt {
      text-overflow: ellipsis;
      white-space: normal;
      display: block;
      overflow: hidden;
      /* max-width: 80%; */
      margin-right: 1.5em;
    }
    .bltd--ico {
      position: absolute;
      right: 0;
      top: 0;
      width: 1em;
      height: 1em;
      background: #fff;
    }

  }

  .bltd--icon--expand {
    &:after {
      content: '';
      position: absolute;
      height: 7px;
      width: 7px;
      transform: rotate(45deg);
      border: 2px solid var(--base-link-color);
      border-top: none;
      border-left: none;
      top: 5px;
      left: 4px;
    }
  }

  //horizontal
  .bltd--scheme {
    display: inline-block;
    margin: 0 auto;
    border: 1px solid #cdcdcd;
    border-radius: 15px 50px 50px 15px;
    position: relative;
    background: #fff;
    /*width: 100%;*/
    &:before {
      content: '';
      border: 1px solid #cdcdcd;
      width: 17px;
      height: 45px;
      display: block;
      position: absolute;
      right: 8px;
      border-radius: 50%;
      -webkit-transform: rotate(-10deg);
      transform: rotate(-10deg);
      top: 18px;
    }
    &:after {
      content: '';
      border: 1px solid #cdcdcd;
      width: 17px;
      height: 45px;
      display: block;
      position: absolute;
      right: 8px;
      bottom: 18px;
      border-radius: 50%;
      -webkit-transform: rotate(10deg);
      transform: rotate(10deg);
    }
    .bltd--places {
      white-space: nowrap;
      display: flex;
      flex-direction: row-reverse;
      flex: 1 0 100%;
      justify-content: center;
      .bltd--place {
        display: flex;
        flex-direction: column;
        flex: 1 0 0;
        /*justify-content: center;*/
        max-width: 36px;
      }
      .bltd--places__item {
        border: 1px solid var(--base-green-color);
        display: block;
        border-radius: 0.25em;
        /*width: 35px;
        height: 35px;
        text-align: center;
        line-height: 35px;*/
        font-weight: bold;
        color: var(--base-green-color);
        margin: 2px;
        cursor: pointer;
        position: relative;
        font-size: 1em;

        max-width: 32px;
        /*min-width: 28px;*/
        /* flex: 1 0 auto;*/


        &::after {
          content: "";
          float:left;
          display: block;
          padding-top: 100%;
        }
        .bltd--item--cell {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &.bltd--occupied {
          background: #eaeaea;
          border-color: #eaeaea;
          cursor: default;
          color: #eaeaea;
        }
        &.bltd--by-ticket {
          background: var(--base-green-color);
          border-color: var(--base-green-color);
          color: #fff;
          cursor: pointer;
        }
        &.bltd--empty {
          border: none;
          cursor: default;
        }
        .bltd--moved {
          position: absolute;
          top: -1px;
          bottom: -1px;
          left: -1px;
          right: -1px;
          background-color: #fff;
          opacity: 0.3;
        }
        .bltd--select-passenger-tooltip {
          cursor: default;
          background: #fff;
          z-index: 100;
          text-align: left;
          width: 100%;
          position: absolute;
          top: -1px;
          left: -1px;
          right: 0;
          bottom: 0;
          &__wrap{
            @include media('<=tablet') {
              position: fixed;
              left: 0;
              right: 0;
              height: auto;
              text-align: center;
            }
          }
          &__content {
            display: inline-block;
            max-height: 243px;
            overflow-x: hidden;
            background: #fff;
            border: 1px solid #e5e4de;
            cursor: default;
            text-align: left;
            > a {
              white-space: nowrap;
              margin-right: 20px;
              font-weight: normal;
              display: block;
              color: #4a4a4a;
              line-height: 150%;
              font-size: 14px;
              margin-top: 0;
              padding: 6px 10px;
              &:first-child {
                margin-top: 0;
              }
            }
            .bltd--select-passenger-tooltip__add-btn {
              color: var(--base-green-color) !important;
              font-weight: bold !important;
            }
          }
        }
      }
    }
  }


}

form.ng-submitted {
  .bltd--labeled--field {
    input {
      &.ng-invalid {
        border-color: #dd4b39 !important;
        color: #dd4b39 !important;
        box-shadow: 0 0 0 0.125em #f8dbd7, 0 0 0 1px #e5e4de inset !important
      }

      &[disabled] {
        color: #ddd;
      }
    }
  }
}

.ng-submitted .bltd--aside-wrapper {
  input {
    &.ng-invalid {
      border-color: #dd4b39 !important;
      color: #dd4b39 !important;
      box-shadow: 0 0 0 0.125em #f8dbd7, 0 0 0 1px #e5e4de inset !important
    }
  }
  .bltd--sbpay .checkbox input.ng-invalid + label {
    background-color: #f8dbd7 !important;
  }
}
.bltd--labeled--field, .bltd--aside-wrapper{
  input {
    &.ng-touched.ng-invalid {
      border-color: #dd4b39 !important;
      color: #dd4b39 !important;
      box-shadow: 0 0 0 0.125em #f8dbd7, 0 0 0 1px #e5e4de inset !important

    }
    &.ng-dirty.ng-invalid {
      border-color: #dd4b39 !important;
      color: #dd4b39 !important;
      box-shadow: 0 0 0 0.125em #f8dbd7, 0 0 0 1px #e5e4de inset !important
    }
    &[disabled] {
      color: #ddd;
    }
  }
}

@include media("<=tablet") {
  .bltd--page--checkout {
    //vertical
    .bltd--scheme {
      width: auto;
      min-width: 80%;
      .bltd--aligner {
        padding: 60px 26px 26px 26px;
        min-height: 360px;
        min-width: 0;
        margin-right: 0;
      }
      .bltd--places {
        justify-content: center;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        .bltd--place {
          flex-direction: row;
          flex: 1 0;
          max-width: 100%;
          max-height: 36px;
          justify-content: center;
        }
        .bltd--places__item {
          flex: 1 0 auto;
        }
      }
      .bltd--right {
        float: none;
      }

      border-radius: 50px 50px 15px 15px;
      &:before {

        left: 32px;
        right: auto;
        margin-top: 0;
        border-radius: 50%;
        transform: rotate(-106deg);
        top: -8px;
      }

      &:after {
        left: auto;
        bottom: auto;
        right: 32px;
        top: -8px;
        transform: rotate(106deg);
      }
    }
    .bltd--wrap {
      padding-left: 0em;
      padding-right: 0em;
      padding-top: 0;
      padding-bottom: 0;
    }
    .bltd--major {

      width: 100%;
      margin-bottom: 0;
    }
    .bltd--checkout--block_summary {
      position: relative;
      top: auto;
      right: auto;

      float: none;
      clear: right;
      width: 100%;
      display: none;
    }

    .bltd--checkout--block {

      margin-bottom: 0;
      &.bltd--checkout--block_seat {
        border: none;

        padding: 1.25em 1.25em;
        padding-top: 0;
      }
      &.checkout--block_ticket {
        .bltd--flex--ticket {
          .bltd--flex--ticket-item {
            &.halfed {
              margin-bottom: 0;
            }
            .bltd--half--wrapper {
              margin-bottom: 0;

              display: block;
              .bltd--half {
                width: 100%;
                display: block;
                margin-bottom: 1em;
              }

            }

          }
        }
      }
    }

    .bltd--expand--back {
      font-size: 1.25em;
      padding: 1em 1em;
      background: #fff;
      .bltd--note {
        color: #cfcfcf;
        font-weight: normal;
      }
      .bltd--expand--nav {
        position: relative;
        color: #4a4a4a;
        display: block;
        padding-left: 1.625em;
        font-weight: 600;
        /* line-height: 1.5em; */
        &::before {
          content: '';
          position: absolute;
          height: 9px;
          width: 9px;
          transform: rotate(51deg) skewX(10deg);
          border: 2px solid var(--base-link-color);
          border-top: none;
          border-right: none;
          top: 5px;
          left: 8px;
        }
      }
    }

    .bltd--expand--seat {

      .bltd--checkout--block_seat {

        .bltd--seat--scheme {
          display: block;
          position: fixed;
          top: 0;
          background: #f8f7f0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2000;
          overflow: scroll;
          margin: 0;
        }
        padding: 0;
        #bltd--bus-scheme{
          padding: 1.25em;
        }
      }
    }
    .bltd--expand--summary {
      .bltd--major {
        display: none;
      }
      .bltd--checkout--block_summary {

        display: block;
        /*position: fixed;
        top: 0;*/
        margin-top: -3.75em;
        background: #F3F3F3;
        /*left: 0;
        width: 100%;
        height: 100%;
        z-index: 2000;
        overflow: scroll;
        margin: 0;*/
        .bltd--aside-wrapper {
          padding: 0;
          margin-bottom: 0;
        }
        .bltd--symmary--content {
          padding: 1.125em 1.25em;
        }
      }
    }

    .bltd--tag--label {
      display: none;
    }

    .bltd--summary--button {
      .bltd--summary--text {
        text-align: left;
        margin-bottom: 1.25em;
        .bltd--text {
          font-size: 14px;
        }
      }
      background-color: #f3f3f3;
      text-align: center;
      padding: 1.25em 1.25em;
    }
  }

}

  //.chekout--block_ticket {
  .bltd--labeled--field {
    input {
      &.ng-dirty.ng-invalid {
        border-color: #dd4b39 !important;
        color: #dd4b39 !important;
        box-shadow: 0 0 0 0.125em #f8dbd7, 0 0 0 1px #e5e4de inset !important

      }
      &[disabled] {

        color: #ddd;

      }
    }
  }
  //}

.bltd--checkout--block {

  margin-bottom: 24px;
  .bltd--counter {
    font-size: 14px;
    color: #cfcfcf;

  }
  .bltd--note {
    font-size: .875em;

  }
  &.bltd--checkout--block_seat {
    //background: #f8f7f0;
    border: 2px solid #f3f3f3;
    background: #fff;
    border-radius: 3px;
    padding: 18px;
    .bltd--seat--heading {

      position: relative;

      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .bltd--seat--count {

      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-right: 114px;
      width: 100%;

      .bltd--seat--count_bus {
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        padding-right: 1em;
        position: relative;
      }
      .bltd--seat--count_counter {
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        padding-right: 1em;
        position: relative;

      }
      .bltd--seat--count_note {
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        text-align: right;
        padding-right: 1em;

        position: relative;
      }
    }
    .bltd--seat--submit {
      //width: 10rem;
      width: 114px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
    .bltd--seat--scheme {
      margin-top: 26px;
    }
  }
}
.bltd--flex--service-item{
  label {
    margin-bottom: .25em;
    display: block;
    span {
      display: block;
      font-size: 0.8125em;
      text-transform: none;
    }
  }
  .bltd--placeholder--error {
    display: block;
    font-size: 0.8125em;
    text-transform: none;
    margin-top: .25em;
    color: #dd4b39 !important;
  }
  input {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    margin: 0;
    outline: none;
    -webkit-appearance: none;
    tap-highlight-color: rgba(255, 255, 255, 0);
    height: 2.5em;
    padding: 0em 0.5em;
    font-size: 1em;
    background: #FFFFFF;
    border: 1px solid #e5e4de;
    color: #4a4a4a;
    border-radius: 3px;
    box-shadow: 0 0 0 1px #e5e4de;
    -webkit-transition: color 0.1s ease, border-color 0.1s ease;
    transition: color 0.1s ease, border-color 0.1s ease;
    width: 100%;
    &[disabled] {
      color: #bdbdbd;
    }
    &.bltd--error {
      border-color: #dd4b39 !important;
      color: #dd4b39 !important;
      box-shadow: 0 0 0 0.125em #f8dbd7, 0 0 0 1px #e5e4de inset !important

    }
    &:focus {
      border-color: var(--base-green-color);
      box-shadow: 0 0 0 0.125em var(--base-focus-shadow), 0.0625em 0.0625em 0.0625em 0.0625em rgba(0, 0, 0, 0.05) inset;
    }
  }
}
.bltd--flex--ticket {
  .bltd--labeled--field {
    &.bltd--labeled--field-checkbox {
      .bltd--labeled--field-field {
        margin-top: 32px;
      }
    }

    &.bltd--labeled--field-account {
      .bltd--labeled--field-field {
        .bltd--input-container {
          .bltd--input--toggle {
            cursor: pointer;
            display: block;
            right: 1em;
            top: 50%;
            margin-top: -.625em;
            position: absolute;
            width: 1.25em;
            height: 1.25em;
            &:after {
              content: '';
              border: none;
              right: auto;
              left: auto;
              top: auto;
              bottom: auto;
              margin: 0;
              background-image: url(../img/icons/icons.svg);
              background-size: 10.5625em 17.3125em;
              position: absolute;
              width: 1.25em;
              height: 1.25em;
              background-position: -151px -59px;
            }
          }
        }
      }
    }
    label {
      margin-bottom: .25em;
      display: block;
      span {
        display: block;
        font-size: 0.8125em;
        text-transform: none;
      }
    }
    .bltd--placeholder--error {
      display: block;
      font-size: 0.8125em;
      text-transform: none;
      margin-top: .25em;
      color: #dd4b39 !important;
    }
    input {
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
      margin: 0;
      outline: none;
      -webkit-appearance: none;
      tap-highlight-color: rgba(255, 255, 255, 0);
      height: 2.5em;
      padding: 0 2em 0 0.5em;
      text-overflow: ellipsis;
      font-size: 1em;
      background: #FFFFFF;
      border: 1px solid #e5e4de;
      color: #4a4a4a;
      border-radius: 3px;
      box-shadow: 0 0 0 1px #e5e4de;
      -webkit-transition: color 0.1s ease, border-color 0.1s ease;
      transition: color 0.1s ease, border-color 0.1s ease;
      width: 100%;
      &[disabled] {
        color: #bdbdbd;
      }
      &.bltd--error {
        border-color: #dd4b39 !important;
        color: #dd4b39 !important;
        box-shadow: 0 0 0 0.125em #f8dbd7, 0 0 0 1px #e5e4de inset !important

      }
      &:focus {
        border-color: var(--base-green-color);
        box-shadow: 0 0 0 0.125em var(--base-focus-shadow), 0.0625em 0.0625em 0.0625em 0.0625em rgba(0, 0, 0, 0.05) inset;
      }
    }
  }

  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: -10px;
  margin-right: -10px;
  flex-wrap: wrap;
  align-items: flex-end;

  .bltd--flex--ticket-item {
    -ms-flex: 1 1 auto;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    margin-bottom: 1em;
    padding: 0 10px;

    &.bltd--w100 {
      width: 100%;
    }
    .bltd--half--wrapper {

      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;

      margin-left: -10px;
      margin-right: -10px;

      flex-wrap: wrap;

      .bltd--half {
        padding: 0 10px;
        -ms-flex: 1 1 auto;
        -webkit-box-flex: 1;
        flex: 1 1 auto;
        width: 50%;
        display: flex;
        align-items: flex-end;
        .bltd--labeled--field{
          flex: 1 1 auto;
        }
      }
    }
  }

  .bltd--labeled--field .bltd--icon--info {
    font-size: 1.6rem;
    cursor: pointer;
    display: block;
    margin-top: -1.25em;
    width: 1.25em;
    height: 1.25em;
    float: right;
    margin-right: 1em;

    &:after {
      content: '';
      border: none;
      right: auto;
      left: auto;
      top: auto;
      bottom: auto;
      margin: 0;
      background-image: url(../img/icons/icons.svg);
      background-size: 338px 554px;
      position: absolute;
      width: 1.25em;
      height: 1.25em;
      background-position: -217px -1085px;
    }
  }
}


#bltd--order-confirm {
  width: 100%;
}

.bltd--sbpay {
  .bltd--agree {
    font-size: 0.85em;
  }
  .bltd--sidebar-info {
    font-size: 0.8125em;
    margin-bottom: 20px;
  }
  .bltd--btn--sberpay {
    background-color: #21A038;

    .sberpay-icon {
      height: 26px;
      background-image: url(../img/payments/SberPay-logo-02.png);
      background-size: contain;
      width: 121px;
      margin: auto;
      background-repeat: no-repeat;
    }
  }
}

#bltd--order-sberpay {
  width: 100%;
  margin-bottom: 7px;
  height: 40px;
}

.bltd--sberpay-modal .bltd--sberpay-info {
  margin: 1em 0;
}

.bltd--send-push {
  .bltd--field.bltd--field--inline {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
  }
  .bltd--input-group {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: stretch;
    width: 100%;

    input[type="text"] {
      border-radius: 0 3px 3px 0;
    }
  }
  .bltd--field-addon {
    padding: 0.5em 0.5em;
    font-weight: 400;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #e5e4de;
    vertical-align: top;
    height: 2.5em;
    box-shadow: 0 0 0 1px #e5e4de;
    display: flex;
    align-items: center;
    border-radius: 3px 0 0 3px;
  }
  .bltd--btn {
    margin-top: 1em;
    width: 100%;
  }
}

@media only screen and (max-width: 459px) {
  .bltd--send-push {
    .bltd--input-group {
      width: 100%;
      justify-content: stretch;
      margin-right: 0;
    }
  }
  .bltd--field.bltd--field--inline {
    display: block;
  }
}

.blank-item {
  padding-bottom: 2em;
  h1.bltd--departure-time {
    margin-bottom: 0;
  }
  p {
    margin: 0;
    margin-top: 20px;
  }
  p.bltd--pseudo-header {
    margin-bottom: 10px;
  }
  &.bltd--without-h {
    padding-top: 1.5em;
  }

  &.bltd--without-h-arrival {
    padding-top: 2.2em;
  }
}

.bltd--lft {
  &.bltd--first {
    width: 34.5%;
    min-width: 20em;
  }
  &.bltd--second {
    width: 67%;
    min-width: 10em;
    padding-right: 3%;
  }
}

.bltd--rght {
  &.bltd--first {
    width: 65.5%;
    min-width: 25em;
    padding-left: 2.7em;
    box-sizing: border-box;
    .bltd--graph {
      display: block;
      position: absolute;
      height: 6em;
      width: 0;
      content: ' ';
      border-right: 2px dotted #C9C9C9;
      margin-left: -2.2em;
      margin-top: 2.25em;
    }
    .bltd--graph:before {
      background: none;
      width: 16px;
      height: 16px;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      content: ' ';
      margin-top: -16px;
      display: block;
      position: absolute;
      left: -7px;
      border: 3px solid #9b9b9b;
    }
    .bltd--graph:after {
      background: none;
      width: 16px;
      height: 16px;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      content: ' ';
      margin-bottom: -16px;
      display: block;
      position: absolute;
      left: -7px;
      bottom: 0;
      border: 3px solid #9b9b9b;
    }
  }
  &.bltd--second {
    width: 30%;
    min-width: 10em;
    font-size: 0.9em;
  }
}

.bltd--second {
  h4 {
    font-size: 1.25em;
    margin-top: 0.7em;
    margin-bottom: 0.4em;
  }
  p {
    margin: 0 0 0.3em;
  }
  .bltd--item {
    margin-bottom: 1em;
  }
  .bltd--cost-block div {
    width: 50%;
    padding-bottom: 0.5em;
  }
}

.bltd--ftr {
  border-top: 1px solid #efeff0;
  padding-top: 1em;
  width: 100%;
  margin-top: -0.5em;
}

.bltd--inf {
  margin-top: 1.8em;
}

.bltd--lgtp {
  margin-top: 7.4em;
  .bltd--green {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    color: var(--base-green-color);
    font-weight: 700;
    font-size: 2em;
  }
  .bltd--descr {
    padding: 1em 0;
  }
  .bltd--foot {
    font-size: 1.2em;
  }
}

.bltd--main_content.ordering {
  padding-top: 0;
  .bltd--ui.segment > .bltd--row.bltd--clearfix.closed {
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
}

@include media("<=tablet") {
  .bltd--checkout--block.bltd--checkout--block_ticket .bltd--flex--ticket .bltd--labeled--field.bltd--labeled--field-checkbox .bltd--labeled--field-field {
    margin-top: 8px;
  }
  .bltd--lft.bltd--first {
    min-width: 100%;
    width: 100%;
  }
  .bltd--rght.bltd--first {
    min-width: 100%;
    width: 100%;
  }
  .bltd--lft.bltd--second {
    width: 100%;
  }
  .bltd--rght.bltd--second {
    width: 100%;
  }
}

/**
 * For the correct positioning of the placeholder element, the dnd-list and
 * it's children must have position: relative
 */

ul[dnd-list],
ul[dnd-list] > li {
  position: relative;
}

[dnd-list] {
  min-height: 42px;
  padding-left: 0px;
}

[dnd-list] .dndDraggingSource {
  display: none;
}

[dnd-list] .dndPlaceholder {
  display: none;
}

//order spinner
aside div.bltd--is-loading {
  opacity: 0.45;
}

aside .bltd--order-panel-loading {
  margin-left: 30%;
  position: absolute;
  top: 50%;
  height: 100px;
  margin-top: -50px;
}

input {
  /*&.ng-dirty.ng-invalid {

    border-color: #dd4b39 !important;
    color: #dd4b39 !important;
    box-shadow: 0 0 0 0.125em #f8dbd7, 0 0 0 1px #e5e4de inset !important;

  }
  &:focus {
    border-color: #82b548;
    box-shadow: 0 0 0 0.125em rgba(130, 181, 72, 0.15), 0 0 0 1px #e5e4de inset;
  }*/
  &.bltd--input-typeahead {
    width: 100%;
    font-family: $base-font-family;
    margin: 0;
    outline: none;
    -webkit-appearance: none;
    tap-highlight-color: rgba(255, 255, 255, 0);
    //line-height: 2.5em;
    padding: 0em 0.5em;
    font-size: 1em;
    background: #FFFFFF;
    border: 1px solid #e5e4de;
    color: #4a4a4a;
    border-radius: 3px;
    box-shadow: 0 0 0 1px #e5e4de;
    -webkit-transition: color 0.1s ease, border-color 0.1s ease;
    transition: color 0.1s ease, border-color 0.1s ease;

    padding-right: 2em !important;
    &.ng-dirty.ng-invalid {

      border-color: #dd4b39 !important;
      color: #dd4b39 !important;
      box-shadow: 0 0 0 0.125em #f8dbd7, 0 0 0 1px #e5e4de inset !important;

    }
    &:focus {
      border-color: var(--base-green-color);
      box-shadow: 0 0 0 0.125em var(--base-focus-shadow), 0 0 0 1px #e5e4de inset;
    }

  }

}

.bltd--input-container {
  width: 100%;
  .bltd--input--toggle {

    cursor: pointer;
    display: block;
    right: 1em;
    top: 50%;
    margin-top: -.625em;

    position: absolute;
    width: 1.25em;
    height: 1.25em;
    &:after {
      /*content: '';
      background-image: url(../img/icons/icons.svg);
      background-size: 10.5625em 17.3125em;
      position: absolute;
      width: 1.25em;
      height: 1.25em;
      background-position: -151px -59px;*/

      color: #9a9a9a;
      border-left: .35em solid transparent;
      border-right: .35em solid transparent;
      border-top: .35em solid;
      content: '';
      right: 0;
      margin: 0 0 0 -0.5em;
      position: absolute;
      top: 50%;
      height: 0;
      width: 0;
      background-image: none;
    }
  }
}

.bltd--input-suggest {

  max-height: 240px;
  /* display: none;*/
  border: 1px solid #E4E4E4;
  box-shadow: 0 1em 1em 0 rgba(0, 0, 0, 0.10);
  background-color: #FFF;
  border-top: none;
  margin-top: 2px;
  left: 0;

  position: absolute;
  width: 100%;
  z-index: 1001;

  /*max-height: 20.85rem;*/
  overflow-y: auto;

  &:after {
    clear: both;
    content: '';
    display: table;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: rgba(255, 255, 255, 0);
    -webkit-border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--base-green-color);
    -webkit-border-radius: 100px;
  }
  .bltd--input-suggest-list {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }

  .bltd--input-suggest-item {
    cursor: pointer;
    &:hover {
      background: #f4f4f4
    }
    &.active {
      background: #f4f4f4;

    }

    a {
      &:hover {
        background: #f4f4f4
      }
      //&.bltd--active {
      //  background: #f4f4f4;
      //}

      display: block;
      /* font-size: .8125em;*/
      padding: .75em 1em;
      color: #e0dfda;
      text-decoration: none;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .bltd--name {
        color: #4a4a4a;
        white-space: nowrap;
        .bltd--match {
          font-weight: bold;

        }
      }
    }
  }
}

.bltd--input-suggest {
  &.ng-enter {
    -webkit-transition: opacity ease-in-out 0.1s;
    -moz-transition: opacity ease-in-out .1s;
    -ms-transition: opacity ease-in-out .1s;
    -o-transition: opacity ease-in-out .1s;
    transition: opacity ease-in-out .1s;
  }
  &.ng-enter {
    opacity: 0;
  }
  &.ng-enter.ng-enter-active {
    opacity: 1;
  }
  .bltd--default--v {
    font-weight: 600;
  }
}

.bltd--input-suggest-popup {

  padding: 1em;
  p {
    margin-bottom: 1em;
  }
  .bltd--suggest {
    position: relative;
    .bltd--suggest-wrapper {
      margin-right: 6.5rem;
      border: $px2 solid #e5e4de;
      border-right: 0;
      border-radius: $px3;
      input {
        width: 100%;
        border: 0;
        outline: 0;
        &.ng-dirty.ng-invalid-pattern {
          color: #dd4b39;
          &[disabled] {
            color: rgba(221, 75, 57, 0.45) !important;
          }
        }
        /* border: 2px solid #e5e4de; */
        /* border-radius: 4px; */
        /* box-shadow: inset 2px 2px 1px #ececec; */

        padding: 0.625em 0.9375em;
      }
    }
    .bltd--suggest-submit {
      width: 6.5rem;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      input {

        color: #fff;
        width: 100%;
        outline: none;
        height: 100%;
        border: none;
        font-weight: bold;
        border-radius: 0 0.125em 0.125em 0;
        background: var(--base-green-color);

        &[disabled] {
          cursor: default;
          opacity: 0.45 !important;
          /*box-shadow: none !important;*/
          pointer-events: none;
        }
      }
    }
  }
}

.bltd--print--invoice {
  .bltd--print--wrapper {
    background: #fff;
    padding: 1.5em 4.5em;
    position: relative;
    .bltd--heading--action {
      position: absolute;
      top: -120px;
      right: 0;
    }
  }
  article {
    margin-bottom: 2em;
  }

  .bltd--ticket--list {
    .bltd--ticket--tr {
      .bltd--ticket--td {
        .bltd--ticket--next-line {
          white-space: normal;
        }
      }
    }
  }
 /* padding: 1.5em 4.25em;
  background: #fff;
  border-radius: 0.25em;
  margin-bottom: 1.875em;*/
  .bltd--print--title {
    h1 {
      line-height: 1.3;
      font-size: 3.2rem;
      color: #666;
      font-weight: 400;
      margin: 0;
    }
    padding-left: 2em;
    margin-bottom: 4em;
  }
  .bltd--barcode {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    bottom: 0;
    height: 120px;
    margin-top: -60px;
  }
  .bltd--print--columns {
    position: relative;
  }
  .bltd--print--columns-inner {
    margin-right: 180px;
  }
  .bltd--print--block {
    &.no-barcode {
      padding-right: 2em;
      .bltd--print--columns-inner {
        margin-right: 0;
      }
    }
  }

  .bltd--print--block {
    border-radius: 5px;
    border: 1px solid;
    padding: 2.125em 2em;
    padding-bottom: 1.65em;
    padding-right: 0;
    margin-bottom: 1.25em;

  }

  .bltd--print--block-waypoint {

    .bltd--h1 {
      line-height: 1;
      font-size: 3.2rem;
      color: #666;
      font-weight: 600;
      margin: 0;
      white-space: normal;
    }

    .bltd--heading {
      display: none;
    }

    .bltd--point {
      clear: both;
      margin-bottom: 3em;
      /*  max-width: 32rem;
        min-height: 2em;*/
      position: relative;

      &:last-child {
        margin-bottom: 1.875em;
        &:before {
          content: none;
        }
      }
      &:before {
        content: ' ';

        border: 1px dashed;
        /*width: 0.125em;*/
        display: block;
        position: absolute;
        top: 1.3125em;
        bottom: -3.5em;
        left: 45%;
        margin-left: 0.0625em;

      }

      &:after {
        clear: both;
        display: block;
        content: '';
      }

      .bltd--time--destination {
        float: left;
        width: 45%;
        .bltd--time {
          margin-bottom: 1em;
          &.bltd--time--destination_arrival {
            margin-top: .5em;
            margin-bottom: 1.25em;
            span {
              font-size: .875em;
            }
          }
        }

      }

      .bltd--location--destination {
        float: left;
        width: 50%;

        position: relative;
        .bltd--location--destination__inner {
          margin-left: 8%;
        }

        &:after {
          background: #fff;
          width: 1em;
          height: 1em;
          border-radius: 100%;
          content: ' ';
          top: 10px;
          /* margin-top: -10px; */
          display: block;
          position: absolute;
          left: -6px;
          border: 2px solid;
        }

      }
      &.bltd--inner {
        .bltd--location--destination {
          &:before {
            background: #bbb;
          }
          &:after {
            border-color: #bbb;
          }
        }
      }
      .bltd--location {
        white-space: nowrap;
        .bltd--main {
          margin-bottom: 1em;
        }
        .bltd--subl {

          color: #666666;
          margin: 0;
        }
      }
      .bltd--subl {

        line-height: 1.33;
        color: #666666;
        margin: 0;
        white-space: normal;
        width: 95%;
      }

    }

  }
  .bltd--print--block-summary {
    border-top: 1px solid #efeff0;
    padding-top: 1em;
    width: 100%;

  }
  .bltd--heading--sub {
    .bltd--heading--wrapper {
      padding-bottom: 1.875em;
      h2 {
        font-size: 1.125em;
        font-weight: 600;
      }
    }
  }

.bltd--returned {
  .bltd--return--text {
    font-size: .875em;
  }
  .bltd--print--return {
    padding: 1.25em 1.875em;
    padding-top: 0;
  }


}

  .bltd--print--info {
    display: flex;
    padding: 1.25em 1.875em;
    .bltd--print--info-item {
      &.bltd--l {
        width: 66%;
        padding-right: 4%;
      }
      &.bltd--r {
        width: 30%;
        .bltd--rblocks {
          display: flex;
          flex-wrap: wrap;
          align-content: space-between;
          height: 100%;
        }
      }
    }
    .bltd--info {
      .bltd--item {
        &:last-child{
          margin-bottom: 0;
        }
        font-size: .875em;
        margin-bottom: 1em;


        p {
          margin-bottom: 1em;
        }

      }
    }
    .bltd--print--logo {

      .bltd--green {
        font-family: "Open Sans", Helvetica, Arial, sans-serif;
        color: var(--base-green-color);
        font-weight: 700;
        font-size: 2em;
      }
      .bltd--descr {
        padding: 1em 0;
        font-size: .875em;
      }
      .bltd--foot {
        font-size: .875em;
      }
    }

    .bltd--ticket--list {


      .bltd--ticket--tr {

        margin-bottom: .5em;
        .bltd--ticket--td {

          width: 100%;
          padding: 1.125em 0;
          padding-top: 0;


        }
      }

    }
    .bltd--bankid {
      font-size: .875em;
    }
    .bltd--route--calc {
      /*font-size: $px14;*/
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: block;
        margin-bottom: .875em;
        overflow: hidden;

        .bltd--t {
          font-size: .875em;
          line-height: 1;
          float: left;
          margin-right: 1em;
        }
        .bltd--cost {
          line-height: 1;
          font-size: .875em;
          float: right;

        }
        &.bltd--total-cost {
          .bltd--t {
            font-size: 1em;
          }
          .bltd--cost {
            line-height: 1;
            font-size: 1em;
            float: right;

          }
          margin-bottom: .875em;
          font-weight: $font-600;
          font-size: 1em;
          padding: 0;

        }
      }
    }
  }

}

@include media("<=tablet") {
  .bltd--heading--action {
    display: none;
  }
  .bltd--print--button {

    background-color: #f3f3f3;
    text-align: center;
    padding: 1.125em 1.25em;

    vertical-align: top;
    .bltd--field:first-child {
      margin-bottom: .5em;
    }
    margin-bottom: 1em;

  }
  .bltd--print--invoice {
    .bltd--timer {
      padding: 1.125em 1.25em;
    }
    .bltd--expand--detail {
      /* margin: 0.9375em 0; */
      position: relative;
      padding: 0;

      border-bottom: 1px solid #efeff0;
      .bltd--td {
        padding: 1.125em 1.25em;
        padding-top: 0;

      }

       .bltd--main-line {
        font-size: .875em;
        line-height: 1;
        font-weight: 400;
        margin-bottom: .625em;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
         &.bltd--name {
           font-size: 1em;
           font-weight: 600;
         }
      }
       .bltd--next-line {
        color: #666666;
        font-size: 0.8125em;
        line-height: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .bltd--main-line .bltd--b {
        font-weight: 600;
      }
    }
    .bltd--wrap {
      padding: 0
    }
    .bltd--print--wrapper {

      padding: 0;
    }
    article {
      margin-bottom: 2em;
    }
    /* padding: 1.5em 4.25em;
     background: #fff;
     border-radius: 0.25em;
     margin-bottom: 1.875em;*/
    .bltd--print--title {
      h1 {
        font-size: 1.25em;
        font-weight: 600;
        padding-bottom: 0;
        margin-bottom: 0;
      }
      padding-left: 1.25em;
      margin-bottom: 2em;
    }
    .bltd--barcode {
      position: relative;
      top: auto;
      right: auto;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      bottom: auto;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 1em;
    }
    .bltd--print--columns {
      position: relative;
    }
    .bltd--print--columns-inner {
      margin-right: 180px;
    }
    .bltd--print--block {
      border-radius: 0;
      border: 0;
      padding: 0;
      margin-bottom: 0;

    }

    .bltd--print--block-summary {
      border-top: 1px solid #efeff0;
      padding-top: 1em;
      width: 100%;

    }
    .bltd--heading--sub {
      .bltd--heading--wrapper {
        padding-bottom: .875em;
        h2 {
          font-size: 1.125em;
          font-weight: 600;
        }
      }
    }



    .bltd--print--info {
      display: block;
      padding: 1.125em 1.25em;
      .bltd--print--info-item {
        &.bltd--l {
          width: 100%;
          padding-right: 0;
        }
        &.bltd--r {
          width: 100%;
          .bltd--rblocks {
            display: block;

            height: auto;
            .bltd--rblock {

            }
          }
        }
      }
      .bltd--info {
        .bltd--item {
          &:last-child{
            margin-bottom: 0;
          }
          font-size: .8125em;
          margin-bottom: 1em;


          p {
            margin-bottom: 1em;
          }

        }
      }
      .bltd--print--logo {

        .bltd--green {
          font-family: "Open Sans", Helvetica, Arial, sans-serif;
          color: var(--base-green-color);
          font-weight: 700;
          font-size: 2em;
        }
        .bltd--descr {
          padding: 1em 0;
          font-size: .875em;
        }
        .bltd--foot {
          font-size: .875em;
        }
      }

      .bltd--ticket--list {


        .bltd--ticket--tr {

          margin-bottom: .5em;
          .bltd--ticket--td {

            width: 100%;
            padding: 1.125em 0;
            padding-top: 0;


          }
        }

      }
      .bltd--bankid {
        font-size: .875em;
        margin-bottom: 1.875em;
      }
      .bltd--route--calc {
        /*font-size: $px14;*/
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          display: block;
          margin-bottom: .875em;
          overflow: hidden;

          .bltd--t {
            font-size: .875em;
            line-height: 1;
            float: left;
          }
          .bltd--cost {
            line-height: 1;
            font-size: .875em;
            float: right;

          }
          &.bltd--total-cost {
            .bltd--t {
              font-size: 1em;
            }
            .bltd--cost {
              line-height: 1;
              font-size: 1em;
              float: right;

            }
            margin-bottom: .875em;
            font-weight: $font-600;
            font-size: 1em;
            padding: 0;

          }
        }
      }
    }

  }

}

.bltd--feesStation-toggle{
  color: var(--base-link-color);
  cursor: pointer;
  border-bottom: 1px dashed;
}

.bltd--feesStation{
  font-weight: normal;
  overflow: hidden;
  margin: -5px 0 .5em;
  .bltd--cost{
    float: right;
  }
  & > div{
    clear: both;
    &:first-child{
      margin-top: 5px;
    }
  }
}

.bltd--summary--text {
  font-size: .875em;
  .bltd--bold {
    font-weight: 600;
  }
}
.bltd--ticket--summary {
  overflow: hidden;
  .bltd--ll {

    float: right;
    padding: 0;
    margin: 0;
    margin-top: .875em;
    li {
      display: table;
      margin-bottom: .875em;
      overflow: hidden;
      table-layout: fixed;
      width: 100%;

      .bltd--t {
        font-size: .875em;
        line-height: 1;
        /* margin-right: 2em; */
        display: table-cell;
        text-align: right;
        width: 70%;
        padding-right: 1em;
        .bltd--desc {

        }

      }
      .bltd--cost {
        line-height: 1;
        font-size: .875em;
        display: table-cell;
        font-weight: 600;
        text-align: right;
      }
      &.bltd--total-cost {
        .bltd--t {
          font-size: 1em;
        }
        .bltd--cost {
          line-height: 1;
          font-size: 1em;


        }
        margin-bottom: .875em;
        font-weight: $font-600;
        font-size: 1em;
        padding: 0;

      }
    }
  }
}

.bltd--refund-policy-link {
  margin-bottom: 1.05em;
  display: block;
  font-size: .85em;
}

.child-fare-tooltip {
  width: 300px;
  padding: 10px;

  .tooltip__label {
    white-space: break-spaces;
  }

  @media screen and (max-width: 1023px) {
    &.tooltip--is-active.tooltip--top {
      transform: translateX(-45%); } }
}

