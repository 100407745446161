.button {
  border: 1px solid #d7d7d7;
  background-color: #f3f3f3;
  line-height: normal;
  padding: .5em 1em;
  text-decoration: none;
  color: #363636;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  &:hover, &:active, &.hover { text-decoration: none; }
  &:hover, &.hover  { background: #f9f9f9; }
}
.button-primary {
  // Add your styles here
}
.button-secondary {
  // Add your styles here
}

.href-link {
  color: $base-white-color;
  text-decoration: none;
  border: 0;
  background: transparent;


  &:hover, &.hover {
    @include transition(0.4s);
    color: $base-white-color-hover;
    text-decoration: none;

  }
  &:active { outline: 0; }

  &_empty {
    color: $base-white-color-hover;

    &:hover {
      text-decoration: none;
      background: transparent;
      border-color: $base-white-color-hover;
      color: $base-white-color-hover;
    }

    &:active { outline: 0; }
  }
}

.button-link {

  color: $base-white-color;
  text-decoration: none;
  border: 0;

  @include  border-radius(0.2em);
  @include border-box;

  background: transparent;

  padding: 0.5em 1em;

  cursor : pointer;

  display: inline-block;
  line-height: 1.375em;

  &:hover, &.hover {
    @include transition(0.4s);
    text-decoration: none;
    background: $base-button-link-background-hover;
  }

  &:active { outline: 0; }



  &_green {
    border-color: $base-green-color;
    background: $base-green-color;

    &:hover {

      background: transparent;
      border-color: $base-green-color;
      color: $base-green-color;
    }

    &:active { outline: 0; }

  }

  &_empty {
    border: 1px solid;
    border-color: #c0daa3;

    &:hover {
      text-decoration: none;
      background: transparent;
      border-color: $base-white-color-hover;
      color: $base-white-color-hover;
    }

    &:active { outline: 0; }
  }


}
.bltd--xs {
  .bltd--l {
    white-space: nowrap;

    .bltd--name {
      @include media('<=tablet') {
        display: none;
      }

      header.header--xs & {
        display: none;
      }
    }


  }
}

.bltd--account {
  .bltd--btn {
    line-height: 1em;
   /* padding-top: .75em;
    padding-bottom: .75em;*/
    padding: .75em .75em;
    &.bltd--btn--stroke.bltd--btn--white.bltd--btn--m{
      box-shadow: 0 0 0 1px #fff inset;
    }

    .bltd--text {
      position: relative;
      margin-right: 1.25em;
      display: inline-block;
      .bltd--i{
        display: none;
        width: 1em;
        height: 1em;
        background-color: #fff/*$base-color*/;
        -webkit-mask:  url(../img/icons/account.svg) no-repeat 50% 50%;
        mask: url(../img/icons/account.svg) no-repeat 50% 50%;
        background-position: 50% 50%;
        background-repeat: no-repeat;

      }
      .bltd--name {
        display: inline-block;
      }

      &:after {
        width: 1em;
        height: 1em;
        display: block;
        content: '';
        background-image: url(../img/icons/icons.svg);
        background-size: 10.5625em 17.3125em;
        position: absolute;
        background-position: -6.9375em -7.75em;
        top: 0;
        right: -1.25em;
        /* background-position: -9.5625em -7.75em; */
      }
    }
  }
}
.bltd--inverted {
  .bltd--account {
    .bltd--btn {
      &.bltd--btn--stroke.bltd--btn--white.bltd--btn--m {
        box-shadow: 0 0 0 1px #8c8c8c inset;
        color: $base-color !important;
      }
      .bltd--text {
        &:after {
          background-position: -9.5625em -7.75em;
        }
      }

    }
  }
}

@include media('<=tablet') {
  .bltd--account {
    .bltd--btn {

      .bltd--text {
        position: relative;


        .bltd--i{
          display: inline-block;
        }
        .bltd--name {
          display: none;
        }
      }
    }
  }
}