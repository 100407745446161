.bltd--ui.checkbox {
  position: relative;
  display: inline-block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: 0;
  vertical-align: top;
  min-height: 2rem;
  min-width: 2rem;
  overflow: visible !important;
  input[type=checkbox], input[type=radio] {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0!important;
    outline: 0;
    z-index: 3;
    /*width: 1em;
    height: 1em;*/
    width: 2rem;
    height: 2rem;
  }
  & input.hidden {
    z-index: -1;
  }
}
.bltd--ui.checkbox label, .bltd--ui.checkbox+label {
  color: rgba(0,0,0,.87);
  -webkit-transition: color .1s ease;
  transition: color .1s ease;
}
.bltd--ui.checkbox input.hidden+label {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bltd--ui.checkbox .box, .bltd--ui.checkbox label {
  cursor: auto;
  position: relative;
  display: block;
  /* */
  outline: 0;
  font-size: 1em;

}
.bltd--ui.checkbox label {
  padding-left: 3rem;
  font-size: 1em;
  line-height: 1.5;
  padding-top: 0px;
}

.bltd--ui.checkbox input:checked~label {

}

.bltd--ui.checkbox .box:before, .bltd--ui.checkbox label:before {
  position: absolute;
  top: 0;
  left: 0;
  /*  width: 1.25em;
    height:  1.25em;*/
  width: 2rem;
  height:  2rem;
  content: '';
  background: #fff;
  border-radius: 4px;
/*  -webkit-transition: border .1s ease,opacity .1s ease,-webkit-transform .1s ease,box-shadow .1s ease;
  transition: border .1s ease,opacity .1s ease,transform .1s ease,box-shadow .1s ease;*/
  box-shadow: 0 0 0 2px #e5e4de inset;

}
.bltd--ui.checkbox input:checked~.box:before, .bltd--ui.checkbox input:checked~label:before {
  background: #dd4b39;
  box-shadow: 0 0 0 2px #dd4b39 inset;
}

.bltd--ui.checkbox.bltd--green input:checked~.box:before, .bltd--ui.checkbox.bltd--green input:checked~label:before {
  background: #6E993D;
  box-shadow: 0 0 0 2px #6E993D inset;
}

.bltd--ui.checkbox .box:after, .bltd--ui.checkbox label:after {
  position: absolute;
  /*font-size: 1em;*/
  /*!*top: $px2;
  left: $px2;*!

!*  width: 1em;
  height:  1em;*!
  */

  font-size: 1.8rem;
  top: 0.1rem;
  left: 0.2rem;
  width: 1.8rem;
  height: 1.8rem;


  text-align: center;
  opacity: 0;
  color: rgba(0,0,0,.87);
  -webkit-transition: border .1s ease, opacity .1s ease,-webkit-transform .1s ease,box-shadow .1s ease;
  transition: border .1s ease, opacity .1s ease,transform .1s ease,box-shadow .1s ease;
  background-image: url("../img/icons/icons.svg");
  background-size: 10.5625em 17.3125em;
}

.bltd--ui.checkbox input:checked~.box:after, .bltd--ui.checkbox input:checked~label:after {
  opacity: 1;
  color: rgba(0,0,0,.95);
}
.bltd--ui.checkbox input:checked~.box:after, .bltd--ui.checkbox input:checked~label:after {
  content: '';
  background-position: -6.9375em -1.1875em;

}
input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

//services
.services{
  &.invalid{
    .bltd--ui.checkbox label:before{
      box-shadow: 0 0 0 0.125em #f8dbd7, 0 0 0 2px #dd4b39 inset !important
    }
  }
  .bltd--labeled--field-field{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .bltd--service__item{
      width: auto;
      margin-bottom: 1em;
      &.bltd--inactive{
        span{
          color: #aaa;
        }
        label{
          display: inline-block;
        }
      }
      @media (max-width: 500px){
        width: 100%;
        display: block;
      }
    }
  }
  .service__info{
    display: flex;
    padding: 0 0 15px;
    font-size: 0.8125em;
    .service__icon{
      width: 15%;
    }
    .service__text{
      width: 85%;
      box-sizing: border-box;
      padding-left: 15px;
    }
  }
}

//services icon
.InsurancePolicyAlfa{
  .service__icon{
    background: url(../img/services/alfa.svg) no-repeat;
    background-size: contain;
  }
}