// ==========================================================================
// Helpers
// ==========================================================================
// General Resets
.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.no-float {
  float: none;
}

.no-background {
  background: transparent;
}

.no-border {
  border: 0;
}

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*cursor: default;*/
}

// Font Weights
.font-100 {
  font-weight: $font-100;
}

.font-200 {
  font-weight: $font-200;
}

.font-300 {
  font-weight: $font-300;
}

.font-400 {
  font-weight: $font-400;
}

.font-500 {
  font-weight: $font-500;
}

.font-600 {
  font-weight: $font-800;
}

.font-700 {
  font-weight: $font-700;
}

.font-800 {
  font-weight: $font-800;
}

.font-900 {
  font-weight: $font-900;
}

// Font Styles
.font-normal {
  font-style: normal;
}

.font-italic {
  font-style: italic;
}

// Text Modifications
.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

// Text Alignments
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.bltd--text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

// Positions
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

// Display Types
.none {
  display: none;
}

.bltd--block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

// Flex Types
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-start {
  justify-content: flex-start;
}

.flex-center {
  justify-content: center;
}

.flex-end {
  justify-content: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

// Floats
.left {
  float: left;
}

.right {
  float: right;
}

// Alignments
.center-element {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

// Hide only visually, but have it available for screenreaders: : h5bp.com/v
.sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Lists
.list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
  }
}

.list-inline {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
    display: inline-block;
  }
}

// Images
.img-responsive {
  max-width: 100%;
}

// Image Positions
.img-left {
  float: left;
  margin-right: 20px;
}

.img-right {
  float: right;
  margin-left: 20px;
}

// Grouped Fields
.field-group {
  padding-top: 40px;
  padding-bottom: .5em;
  overflow: hidden;
  label {
    padding-top: 8px;
  }
}

.bltd--field {
  width: 100%;
}

// Disable Element
.disabled {
  pointer-events: none;
  opacity: .5;
}

// Checkbox and Radio

/*.checkbox,
.radio {
  display: inline-block;
  position: relative;
 */

/* margin-top: .5em;*/

/* margin-bottom: .5em;*/

/*
  label {
    padding-left: 20px;
    padding-top: 0;
    display: inline-block;
  }
  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    top: 4px;
    left: 0;
  }
}*/

// Animations

/*.animate {
  $include animate(1s);
}
.animate-infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}*/

// Tablet Helpers
@media only screen and (min-width: $breakpoint-tablet) {
  // General Resets
  .no-float-tablet {
    float: none;
  }
  .no-padding-tablet {
    padding: 0;
  }
  .no-margin-tablet {
    margin: 0;
  }
  // Display Types
  .none-tablet {
    display: none;
  }
  .bltd--block-tablet {
    display: block;
  }
  .inline-block-tablet {
    display: inline-block;
  }
  .inline-tablet {
    display: inline;
  }
  // Floats
  .left-tablet {
    float: left;
  }
  .right-tablet {
    float: right;
  }
  // Text Alignments
  .text-left-tablet {
    text-align: left;
  }
  .text-right-tablet {
    text-align: right;
  }
  .bltd--text-center-tablet {
    text-align: center;
  }
  .text-justify-tablet {
    text-align: justify;
  }
  // Grid Resets
  .no-col-tablet {
    width: auto;
    float: none;
  }
  .no-push-tablet,
  .no-pull-tablet {
    left: 0;
  }
}

// Desktop Helpers
@media only screen and (min-width: $breakpoint-desktop) {
  // General Resets
  .no-float-desktop {
    float: none;
  }
  .no-padding-desktop {
    padding: 0;
  }
  .no-margin-desktop {
    margin: 0;
  }
  // Display Types
  .none-desktop {
    display: none;
  }
  .bltd--block-desktop {
    display: block;
  }
  .inline-block-desktop {
    display: inline-block;
  }
  .inline-desktop {
    display: inline;
  }
  // Floats
  .left-desktop {
    float: left;
  }
  .right-desktop {
    float: right;
  }
  // Text Alignments
  .text-left-desktop {
    text-align: left;
  }
  .text-right-desktop {
    text-align: right;
  }
  .bltd--text-center-desktop {
    text-align: center;
  }
  .text-justify-desktop {
    text-align: justify;
  }
  // Grid Resets
  .no-col-desktop {
    width: auto;
    float: none;
  }
  .no-push-desktop,
  .no-pull-desktop {
    left: 0;
  }
}

// HD Helpers
@media only screen and (min-width: $breakpoint-hd) {
  // General Resets
  .no-float-hd {
    float: none;
  }
  .no-padding-hd {
    padding: 0;
  }
  .no-margin-hd {
    margin: 0;
  }
  // Display Types
  .none-hd {
    display: none;
  }
  .bltd--block-hd {
    display: block;
  }
  .inline-block-hd {
    display: inline-block;
  }
  .inline-hd {
    display: inline;
  }
  // Floats
  .left-hd {
    float: left;
  }
  .right-hd {
    float: right;
  }
  // Text Alignments
  .text-left-hd {
    text-align: left;
  }
  .text-right-hd {
    text-align: right;
  }
  .bltd--text-center-hd {
    text-align: center;
  }
  .text-justify-hd {
    text-align: justify;
  }
  // Grid Resets
  .no-col-hd {
    width: auto;
    float: none;
  }
  .no-push-hd,
  .no-pull-hd {
    left: 0;
  }
}

// Print Helpers
@media print {
  // General Resets
  .no-float-print {
    float: none;
  }
  .no-padding-print {
    padding: 0;
  }
  .no-margin-print {
    margin: 0;
  }
  // Display Types
  .none-print {
    display: none;
  }
  .bltd--block-print {
    display: block;
  }
  .inline-block-print {
    display: inline-block;
  }
  .inline-print {
    display: inline;
  }
  // Text Alignments
  .text-left-print {
    text-align: left;
  }
  .text-right-print {
    text-align: right;
  }
  .bltd--text-center-print {
    text-align: center;
  }
  .text-justify-print {
    text-align: justify;
  }
  // Grid Resets
  .no-col-print {
    width: auto;
    float: none;
  }
  .no-push-print,
  .no-pull-print {
    left: 0;
  }
}

.bltd--clear {
  &:after {
    clear: both;
    content: '';
    display: table;
  }
}

.bltd--clearfix:before,
.bltd--clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.bltd--clearfix:after {
  clear: both;
}
