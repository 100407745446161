/*--------------
     Filter by stops
---------------*/

.expand--filter {
  cursor: pointer;
  font-size: 0.8125em;
  color: #666666;
  font-weight: normal;
  white-space: nowrap;
  span {
    position: relative;
    margin-right: 1.25em;
    display: inline-block;
    line-height: 1;
    &:after {
      content: '';
      position: absolute;
      height: 6px;
      width: 6px;
      transform: rotate(40deg) skewX(-10deg);
      border: 2px solid var(--base-link-color);
      border-top: none;
      border-left: none;
      top: 1px;
      right: -10px;
    }
  }
}
.bltd--sorting--mobile {
  .bltd--route--filter, .bltd--route--sorting {
    font-size: 1em;
    .bltd--filter-block {
      font-size: 1em;
    }
    ul.bltd--block-list {
      li {
        .bltd--name {
          margin-bottom: .5em;
          max-width: 100%;
          .bltd--text{
            margin-bottom: 0em;
          }
          .bltd--state {
            margin: .25em 0;
          }
        }

      }
    }
  }
}
.bltd--route--filter, .bltd--route--sorting {
  padding: .625em;
  .bltd--filter-block {
    font-size: .8125em;
  }
  .bltd--filter-departure {
    margin-bottom: 1em;
  }
  .bltd--block-title {
    margin-bottom: .5em;
    font-weight: 600;
  }
  ul.bltd--block-list {
    list-style-type: none;
    padding: 0;
    color: #000000;
    li {
      position: relative;
      line-height: 1.5em;
      padding-right: 1.25em;
      .bltd--name {
        position: relative;
        display: inline-block;
        line-height: 1;
        margin-bottom: .85em;
        max-width: 180px;
        .bltd--text{
          margin-bottom: .5em;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .bltd--state {
        display: block;
        color: #e0dfda;
        text-decoration: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &.bltd--selected {
        .bltd--name {
          position: relative;
          margin-bottom: .5em;
          display: inline-block;
          line-height: 1;
          .bltd--text {
            font-weight: 800;
          }

          //&:after {
          //  width: 1em;
          //  height: 1em;
          //  display: block;
          //  content: '';
          //  background-image: url(../img/icons/icons.svg);
          //  background-size: 10.5625em 17.3125em;
          //  position: absolute;
          //  background-position: -5.625em 0em;
          //  top: 0;
          //  right: -1.25em;
          //}

          &:before {
            content: '';
            display: block;
            width: 9px;
            height: 15px;
            position: absolute;
            border: 2px solid var(--base-green-color);
            border-top: none;
            border-left: none;
            transform: rotate(45deg);
            top: -4px;
            right: -17px;
          }
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.tabFilter {
  position: relative;
  vertical-align: top;
  float: right;
}

.tabFilter .bltd--counter {
  position: absolute;
}

.tabFilter .bltd--counter:hover {
  cursor: pointer;
}

.bltd--filter-list .with-icon-checked {
  position: relative;
}

.bltd--filter-list .with-icon-checked {
  position: absolute;
  right: 0px;
  background: url("../img/icons/icon_checkmark-list_blue.png") no-repeat 50% 50%, transparent;
  width: 16px;
  height: 16px;
  content: ' ';
  bottom: 2px;
}

.box-shadow {
  box-shadow: 0 0.625em 0.625em rgba(0, 0, 0, 0.1);
}