$red: #dd4b39 !default;
$white: #dd4b39 !default;
$blue: #4599e6 !default; //#408bd1 !default;
$green: var(--base-btn-green) !default;
$gray: #e6e6e6 !default;
$black: #cdcdcd !default;

$size-s: 1em !default;
$size-m: 1em !default;
$size-l: 1em !default;

$btn-padding: ('s': 1.25em, 'm': 1.5em, 'l': 2em);
$btn-font-size: ('s': 0.875em, 'm': 1em, 'l': 1em);
$btn-line-height: ('s': 2.125em, 'm': 2.5em, 'l': 3.5em);
$btn-font-weigth: ('s': 400, 'm': 600, 'l': 600);
$btn-border: ('s': 1px, 'm': .125em, 'l': .125em);

$base-round: $px3;

$base-spacing-unit: 8px;
$colors: ('red': $red, 'green': $green, 'blue': $blue, 'gray': $gray, 'black': $black, 'white':$white);
$sizes: ('s': $size-s, 'm': $size-m, 'l': $size-l);

@function contains($list, $values...) {
  @each $value in $values {
    @if type-of(index($list, $value)) != "number" {
      @return false;
    }
  }

  @return true;
}

@function is-length($value) {
  @return type-of($value) != "null" and (str-slice($value + "", 1, 4) == "calc"
       or index(auto inherit initial 0, $value)
       or (type-of($value) == "number" and not(unitless($value))));
}

@function is-size($value) {
  @return is-length($value)
          or contains("fill" "fit-content" "min-content" "max-content", $value);
}

@mixin size($value) {
  $width: nth($value, 1);
  $height: $width;

  @if length($value) > 1 {
    $height: nth($value, 2);
  }

  @if is-size($height) {
    height: $height;
  } @else {
    @warn "`#{$height}` is not a valid length for the `$height` parameter in the `size` mixin.";
  }

  @if is-size($width) {
    width: $width;
  } @else {
    @warn "`#{$width}` is not a valid length for the `$width` parameter in the `size` mixin.";
  }
}

@mixin btn-disabled($type) {

  cursor: default;
  opacity: 0.45 !important;
  /*box-shadow: none !important;*/
  pointer-events: none;

}

@mixin btn-rarr($color, $type: null, $colorValue: #fff) {

  span {
    position: relative;
    padding-right: 1em;
    &:after {
      //width: 1em;
      //height: 1em;
      //display: block;
      //content: '';
      //background-image: url(../img/icons/icons.svg);
      //background-size: 10.5625em 17.3125em;
      //top: .25em;
      //right: 0;
      //position: absolute;
      //todo добавить анимацию на плавную смену стрелки
    }
    &:before {
      content: '';
      position: absolute;
      height: 7px;
      width: 7px;
      transform: rotate(40deg) skewY(10deg);
      border: 2px solid;
      border-color: $colorValue;
      border-bottom: none;
      border-left: none;
      top: 50%;
      margin-top: -3.5px;
      right: 5px;
      transition: all .2s ease;
    }
  }

  &:hover {
    span {
      &:before {
        border-color: #fff;
      }
    }
  }

  span {
    &:after {
      @if $color == 'green' {
        background-position: -8.1875em -9.0625em;
      }
    }
  }

  &.bltd--btn--primary span:after {
    @if $color == 'green' {
      background-position: -5.625em -6.4375em;
    }
  }
}

@mixin btn-weigth($size, $type: null) {
  font-weight: map-get($btn-font-weigth, $size);
}

@mixin btn-padding($size, $type: null) {
  padding: 0 map-get($btn-padding, $size);
}

@mixin btn-size($size, $type: null) {
  line-height: map-get($btn-line-height, $size);
  font-size: map-get($btn-font-size, $size);
  @include btn-weigth($size, $type);
  @include btn-padding($size, $type);

}

@mixin btn-color($color, $type) {

  color: map-get($colors, $color);
  &:hover {
    @if $color == 'green' {
      color: map-get($colors, $color);
    }
    @else {
      color: darken(map-get($colors, $color), 10%);
    }
  }
  &:active {
    @if $color == 'green' {
      color: map-get($colors, $color);
    }
    @else {
      color: darken(map-get($colors, $color), 10%);
    }
  }
  @if $type == 'stroke' {
    &:hover {
      @if $color != 'white' {
        background: map-get($colors, $color);
      }
      color: $white !important;
    }
    &:active {

      @if $color != 'white' {
        color: $white !important;
      }

      @if $color == 'green' {
        background-color: map-get($colors, $color);
      }
      @else {
        background-color: darken(map-get($colors, $color), 10%);
      }
    }

    @each $key, $size in $sizes {
      &.bltd--btn--#{$key} {
        box-shadow: 0 0 0 map-get($btn-border, $key) map-get($colors, $color) inset;

        &:active {
          box-shadow: 0 0 0 map-get($btn-border, $key) map-get($colors, $color) inset;
        }
      }
    }
  }

  @if $type == 'primary' {

    background: map-get($colors, $color);

    @if $color == 'gray' {
      color: #666666 !important;
    } @else {
      color: $white !important;
    }

    text-shadow: none;

    &:hover {

      @if $color == 'green' {
        background: var(--base-btn-green-hover);
      } @else {
        background: darken(map-get($colors, $color), 10%);
      }

      @if $color == 'gray' {
        color: #666666 !important;
      } @else {
        color: $white !important;
      }
    }
    &:active {
      @if $color == 'green' {
        background: var(--base-btn-green-hover);
      } @else {
        background: darken(map-get($colors, $color), 10%);
      }

      @if $color == 'gray' {
        color: #666666 !important;
      } @else {
        color: $white !important;
      }

    }
  }
}

@mixin btn-icon($color) {
  .bltd--icon {
    vertical-align: middle;

  }

  .bltd--icon {
    height: 1em;
    margin: 0 0.45em 0 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
    width: 1em;
    display: inline-block;
    &:before {
      width: 100%;
      height: 100%;
      content: ' ';
      top: 0;
      margin-top: 0;
      display: block;
      position: absolute;
      left: 0;

    }

    &.bltd--plus {
      @if $color == 'green' {
        &:before {
          content: '';
          display: block;
          height: 100%;
          width: 0;
          border: 1px solid $green;
          position: absolute;
          left: calc(50% - 1px);
          top: -3px;
        }
        &:after {
          content: '';
          display: block;
          width: 100%;
          border: 1px solid $green;
          position: absolute;
          top: 4px;
        }
      }
    }

  }
}

.bltd--btn {
  &.bltd--btn--printer {
    padding: 0.35em;
    line-height: 1em;
    &:hover {
      background: #f9f9f9;
    }
    i {
      display: inline-block;
      width: 1.25em;
      height: 1em;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 1.3125em;
        height: 1.125em;
        top: 50%;
        margin-top: -0.5625em;
        left: 50%;
        margin-left: -0.65625em;
      }

    }
  }
}

@mixin btn-type($type) {

  border-radius: $base-round;

}

// Button mixins
@mixin btn($size, $color, $type, $disabled: null) {
  @include btn-reset;

  @if $disabled {
    @include btn-disabled($type);
  }

  @include btn-size($size, $type);
  @include btn-color($color, $type);
  @include btn-type($type);
  @include btn-rarr($color, $type);

}

@mixin btn-reset() {
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: none;
  border: none;
  vertical-align: baseline;
  background: none;

  color: $base-color;

  font-family: $base-font-family;

  font-size: 1em;

  margin: 0 0 0 0;

  line-height: 2.5em;

  padding: 0 2em 0 2em;

  text-transform: none;

  text-shadow: none;

  font-weight: $font-800;

  font-style: normal;
  text-align: center;
  text-decoration: none;

  border-radius: $base-round;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: transparent;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

}

.bltd--btn,
%bltd--btn {
  @include btn-reset;
}

// rarr states
@each $key, $color in $colors {
  .bltd--btn--is-rarr.bltd--btn--#{$key},
  %bltd--btn--is-rarr%bltd--btn--#{$key} {
    @include btn-rarr($key, 'stroke', $color);
  }
}

@each $key, $color in $colors {
  .bltd--btn.bltd--btn--#{$key},
  %bltd--btn%bltd--btn--#{$key} {
    @include btn-icon($key);
  }
}

.bltd--btn--stroke[disabled],
%bltd--btn--stroke[disabled],
.bltd--btn--stroke.bltd--btn--is-disabled,
%bltd--btn--stroke%bltd--btn--is-disabled {
  @include btn-disabled('stroke');
}

.bltd--btn--primary[disabled],
%bltd--btn--primary[disabled],
.bltd--btn--primary.bltd--btn--is-disabled,
%bltd--btn--primary%bltd--btn--is-disabled {
  @include btn-disabled('primary');
}

@each $key, $size in $sizes {
  .bltd--btn--#{$key},
  %bltd--btn--#{$key} {
    @include btn-size($key);
  }
}

//кнопки с обводкой
.bltd--btn--stroke,
%bltd--btn--stroke {
  @include btn-type('stroke');

}

@each $key, $color in $colors {
  .bltd--btn--stroke.bltd--btn--#{$key},
  %bltd--btn--stroke%bltd--btn--#{$key} {
    @include btn-color($key, 'stroke');

  }
}

@each $key, $color in $colors {
  .bltd--btn.bltd--btn--#{$key},
  %bltd--btn%bltd--btn--#{$key} {
    @include btn-color($key, '');

  }
}

//кнопки главные
.bltd--btn--primary,
%bltd--btn--primary {
  @include btn-type('primary');

}

@each $key, $color in $colors {
  .bltd--btn--primary.bltd--btn--#{$key},
  %bltd--btn--primary%bltd--btn--#{$key} {
    @include btn-color($key, 'primary');

  }
}

//специальные кнопки
.bltd--btn--bbc {
  overflow: hidden;
  position: relative;
  box-shadow: none !important;
  border: .125em solid #054752 !important;
  color: #054752 !important;
  line-height: 2.2em;

  span {
    position: relative;
    z-index: 2;
    padding-right: 1em;

    &:before {
      content: '';
      position: absolute;
      height: 7px;
      width: 7px;
      transform: rotate(40deg) skewY(10deg);
      border: 2px solid #054752;
      border-bottom: none;
      border-left: none;
      top: 50%;
      margin-top: -3.5px;
      right: 5px;
      transition: all .2s ease;
    }
  }

  &:hover {
    background: #3ecaf3 !important;
    color: #054752 !important;

    .bltd--bbc-icon {
      opacity: 1;
    }
  }

  @include media('<=tablet') {
    background: #3ecaf3 !important;

    .bltd--bbc-icon {
      opacity: 1;
    }
  }
}

.bltd--btn--vk {
  padding: 0;
  background-color: #51749D;
  background: -webkit-gradient(linear, left top, left bottom, from(#51749D), to(#3D5E85));
  background: -webkit-linear-gradient(top, #51749D, #3D5E85);
  background: -o-linear-gradient(top, #51749D, #3D5E85);
  color: #FFFFFF;
  &:hover, &:active {
    background-color: #3D5E85;
    background: -webkit-gradient(linear, left top, left bottom, from(#3D5E85), to(#51749D));
    background: -webkit-linear-gradient(top, #3D5E85, #51749D);
    background: -o-linear-gradient(top, #3D5E85, #51749D);
    color: #FFFFFF;
  }
  @include btn-icon('blue');
}

.bltd--btn--fb {
  padding: 0;
  background-color: #4463A4;
  background: -webkit-gradient(linear, left top, left bottom, from(#4463A4), to(#33508C));
  background: -webkit-linear-gradient(top, #4463A4, #33508C);
  background: -o-linear-gradient(top, #4463A4, #33508C);
  color: #FFFFFF;
  text-shadow: none;
  &:hover, &:active {
    background-color: #33508C;
    background: -webkit-gradient(linear, left top, left bottom, from(#33508C), to(#4463A4));
    background: -webkit-linear-gradient(top, #33508C, #4463A4);
    background: -o-linear-gradient(top, #33508C, #4463A4);
    color: #FFFFFF;
    text-shadow: none;
  }
  @include btn-icon('blue');
}

.bltd--btn--fb i, .bltd--btn--vk i {

  height: 1em;
  margin: 0 0.45em 0 0;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  position: relative;
  width: 1em;
  display: inline-block;

  padding-left: 1.8em;

  &:before {
    content: '';
    background-image: url("../img/icons/icons.svg");
    background-size: 10.5625em 17.3125em;
    position: absolute;

    top: 0;
    left: 0;

    width: 1.3125em;
    height: $px20;

    right: auto;
  }
}

.bltd--btn--fb i {
  &:before {
    background-position: -5.625em -13em;

  }
}

.bltd--btn--vk i {
  &:before {
    background-position: -7.1875em -13em;
    top: 2px;
  }
}

.bltd--btn {
  &.bltd--btn--width-100 {
    width: 100%;
    display: block;
  }
}

//layouts

//процент
.bltd--ui {
  &.bltd--fluid {
    &.bltd--buttons {
      width: 100%;
    }
    &.bltd--button {
      width: 100%;
      display: block;
    }
  }
  &.two.bltd--buttons {
    width: 100%;
    > .bltd--btn {
      width: 50%;
    }
  }
  &.three.bltd--buttons {
    width: 100%;
    > .bltd--btn {
      width: 33.333%;
    }
  }
  &.four.bltd--buttons {
    width: 100%;
    > .bltd--btn {
      width: 25%;
    }
  }
  &.five.bltd--buttons {
    width: 100%;
    > .bltd--btn {
      width: 20%;
    }
  }
  &.six.bltd--buttons {
    width: 100%;
    > .bltd--btn {
      width: 16.666%;
    }
  }
  &.seven.bltd--buttons {
    width: 100%;
    > .bltd--btn {
      width: 14.285%;
    }
  }
  &.eight.bltd--buttons {
    width: 100%;
    > .bltd--btn {
      width: 12.500%;
    }
  }
  &.nine.bltd--buttons {
    width: 100%;
    > .bltd--btn {
      width: 11.11%;
    }
  }
  &.ten.bltd--buttons {
    width: 100%;
    > .bltd--btn {
      width: 10%;
    }
  }
  &.eleven.bltd--buttons {
    width: 100%;
    > .bltd--btn {
      width: 9.09%;
    }
  }
  &.twelve.bltd--buttons {
    width: 100%;
    > .bltd--btn {
      width: 8.3333%;
    }
  }
}

//flex
.bltd--ui {
  &.bltd--flexed {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    /*font-size: 0;*/
    vertical-align: baseline;
    margin: 0 .25em 0 0;
    .or {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
      margin: 0;
    }
  }
  &.bltd--fluid {
    &.bltd--flexed {
      width: 100%;
    }
    &.bltd--btn {
      width: 100%;
      display: block;
    }
  }
  &.two.bltd--flexed {
    width: 100%;
    justify-content: space-between;
    > .bltd--btn {
      width: 47%;
      flex: initial;
      flex: inherit;
    }
  }
  &.three.bltd--flexed {
    width: 100%;
    > .bltd--btn {
      width: 33.333%;
    }
  }
  &.four.bltd--flexed {
    width: 100%;
    > .bltd--btn {
      width: 25%;
    }
  }
  &.five.bltd--flexed {
    width: 100%;
    > .bltd--btn {
      width: 20%;
    }
  }
  &.six.bltd--flexed {
    width: 100%;
    > .bltd--btn {
      width: 16.666%;
    }
  }
  &.seven.bltd--flexed {
    width: 100%;
    > .bltd--btn {
      width: 14.285%;
    }
  }
  &.eight.bltd--flexed {
    width: 100%;
    > .bltd--btn {
      width: 12.500%;
    }
  }
  &.nine.bltd--flexed {
    width: 100%;
    > .bltd--btn {
      width: 11.11%;
    }
  }
  &.ten.bltd--flexed {
    width: 100%;
    > .bltd--btn {
      width: 10%;
    }
  }
  &.eleven.bltd--flexed {
    width: 100%;
    > .bltd--btn {
      width: 9.09%;
    }
  }
  &.twelve.bltd--flexed {
    width: 100%;
    > .bltd--btn {
      width: 8.3333%;
    }
  }
}

.bltd--ui.bltd--buttons {
  display: inline-block;
  vertical-align: middle;
  margin: 0;

  box-shadow: none;
  white-space: nowrap;

  .bltd--btn {
    margin: 0 1em 0 0;
    display: inline-block;
    vertical-align: middle;
    &:last-child {
      margin: 0;
    }
  }

  /*:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }*/
}

//todo
.button-group {
  .button:last-child {
    margin-right: 0;
  }
}

.bltd--btn {
  &.bltd--btn--link {
    padding: 0;
  }
}

@include media("<=tablet") {
  .bltd--ui.bltd--buttons {
    white-space: normal;
    margin-bottom: 1.25em;
    margin-top: 1em;

    .bltd--btn {
      margin-top: 1em;
    }
  }
}

///*------------------------------------*\
//    #DROPDOWN
//\*------------------------------------*/

// Z-depth mixin
@mixin z-depth($z-depth) {
  @if $z-depth == 1 {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .14), 0 2px 2px 0 rgba(0, 0, 0, .098), 0 1px 5px 0 rgba(0, 0, 0, .084);
  } @else if $z-depth == 2 {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .14), 0 4px 5px 0 rgba(0, 0, 0, .098), 0 1px 10px 0 rgba(0, 0, 0, .084);
  } @else if $z-depth == 3 {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .14), 0 6px 10px 0 rgba(0, 0, 0, .098), 0 1px 18px 0 rgba(0, 0, 0, .084);
  } @else if $z-depth == 4 {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .14), 0 8px 10px 1px rgba(0, 0, 0, .098), 0 3px 14px 2px rgba(0, 0, 0, .084);
  } @else if $z-depth == 5 {
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .14), 0 16px 24px 2px rgba(0, 0, 0, .098), 0 6px 30px 5px rgba(0, 0, 0, .084);
  }
}

@mixin bare-list() {
  margin: 0;
  padding: 0;
  list-style: none;
}

.bltd--bare-list,
%bltd--bare-list {
  @include bare-list;
}

// Dropdown
.bltd--dropdown--has-toggle {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.bltd--dropdown--relative {
  .bltd--dropdown-menu {
    //position: relative;
  }
}

// Dropdown menu
.bltd--dropdown-menu {
  position: absolute;
  border-radius: 2px;
  background-color: #fff;
  text-align: left;
  opacity: 0;
  overflow: auto;

  border: 1px solid #E4E4E4;
  box-shadow: 0 1em 1em 0 rgba(0, 0, 0, 0.10);
  box-sizing: content-box;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    & > li {
      list-style: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      position: relative;

    }
  }
}

// Dropdown menu: content
.bltd--dropdown-menu__content {
  /*padding: $base-spacing-unit 0;*/
  opacity: 0;
  .bltd--dropdown-menu--is-open & {
    opacity: 1;
  }
}

// Dropdown divider
.bltd--dropdown-divider {
  height: 1px;
  margin-top: $base-spacing-unit;
  margin-bottom: $base-spacing-unit;
  background-color: #fff;
}

@mixin flex-direction($direction: row) {
  -webkit-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin flex-wrap($wrap: nowrap) {
  @if $wrap == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $wrap;
  }
  -webkit-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin flex-order($order: 0) {
  -ms-flex-order: $order;
  -webkit-order: $order;
  -ms-order: $order;
  order: $order;
}

@mixin justify-content($justify: flex-start) {
  @if $justify == flex-start {
    -ms-flex-pack: start;
  } @else if $justify == flex-end {
    -ms-flex-pack: end;
  } @else if $justify == space-between {
    -ms-flex-pack: justify;
  } @else {
    -ms-flex-pack: $justify;
  }
  -webkit-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
}

@mixin align-items($align: auto) {
  @if $align == flex-start {
    -ms-flex-align: start;
  } @else if $align == flex-end {
    -ms-flex-align: end;
  } @else {
    -ms-flex-align: $align;
  }
  -webkit-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

@mixin align-content($align: auto) {
  @if $align == flex-start {
    -ms-flex-line-pack: start;
  } @else if $align == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $align;
  }
  -webkit-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}

@mixin align-self($align: auto) {
  @if $align == flex-start {
    -ms-flex-item-align: start;
  } @else if $align == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $align;
  }
  -webkit-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}

@mixin flex($value) {
  -webkit-box-flex: $value;
  -moz-box-flex: $value;
  -webkit-flex: $value;
  -ms-flex: $value;
  flex: $value;
}

@mixin flex-display($important: false) {
  @if ($important) {
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

// Dropdown link
.bltd--dropdown-link {

  font-size: .875em;
  display: block;
  /* font-size: .8125em;*/
  padding: .75em 1em;
  color: #4a4a4a;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:not(.bltd--dropdown-link--is-header) {
    &:hover {
      background-color: #f4f4f4;
      color: #4a4a4a;
    }
  }
}

.bltd--dropdown-link--is-large {
  height: 48px;

}

.bltd--dropdown-link--is-header {
  color: #000;
  cursor: default;

}

.bltd--inverted {
  .bltd--btn--stroke.bltd--btn--white {
    box-shadow: 0 0 0 0.125em #cdcdcd inset;
    color: #4A4A4A !important;
    &:hover {
      background: #fff;
      color: #4A4A4A !important;
    }
    &:active {
      background: #fff;
      color: #4A4A4A !important;
    }
  }
}

.bltd--btn-wrap{
  margin: 0 20px 0 0;
  display: inline-block;
}