#bltd--page.expanded:before {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  z-index: 9990;
}

.bltd--layout--sidebar {
  position: fixed;
  display: none;
  left: 0;
  bottom: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  visibility: hidden;
  -webkit-overflow-scrolling: touch;
  border-radius: 0em !important;
  margin: 0em !important;
  overflow-y: auto !important;
  z-index: 9999;
  padding-left: $px30;
  padding-right: $px34;
  min-height: 300px;
  top: 60px !important;
  width: 18em;
  border: 0 solid transparent;
  background-color: var(--sidebar-mobile);
  transform: translate3d(-100%, 0, 0);
  will-change: transform;
  transition: transform 0.15s ease-out;
  .bltd--push {
    padding-top: $px60;
    padding-bottom: 3em;
  }

  .bltd--copyrights {
    margin-top: 3em;
    margin-bottom: 0;
    p {
      color: #83949e;
      font-size: $px14;
    }
  }

  &.bltd--visible {
    visibility: visible;
    display: block;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    box-shadow: 0px 0px 20px rgba(34, 36, 38, 0.15);
  }
  .bltd--divider {
    border-top: 1px solid rgba(223, 223, 224, 0.5);
    height: 0;
    padding-bottom: 1em;
  }
  .bltd--layout--menu {
    .bltd--item {
      position: relative;
      vertical-align: middle;
      line-height: 1;
      text-decoration: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background: none;
      padding: .75em 0;
      text-transform: none;
      font-weight: normal;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease, color 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease, color 0.1s ease;
      a{
        color: #fff;
      }

      &.bltd--pre {
        margin-top: 1.875em;
        margin-bottom: 0;
        .bltd--text {
          color: #83949e;
          font-size: 0.875em;
          line-height: 1.25em;
        }
      }
    }
  }
}
