
.bltd--ui.avatar.images .image,
.bltd--ui.avatar.images img,
.bltd--ui.avatar.images svg,
.bltd--ui.avatar.image img,
.bltd--ui.avatar.image svg,
.bltd--ui.avatar.image {
  margin-right: 0.25em;
  display: inline-block;
  width: 2em;
  height: 2em;
  border-radius: 500rem;
}
.list-footer {
  margin: 2rem 0;
}

@include media("<=tablet") {
  .list-footer {
    margin-left: 0.75em;
    margin-right: 0.75em;
  }
}