/*-------------------
   Personal Return
--------------------*/
.bltd--error.bltd--msg,
.ui-personal-return-form .bltd--error.bltd--msg {
  margin-top: 1em;
  font-size: 0.875em;
  color: #dd4b39 !important; }

.ui-personal-return-form .success.bltd--msg {
  margin-top: 1em;
  font-size: 0.875em;
  color: #90C851 !important; }

.ui-personal-return-form .bltd--is-loading{
  width: 100%;
  height: 100%;
  position: absolute;
  background: white;
  opacity: 0.45;
  z-index: 1;
}
.ui-personal-return-form .bltd--is-loading .loader-giff{
  position: absolute;
  margin-left: 43%;
  margin-top: -50px;
  top: 50%;
  left: 0px;
}