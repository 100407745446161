.bltd--with-icon {
  position: relative;
}

.bltd--with-icon:after {
  content: '';
  background-image: url("../img/icons/icons.svg");
  background-size: 10.5625em 17.3125em;
  position: absolute;
  width: 1em;
  height: 1em;
  top: 0;
  left: 0;
}
.bltd--with-icon_rarr {
  margin-right: 1.25em;
  position: relative;
  &:after {
    background-position: -5.625em -6.4375em;
    top: .25em;
    left: auto;
    right: -1.25em;
  }
}
.bltd--with-icon_larr {
  padding-left: 1.25em;
  position: relative;
  &:after {
    //background-position: -6.9375em -9.0625em;
    //top: .25em;
    //left:0em;
    //right: auto;
    background: none;
  }
  &:before {
    content: '';
    position: absolute;
    height: 6px;
    width: 6px;
    transform: rotate(50deg) skewX(10deg);
    border: 2px solid var(--base-link-color);
    border-top: none;
    border-right: none;
    top: 8px;
    left: 5px;
  }
}

.bltd--with-icon_off {
  padding-right: 1.25em;
  position: relative;
  &:after {
    background-image: none;
    width: $px8;
    height: $px8;
    background-color: #cfcfcf;
    border-radius: 100%;
    top: 50%;
    right:0em;
    left: auto;
    margin-top: -$px4;
  }
}

.bltd--with-icon_on {
  padding-right: 1.25em;
  position: relative;
  &:after {
    background-image: none;
    width: $px8;
    height: $px8;
    background-color: var(--base-green-color);
    border-radius: 100%;
    top: 50%;
    right:0em;
    left: auto;
    margin-top: -$px4;
  }
}

.bltd--with-icon_facebook {
  padding-left: 1.8em;
  position: relative;


  &:after {
    background-position: -5.625em -13em;
    width: 1.3125em;
    height: $px20;
    top: 0;
    left:0em;
    right: auto;
  }
}
.bltd--with-icon_vk {
  padding-left:  1.8em;
  position: relative;
  &:after {
    width: 1.3125em;
    height: $px20;
    background-position: -7.1875em -13em;
    top: 2px;
    left:0;
    right: auto;
  }
}
.bltd--with-icon_printer {
  display: inline-block;
  width: 1.25em;
  height: 1em;
}

.bltd--with-icon_male:after {
  background-position: 0em -14.75em;
  width: $px40;
  height: $px40;
  top: $px15;
  left: -$px60;
  border-radius: 100%;
}
.bltd--with-icon_female:after {
  background-position:  -2.8125em -14.75em;
  width: $px40;
  height: $px40;
  top: $px15;
  left: -3.25em;
  border-radius: 100%;
}
.bltd--with-icon_time {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
  margin-top: 3px;
  margin-left: .25em;
  font-size: 16px;

  &:after {
    background-position: -6.75em -15.625em;
    top: 0;
    left: 0;
  }
}

.bltd--with-icon_expand {
  display: inline-block;
  width: 1em;
  height: 1em;
  position: relative;

  &:after {
    height: 1em;
    background-position: -7.125em -10.6875em;
    top: .5em;
    left: .25em;
  }
}

.bltd--expand .bltd--with-icon_expand:after {
  background-position: -5.8125em -10.6875em;
}

.zoom.bltd--icon {
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0.75em;
    transform: translateY(-50%);
    background: url(../img/icons/zoom.svg) 0 0 no-repeat;
    width: 18px;
    height: 18px;
  }
}

@include media("<=tablet") {
  .bltd--with-icon_female:after {
    left: -3em;
  }
}

.bltd--icon {
  &--lock {
    svg {
      width: 16px;
      height: 16px;
      margin-top: 3px;
      margin-left: 1px;
    }
    use {
      transform: translate(-112px, -61px);
    }
  }

  &--calendar {
    svg {
      width: 20px;
      height: 20px;
      display: block;
    }
    use {
      transform: translate(-88px, -59px);
    }
  }

  &--graycalendar {
    svg {
      width: 20px;
      height: 20px;
      display: block;
    }
    use {
      transform: translate(-130px, -59px);
    }
  }

  &--swap {
    position: absolute;
    width: 1.25em;
    height: 1.25em;
    top: 50%;
    left: 50%;
    margin-left: -.625em;
    margin-top: -0.625em;
    background: #fff;
    border-radius: 100%;

    svg {
      width: 20px;
      height: 20px;
      display: block;
    }
    use {
      transform: translate(-88px, -38px);
    }
  }

  &--account {
    margin: 0 !important;
    display: none !important;

    svg {
      width: 16px;
      height: 16px;
      display: block;
      margin: -3px 0 0 0;
    }
  }
}

@include media('<=tablet') {
  .bltd--icon {
    &--account {
      display: inline-block !important;
    }
  }
}