//noscript:
.disabled-js {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  height: 100%;

  &__window {
    max-width: 625px;
    padding: 33px;
    border: 2px solid #dadada;
    border-radius: 4px;

    h2 {
      color: $base-green-color;
      margin-bottom: 28px;
    }
  }

  &__action {
    float: right;
    width: 100%;
    max-width: 300px;
    margin-top: 25px;
  }
}

@-webkit-keyframes fadeInDownN {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0)
  }
}

@-moz-keyframes fadeInDownN {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px)
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0)
  }
}

@-o-keyframes fadeInDownN {
  0% {
    opacity: 0;
    -o-transform: translateY(-20px)
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0)
  }
}

@keyframes fadeInDownN {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

.ads {

  -webkit-animation: fadeInDownN 1s ease 1s 1 both;
  -moz-animation: fadeInDownN 1s ease 1s 1 both;
  animation: fadeInDownN 1s ease 1s 1 both;

  position: fixed;
  width: 100%;
  z-index: 9999;
  background: rgb(255, 235, 59);
  padding: .5em;

  .ads--content {
    margin: 0 1.875em;
    position: relative;
  }
  .bltd--text {
    font-size: .85em;

  }
  .bltd--max-width {
    position: relative;
  }
  .bltd--close {

    cursor: pointer;
    width: 1.25em;
    height: 1.25em;

    top: 50%;
    margin-top: -0.625em;
    right: 0.625em;
    display: block;
    position: absolute;

    &:after {
      content: '';
      background-image: url(../img/icons/icons.svg);
      background-size: 10.625em 17.3125em;
      position: absolute;
      width: 1em;
      height: 1em;
      top: 0;
      left: 0;
      background-position: -9.625em -2.5em;
      top: .125em;
      left: .125em;
    }
  }
}

header {
  width: 100%;
  z-index: 1400;
  position: fixed;
  @include backface;

  .bltd--header-wrapper {
    margin: 0 1.875em;
    position: relative;
  }

  .bltd--logo {
    margin-right: 0.9375em;
    float: left;

    & .bltd--expand-nav {
      position: relative;
      overflow: hidden;
      float: left;
      display: none;
      /* height: 100%; */
      @include rem('margin', 2.2, 2, 0, 1);

      & a {
        background-position: center 18px;
        background-size: 26px;
        color: #FFF;
        display: block;

        @include rem('line-height', 3.2);
        @include rem('width', 2);
        @include rem('height', 2);

        overflow: hidden;

      }

    }

    & .bltd--logo-link {
      position: relative;
      overflow: hidden;
      float: left;

      margin: 1.125em 0;

      a {

        background-size: contain;
        display: inline-block;

        font-size: 1.875em;
        line-height: 1.4em;

        text-decoration: none;
        font-weight: 700;

      }
    }
  }

  .bltd--main-nav {
    position: relative;
    float: left;
    margin-top: 1.25em;
    .bltd--link {

      text-decoration: none;
    }
  }

  header.bltd--header--inner .bltd--support--link  a {
    color: #ffffff;
  }
  .bltd--account.no-auth .bltd--btn .bltd--text:after {
    content: none;
  }

  .bltd--account.no-auth .bltd--btn .bltd--text {
    margin: 0;
    margin-right: 0em;
  }

  .bltd--support--link {
    display: inline-block;
    float: right;

    margin-top: 1.25em;
    a {
      padding: .75em 1.5em;
      position: relative;
      color: #ffffff;
      display: inline-block;
      line-height: 1em;
    }
  }

  .bltd--account {
    float: right;
    margin-top: 1.25em;
    a.acc-name {
      color: #4A4A4A;
    }
    .bltd--ui.bltd--menu .bltd--item.bltd--dropdown {
      font-weight: $font-600;
      position: static;
    }
  }

  .bltd--top-flex {
    display: flex;
    .bltd--logo {
      float: none;
      display: inline-block;
      white-space: nowrap;
      vertical-align: top;
      /* max-width: 45em; */
      /* overflow: hidden; */
      /* flex-basis: 80%; */
      text-overflow: ellipsis;
      overflow: hidden;
      .bltd--logo-flex {
        display: flex;
        .bltd--expand-nav {
          margin-top: 0;
          min-width: 1em;

        }
        .bltd--logo-link {
          a {
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
          }

        }
      }
      .bltd--logo-link {
        float: none;
        display: inline-block;
        vertical-align: top;
      }
      .bltd--expand-nav {
        float: none;

        display: none;
        vertical-align: top;
        width: 1em;
      }
    }
    .bltd--main-nav {
      float: none;
      display: inline-block;
    }

    .bltd--top--menu {
      display: inline-block;
      flex: 1 30%;
    }
  }

}

@include media("<=tablet") {
  .bltd--top--menu {
    display: inline-block;
    flex: 1 60px;
  }
  header .bltd--top-flex .bltd--logo .bltd--expand-nav {
    display: inline-block;
  }

  header .bltd--top-flex .bltd--main-nav {
    display: none;
  }
  header .bltd--top-flex .bltd--logo {
    margin-right: 0;
  }

  header .bltd--support--link  {


    margin-top: .625em;

  }

  .iframe{
    .bltd--top--menu > div {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background: rgba(14, 46, 67, 0.5);
  min-width: 100%;
  z-index: 999;
  .bltd--wrapper {
    position: relative;
    min-height: 100%;
  }
  &.bltd--ui-overlay-active {
    display: block;
  }
}

.bltd--ui.bltd--divider {
  margin: 0;

  line-height: 1;
  height: 0em;

  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  small {
    font-size: $px13;
  }
}

.bltd--ui.horizontal.bltd--divider {
  display: table;
  white-space: nowrap;
  height: auto;

  overflow: hidden;
  line-height: 1;
  text-align: center;
  margin-bottom: 28px;
}

.bltd--ui.horizontal.bltd--divider.small {
  font-size: $px13;
}

.bltd--ui.horizontal.bltd--divider:after, .bltd--ui.horizontal.bltd--divider:before {
  content: '';
  display: table-cell;
  position: relative;
  top: 50%;
  width: 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAACCAYAAACuTHuKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1OThBRDY4OUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1OThBRDY4QUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU5OEFENjg3Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU5OEFENjg4Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+VU513gAAADVJREFUeNrs0DENACAQBDBIWLGBJQby/mUcJn5sJXQmOQMAAAAAAJqt+2prAAAAAACg2xdgANk6BEVuJgyMAAAAAElFTkSuQmCC)
}

.bltd--ui.horizontal.bltd--divider:before {
  background-position: right 10px top 65%
}

.bltd--ui.horizontal.bltd--divider:after {
  background-position: left 10px top 65%
}

.bltd--reset-password {
  font-size: 0.8125em;
  text-align: center;
  margin-bottom: 2em;
  padding-top: .5em;
}

.login-popup.showed {
  display: block
}

.bltd--ui-popup.bltd--ui-popup-disabled-animation,
.bltd--ui-popup.bltd--ui-popup-disabled-animation .bltd--ui-popup-overlay,
.bltd--ui-popup.bltd--ui-popup-disabled-animation .bltd--ui-popup-content {
  -webkit-animation: none !important;
  animation: none !important;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal.fade .bltd--modal-dialog {
  -webkit-transition: -webkit-transform .3s ease-out;
  -o-transition: -o-transform .3s ease-out;
  transition: transform .3s ease-out;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

.modal.in .bltd--modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.bltd--modal-dialog {
  position: relative;
  width: auto;

}

.bltd--modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;

  outline: 0;

}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

}

.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}

.modal-backdrop.in {
  filter: alpha(opacity=100);
  opacity: 1;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.modal {
  display: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  /*bottom: 0;*/
  left: 0;
  z-index: 2401;
  -webkit-overflow-scrolling: touch;

  outline: 0;
  .bltd--close {
    width: 1em;
    height: 1em;
    display: block;
    top: 1em;
    right: 1em;
    position: absolute;
    z-index: 999;
    cursor: pointer;
    background: #fff;
    padding: 15px;
    border-radius: 50%;

    &:after {
      width: 1em;
      height: 1em;
      display: block;
      content: '';
      background-image: url(../img/icons/icons.svg);
      background-size: 10.5625em 17.3125em;
      position: absolute;
      background-position: -9.625em -2.6125em;
      top: 8px;
      left: 9px;

      /* background-position: -9.5625em -7.75em; */
      z-index: 999;
    }
  }

  .bltd--modal-dialog {
    position: relative;
    z-index: 2;
    width: 80%;
    /*background: #FFF;*/
    margin: 70px auto;
    min-height: 120px;
    text-align: left;

    /*&:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 $px13 $px13 $px13;
      border-color: transparent transparent #ffffff transparent;
      position: absolute;
      top: -$px13;
      right: 22%;
    }*/

    &.bltd--modal-small {
      max-width: 600px;
    }
  }
  .bltd--modal-content {
    padding: 1.25em;
  }
  .modal-title {
    margin-bottom: 1.5em;
    .bltd--h1 {
      font-size: 3.2rem;
      color: #666;
      font-weight: 400;
    }
  }
}

@include media("<=tablet") {
  .modal {
    .bltd--modal-dialog {
      width: 90%;
      .bltd--btn--primary {
        line-height: 1.25em;
        padding: 0.5em;
      }
    }
    &.bltd--checkout--paymants{
      .bltd--modal-dialog {
        width: 100%;
        margin: auto;
      }
    }
  }
}

.modal-backdrop, [uib-modal-backdrop] {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background: rgba(14, 46, 67, 0.5);

  -webkit-backface-visibility: hidden;
  -webkit-animation: ui-popup-fadein 0.5s;
  animation: ui-popup-fadein 0.5s;

}

.modal-backdrop.leave, [uib-modal-backdrop].leave {
  -webkit-animation: ui-popup-fadeout 0.5s;
  animation: ui-popup-fadeout 0.5s;
}

.modal-open .modal, .bltd--btn:focus {
  outline: none;
}

.bltd--ui-popup-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

}

.bltd--ui-popup-no-overlay {
  pointer-events: none;
}

.bltd--ui-popup.bltd--ui-popup-closing .bltd--ui-popup-overlay {
  -webkit-backface-visibility: hidden;
  -webkit-animation: ui-popup-fadeout 0.5s;
  animation: ui-popup-fadeout 0.5s;
}

.bltd--ui-popup.bltd--ui-popup-closing .bltd--ui-popup-content {
  -webkit-backface-visibility: hidden;
  -webkit-animation: ui-popup-fadeout 0.5s;
  animation: ui-popup-fadeout 0.5s;
}

@-webkit-keyframes ui-popup-fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes ui-popup-fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes ui-popup-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ui-popup-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*.bltd--header-wrapper {
  &.modal-open {
    position: relative;
    overflow: visible;
  }
}*/
.confirm-popup.in {
  margin: 0;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px
}

.confirm-popup {

  min-height: 30em;

  /* padding: 2.25em 3.125em; */
  position: fixed;
  bottom: auto;
  /* padding: 2.25em 3.125em; */
  .bltd--modal-dialog {
    max-width: 25em;
    width: 90%;
    /*position: absolute;
    right: 0;
    padding-top: 80px;
    margin: 0;*/
  }

  .bltd--modal-content {
    text-align: left;
    border-radius: 0.25em;
    background: #FFFFFF;
    max-width: 25em;
    margin: 0px auto;
    width: 100%;
    box-shadow: 0 1.875em 1.875em rgba(0, 0, 0, 0.5);
  }

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 $px13 $px13 $px13;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    top: -$px13;
    right: 22%;
  }

  .bltd--h, .bltd--message {
    margin-bottom: 1.1875em;
  }
  .bltd--message {
    margin-bottom: 1.5em;
  }
  .bltd--btn--action {
    text-align: center;
  }
  h3 {
    font-size: $px22;
    font-weight: 400;
    line-height: $px26;
    color: #4A4A4A;

    &.bltd--error {
      color: #DD4B39;
    }
  }

  .bltd--confirmation-action {
    display: flex;
    justify-content: space-between;
    //> button {
    //
    //}
  }

}

.login-popup {

  min-height: 30em;

  /* padding: 2.25em 3.125em; */
  position: fixed;
  bottom: auto;
  /* padding: 2.25em 3.125em; */
  .bltd--modal-dialog {
    max-width: 25em;
    width: 90%;
    /*position: absolute;
    right: 0;
    padding-top: 80px;
    margin: 0;*/
  }

  .bltd--modal-content {
    text-align: center;
    border-radius: 0.25em;
    background: #FFFFFF;
    max-width: 25em;
    margin: 0px auto;
    width: 100%;
    box-shadow: 0 1.875em 1.875em rgba(0, 0, 0, 0.5);
  }

  &.is-del {
    top: 50%;
    right: inherit;
    right: initial;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;

    .fake.bltd--ui.bltd--dropdown {
      display: none;
    }

    &:before {
      content: none;
    }
  }

  .fake {
    top: 0;
    margin-top: -3.675em;
    position: absolute;

    padding: .75em 1.5em .75em 1em;
    /* position: relative; */
    right: 0;
    font-weight: $font-600;
    line-height: 1em;

    border-color: #ffffff;
    box-shadow: 0 0 0 1px #fff inset;
    color: #fff;

    & > i.bltd--dropdown.bltd--icon {
      padding: 0;
      float: right;
      margin: 0 0 0 .75em;
    }

  }

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 $px13 $px13 $px13;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    top: -$px13;
    right: 22%;
  }
  .social-links {
    margin-bottom: 1em;
  }
  .bltd--h, .bltd--message {
    margin-bottom: 1.1875em;
  }
  h3 {
    font-size: $px22;
    font-weight: 400;
    line-height: $px26;
    color: #4A4A4A;

    &.bltd--error {
      color: #DD4B39;
    }
  }
  .bltd--login-links {
    margin-top: 24px;
    text-align: center;
  }
  .bltd--login-links a,
  .bltd--login-links span {
    font-size: $px14;

  }

  .bltd--confirmation-action {
    display: flex;
    justify-content: space-between;
    //> button {
    //
    //}
  }

}

.login-popup .bltd--is-loading {
  opacity: 0.45;
  position: absolute;
  background-color: white;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.login-popup .bltd--is-loading .panel-loading {
  top: 0px;
  left: 0px;
  margin-left: 35%;
  position: absolute;
  top: 50%;
  height: 100px;
  margin-top: -50px;
}

.confirm-window {
  position: fixed;
  display: table;
  z-index: 99999;
  width: 100%;
  height: 100%;

  > div {
    position: absolute;
    top: 30%;
    left: 37%;
    width: 25%;
    background: #fff;
    padding: 1.5em;
    text-align: center;
  }

  .bltd--modal-dialog {
    .bltd--confirmation-action {
      margin-top: 2em;

      button {
        display: inline-block;
        width: 30%;
      }
    }
  }
}

.bltd--text-green {
  color: #82b548;
}

.bltd--coupons, .bltd--reg-alert, .bltd--move-order{
  .bltd--modal-dialog{
    max-width: 25em;
    width: 90%;
  }
  .bltd--h, .bltd--message {
    margin-bottom: 1.1875em;
  }
  .bltd--modal-content{
    text-align: center;
  }
  h3{
    font-size: 1.5625em;
    font-weight: 400;
    line-height: 1.625em;
    color: #4A4A4A;
  }
  .bltd--ui{
    margin-bottom: 20px;
  }
  .bltd--spinner-wrap{
    margin-top: -20px;
  }

  &-del {
    .bltd--modal-dialog {
      max-width: 18em;
      width: 90%;
    }
    .bltd--error.bltd--msg{
      margin-top: 1em;
      font-size: 0.875em;
      color: #dd4b39 !important;
      margin-bottom: 0;
    }
    .bltd--spinner-wrap{
      margin-top: 0;
    }
  }
}
