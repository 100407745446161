.bltd--checkout--block.bltd--checkout--block_seat {
    border-color: $font-color-impaired;
}

.bltd--spinner-wrap .bltd--text {
    color: $font-color-impaired;
}

.bltd--page--checkout {
    font-size: 2.4rem;
    @include media('<=tablet') {
        font-size: 1.9rem;

        .bltd--checkout--block.bltd--checkout--block_seat .bltd--seat--submit {
            width: 7.125em;
        }

        .bltd--expand--back .bltd--note, .bltd--expand--back .bltd--expand--nav {
            color: $font-color-impaired;
        }

        .bltd--expand--back .bltd--expand--nav:before {
            filter: brightness(0) saturate(100%);
        }

        .bltd--expand--seat .bltd--checkout--block_seat .bltd--seat--scheme {
            background: $bg-color-impaired;
            font-size: 1.6rem;
        }
    }

    article {
        background: $bg-color-impaired;
    }

    aside .bltd--aside-wrapper {
        background: $bg-color-impaired;
        border: 0.125em solid $font-color-impaired;
    }

    aside .bltd--waypoint .bltd--point.bltd--point--date:before {
        top: 5px;
    }

    .bltd--expand-link .bltd--txt {
        color: $font-color-impaired;
        text-decoration: underline;
        margin-right: 1em;
    }

    .bltd--scheme {
        &,&:before,&:after {
            border: 0.0625em solid $font-color-impaired;
        }
        .bltd--places .bltd--places__item {
            border: 0.0625em solid $font-color-impaired;
            color: $font-color-impaired;

            &.bltd--by-ticket {
                background: $font-color-impaired;
                border-color: $font-color-impaired;
                color: $bg-color-impaired;
            }

            &.bltd--empty {
                border: none;
            }
        }
    }

    #bltd--cost .bltd--ui.bltd--tag.bltd--labels .bltd--label, .bltd--page--checkout #bltd--cost .bltd--ui.bltd--tag.bltd--label {
        color: $font-color-impaired;
    }
}


.bltd--payment--list {
    .bltd--heading .bltd--title {
        font-size: 3.2rem;
        color: $font-color-impaired;
        font-weight: 400;
        line-height: 1.1;
    }

    .bltd--note--warning {
        color: $font-color-impaired !important;
        text-decoration: underline;
    }
}

.bltd--pseudo-link {
    color: $font-color-impaired;
    border-color: $font-color-impaired;

    &:active, &:focus, &:hover {
        color: $font-color-impaired;
    }
}

.bltd--page--checkout aside .bltd--waypoint .bltd--point.bltd--point--departure:after,
.bltd--page--checkout aside .bltd--waypoint .bltd--point.bltd--point--destination:after,
.bltd--page--checkout aside .bltd--waypoint .bltd--point.bltd--point--date:after {
    border-color: $font-color-impaired;
}

.bltd--flex--ticket .bltd--labeled--field input {
    border: 1px solid $font-color-impaired;

    &:focus {
        border-color: $font-color-impaired;
        box-shadow: none;
    }
}

.bltd--flex--ticket .bltd--labeled--field.bltd--labeled--field-account .bltd--labeled--field-field .bltd--input-container .bltd--input--toggle:after {
    background-position: -227px -88px;
}

.bltd--labeled--field input.ng-touched.ng-invalid, .bltd--aside-wrapper input.ng-touched.ng-invalid,
.bltd--labeled--field input.ng-dirty.ng-invalid, .bltd--aside-wrapper input.ng-dirty.ng-invalid {
    border-color: $font-color-impaired !important;
    color: $font-color-impaired !important;
    box-shadow: none;
    border-width: 0.125em;
    box-shadow: none !important;
}
.bltd--checkout--block .bltd--counter {
    color: $font-color-impaired;
}

form.ng-submitted .bltd--labeled--field input.ng-invalid {
    border-color: $font-color-impaired !important;
    color: $font-color-impaired !important;
    box-shadow: none !important;
    border-width: 0.125em;
}

.bltd--page--return .bltd--notice:before {
    filter: brightness(0) saturate(100%);
}

.bltd--ui.checkbox input:checked ~ .box:before, .bltd--ui.checkbox input:checked ~ label:before {
    background: $font-color-impaired;
    box-shadow: 0 0 0 0.125em $font-color-impaired inset;
}

.bltd--wrap {
    padding-left: 0;
    padding-right: 0;
}
.bltd--bus-model .bltd--model {
    font-size: .875em;
}

.bltd--page--checkout aside {
    top: 0 !important;
}

.bltd--page--checkout .bltd--major {
    margin-bottom: 145px;
}
