.bltd--ui.bltd--dropdown {
  cursor: pointer;
  position: relative;
  display: inline-block;
  outline: none;
  text-align: left;

  -webkit-transition: box-shadow 0.1s ease, width 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.bltd--ui.bltd--dropdown {
  box-shadow: 0 0 0 /*.125em*/1px $base-gray-border inset;
  border-radius: $border-radius;
  &.no-border {
    border: none;
    box-shadow: none;
  }
}

.bltd--ui.bltd--dropdown .bltd--menu {
  cursor: auto;
  position: absolute;
  display: none;
  outline: none;
  top: 100%;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  margin: 0;
  padding: 0 0;
  background: #FFFFFF;
  font-size: .875em;
  text-shadow: none;
  text-align: left;
  box-shadow: 0 0.625em 0.625em rgba(0, 0, 0, 0.1);
  border: 1px solid #e4e4e4;
  /*border: 1px solid rgba(34, 36, 38, 0.15);*/
  /*border-radius: $border-radius;*/
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
  z-index: 11;
  will-change: transform, opacity;
}

.bltd--ui.bltd--dropdown .bltd--menu > * {
  white-space: nowrap;
}

/*--------------
  Hidden Input
---------------*/

.bltd--ui.bltd--dropdown > input:not(.search):first-child,
.bltd--ui.bltd--dropdown > select {
  display: none !important;
}

/*--------------
 Dropdown Icon
---------------*/

.bltd--ui.bltd--dropdown > .bltd--dropdown.bltd--icon {
  position: relative;
  width: auto;
  margin: 0 0 0 .75em;
}
.bltd--ui.bltd--dropdown .bltd--menu > .bltd--item .bltd--dropdown.bltd--icon {
  width: auto;
  float: right;
  margin: 0 0 0 .75em;
}
.bltd--ui.bltd--dropdown .bltd--menu > .bltd--item .bltd--dropdown.bltd--icon + .bltd--text {
  margin-right: 1em;
}

/*--------------
      Text
---------------*/

.bltd--ui.bltd--dropdown > .bltd--text {
  display: inline-block;
  -webkit-transition: none;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.2s ease 0s;
  white-space: nowrap;
  width: 95%;
}

/*--------------
    Menu Item
---------------*/

.bltd--ui.bltd--dropdown .bltd--menu > .bltd--item {
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  height: auto;
  text-align: left;

 /* line-height: 1.215em;


  font-size: 0.85em;*/
  text-transform: none;
  font-weight: normal;
  box-shadow: none;
  -webkit-touch-callout: none;
}
.bltd--ui.bltd--dropdown .bltd--menu > .bltd--item:first-child {
  border-top-width: 0px;
}

/*--------------
  Floated Content
---------------*/

.bltd--ui.bltd--dropdown > .bltd--text > [class*="right floated"],
.bltd--ui.bltd--dropdown .bltd--menu .bltd--item > [class*="right floated"] {
  float: right !important;
  margin-right: 0 !important;
  margin-left: 1em !important;
}
.bltd--ui.bltd--dropdown > .bltd--text > [class*="left floated"],
.bltd--ui.bltd--dropdown .bltd--menu .bltd--item > [class*="left floated"] {
  float: left !important;
  margin-left: 0 !important;
  margin-right: 1em !important;
}
.bltd--ui.bltd--dropdown .bltd--menu .bltd--item > .bltd--icon.floated,
.bltd--ui.bltd--dropdown .bltd--menu .bltd--item > .flag.floated,
.bltd--ui.bltd--dropdown .bltd--menu .bltd--item > .image.floated,
.bltd--ui.bltd--dropdown .bltd--menu .bltd--item > img.floated {
  margin-top: 0;
}

/*--------------
  Menu Divider
---------------*/

/*.bltd--ui.bltd--dropdown .bltd--menu > .header {
  margin: 1rem 0rem 0.75rem;
  padding: 0 1.14285714rem;
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.78571429em;
  font-weight: bold;
  text-transform: uppercase;
}*/
.bltd--ui.bltd--dropdown .bltd--menu > .bltd--divider {
  border-top: 1px solid rgba(223, 223, 224, 0.5);

  height: 0;
  padding-bottom: 1em;

}
/*
.bltd--ui.bltd--dropdown .bltd--menu > .input {
  width: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 1.14285714rem 0.71428571rem;
  min-width: 10rem;
}
.bltd--ui.bltd--dropdown .bltd--menu > .header + .input {
  margin-top: 0;
}
.bltd--ui.bltd--dropdown .bltd--menu > .input:not(.transparent) input {
  padding: 0.5em 1em;
}
.bltd--ui.bltd--dropdown .bltd--menu > .input:not(.transparent) .button,
.bltd--ui.bltd--dropdown .bltd--menu > .input:not(.transparent) .bltd--icon,
.bltd--ui.bltd--dropdown .bltd--menu > .input:not(.transparent) .label {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}*/

/*-----------------
  Item Description
-------------------*/

.bltd--ui.bltd--dropdown > .bltd--text > .description,
.bltd--ui.bltd--dropdown .bltd--menu > .bltd--item > .description {
  float: right;
  margin: 0 0 0 1em;
  color: rgba(0, 0, 0, 0.4);
}

/*-----------------
       Message
-------------------*/

.bltd--ui.bltd--dropdown .bltd--menu > .bltd--message {

  font-weight: normal;
}
.bltd--ui.bltd--dropdown .bltd--menu > .bltd--message:not(.bltd--ui) {
  color: rgba(0, 0, 0, 0.4);
}

/*--------------
    Sub Menu
---------------*/

.bltd--ui.bltd--dropdown .bltd--menu .bltd--menu {
  top: 0% !important;
  left: 100% !important;
  right: auto !important;
  margin: 0 0 0 -0.5em !important;
  border-radius: 0.28571429rem !important;
  z-index: 21 !important;
}

/* Hide Arrow */
.bltd--ui.bltd--dropdown .bltd--menu .bltd--menu:after {
  display: none;
}

/*--------------
   Sub Elements
---------------*/


/* Icons / Flags / Labels / Image */
.bltd--ui.bltd--dropdown > .bltd--text > .bltd--icon,
.bltd--ui.bltd--dropdown > .bltd--text > .label,
.bltd--ui.bltd--dropdown > .bltd--text > .flag,
.bltd--ui.bltd--dropdown > .bltd--text > img,
.bltd--ui.bltd--dropdown > .bltd--text > .image {
  margin-top: 0;
}
.bltd--ui.bltd--dropdown .bltd--menu > .bltd--item > .bltd--icon,
.bltd--ui.bltd--dropdown .bltd--menu > .bltd--item > .label,
.bltd--ui.bltd--dropdown .bltd--menu > .bltd--item > .flag,
.bltd--ui.bltd--dropdown .bltd--menu > .bltd--item > .image,
.bltd--ui.bltd--dropdown .bltd--menu > .bltd--item > img {
  margin-top: 0;
}
.bltd--ui.bltd--dropdown > .bltd--text > .bltd--icon,
.bltd--ui.bltd--dropdown > .bltd--text > .label,
.bltd--ui.bltd--dropdown > .bltd--text > .flag,
.bltd--ui.bltd--dropdown > .bltd--text > img,
.bltd--ui.bltd--dropdown > .bltd--text > .image,
.bltd--ui.bltd--dropdown .bltd--menu > .bltd--item > .bltd--icon,
.bltd--ui.bltd--dropdown .bltd--menu > .bltd--item > .label,
.bltd--ui.bltd--dropdown .bltd--menu > .bltd--item > .flag,
.bltd--ui.bltd--dropdown .bltd--menu > .bltd--item > .image,
.bltd--ui.bltd--dropdown .bltd--menu > .bltd--item > img {
  margin-left: 0;
  float: none;

}

/*--------------
     Image
---------------*/

.bltd--ui.bltd--dropdown > .bltd--text > img,
.bltd--ui.bltd--dropdown > .bltd--text > .image,
.bltd--ui.bltd--dropdown .bltd--menu > .bltd--item > .image,
.bltd--ui.bltd--dropdown .bltd--menu > .bltd--item > img {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  max-height: 2em;
}


/*******************************
            Coupling
*******************************/


/*--------------
      Menu
---------------*/


/* Remove Menu Item Divider */
.bltd--ui.bltd--dropdown .bltd--ui.bltd--menu > .bltd--item:before,
.bltd--ui.bltd--menu .bltd--ui.bltd--dropdown .bltd--menu > .bltd--item:before {
  display: none;
}

/* Prevent Menu Item Border */
.bltd--ui.bltd--menu .bltd--ui.bltd--dropdown .bltd--menu .active.bltd--item {
  border-left: none;
}

/* Automatically float dropdown menu right on last menu item */
.bltd--ui.bltd--menu .right.bltd--menu .bltd--dropdown:last-child .bltd--menu,
.bltd--ui.bltd--menu .right.bltd--dropdown.bltd--item .bltd--menu,
.bltd--ui.bltd--buttons > .bltd--ui.bltd--dropdown:last-child .bltd--menu {
  left: auto;
  right: 0;
}

/*--------------
      Label
---------------*/


/* Dropdown Menu */
.bltd--ui.label.bltd--dropdown .bltd--menu {
  min-width: 100%;
}

/*--------------
     Button
---------------*/


/* No Margin On Icon Button */
.bltd--ui.bltd--dropdown.bltd--icon.button > .bltd--dropdown.bltd--icon {
  margin: 0;
}
.bltd--ui.button.bltd--dropdown .bltd--menu {
  min-width: 100%;
}


/*******************************
              Types
*******************************/


/*--------------
    Selection
---------------*/


/* Displays like a select box */
.bltd--ui.selection.bltd--dropdown {
  cursor: pointer;
  word-wrap: break-word;
  line-height: 1em;
  white-space: normal;
  outline: 0;
  -webkit-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
  //min-width: 14em;
  /*min-height: 2.5em;*/
  background: #FFFFFF;
  display: inline-block;
  min-height: 2.5em;
 /* padding: 0.5em .5em .5em 1em;*/

  padding: 0.6125em .5em 0.6125em .5em;
  -webkit-transition: box-shadow 0.1s ease, width 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease;


  border: 0.0625em solid #e5e4de;
  color: #4a4a4a;
  border-radius: 0.25em;
  box-shadow: 0 0 0 0.0625em #e5e4de;


}
.bltd--ui.selection.bltd--dropdown.visible,
.bltd--ui.selection.bltd--dropdown.active {
  z-index: 10;
}
select.bltd--ui.bltd--dropdown {
  height: 38px;
  padding: 0.5em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  visibility: visible;
}
.bltd--ui.selection.bltd--dropdown > .search.bltd--icon,
.bltd--ui.selection.bltd--dropdown > .bltd--delete.bltd--icon,
.bltd--ui.selection.bltd--dropdown > .bltd--dropdown.bltd--icon {
  cursor: pointer;
  position: absolute;
  top: auto;
  width: auto;
  z-index: 3;
 /* margin: -0.78571429em;
  padding: 0.78571429em;
  right: 1em;
  opacity: 0.8;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;*/
  &:before {
    color: #9a9a9a;
    border-left: .35em solid transparent;
    border-right: .35em solid transparent;
    border-top: .35em solid;
    content: '';
    left: 0;
    margin: 0 0 0 -0.5em;
    position: absolute;
    top: 50%;
    height: 0;
    width: 0;
    background-image: none;
  }
}

/* Compact */
.bltd--ui.compact.selection.bltd--dropdown {
  min-width: 0px;
}

/*  Selection Menu */
.bltd--ui.selection.bltd--dropdown .bltd--menu {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  border-top-width: 0px !important;
  width: auto;
  outline: none;
  margin: .125em -1px;
  min-width: calc(100% +  2px );
  width: calc(100% +  2px );
  border-radius: 0 0 0.28571429rem 0.28571429rem;
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
}
.bltd--ui.selection.bltd--dropdown .bltd--menu:after,
.bltd--ui.selection.bltd--dropdown .bltd--menu:before {
  display: none;
}

/*--------------
    Message
---------------*/

.bltd--ui.selection.bltd--dropdown .bltd--menu > .bltd--message {
  padding: 0.71428571rem 1.14285714rem;
}
@media only screen and (max-width: 767px) {
  .bltd--ui.selection.bltd--dropdown .bltd--menu {
    max-height: 7.58571429rem;
  }
}
@media only screen and (min-width: 768px) {
  .bltd--ui.selection.bltd--dropdown .bltd--menu {
    max-height: 10.11428571rem;
  }
}
@media only screen and (min-width: 992px) {
  .bltd--ui.selection.bltd--dropdown .bltd--menu {
    max-height: 15.17142857rem;
  }
}
@media only screen and (min-width: 1920px) {
  .bltd--ui.selection.bltd--dropdown .bltd--menu {
    max-height: 20.22857143rem;
  }
}

/* Menu Item */
.bltd--ui.selection.bltd--dropdown .bltd--menu > .bltd--item {
  border-top: 1px solid #FAFAFA;
  padding: 0.71428571rem 1.14285714rem !important;
  white-space: normal;
  word-wrap: normal;
}

/* Hover */
.bltd--ui.selection.bltd--dropdown:hover {
  border-color: rgba(34, 36, 38, 0.35);
  box-shadow: none;
}

/* Active */
.bltd--ui.selection.active.bltd--dropdown {
  /*border-color: rgba(0, 0, 0, 0.2);;
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);*/
 }
.bltd--ui.selection.active.bltd--dropdown .bltd--menu {
  border-color: rgba(0, 0, 0, 0.2);;
  box-shadow: 0 1em 1em 0 rgba(0, 0, 0, 0.1)
}

/* Focus */
.bltd--ui.selection.bltd--dropdown:focus {
  border-color: #82b548;
  box-shadow: 0 0 0 0.125em rgba(130, 181, 72, 0.15), 0.0625em 0.0625em 0.0625em 0.0625em rgba(0, 0, 0, 0.05) inset;

}
.bltd--ui.selection.bltd--dropdown:focus .bltd--menu {
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
}

/* Visible */
.bltd--ui.selection.visible.bltd--dropdown > .bltd--text:not(.default) {
  font-weight: normal;
  color: rgba(0, 0, 0, 0.8);
}

/* Visible Hover */
.bltd--ui.selection.active.bltd--dropdown:hover {
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
}
.bltd--ui.selection.active.bltd--dropdown:hover .bltd--menu {
  border-color: rgba(0, 0, 0, 0.2);;
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
}

/* Dropdown Icon */
.bltd--ui.active.selection.bltd--dropdown > .bltd--dropdown.bltd--icon,
.bltd--ui.visible.selection.bltd--dropdown > .bltd--dropdown.bltd--icon {
  opacity: 1;
  z-index: 3;
}

/* Connecting Border */
.bltd--ui.active.selection.bltd--dropdown {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/*--------------
   Searchable
---------------*/


/* Search Selection */
.bltd--ui.search.bltd--dropdown {
  min-width: '';
}

/* Search Dropdown */
.bltd--ui.search.bltd--dropdown > input.search {
  background: none transparent !important;
  border: none !important;
  box-shadow: none !important;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  padding: inherit;
}

/* Text Layering */
.bltd--ui.search.bltd--dropdown > input.search {
  position: absolute;
  z-index: 2;
}
.bltd--ui.search.bltd--dropdown > .bltd--text {
  cursor: text;
  position: relative;
  z-index: 3;
}

/* Search Selection */
.bltd--ui.search.selection.bltd--dropdown > input.search {
  line-height: 1.2142em;
  padding: 0.67861429em 2.6em 0.67861429em 1em;
}

/* Active/Visible Search */
.bltd--ui.search.bltd--dropdown.active > input.search,
.bltd--ui.search.bltd--dropdown.visible > input.search {
  cursor: auto;
}
.bltd--ui.search.bltd--dropdown.active > .bltd--text,
.bltd--ui.search.bltd--dropdown.visible > .bltd--text {
  pointer-events: none;
}

/* Filtered Text */
.bltd--ui.active.search.bltd--dropdown input.search:focus + .bltd--text .bltd--icon,
.bltd--ui.active.search.bltd--dropdown input.search:focus + .bltd--text .flag {
  opacity: 0.45;
}
.bltd--ui.active.search.bltd--dropdown input.search:focus + .bltd--text {
  color: rgba(0, 0, 0, 0.4) !important;
}

/* Search Menu */
.bltd--ui.search.bltd--dropdown .bltd--menu {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
}
@media only screen and (max-width: 767px) {
  .bltd--ui.search.bltd--dropdown .bltd--menu {
    max-height: 7.58571429rem;
  }
}
@media only screen and (min-width: 768px) {
  .bltd--ui.search.bltd--dropdown .bltd--menu {
    max-height: 10.11428571rem;
  }
}
@media only screen and (min-width: 992px) {
  .bltd--ui.search.bltd--dropdown .bltd--menu {
    max-height: 15.17142857rem;
  }
}
@media only screen and (min-width: 1920px) {
  .bltd--ui.search.bltd--dropdown .bltd--menu {
    max-height: 20.22857143rem;
  }
}

/*--------------
    Multiple
---------------*/


/* Multiple Selection */
.bltd--ui.multiple.bltd--dropdown {
  padding: 0.22620476em 2.6em 0.22620476em 0.28571429em;
}
.bltd--ui.multiple.bltd--dropdown .bltd--menu {
  cursor: auto;
}

/* Multiple Search Selection */
.bltd--ui.multiple.search.bltd--dropdown,
.bltd--ui.multiple.search.bltd--dropdown > input.search {
  cursor: text;
}

/* Selection Label */
.bltd--ui.multiple.bltd--dropdown > .label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  font-size: 1em;
  padding: 0.35714286em 0.71428571em;
  margin: 0.21428571em 0.28571429rem 0.21428571em 0;
  box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
}

/* Dropdown Icon */
.bltd--ui.multiple.bltd--dropdown .bltd--dropdown.bltd--icon {
  margin: 0 -0.71428571em 0 0;
  padding: 0.5em;
}

/* Text */
.bltd--ui.multiple.bltd--dropdown > .bltd--text {
  position: static;
  padding: 0;
  max-width: 100%;
  margin: 0.45240952em 0 0.45240952em 0.71428571em;
  line-height: 1.2142em;
}
.bltd--ui.multiple.bltd--dropdown > .label ~ .bltd--text {
  display: none;
}

/*-----------------
  Multiple Search
-----------------*/


/* Prompt Text */
.bltd--ui.multiple.search.bltd--dropdown > .bltd--text {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  padding: inherit;
  margin: 0.45240952em 0 0.45240952em 0.71428571em;
  line-height: 1.2142em;
}
.bltd--ui.multiple.search.bltd--dropdown > .label ~ .bltd--text {
  display: none;
}

/* Search */
.bltd--ui.multiple.search.bltd--dropdown > input.search {
  position: static;
  padding: 0;
  max-width: 100%;
  margin: 0.45240952em 0 0.45240952em 0.71428571em;
  width: 2.2em;
  line-height: 1.2142em;
}

/*--------------
     Inline
---------------*/

.bltd--ui.inline.bltd--dropdown {
  cursor: pointer;
  display: inline-block;
  color: inherit;
}
.bltd--ui.inline.bltd--dropdown .bltd--dropdown.bltd--icon {
  margin: 0 0.5em 0 0.25em;
  vertical-align: baseline;
}
.bltd--ui.inline.bltd--dropdown > .bltd--text {
  font-weight: bold;
}
.bltd--ui.inline.bltd--dropdown .bltd--menu {
  cursor: auto;
  margin-top: 0.25em;
  border-radius: 0.28571429rem;
}


/*******************************
            States
*******************************/


/*--------------------
        Active
----------------------*/


/* Menu Item Active */
.bltd--ui.bltd--dropdown .bltd--menu .active.bltd--item {
  background: transparent;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.95);
  box-shadow: none;
  z-index: 12;
}

/*--------------------
        Hover
----------------------*/


/* Menu Item Hover */
.bltd--ui.bltd--dropdown .bltd--menu > .bltd--item:hover {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
  z-index: 13;
}

/*--------------------
       Loading
---------------------*/


/* Positioning */
.bltd--ui.loading.bltd--dropdown > i.bltd--icon:before,
.bltd--ui.loading.bltd--dropdown > i.bltd--icon:after {
  left: 30% !important;
}
.bltd--ui.loading.bltd--dropdown > i.bltd--icon {
  top: 50% !important;
}
.bltd--ui.multiple.loading.bltd--dropdown > i.bltd--icon:before,
.bltd--ui.multiple.loading.bltd--dropdown > i.bltd--icon:after {
  top: 0% !important;
  left: 0% !important;
}
.bltd--ui.loading.bltd--dropdown > i.bltd--icon:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0 0 -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  border-radius: 500rem;
  border: 0.2em solid rgba(0, 0, 0, 0.1);
}
.bltd--ui.loading.bltd--dropdown > i.bltd--icon:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  box-shadow: 0px 0px 0px 1px transparent;
  margin: -0.64285714em 0 0 -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  -webkit-animation: dropdown-spin 0.6s linear;
  animation: dropdown-spin 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #767676 transparent transparent;
  border-style: solid;
  border-width: 0.2em;
}

/* Coupling */
.bltd--ui.loading.bltd--dropdown.button > i.bltd--icon:before,
.bltd--ui.loading.bltd--dropdown.button > i.bltd--icon:after {
  display: none;
}
@-webkit-keyframes dropdown-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes dropdown-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*--------------------
     Default Text
----------------------*/

.bltd--ui.bltd--dropdown > .default.bltd--text,
.bltd--ui.default.bltd--dropdown > .bltd--text {
  /*color: rgba(179, 179, 179, 0.7);*/
}
.bltd--ui.bltd--dropdown:hover > .default.bltd--text,
.bltd--ui.default.bltd--dropdown:hover > .bltd--text {
  /*color: rgba(179, 179, 179, 0.7);*/
}

/*--------------------
        Loading
----------------------*/

.bltd--ui.loading.bltd--dropdown > .bltd--text {
  -webkit-transition: none;
  transition: none;
}

/* Used To Check Position */
.bltd--ui.bltd--dropdown .loading.bltd--menu {
  display: block;
  visibility: hidden;
  z-index: -1;
}

/*--------------------
    Keyboard Select
----------------------*/


/* Selected Item */
.bltd--ui.bltd--dropdown.selected,
.bltd--ui.bltd--dropdown .bltd--menu .selected.bltd--item {
  background: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.95);
}

/*--------------------
    Search Filtered
----------------------*/


/* Filtered Item */
.bltd--ui.bltd--dropdown > .filtered.bltd--text {
  visibility: hidden;
}
.bltd--ui.bltd--dropdown .filtered.bltd--item {
  display: none !important;
}

/*--------------------
        Error
----------------------*/

.bltd--ui.bltd--dropdown.bltd--error,
.bltd--ui.bltd--dropdown.bltd--error > .bltd--text,
.bltd--ui.bltd--dropdown.bltd--error > .default.bltd--text {
  color: #9F3A38;
}
.bltd--ui.selection.bltd--dropdown.bltd--error {
  background: #FFF6F6;
  border-color: #E0B4B4;
}
.bltd--ui.selection.bltd--dropdown.bltd--error:hover {
  border-color: #E0B4B4;
}
.bltd--ui.bltd--dropdown.bltd--error > .bltd--menu,
.bltd--ui.bltd--dropdown.bltd--error > .bltd--menu .bltd--menu {
  border-color: #E0B4B4;
}
.bltd--ui.bltd--dropdown.bltd--error > .bltd--menu > .bltd--item {
  color: #9F3A38;
}
.bltd--ui.multiple.selection.bltd--error.bltd--dropdown > .label {
  border-color: #E0B4B4;
}

/* Item Hover */
.bltd--ui.bltd--dropdown.bltd--error > .bltd--menu > .bltd--item:hover {
  background-color: #FFF2F2;
}

/* Item Active */
.bltd--ui.bltd--dropdown.bltd--error > .bltd--menu .active.bltd--item {
  background-color: #FDCFCF;
}

/*--------------------
        Disabled
----------------------*/


/* Disabled */
.bltd--ui.disabled.bltd--dropdown,
.bltd--ui.bltd--dropdown .bltd--menu > .disabled.bltd--item {
  cursor: default;
  pointer-events: none;
  opacity: 0.45;
}


/*******************************
           Variations
*******************************/


/*--------------
    Direction
---------------*/


/* Flyout Direction */
.bltd--ui.bltd--dropdown .bltd--menu {
  left: 0px;
}

/* Default Side (Right) */
.bltd--ui.bltd--dropdown .right.bltd--menu > .bltd--menu,
.bltd--ui.bltd--dropdown .bltd--menu .right.bltd--menu {
  left: 100% !important;
  right: auto !important;
  border-radius: 0.28571429rem !important;
}

/* Left Flyout Menu */
.bltd--ui.bltd--dropdown > .left.bltd--menu .bltd--menu,
.bltd--ui.bltd--dropdown .bltd--menu .left.bltd--menu {
  left: auto !important;
  right: 100% !important;
  border-radius: 0.28571429rem !important;
}
.bltd--ui.bltd--dropdown .bltd--item .left.bltd--dropdown.bltd--icon,
.bltd--ui.bltd--dropdown .left.bltd--menu .bltd--item .bltd--dropdown.bltd--icon {
  width: auto;
  float: left;
  margin: 0 0.71428571rem 0 0;
}
.bltd--ui.bltd--dropdown .bltd--item .left.bltd--dropdown.bltd--icon,
.bltd--ui.bltd--dropdown .left.bltd--menu .bltd--item .bltd--dropdown.bltd--icon {
  width: auto;
  float: left;
  margin: 0 0.71428571rem 0 0;
}
.bltd--ui.bltd--dropdown .bltd--item .left.bltd--dropdown.bltd--icon + .bltd--text,
.bltd--ui.bltd--dropdown .left.bltd--menu .bltd--item .bltd--dropdown.bltd--icon + .bltd--text {
  margin-left: 1em;
}

/*--------------
     Upward
---------------*/


/* Upward Main Menu */
.bltd--ui.upward.bltd--dropdown > .bltd--menu {
  top: auto;
  bottom: 100%;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.08);
  border-radius: 0.28571429rem 0.28571429rem 0 0;
}

/* Upward Sub Menu */
.bltd--ui.bltd--dropdown .upward.bltd--menu {
  top: auto !important;
  bottom: 0 !important;
}

/* Active Upward */
.bltd--ui.bltd--simple.upward.active.bltd--dropdown,
.bltd--ui.bltd--simple.upward.bltd--dropdown:hover {
  border-radius: 0.28571429rem 0.28571429rem 0 0 !important;
}
.bltd--ui.upward.bltd--dropdown.button:not(.bltd--pointing):not(.floating).active {
  border-radius: 0.28571429rem 0.28571429rem 0 0;
}

/* Selection */
.bltd--ui.upward.selection.bltd--dropdown .bltd--menu {
  border-top-width: 1px !important;
  border-bottom-width: 0px !important;
  box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.08);
}
.bltd--ui.upward.selection.bltd--dropdown:hover {
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.05);
}

/* Active Upward */
.bltd--ui.active.upward.selection.bltd--dropdown {
  border-radius: 0 0 0.28571429rem 0.28571429rem !important;
}

/* Visible Upward */
.bltd--ui.upward.selection.bltd--dropdown.visible {
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.08);
  border-radius: 0 0 0.28571429rem 0.28571429rem !important;
}

/* Visible Hover Upward */
.bltd--ui.upward.active.selection.bltd--dropdown:hover {
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.05);
}
.bltd--ui.upward.active.selection.bltd--dropdown:hover .bltd--menu {
  box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.08);
}

/*--------------
     Simple
---------------*/


/*  Selection Menu */
.bltd--ui.scrolling.bltd--dropdown .bltd--menu,
.bltd--ui.bltd--dropdown .scrolling.bltd--menu {
  overflow-x: hidden;
  overflow-y: auto;
}
.bltd--ui.scrolling.bltd--dropdown .bltd--menu {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  min-width: 100% !important;
  width: auto !important;
}
.bltd--ui.bltd--dropdown .scrolling.bltd--menu {
  position: static;
  overflow-y: auto;
  border: none;
  box-shadow: none !important;
  border-radius: 0 !important;
  margin: 0 !important;
  min-width: 100% !important;
  width: auto !important;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}
.bltd--ui.scrolling.bltd--dropdown .bltd--menu .bltd--item.bltd--item.bltd--item,
.bltd--ui.bltd--dropdown .scrolling.bltd--menu > .bltd--item.bltd--item.bltd--item {
  border-top: none;
  padding-right: calc( 1.14285714rem  +  17px ) !important;
}
.bltd--ui.scrolling.bltd--dropdown .bltd--menu .bltd--item:first-child,
.bltd--ui.bltd--dropdown .scrolling.bltd--menu .bltd--item:first-child {
  border-top: none;
}
.bltd--ui.bltd--dropdown > .animating.bltd--menu .scrolling.bltd--menu,
.bltd--ui.bltd--dropdown > .visible.bltd--menu .scrolling.bltd--menu {
  display: block;
}

/* Scrollbar in IE */
@media all and (-ms-high-contrast: none) {
  .bltd--ui.scrolling.bltd--dropdown .bltd--menu,
  .bltd--ui.bltd--dropdown .scrolling.bltd--menu {
    min-width: calc(100% -  17px );
  }
}
@media only screen and (max-width: 767px) {
  .bltd--ui.scrolling.bltd--dropdown .bltd--menu,
  .bltd--ui.bltd--dropdown .scrolling.bltd--menu {
    max-height: 9.71428571rem;
  }
}
@media only screen and (min-width: 768px) {
  .bltd--ui.scrolling.bltd--dropdown .bltd--menu,
  .bltd--ui.bltd--dropdown .scrolling.bltd--menu {
    max-height: 14.57142857rem;
  }
}
@media only screen and (min-width: 992px) {
  .bltd--ui.scrolling.bltd--dropdown .bltd--menu,
  .bltd--ui.bltd--dropdown .scrolling.bltd--menu {
    max-height: 19.42857143rem;
  }
}
@media only screen and (min-width: 1920px) {
  .bltd--ui.scrolling.bltd--dropdown .bltd--menu,
  .bltd--ui.bltd--dropdown .scrolling.bltd--menu {
    max-height: 19.42857143rem;
  }
}

/*--------------
     Simple
---------------*/


/* Displays without javascript */
.bltd--ui.bltd--simple.bltd--dropdown .bltd--menu:before,
.bltd--ui.bltd--simple.bltd--dropdown .bltd--menu:after {
  display: none;
}
.bltd--ui.bltd--simple.bltd--dropdown .bltd--menu {
  position: absolute;
  display: block;
  overflow: hidden;
  top: -9999px !important;
  opacity: 0;
  width: 0;
  height: 0;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
}
.bltd--ui.bltd--simple.active.bltd--dropdown,
.bltd--ui.bltd--simple.bltd--dropdown:hover {
 /* border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;*/

}
.bltd--ui.bltd--simple.active.bltd--dropdown > .bltd--menu,
.bltd--ui.bltd--simple.bltd--dropdown:hover > .bltd--menu,
.bltd--ui.bltd--simple.bltd--dropdown:target > .bltd--menu {
  overflow: visible;
  width: auto;
  height: auto;
  min-width: 220px;
  top: 100% !important;
  margin-top: -18px;
  opacity: 1;
  padding: 0.9375em;

  &--support {
    margin-top: 0;
  }
}
.bltd--ui.bltd--simple.bltd--dropdown > .bltd--menu > .bltd--item:active > .bltd--menu,
.bltd--ui.bltd--simple.bltd--dropdown:hover > .bltd--menu > .bltd--item:hover > .bltd--menu {
  overflow: visible;
  width: auto;
  height: auto;
  min-width: 220px;
  top: 0% !important;
  margin-top: -18px;
  left: 100% !important;
  opacity: 1;
}
.bltd--ui.bltd--simple.disabled.bltd--dropdown:hover .bltd--menu {
  display: none;
  height: 0;
  width: 0;
  overflow: hidden;
}

/* Visible */
.bltd--ui.bltd--simple.visible.bltd--dropdown > .bltd--menu {
  display: block;
}

/*--------------
      Fluid
---------------*/

.bltd--ui.fluid.bltd--dropdown {
  display: block;
  width: 100%;
  min-width: 0;
}
.bltd--ui.fluid.bltd--dropdown > .bltd--dropdown.bltd--icon {
  float: right;
}

/*--------------
    Floating
---------------*/

.bltd--ui.floating.bltd--dropdown .bltd--menu {
  left: 0;
  right: auto;
  box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.08) !important;
  border-radius: 0.28571429rem !important;
}
.bltd--ui.floating.bltd--dropdown > .bltd--menu {
  margin-top: 0.5em !important;
  border-radius: 0.28571429rem !important;
}

/*--------------
     Pointing
---------------*/

.bltd--ui.bltd--pointing.bltd--dropdown > .bltd--menu {
  top: 100%;

}
.bltd--ui.bltd--pointing.bltd--dropdown > .bltd--menu:after {
  display: block;
  position: absolute;
  pointer-events: none;
  content: '';
  visibility: visible;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 0.5em;
  height: 0.5em;
  box-shadow: -1px -1px 0px 1px rgba(34, 36, 38, 0.15);
  background: #FFFFFF;
  z-index: 2;
}
.bltd--ui.bltd--pointing.bltd--dropdown > .bltd--menu:after {
  top: -0.25em;
  left: 50%;
  margin: 0 0 0 -0.25em;
}

/* Top Left Pointing */
.bltd--ui.top.left.bltd--pointing.bltd--dropdown > .bltd--menu {
  top: 100%;
  bottom: auto;
  left: 0%;
  right: auto;
  margin: 1em 0 0;
}
.bltd--ui.top.left.bltd--pointing.bltd--dropdown > .bltd--menu {
  top: 100%;
  bottom: auto;
  left: 0%;
  right: auto;
  margin: 1em 0 0;
}
.bltd--ui.top.left.bltd--pointing.bltd--dropdown > .bltd--menu:after {
  top: -0.25em;
  left: 1em;
  right: auto;
  margin: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Top Right  Pointing */
.bltd--ui.top.right.bltd--pointing.bltd--dropdown > .bltd--menu {
  top: 100%;
  bottom: auto;
  right: 0%;
  left: auto;
  margin: 1em 0 0;
}
.bltd--ui.top.right.bltd--pointing.bltd--dropdown > .bltd--menu:after {
  top: -0.25em;
  left: auto;
  right: 1em;
  margin: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Left Pointing */
.bltd--ui.left.bltd--pointing.bltd--dropdown > .bltd--menu {
  top: 0%;
  left: 100%;
  right: auto;
  margin: 0 0 0 1em;
}
.bltd--ui.left.bltd--pointing.bltd--dropdown > .bltd--menu:after {
  top: 1em;
  left: -0.25em;
  margin: 0 0 0 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* Right Pointing */
.bltd--ui.right.bltd--pointing.bltd--dropdown > .bltd--menu {
  top: 0%;
  left: auto;
  right: 100%;
  margin: 0 1em 0 0;
}
.bltd--ui.right.bltd--pointing.bltd--dropdown > .bltd--menu:after {
  top: 1em;
  left: auto;
  right: -0.25em;
  margin: 0 0 0 0;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

/* Bottom Pointing */
.bltd--ui.bottom.bltd--pointing.bltd--dropdown > .bltd--menu {
  top: auto;
  bottom: 100%;
  left: 0%;
  right: auto;
  margin: 0 0 1em;
}
.bltd--ui.bottom.bltd--pointing.bltd--dropdown > .bltd--menu:after {
  top: auto;
  bottom: -0.25em;
  right: auto;
  margin: 0;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

/* Reverse Sub-Menu Direction */
.bltd--ui.bottom.bltd--pointing.bltd--dropdown > .bltd--menu .bltd--menu {
  top: auto !important;
  bottom: 0px !important;
}

/* Bottom Left */
.bltd--ui.bottom.left.bltd--pointing.bltd--dropdown > .bltd--menu {
  left: 0%;
  right: auto;
}
.bltd--ui.bottom.left.bltd--pointing.bltd--dropdown > .bltd--menu:after {
  left: 1em;
  right: auto;
}

/* Bottom Right */
.bltd--ui.bottom.right.bltd--pointing.bltd--dropdown > .bltd--menu {
  right: 0%;
  left: auto;
}
.bltd--ui.bottom.right.bltd--pointing.bltd--dropdown > .bltd--menu:after {
  left: auto;
  right: 1em;
}

/* Upward pointing */
.bltd--ui.upward.bltd--pointing.bltd--dropdown > .bltd--menu,
.bltd--ui.upward.top.bltd--pointing.bltd--dropdown > .bltd--menu {
  top: auto;
  bottom: 100%;

}
.bltd--ui.upward.bltd--pointing.bltd--dropdown > .bltd--menu:after,
.bltd--ui.upward.top.bltd--pointing.bltd--dropdown > .bltd--menu:after {
  top: 100%;
  bottom: auto;
  box-shadow: 1px 1px 0px 1px rgba(34, 36, 38, 0.15);
  margin: -0.25em 0 0;
}


/*******************************
         Theme Overrides
*******************************/


/* Dropdown Carets */

.bltd--ui.bltd--dropdown > .bltd--dropdown.bltd--icon {
  line-height: 1;
  height: 1em;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-weight: normal;
  font-style: normal;
  text-align: center;
}
.bltd--ui.bltd--dropdown > .bltd--dropdown.bltd--icon {
  width: auto;
}
.bltd--ui.bltd--dropdown > .bltd--dropdown.bltd--icon:before,
a.bltd--top-btn:before,
.bltd--booker__params .bltd--dropdown.bltd--icon:before {
  width: 1em;
  height: 1em;
  display: block;
  content: '';
  background-image: url("../img/icons/icons.svg");
  background-size: 10.5625em 17.3125em;
  position: absolute;

  background-position: -6.9375em -7.75em;
  top: 0em;
  left: 0em;
}
.bltd--ui.bltd--dropdown.bltd--inverted > .bltd--dropdown.bltd--icon:before,
.booker__params .bltd--dropdown.bltd--icon:before {
  background-position: -9.5625em -7.75em;
  /*background-position: -6.9375em -7.75em;*/
}
