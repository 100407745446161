.table {
  background: #fff;
  border-radius: $px4;
  margin-bottom: $px30;
  .w4 {
    width: 4%;
    min-width: 1em;
  }
  .wpx24 {
    width: 1.5em;

    min-width: 1em;
  }

  .w8 {
    width: 8%;

  }
  .w10 {
    width: 10%;

  }
  .w20 {
    width: 20%;

  }
  .w12 {
    width: 12%;
  }

  .w15 {
    width: 15%;
  }

  .w18 {
    width: 18%;
  }

  .w24 {
    width: 24%;

  }
  .w32 {
    width: 32%;

  }
  .wauto, .wa {
    width: auto;
  }
  .item--number {
    text-align: right;
  }
  .bltd--bold {
    white-space: nowrap;
    font-weight: $font-800;
  }
  .pname {
    font-weight: $font-800;
  }
  .bltd--total {
    margin: 1.25em 1.5% .5em;
    text-align: right;
    .bltd--bold {
      font-weight: $font-800;
    }
  }
}
.routes--page .table {
  background: none;
}
.routes--page .table-content {
  background: #fff;

  &.train-list-content {
    min-height: 140px;
  }
}
.table-header {
  width: 100%;
  border-radius: 0;
  box-shadow: none;
  background-image: none;

  display: table;
  table-layout: fixed;

  position: relative;

  .table-header__item {
    display: table-cell;

    /*padding: 2.75rem 1%  1.25rem;*/
    padding: 0 1%;
    vertical-align: top;

    box-sizing: border-box;
    & > div, & > span {
      white-space: nowrap;
      font-size: .8125em;
      font-weight: $font-800;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

.table-content {
  padding: 0;
  box-sizing: border-box;
  position: relative;



  .table-content__row {

    &.auto {
      table-layout: auto;
    }
    display: table;
    table-layout: fixed;
    width: 100%;
    position: relative;
    .table-content__item {
      //overflow: hidden;
      /*padding: 2rem 1% ;*/
      padding: 0.825em 1%;
      box-sizing: border-box;
      display: table-cell;
      vertical-align: middle;
      line-height: 1.5em;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

}

.table-footer {
  padding: 1.25em 1.5%;
  .bltd--row {
    padding: .25em;
    margin-bottom: 1.5em;
    &:after {
      clear: both;
      content: '';
      display: table;
    }
  }

}
@include media('<=tablet') {
  .routes--page .table {
    background: none;
  }
  .routes--page .table-content {
    background:  none;

  }

  .table {
    background: transparent;

  }
  .table.with-checkbox {
    background: #fff;
    margin-bottom: 0;

  }
  .table-content {

    .table-content__row {

      display: block;
      width: 100%;
      position: relative;
      .table-content__item {
        display: block;
        width: 100%;

        &:first-child {
          padding: 0.625em 1.25em;
        }
        &:last-child {
          padding: 0.625em 1.25em;
        }

      }
    }

  }
}