.bltd--impaired-button {
    float: right;
    margin-top: 1.75em;
    margin-right: 0.75em;
}

.bltd--impaired-icon {
    width: 2.375em;
    height: 1.25em;
    display: inline-block;
    background: url(../img/icons/icon-eye-w.svg) center center no-repeat;
}

.bltd--inverted .bltd--impaired-icon {
    background: url(../img/icons/icon-eye-g.svg) center center no-repeat;
}

@include media('<=tablet') {
    .bltd--impaired-button {
        margin-top: 1.25em;
    }
}

$font-color-impaired: #000;
$link-color-impaired: #000;
$bg-color-impaired: #fff;

.bltd--impaired {
	// основной стиль, класс добавляется к body

    color: $font-color-impaired;
    background: $bg-color-impaired;
    font-size: 2.4rem;

    .bltd--impaired-button {
        margin-top: 1.25em;
    }

    .bltd--impaired-icon {
        background: url(../img/icons/icon-eye-w.svg) center center no-repeat;
    }

    @import "common";
    @import "header";
    @import "booker";
    @import "rlist";
    @import "personal";
    @import "checkout";

    @include media('<=tablet') {
        font-size: 1.9rem;
    }

}