
// Tooltip
.tooltip {
  position: absolute;
  z-index: 9999;

  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  -webkit-transition-property: opacity, -webkit-transform;
  -moz-transition-property: opacity, -moz-transform;
  transition-property: opacity, transform;
  -webkit-transition-duration: 0.6s;
  -moz-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1 );
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  box-shadow: 0 3px 20px 0px #e4e4e4;
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  padding: 15px  20px;
}

.tooltip--is-active {
  opacity: 1;
}

@media screen and (max-width: 1023px) {
  .tooltip--is-active.tooltip--top {
    -webkit-transform: translateY(-24px);
    -moz-transform: translateY(-24px);
    -ms-transform: translateY(-24px);
    -o-transform: translateY(-24px);
    transform: translateY(-24px);
  }
}

@media screen and (min-width: 1024px) {
  .tooltip--is-active.tooltip--top {
    -webkit-transform: translateY(-14px);
    -moz-transform: translateY(-14px);
    -ms-transform: translateY(-14px);
    -o-transform: translateY(-14px);
    transform: translateY(-14px);
  }
}

@media screen and (max-width: 1023px) {
  .tooltip--is-active.tooltip--bottom {
    -webkit-transform: translateY(24px);
    -moz-transform: translateY(24px);
    -ms-transform: translateY(24px);
    -o-transform: translateY(24px);
    transform: translateY(24px);
  }
}

@media screen and (min-width: 1024px) {
  .tooltip--is-active.tooltip--bottom {
    -webkit-transform: translateY(14px);
    -moz-transform: translateY(14px);
    -ms-transform: translateY(14px);
    -o-transform: translateY(14px);
    transform: translateY(14px);
  }
}

@media screen and (max-width: 1023px) {
  .tooltip--is-active.tooltip--left {
    -webkit-transform: translateX(-24px);
    -moz-transform: translateX(-24px);
    -ms-transform: translateX(-24px);
    -o-transform: translateX(-24px);
    transform: translateX(-24px);
  }
}

@media screen and (min-width: 1024px) {
  .tooltip--is-active.tooltip--left {
    -webkit-transform: translateX(-14px);
    -moz-transform: translateX(-14px);
    -ms-transform: translateX(-14px);
    -o-transform: translateX(-14px);
    transform: translateX(-14px);
  }
}

@media screen and (max-width: 1023px) {
  .tooltip--is-active.tooltip--right {
    -webkit-transform: translateX(24px);
    -moz-transform: translateX(24px);
    -ms-transform: translateX(24px);
    -o-transform: translateX(24px);
    transform: translateX(24px);
  }
}

@media screen and (min-width: 1024px) {
  .tooltip--is-active.tooltip--right {
    -webkit-transform: translateX(14px);
    -moz-transform: translateX(14px);
    -ms-transform: translateX(14px);
    -o-transform: translateX(14px);
    transform: translateX(14px);
  }
}

.tooltip__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transform: scale(0) translateY(50%);
  -moz-transform: scale(0) translateY(50%);
  -ms-transform: scale(0) translateY(50%);
  -o-transform: scale(0) translateY(50%);
  transform: scale(0) translateY(50%);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.6s;
  -moz-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.tooltip__label {
  display: block;

  white-space: nowrap;
  font-size: 13px;

}



.tooltip--top {
  .tooltip__background {
    -webkit-transform: scale(0) translateY(50%);
    -moz-transform: scale(0) translateY(50%);
    -ms-transform: scale(0) translateY(50%);
    -o-transform: scale(0) translateY(50%);
    transform: scale(0) translateY(50%);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
  }
  &.tooltip--is-active .tooltip__background {
    -webkit-transform: scale(3) translateY(50%);
    -moz-transform: scale(3) translateY(50%);
    -ms-transform: scale(3) translateY(50%);
    -o-transform: scale(3) translateY(50%);
    transform: scale(3) translateY(50%);
  }
}

.tooltip--bottom {
  .tooltip__background {
    -webkit-transform: scale(0) translateY(-50%);
    -moz-transform: scale(0) translateY(-50%);
    -ms-transform: scale(0) translateY(-50%);
    -o-transform: scale(0) translateY(-50%);
    transform: scale(0) translateY(-50%);
    -webkit-transform-origin: center top;
    -moz-transform-origin: center top;
    -ms-transform-origin: center top;
    -o-transform-origin: center top;
    transform-origin: center top;
  }
  &.tooltip--is-active .tooltip__background {
    -webkit-transform: scale(3) translateY(-50%);
    -moz-transform: scale(3) translateY(-50%);
    -ms-transform: scale(3) translateY(-50%);
    -o-transform: scale(3) translateY(-50%);
    transform: scale(3) translateY(-50%);
  }
}

.tooltip--left {
  .tooltip__background {
    -webkit-transform: scale(0) translateX(50%);
    -moz-transform: scale(0) translateX(50%);
    -ms-transform: scale(0) translateX(50%);
    -o-transform: scale(0) translateX(50%);
    transform: scale(0) translateX(50%);
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
  }
  &.tooltip--is-active .tooltip__background {
    -webkit-transform: scale(3) translateX(50%);
    -moz-transform: scale(3) translateX(50%);
    -ms-transform: scale(3) translateX(50%);
    -o-transform: scale(3) translateX(50%);
    transform: scale(3) translateX(50%);
  }
}

.tooltip--right {
  .tooltip__background {
    -webkit-transform: scale(0) translateX(-50%);
    -moz-transform: scale(0) translateX(-50%);
    -ms-transform: scale(0) translateX(-50%);
    -o-transform: scale(0) translateX(-50%);
    transform: scale(0) translateX(-50%);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  &.tooltip--is-active .tooltip__background {
    -webkit-transform: scale(3) translateX(-50%);
    -moz-transform: scale(3) translateX(-50%);
    -ms-transform: scale(3) translateX(-50%);
    -o-transform: scale(3) translateX(-50%);
    transform: scale(3) translateX(-50%);
  }
}