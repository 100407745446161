.bltd--ui.popup {
  display: none;
  position: absolute;
  top: 0;
  right: 0;

  /* Fixes content being squished when inline (moz only) */
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  z-index: 1900;
  border: 1px solid #D4D4D5;
  line-height: 1.333em;
  max-width: 250px;
  min-width: 200px;
  background: #FFFFFF;
  padding: 1em 1em;
  font-weight: normal;
  font-style: normal;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.08);
}
.bltd--ui.popup > .header {
  padding: 0;

  font-size: 1em;
  line-height: 1.2;
  font-weight: bold;
}
.bltd--ui.popup > .header + .bltd--content {
  padding-top: 0.5em;
}
.bltd--ui.popup:before {
  position: absolute;
  content: '';
  width: 0.75em;
  height: 0.75em;
  background: #FFFFFF;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 2;
  box-shadow: 1px 1px 0 0 #bababc;
}

.bltd--ui.popup {
  margin: 0;
}

/* Extending from Top */
.bltd--ui.top.popup {
  margin: 0 0 0.75em;
}
.bltd--ui.top.left.popup {
  -webkit-transform-origin: left bottom;
  -ms-transform-origin: left bottom;
  transform-origin: left bottom;
}
.bltd--ui.top.center.popup {
  -webkit-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
}
.bltd--ui.top.right.popup {
  -webkit-transform-origin: right bottom;
  -ms-transform-origin: right bottom;
  transform-origin: right bottom;
}

/* Extending from Vertical Center */
.bltd--ui.left.center.popup {
  margin: 0 0.75em 0 0;
  -webkit-transform-origin: right 50%;
  -ms-transform-origin: right 50%;
  transform-origin: right 50%;
}
.bltd--ui.right.center.popup {
  margin: 0 0 0 0.75em;
  -webkit-transform-origin: left 50%;
  -ms-transform-origin: left 50%;
  transform-origin: left 50%;
}

/* Extending from Bottom */
.bltd--ui.bottom.popup {
  margin: 0.75em 0 0;
}
.bltd--ui.bottom.left.popup {
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
}
.bltd--ui.bottom.center.popup {
  -webkit-transform-origin: center top;
  -ms-transform-origin: center top;
  transform-origin: center top;
}
.bltd--ui.bottom.right.popup {
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
}

/*--------------
     Pointer
---------------*/


/*--- Below ---*/

.bltd--ui.bottom.center.popup:before {
  margin-left: -0.325em;
  top: -0.325em;
  left: 50%;
  right: auto;
  bottom: auto;
  box-shadow: -1px -1px 0 0 #bababc;
}
.bltd--ui.bottom.left.popup {
  margin-left: 0;
}
/*rtl:rename*/
.bltd--ui.bottom.left.popup:before {
  top: -0.325em;
  left: 1em;
  right: auto;
  bottom: auto;
  margin-left: 0;
  box-shadow: -1px -1px 0 0 #bababc;
}
.bltd--ui.bottom.right.popup {
  margin-right: 0;
}
/*rtl:rename*/
.bltd--ui.bottom.right.popup:before {
  top: -0.325em;
  right: 1em;
  bottom: auto;
  left: auto;
  margin-left: 0;
  box-shadow: -1px -1px 0 0 #bababc;
}

/*--- Above ---*/

.bltd--ui.top.center.popup:before {
  top: auto;
  right: auto;
  bottom: -0.325em;
  left: 50%;
  margin-left: -0.325em;
}
.bltd--ui.top.left.popup {
  margin-left: 0;
}
/*rtl:rename*/
.bltd--ui.top.left.popup:before {
  bottom: -0.325em;
  left: 1em;
  top: auto;
  right: auto;
  margin-left: 0;
}
.bltd--ui.top.right.popup {
  margin-right: 0;
}
/*rtl:rename*/
.bltd--ui.top.right.popup:before {
  bottom: -0.325em;
  right: 1em;
  top: auto;
  left: auto;
  margin-left: 0;
}

/*--- Left Center ---*/

/*rtl:rename*/
.bltd--ui.left.center.popup:before {
  top: 50%;
  right: -0.325em;
  bottom: auto;
  left: auto;
  margin-top: -0.325em;
  box-shadow: 1px -1px 0 0 #bababc;
}

/*--- Right Center  ---*/

/*rtl:rename*/
.bltd--ui.right.center.popup:before {
  top: 50%;
  left: -0.325em;
  bottom: auto;
  right: auto;
  margin-top: -0.325em;
  box-shadow: -1px 1px 0 0 #bababc;
}

/* Arrow Color By Location */
.bltd--ui.bottom.popup:before {
  background: #FFFFFF;
}
.bltd--ui.right.center.popup:before,
.bltd--ui.left.center.popup:before {
  background: #FFFFFF;
}
.bltd--ui.top.popup:before {
  background: #FFFFFF;
}

/* Inverted Arrow Color */
.bltd--ui.bltd--inverted.bottom.popup:before {
  background: #1B1C1D;
}
.bltd--ui.bltd--inverted.right.center.popup:before,
.bltd--ui.bltd--inverted.left.center.popup:before {
  background: #1B1C1D;
}
.bltd--ui.bltd--inverted.top.popup:before {
  background: #1B1C1D;
}


/*******************************
            Coupling
*******************************/


/* Immediate Nested Grid */
.bltd--ui.popup > .bltd--ui.grid:not(.padded) {
  width: calc(100% + 1.75rem);
  margin: -0.7rem -0.875rem;
}


/*******************************
            States
*******************************/

.bltd--ui.loading.popup {
  display: block;
  visibility: hidden;
  z-index: -1;
}
.bltd--ui.animating.popup,
.bltd--ui.visible.popup {
  display: block;
}
.bltd--ui.visible.popup {
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}


/*******************************
            Variations
*******************************/


/*--------------
     Basic
---------------*/

.bltd--ui.basic.popup:before {
  display: none;
}

/*--------------
     Wide
---------------*/

.bltd--ui.wide.popup {
  max-width: 350px;
}
.bltd--ui[class*="very wide"].popup {
  max-width: 550px;
}
@media only screen and (max-width: 767px) {
  .bltd--ui.wide.popup,
  .bltd--ui[class*="very wide"].popup {
    max-width: 250px;
  }
}

/*--------------
     Fluid
---------------*/

.bltd--ui.fluid.popup {
  width: 100%;
  max-width: none;
}

/*--------------
     Colors
---------------*/


/* Inverted colors  */
.bltd--ui.bltd--inverted.popup {
  background: #1B1C1D;
  color: #FFFFFF;
  border: none;
  box-shadow: none;
}
.bltd--ui.bltd--inverted.popup .header {
  background-color: none;
  color: #FFFFFF;
}
.bltd--ui.bltd--inverted.popup:before {
  background-color: #1B1C1D;
  box-shadow: none !important;
}

/*--------------
     Flowing
---------------*/

.bltd--ui.flowing.popup {
  max-width: none;
}

/*--------------
     Sizes
---------------*/

.bltd--ui.mini.popup {
  font-size: 0.71428571em;
}
.bltd--ui.tiny.popup {
  font-size: 0.85714286em;
}
.bltd--ui.small.popup {
  font-size: 0.92857143em;
}
.bltd--ui.popup {
  font-size: 1em;
}
.bltd--ui.large.popup {
  font-size: 1.14285714em;
}
.bltd--ui.huge.popup {
  font-size: 1.42857143em;
}
