.page__form {
  margin: 0;
  padding: 0;

}

/*.route-calc {
  !*font-size: $px14;*!
  list-style: none;

  padding: 0;
  li {
    display: block;
    margin-bottom: .85em;
    overflow: hidden;

    .t {
      float: left;
      .bltd--desc {

      }

    }
    .cost {
      float: right;

    }
    &.bltd--total-cost {

      margin-bottom: .875em;
      font-weight: $font-800;
      font-size: 1.14285em;
      padding: 0;

    }
  }
}*/

.bltd--ticket-buttons {
  text-align: center;
  .bltd--field {
    margin-bottom: 0.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }


}

.bltd--routing {
  background: var(--base-body-color);
  .bltd--wr {
    margin: 0 3.2rem;
  }

  .bltd--notfound-container {
    &.bltd--text-error > h3 {
      color: #DD4B39;
    }
  }
}

.bltd--sorting {
  display: none;
  background: var(--base-body-color);
  padding: 0.625em;
  text-align: center;
}

.bltd--route-notes {
  display: none;

  /* margin: 0 auto; */
  text-align: center;

  padding: 0.5em;
  .bltd--content {
    font-size: 0.75em;
    display: inline-block;

  }
  & .with-icon_time:after {
    //top: .125em;
    left: 0;
  }
}

@include media('<=tablet') {

  .bltd--wrapper--routing {

    .bltd--switcher {
      display: none;
    }
  }
  .table-content {
    background: transparent;
  }
  .bltd--routing {
    .bltd--wrap {
      padding: 0;
    }
    .bltd--wr {
      margin: 0;
    }
    .bltd--routing-header {
      display: none;
    }
  }
  .bltd--sorting {
    display: block;

    .bltd--dropdown--has-toggle {
      width: 100%;
      text-align: left;
    }
    .bltd--dropdown-toggle {
      width: 100%;

      cursor: pointer;
      word-wrap: break-word;
      line-height: 1em;
      white-space: normal;
      outline: 0;
      -webkit-transform: rotateZ(0deg);
      transform: rotateZ(0deg);

      background: #FFFFFF;
      display: inline-block;

      padding: 0.6125em .5em;
      -webkit-transition: box-shadow 0.1s ease, width 0.1s ease;
      transition: box-shadow 0.1s ease, width 0.1s ease;
      border: 0.0625em solid #e5e4de;
      color: #4a4a4a;

      border-radius: 0.25em;
      box-shadow: 0 0 0 0.0625em #e5e4de;

      .bltd--toggle--text {
        display: block;
        padding-right: 2em;
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &:before {
          color: #9a9a9a;
          border-left: .35em solid transparent;
          border-right: .35em solid transparent;
          border-top: .35em solid;
          content: '';
          right: 0.25em;
          margin: 0 0 0 -0.5em;
          position: absolute;
          top: 50%;
          height: 0;
          width: 0;
          background-image: none;
        }
      }
    }

    .bltd--desc {
      .bltd--toggle--text {
        &:before {
          transform: rotate(180deg);
          margin-top: -.25em;
        }
      }
    }
    .bltd--ui.selection.bltd--dropdown {
      width: 100%;
      z-index: 300;

      > .bltd--dropdown.bltd--icon.bltd--desc:before {
        transform: rotate(180deg);
      }

      .bltd--menu {
        max-height: 10rem;
      }
    }
  }
  .bltd--route-notes {
    display: block;
    border-bottom: 1px solid #EBEAE8;

  }
  .table-content {

    .bltd--item {
      .bltd--item-row {
        .table-content__item {
          padding: 0.625em 0;
          &:first-child {
            padding: 0.625em 0;
          }
          &:last-child {
            padding: 0.625em 0;
          }
          &.bltd--ticket--number, &.bltd--ticket--tax, &.bltd--ticket--seat, &.bltd--ticket--bag, &.bltd--ticket--cost {
            display: none;
          }
        }
      }
    }

  }
  .with-checkbox {
    .bltd--total {
      margin: 1.25em 1.25em .5em
    }
    .table-footer {
      padding: 1.25em;
    }

    .route {
      margin-bottom: 0;
      background: #fff;
      padding: 0 1.25em;

      &:last-child .route-row {
        border-bottom: none;
      }
      .route-content {

        border-bottom: 1px solid #efeff0;
      }

      .route-row {
        padding-left: 2em;
        .table-content__item {
          display: block;
          padding: 0.625em 0;
          &.route--checkbox {

            position: absolute;
            top: 0.3125em;
            left: 0;
            /* display: none;*/
          }
          &.route--bag {
            display: none;
          }
          &.route--kind, &.route--seat, &.bltd--route--cost {
            width: 33%;
            float: left;
            text-align: left;
            padding: 0.625em 0;
            font-size: 0.875em;

          }
          &.bltd--route--cost, &.route--seat {
            white-space: nowrap;
          }
          &.bltd--route--num {
            padding: 0.625em 0 0;
            //display: none;
          }
          .agreement {
            margin-bottom: $px20;
          }
        }
      }
    }
    .bltd--ui.bltd--form .two.bltd--fields > .bltd--field {
      margin-bottom: 0;
    }
  }
  .route {
    margin-bottom: 0.625em;
    background: #fff;

    &:after {
      clear: both;
      display: block;
      content: '';
    }
    .bltd--expanded {
      display: none;
    }
    &.expand .route-over {
      display: none;
    }
    .route-content {
      padding: 0;
      margin: 0;
      z-index: inherit !important;
    }
    .route-row {
      clear: both;
      border: none;
      &:after {
        clear: both;
        display: block;
        content: '';
      }

      display: block;
      table-layout: auto;

      padding: 1em 0;

      .table-content__item {
        display: block;
        padding: 1.125em 1.25em;
        padding-top: 0;
        &:last-child {
          padding: 0 1.25em;
        }
        &.bltd--route--num {
          .num {
            font-size: 1em;
            /*font-weight: $font-800;*/
            font-weight: normal;
            color: #666;
          }
          width: 100%;
          padding: 0.625em 1.25em 0;
          display: none;
        }

        &.bltd--route--name {
          width: 100%;

        }
        &.bltd--route--days {
          width: 100%;

        }
        .route-item__name {
          padding-right: 1em;
        }
        .route-item__name:hover {
          cursor: pointer;
        }

        .route-item__name:after {
          width: 1em;
          height: 1em;
          display: block;
          content: '';
          background-image: url("../img/icons/icons.svg");
          background-size: 10.5625em 17.3125em;
          background-position: -8.25em -6.4375em;
          top: .25em;
          right: -.25em;
          position: absolute;

        }
        &.bltd--route--date-payed, &.bltd--route--passangers, &.bltd--route--status {
          display: none;
        }
        &.bltd--route--date, &.bltd--route--cost {
          padding: 0 1.25em;
          .ellipsis {
            font-size: $px13;
          }
        }
        &.bltd--route--date {
          float: left;
          width: 60%;
        }

        &.bltd--route--cost {
          float: left;
          width: 40%;
          text-align: right;
          padding-right: 2.75em;
        }

        &.bltd--route--dates {

          .da-wrapper {
            position: relative;

            &:after {
              content: ' ';

              width: auto;
              height: 0.5em;
              background: url("../img/icons/route-arrow.svg") no-repeat 100% 0;
              display: block;
              position: absolute;

              top: 0;
              margin-top: 0.5em;

              right: 5rem;
              left: 5rem;
            }

            &.with-date {
              &:after {
                right: 7em;
                left: 4em;
              }
            }
          }
          width: 100%;

          .route-item__depart-time {
            float: left;
            width: 50%;
          }

          .route-item__arrive-time {
            float: left;
            width: 50%;
            text-align: right;

            span.bltd--hint {
              margin-left: .25em;
            }
          }
        }

        &.bltd--route--spent-time {
          display: none;
        }
        &.bltd--route--link.w180, &.bltd--route--link.w1802 {
          display: none;
        }
        &.bltd--route--link {

          .bltd--ticket-inner {
            text-align: left;
          }
          float: left;
          width: 100%;
          text-align: left;
        }

        .pname {
          display: block;
        }
        .ellipsis, .bolder, .bltd--bold {
          white-space: normal;
        }
        .bolder, .bltd--bold {
          font-weight: $font-800;
          margin-bottom: 0;
        }
      }
    }

  }
  .route-extend {
    .route-expand_heading {
      display: block;
    }
    .route-extend__inner {
      padding: 0;
      display: block;
      table-layout: auto;
      width: 100%;
      margin: 0;

      &.bltd--error {
        padding: 1em 1.25em;
      }

      .bltd--ui.h2 {
        display: block;
      }
      &.bltd--bankid {
        width: 100%;
        padding: 1.5em 1.25em .5em;
      }
      .bltd--column {

        display: block;

        &.bltd--waypoint, &.passanger, &.calc, &.bltd--ticket-buttons {
          width: 100%;
          padding: 1em 1.25em;
        }
        &.calc {
          width: 100%;
          padding: 1.5em 1.25em 1.5em;
        }
        &.bltd--ticket-buttons {
          background-color: #f3f3f3;
        }

      }
    }
  }
}

//стили для мобильной версии Персональй Список поездок
@include media('<=tablet') {
  .routes--page {
    .table {
      margin-bottom: 0;
    }
  }

}
//стили для мобильной версии Персональй Список поездок
@include media('<=tablet') {
  .bltd--routing {

    .bltd--routing__personal {
      .table {

        margin-bottom: 0;
        .route {


        }
      }

      .route-extend {
        .route-extend__inner {

          .bltd--ui.h2 {
            margin-bottom: 1em;
          }
          .bltd--column {

            &.calc {
              width: 100%;
              padding: .5em 1.25em 1.5em;
            }
            &.passanger {
              width: 100%;
              padding: 1em 1.25em ;
            }
          }
        }

        .expand-item {
          margin-bottom: 1em;
        }
        .route-item__extra .bolder {

          margin-bottom: .5em;
        }
      }

      .bltd--ticket--name {
        width: 100%;
        padding: 0;
        .pname {
          display: block;

        }
        .pdoc {
          display: block;
        }
      }

    }
  }
}

.bltd--page--reload {
  position: fixed;
  background: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  display: flex;
  text-align: center;
  transition: all .3s ease;

  &-content {
    margin: auto;
  }

  &-header {
    font-size: 26px;
    font-weight: 700;
    color: var(--base-green-color);
  }

  &-images {
    margin: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-from, &-to {
    img {
      height: 80px;
      width: 80px;
    }
  }

  &-info {
    &-additional {
      color: var(--base-green-color);
      font-weight: 600;
      margin-top: 40px;
    }
  }

  &-str {
    margin: 0 45px 0 30px;
    display: flex;

    div {
      height: 35px;
      width: 35px;
      position: relative;
      opacity: 0;
      transform: rotate(45deg);
      border-right: 8px solid var(--base-green-color);
      border-top: 8px solid var(--base-green-color);

      &:nth-child(1) {
        animation: strLoader 2.5s ease-in infinite;
      }
      &:nth-child(2) {
        animation: strLoader 2.5s ease-in .5s infinite;
      }
      &:nth-child(3) {
        animation: strLoader 2.5s ease-in 1s infinite;
      }
    }
  }

  &-buttons {
    margin-top: 30px;

    button {
      margin: 10px;
    }
  }
}

.bltd--bbc-icon {
  background: url(../img/icons/bbc_white_logo.svg) no-repeat;
  position: absolute;
  width: 60px;
  height: 60px;
  top: -11px;
  right: -7px;
  opacity: 0;
  transition: all .2s ease;
  z-index: 0;
}

@keyframes strLoader {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80%, 100% {
    opacity: 0;
  }
}
