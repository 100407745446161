header.bltd--header--index {
  background: $base-white-color;
  margin: 0;
  .bltd--logo {
    color: var(--base-green-color);
    & .bltd--expand-nav {
      & a {
        color: var(--base-green-color);
      }
    }
    & .bltd--logo-link {
      a {
        color: var(--base-green-color);
      }
    }
  }
  .bltd--main-nav {
    .bltd--link {
      color: var(--base-color);
    }
  }
  .bltd--account {
    .bltd--xs {
      i {
        background-color: var(--base-color);
      }
    }
  }
}