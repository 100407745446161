// ==========================================================================
// Forms
// ==========================================================================

// Form, Fieldset and Legend
fieldset,
legend {
  border: 0;
  margin: 0;
  padding: 0;
}
legend {
  @include font-size($base-font-size + 2);
  @include line-height($base-line-height + 2);
  font-weight: $font-700;
}
.bltd--form {
  /*padding-top: 1em;
  padding-bottom: 1em;*/
}

// All Form Elements
label,
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

.bltd--wrapper--routing input {
  width: 100%;
}
.bltd--ui.bltd--input {
  position: relative;
  font-weight: normal;
  font-style: normal;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: rgba(0, 0, 0, 0.87);
}
.bltd--ui.bltd--input input {
  margin: 0em;
  max-width: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.2142em;
 padding: 0.67861429em 1em;
  background: #FFFFFF;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
}

/*--------------------
      Placeholder
---------------------*/


/* browsers require these rules separate */
.bltd--ui.bltd--input input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.bltd--ui.bltd--input input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.bltd--ui.bltd--input input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}


/*******************************
            States
*******************************/


/*--------------------
        Disabled
---------------------*/

.bltd--ui.disabled.bltd--input,
.bltd--ui.bltd--input input[disabled] {
  opacity: 0.45;
}
.bltd--ui.disabled.bltd--input input {
  pointer-events: none;
}

/*--------------------
        Active
---------------------*/

.bltd--ui.bltd--input input:active,
.bltd--ui.bltd--input.down input {
  border-color: rgba(0, 0, 0, 0.3);
  background: #FAFAFA;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
}

/*--------------------
       Loading
---------------------*/

.bltd--ui.loading.loading.bltd--input > i.bltd--icon:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0em 0em -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  border-radius: 500rem;
  border: 0.2em solid rgba(0, 0, 0, 0.1);
}
.bltd--ui.loading.loading.bltd--input > i.bltd--icon:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0em 0em -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  -webkit-animation: button-spin 0.6s linear;
  animation: button-spin 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #767676 transparent transparent;
  border-style: solid;
  border-width: 0.2em;
  box-shadow: 0px 0px 0px 1px transparent;
}

/*--------------------
        Focus
---------------------*/

.bltd--ui.bltd--input.focus input,
.bltd--ui.bltd--input input:focus {
  border-color: #85B7D9;
  background: #FFFFFF;
  color: rgba(0, 0, 0, 0.8);
  box-shadow: none;
}
.bltd--ui.bltd--input.focus input::-webkit-input-placeholder,
.bltd--ui.bltd--input input:focus::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.87);
}
.bltd--ui.bltd--input.focus input::-moz-placeholder,
.bltd--ui.bltd--input input:focus::-moz-placeholder {
  color: rgba(0, 0, 0, 0.87);
}
.bltd--ui.bltd--input.focus input::-ms-input-placeholder,
.bltd--ui.bltd--input input:focus::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.87);
}

/*--------------------
        Error
---------------------*/

.bltd--ui.bltd--input.bltd--error input {
  background-color: #FFF6F6;
  border-color: #E0B4B4;
  color: #9F3A38;
  box-shadow: none;
}

/* Error Placeholder */
.bltd--ui.bltd--input.bltd--error input::-webkit-input-placeholder {
  color: #e7bdbc;
}
.bltd--ui.bltd--input.bltd--error input::-moz-placeholder {
  color: #e7bdbc;
}
.bltd--ui.bltd--input.bltd--error input::-ms-input-placeholder {
  color: #e7bdbc;
}

/* Focused Error Placeholder */
.bltd--ui.bltd--input.bltd--error input:focus::-webkit-input-placeholder {
  color: #da9796;
}
.bltd--ui.bltd--input.bltd--error input:focus::-moz-placeholder {
  color: #da9796;
}
.bltd--ui.bltd--input.bltd--error input:focus::-ms-input-placeholder {
  color: #da9796;
}


/*******************************
           Variations
*******************************/


/*--------------------
      Transparent
---------------------*/

.bltd--ui.transparent.bltd--input input {
  border-color: transparent !important;
  background-color: transparent !important;
  padding: 0em !important;
  box-shadow: none !important;
}

/* Transparent Icon */
.bltd--ui.transparent.bltd--icon.bltd--input > i.bltd--icon {
  width: 1.1em;
}
.bltd--ui.transparent.bltd--icon.bltd--input > input {
  padding-left: 0em !important;
  padding-right: 2em !important;
}
.bltd--ui.transparent[class*="left icon"].bltd--input > input {
  padding-left: 2em !important;
  padding-right: 0em !important;
}

/* Transparent Inverted */
.bltd--ui.transparent.bltd--inverted.bltd--input {
  color: #FFFFFF;
}
.bltd--ui.transparent.bltd--inverted.bltd--input input {
  color: inherit;
}
.bltd--ui.transparent.bltd--inverted.bltd--input input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.bltd--ui.transparent.bltd--inverted.bltd--input input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.bltd--ui.transparent.bltd--inverted.bltd--input input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/*--------------------
         Icon
---------------------*/

.bltd--ui.bltd--icon.bltd--input > i.bltd--icon {
  cursor: default;
  position: absolute;
  line-height: 1;
  text-align: center;
  top: 0px;
  right: 0px;
  margin: 0em;
  height: 100%;
  width: 2.67142857em;
  opacity: 0.5;
  border-radius: 0em 0.28571429rem 0.28571429rem 0em;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.bltd--ui.bltd--icon.bltd--input > i.bltd--icon:not(.bltd--link) {
  pointer-events: none;
}
.bltd--ui.bltd--icon.bltd--input input {
  padding-right: 2.67142857em !important;
}
.bltd--ui.bltd--icon.bltd--input > i.bltd--icon:before,
.bltd--ui.bltd--icon.bltd--input > i.bltd--icon:after {
  left: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
  margin-top: -0.5em;
}
.bltd--ui.bltd--icon.bltd--input > i.bltd--link.bltd--icon {
  cursor: pointer;
}
.bltd--ui.bltd--icon.bltd--input > i.circular.bltd--icon {
  top: 0.35em;
  right: 0.5em;
}

/* Left Icon Input */
.bltd--ui[class*="left icon"].bltd--input > i.bltd--icon {
  right: auto;
  left: 1px;
  border-radius: 0.28571429rem 0em 0em 0.28571429rem;
}
.bltd--ui[class*="left icon"].bltd--input > i.circular.bltd--icon {
  right: auto;
  left: 0.5em;
}
.bltd--ui[class*="left icon"].bltd--input > input {
  padding-left: 2.67142857em !important;
  padding-right: 1em !important;
}

/* Focus */
.bltd--ui.bltd--icon.bltd--input > input:focus ~ i.bltd--icon {
  opacity: 1;
}

/*--------------------
        Labeled
---------------------*/


/* Adjacent Label */
.bltd--ui.labeled.bltd--input > .label {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin: 0;
  font-size: 1em;
}
.bltd--ui.labeled.bltd--input > .label:not(.corner) {
  padding-top: 0.78571429em;
  padding-bottom: 0.78571429em;
}

/* Regular Label on Left */
.bltd--ui.labeled.bltd--input:not([class*="corner labeled"]) .label:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.bltd--ui.labeled.bltd--input:not([class*="corner labeled"]) .label:first-child + input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left-color: transparent;
}
.bltd--ui.labeled.bltd--input:not([class*="corner labeled"]) .label:first-child + input:focus {
  border-left-color: #85B7D9;
}

/* Regular Label on Right */
.bltd--ui[class*="right labeled"].bltd--input input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right-color: transparent !important;
}
.bltd--ui[class*="right labeled"].bltd--input input + .label {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.bltd--ui[class*="right labeled"].bltd--input input:focus {
  border-right-color: #85B7D9 !important;
}

/* Corner Label */
.bltd--ui.labeled.bltd--input .corner.label {
  top: 1px;
  right: 1px;
  font-size: 0.64285714em;
  border-radius: 0em 0.28571429rem 0em 0em;
}

/* Spacing with corner label */
.bltd--ui[class*="corner labeled"]:not([class*="left corner labeled"]).labeled.bltd--input input {
  padding-right: 2.5em !important;
}
.bltd--ui[class*="corner labeled"].bltd--icon.bltd--input:not([class*="left corner labeled"]) > input {
  padding-right: 3.25em !important;
}
.bltd--ui[class*="corner labeled"].bltd--icon.bltd--input:not([class*="left corner labeled"]) > .bltd--icon {
  margin-right: 1.25em;
}

/* Left Labeled */
.bltd--ui[class*="left corner labeled"].labeled.bltd--input input {
  padding-left: 2.5em !important;
}
.bltd--ui[class*="left corner labeled"].bltd--icon.bltd--input > input {
  padding-left: 3.25em !important;
}
.bltd--ui[class*="left corner labeled"].bltd--icon.bltd--input > .bltd--icon {
  margin-left: 1.25em;
}

/* Corner Label Position  */
.bltd--ui.bltd--input > .bltd--ui.corner.label {
  top: 1px;
  right: 1px;
}
.bltd--ui.bltd--input > .bltd--ui.left.corner.label {
  right: auto;
  left: 1px;
}

/*--------------------
        Action
---------------------*/

.bltd--ui.bltd--action.bltd--input > .button,
.bltd--ui.bltd--action.bltd--input > .bltd--buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.bltd--ui.bltd--action.bltd--input > .button,
.bltd--ui.bltd--action.bltd--input > .bltd--buttons > .button {
  padding-top: 0.78571429em;
  padding-bottom: 0.78571429em;
  margin: 0;
}

/* Button on Right */
.bltd--ui.bltd--action.bltd--input:not([class*="left action"]) > input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right-color: transparent !important;
}
.bltd--ui.bltd--action.bltd--input:not([class*="left action"]) > .bltd--dropdown,
.bltd--ui.bltd--action.bltd--input:not([class*="left action"]) > .button,
.bltd--ui.bltd--action.bltd--input:not([class*="left action"]) > .bltd--buttons > .button {
  border-radius: 0px;
}
.bltd--ui.bltd--action.bltd--input:not([class*="left action"]) > .bltd--dropdown:last-child,
.bltd--ui.bltd--action.bltd--input:not([class*="left action"]) > .button:last-child,
.bltd--ui.bltd--action.bltd--input:not([class*="left action"]) > .bltd--buttons:last-child > .button {
  border-radius: 0px 0.28571429rem 0.28571429rem 0px;
}

/* Input Focus */
.bltd--ui.bltd--action.bltd--input:not([class*="left action"]) input:focus {
  border-right-color: #85B7D9 !important;
}

/* Button on Left */
.bltd--ui[class*="left action"].bltd--input > input {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left-color: transparent !important;
}
.bltd--ui[class*="left action"].bltd--input > .bltd--dropdown,
.bltd--ui[class*="left action"].bltd--input > .button,
.bltd--ui[class*="left action"].bltd--input > .bltd--buttons > .button {
  border-radius: 0px;
}
.bltd--ui[class*="left action"].bltd--input > .bltd--dropdown:first-child,
.bltd--ui[class*="left action"].bltd--input > .button:first-child,
.bltd--ui[class*="left action"].bltd--input > .bltd--buttons:first-child > .button {
  border-radius: 0.28571429rem 0px 0px 0.28571429rem;
}

/* Input Focus */
.bltd--ui[class*="left action"].bltd--input > input:focus {
  border-left-color: #85B7D9 !important;
}

/*--------------------
       Inverted
---------------------*/


/* Standard */
.bltd--ui.bltd--inverted.bltd--input input {
  border: none;
}

/*--------------------
        Fluid
---------------------*/

.bltd--ui.fluid.bltd--input {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.bltd--ui.fluid.bltd--input > input {
  width: 0px !important;
}

/*--------------------
        Size
---------------------*/

.bltd--ui.mini.bltd--input {
  font-size: 0.71428571em;
}
.bltd--ui.small.bltd--input {
  font-size: 0.92857143em;
}
.bltd--ui.bltd--input {
  font-size: 1em;
}
.bltd--ui.large.bltd--input {
  font-size: 1.14285714em;
}
.bltd--ui.big.bltd--input {
  font-size: 1.28571429em;
}
.bltd--ui.huge.bltd--input {
  font-size: 1.42857143em;
}
.bltd--ui.massive.bltd--input {
  font-size: 1.71428571em;
}

.bltd--ui.bltd--form .bltd--is-loading{
  width: 100%;
  height: 100%;
  position: absolute;
  background: white;
  opacity: 0.45;
  z-index: 1;
}
.bltd--ui.bltd--form .bltd--is-loading .loader-giff{
  position: absolute;
  margin-left: 43%;
  margin-top: -50px;
  top: 50%;
  left: 0px;
}
