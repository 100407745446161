/*******************************
            Elements
*******************************/

fieldset.bltd--ui {
  margin-bottom: 40px;
}
/*--------------------
        Form
---------------------*/

.bltd--ui.bltd--form {
  position: relative;
  max-width: 100%;

}

/*--------------------
        Content
---------------------*/

.bltd--ui.bltd--form > p {
  margin: 1em 0;
}

/*--------------------
        Field
---------------------*/

.bltd--ui.bltd--form .bltd--field {
  clear: both;
  margin: 0 0 20px 0;

  + .bltd--reset-password {
    margin-top: -15px;
  }
}
.bltd--ui.bltd--form .bltd--field.col-2 {
  max-width: 50%;
}
.bltd--ui.bltd--form .bltd--field:last-child,
.bltd--ui.bltd--form .bltd--fields:last-child .bltd--field {
  margin-bottom: 0;
}

.bltd--ui.bltd--form .bltd--fields > .bltd--field > .bltd--field {
  margin-bottom: 0;
}

.bltd--ui.bltd--form .bltd--fields + .bltd--ui.header {
  margin-top: 1.2em;
}

.bltd--ui.bltd--form .bltd--fields .bltd--field {
  &.button-group {
    text-align: right;
  }
  clear: both;
  margin: 0 0 1em;
  &.vertical {
    padding-top: 2em;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  &.last {
    text-align: right;
  }
}


/*--------------------
        Labels
---------------------*/

.bltd--ui.bltd--form {
  .bltd--field {
    label {
      margin-bottom: .5em;
      display: block;
      span{
        display: block;

        font-size: 0.8125em;

        text-transform: none;
      }
    }
    .bltd--label-additional {
      font-size: 14px;
      margin-bottom: .5em;
      color: #a7a7a7;
      display: block;
    }
  }
}

/*--------------------
    Standard Inputs
---------------------*/

.bltd--ui.bltd--form textarea,
.bltd--ui.bltd--form input:not([type]),
.bltd--ui.bltd--form input[type="date"],
.bltd--ui.bltd--form input[type="datetime-local"],
.bltd--ui.bltd--form input[type="email"],
.bltd--ui.bltd--form input[type="number"],
.bltd--ui.bltd--form input[type="password"],
.bltd--ui.bltd--form input[type="search"],
.bltd--ui.bltd--form input[type="tel"],
.bltd--ui.bltd--form input[type="time"],
.bltd--ui.bltd--form input[type="text"],
.bltd--ui.bltd--form input[type="url"] {
  width: 100%;
  vertical-align: top;
}

/* Set max height on unusual input */
.bltd--ui.bltd--form ::-webkit-datetime-edit,
.bltd--ui.bltd--form ::-webkit-inner-spin-button {
  height: 1.2142em;
}
.bltd--ui.bltd--form input:not([type]),
.bltd--ui.bltd--form input[type="date"],
.bltd--ui.bltd--form input[type="datetime-local"],
.bltd--ui.bltd--form input[type="email"],
.bltd--ui.bltd--form input[type="number"],
.bltd--ui.bltd--form input[type="password"],
.bltd--ui.bltd--form input[type="search"],
.bltd--ui.bltd--form input[type="tel"],
.bltd--ui.bltd--form input[type="time"],
.bltd--ui.bltd--form input[type="text"],
.bltd--ui.bltd--form input[type="url"] {
  font-family: $base-font-family;
  margin: 0;
  outline: none;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  //line-height: 2.5em;
  padding: 0.2em 0.5em;
  font-size: 1em;
  background: #FFFFFF;
  border: 1px solid #e5e4de;
  color: #4a4a4a;
  border-radius: 3px;
  box-shadow: 0 0 0 1px #e5e4de;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;

  height: 2.5em;


}

/* Text Area */
.bltd--ui.bltd--form label{
  margin-bottom: .5em;
  display: block;
}
.bltd--ui.bltd--form textarea {
  margin: 0 0 30px;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  padding: 0.78571429em 1em;
  background: #FFFFFF;
  border: 1px solid #efeff0;
  box-shadow: /*0 0 0 1px #e5e4de,*/ 0 0 0 0 transparent inset;
  outline: none;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 3px;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 1em;
  line-height: 1.2857;
  resize: vertical;
}
.bltd--ui.bltd--form textarea:not([rows]) {
  height: 12em;
  min-height: 8em;
  max-height: 24em;
}
.bltd--ui.bltd--form textarea,
.bltd--ui.bltd--form input[type="checkbox"] {
  vertical-align: top;
}

/*--------------------------
  Input bltd--w/ attached Button
---------------------------*/

.bltd--ui.bltd--form input.attached {
  width: auto;
}

/*--------------------
     Basic Select
---------------------*/

.bltd--ui.bltd--form select {
  display: block;
  height: auto;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 3px;
  box-shadow: 0 0 0 0 transparent inset;
  padding: 0.62em 1em;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
}

/*--------------------
       Dropdown
---------------------*/


/* Block */
.bltd--ui.bltd--form .bltd--field > .selection.bltd--dropdown {
  width: 100%;
}
.bltd--ui.bltd--form .bltd--field > .selection.bltd--dropdown > .bltd--dropdown.bltd--icon {
  float: right;
}
.bltd--ui.selection.bltd--dropdown>.bltd--delete.bltd--icon, .bltd--ui.selection.bltd--dropdown>.bltd--dropdown.bltd--icon, .bltd--ui.selection.bltd--dropdown>.search.bltd--icon {
  cursor: pointer;
  position: absolute;
  top: auto;
  width: auto;
  z-index: 3;
  /*margin: -.78571429em;
  padding: .78571429em;*/
  right: $px15;
  opacity: .8;
  -webkit-transition: opacity .1s ease;
  transition: opacity .1s ease;
}
/* Inline */
.bltd--ui.bltd--form .inline.bltd--fields .bltd--field > .selection.bltd--dropdown,
.bltd--ui.bltd--form .inline.bltd--field > .selection.bltd--dropdown {
  width: auto;
}
.bltd--ui.bltd--form .inline.bltd--fields .bltd--field > .selection.bltd--dropdown > .bltd--dropdown.bltd--icon,
.bltd--ui.bltd--form .inline.bltd--field > .selection.bltd--dropdown > .bltd--dropdown.bltd--icon {
  float: none;
}

/*--------------------
       UI Input
---------------------*/


/* Block */
.bltd--ui.bltd--form .bltd--field .bltd--ui.bltd--input,
.bltd--ui.bltd--form .bltd--fields .bltd--field .bltd--ui.bltd--input,
.bltd--ui.bltd--form .wide.bltd--field .bltd--ui.bltd--input {
  width: 100%;
}
.bltd--ui.bltd--form .bltd--field .bltd--ui.bltd--input .ng,
.bltd--ui.bltd--form .bltd--fields .bltd--field .bltd--ui.bltd--input .ng,
.bltd--ui.bltd--form .wide.bltd--field .bltd--ui.bltd--input .ng{
  width: 100%;
}
/* Inline  */
.bltd--ui.bltd--form .inline.bltd--fields .bltd--field:not(.wide) .bltd--ui.bltd--input,
.bltd--ui.bltd--form .inline.bltd--field:not(.wide) .bltd--ui.bltd--input {
  width: auto;
  vertical-align: middle;
}

/* Auto Input */
.bltd--ui.bltd--form .bltd--fields .bltd--field .bltd--ui.bltd--input input,
.bltd--ui.bltd--form .bltd--field .bltd--ui.bltd--input input {
  /*width: auto;*/
}

/* Full Width Input */
.bltd--ui.bltd--form .ten.bltd--fields .bltd--ui.bltd--input input,
.bltd--ui.bltd--form .nine.bltd--fields .bltd--ui.bltd--input input,
.bltd--ui.bltd--form .eight.bltd--fields .bltd--ui.bltd--input input,
.bltd--ui.bltd--form .seven.bltd--fields .bltd--ui.bltd--input input,
.bltd--ui.bltd--form .six.bltd--fields .bltd--ui.bltd--input input,
.bltd--ui.bltd--form .five.bltd--fields .bltd--ui.bltd--input input,
.bltd--ui.bltd--form .four.bltd--fields .bltd--ui.bltd--input input,
.bltd--ui.bltd--form .three.bltd--fields .bltd--ui.bltd--input input,
.bltd--ui.bltd--form .two.bltd--fields .bltd--ui.bltd--input input,
.bltd--ui.bltd--form .wide.bltd--field .bltd--ui.bltd--input input {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  width: 0px;
}



/*--------------------
   Types of Messages
---------------------*/

.bltd--ui.bltd--form .bltd--error.bltd--msg {
  margin-top: 1em;
  font-size: $px14;
  color: #dd4b39 !important;
}
.bltd--ui.bltd--form .success.bltd--msg {
  margin-top: 1em;
  font-size: $px14;
  color: #90C851 !important;
}
/* Assumptions */
.bltd--ui.bltd--form .bltd--message:first-child {
  margin-top: 0px;
}

/*--------------------
   Validation Prompt
---------------------*/

.bltd--ui.bltd--form .bltd--field .prompt.label {
  white-space: normal;
  background: #FFFFFF !important;
  border: 1px solid #E0B4B4 !important;
  color: #dd4b39 !important;
}
.bltd--ui.bltd--form .inline.bltd--fields .bltd--field .prompt,
.bltd--ui.bltd--form .inline.bltd--field .prompt {
  vertical-align: top;
  margin: -0.25em 0 -0.5em 0.5em;
}
.bltd--ui.bltd--form .inline.bltd--fields .bltd--field .prompt:before,
.bltd--ui.bltd--form .inline.bltd--field .prompt:before {
  border-width: 0px 0px 1px 1px;
  bottom: auto;
  right: auto;
  top: 50%;
  left: 0;
}


/*******************************
            States
*******************************/


/*--------------------
      Autofilled
---------------------*/

.bltd--ui.bltd--form .bltd--field.bltd--field input:-webkit-autofill {
  box-shadow: 0px 0px 0px 100px #FFFFF0 inset !important;
  border-color: #E5DFA1 !important;
}

/* Focus */
.bltd--ui.bltd--form .bltd--field.bltd--field input:-webkit-autofill:focus {
  box-shadow: 0px 0px 0px 100px #FFFFF0 inset !important;
  border-color: #D5C315 !important;
}

/* Error */
.bltd--ui.bltd--form .bltd--error.bltd--error input:-webkit-autofill {
  box-shadow: 0px 0px 0px 100px #FFFAF0 inset !important;
  border-color: #E0B4B4 !important;
}

.bltd--ui.bltd--form .message-error,
.bltd--ui.bltd--form .bltd--fields .bltd--field .message-error {
  margin-top: 1em;
  font-size: 0.875em;
  color: #dd4b39 !important;
}

/*--------------------
      Placeholder
---------------------*/


/* browsers require these rules separate */
.bltd--ui.bltd--form ::-webkit-input-placeholder {
  color: rgba(140, 140, 140, 0.87);
}
.bltd--ui.bltd--form ::-ms-input-placeholder {
  color: rgba(140, 140, 140, 0.87);
}
.bltd--ui.bltd--form ::-moz-placeholder {
  color: rgba(140, 140, 140, 0.87);
}
.bltd--ui.bltd--form :focus::-webkit-input-placeholder {
  color: rgba(89, 89, 89, 0.87);
}
.bltd--ui.bltd--form :focus::-ms-input-placeholder {
  color: rgba(89, 89, 89, 0.87);
}
.bltd--ui.bltd--form :focus::-moz-placeholder {
  color: rgba(89, 89, 89, 0.87);
}

/* Error Placeholder */
.bltd--ui.bltd--form .bltd--error ::-webkit-input-placeholder {
  color: #e7bdbc;
}
.bltd--ui.bltd--form .bltd--error ::-ms-input-placeholder {
  color: #e7bdbc;
}
.bltd--ui.bltd--form .bltd--error ::-moz-placeholder {
  color: #e7bdbc;
}
.bltd--ui.bltd--form .bltd--error :focus::-webkit-input-placeholder {
  color: #da9796;
}
.bltd--ui.bltd--form .bltd--error :focus::-ms-input-placeholder {
  color: #da9796;
}
.bltd--ui.bltd--form .bltd--error :focus::-moz-placeholder {
  color: #da9796;
}

/*--------------------
        Success
---------------------*/


/* On Form */
.bltd--ui.bltd--form.success .success.bltd--message:not(:empty) {
  display: block;
}
.bltd--ui.bltd--form.success .bltd--icon.success.bltd--message:not(:empty) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

/*--------------------
        Warning
---------------------*/


/* On Form */
.bltd--ui.bltd--form.warning .warning.bltd--message:not(:empty) {
  display: block;
}
.bltd--ui.bltd--form.warning .bltd--icon.warning.bltd--message:not(:empty) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

/*--------------------
        Error
---------------------*/


/* On Form */
.bltd--ui.bltd--form.bltd--error .bltd--error.bltd--message:not(:empty) {
  display: block;
}
.bltd--ui.bltd--form.bltd--error .bltd--icon.bltd--error.bltd--message:not(:empty) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

/* On Field(s) */
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field label,
.bltd--ui.bltd--form .bltd--field.bltd--error label,
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field .bltd--input,
.bltd--ui.bltd--form .bltd--field.bltd--error .bltd--input {
  color: #dd4b39
}
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field .corner.label,
.bltd--ui.bltd--form .bltd--field.bltd--error .corner.label {
  border-color: #dd4b39;
  color: #FFFFFF;
}
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field textarea,
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field select,
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field input:not([type]),
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field input[type="date"],
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field input[type="datetime-local"],
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field input[type="email"],
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field input[type="number"],
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field input[type="password"],
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field input[type="search"],
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field input[type="tel"],
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field input[type="time"],
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field input[type="text"],
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field input[type="url"],
.bltd--ui.bltd--form .bltd--field.bltd--error textarea,
.bltd--ui.bltd--form .bltd--field.bltd--error select,
.bltd--ui.bltd--form .bltd--field.bltd--error input:not([type]),
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="date"],
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="datetime-local"],
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="email"],
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="number"],
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="password"],
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="search"],
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="tel"],
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="time"],
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="text"],
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="url"] {
  border-color: #dd4b39;
  box-shadow: 0 0 0 .125em  rgba(221, 75, 57, 0.35) ;
}
.bltd--ui.bltd--form .bltd--field.bltd--error textarea:focus,
.bltd--ui.bltd--form .bltd--field.bltd--error select:focus,
.bltd--ui.bltd--form .bltd--field.bltd--error input:not([type]):focus,
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="date"]:focus,
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="datetime-local"]:focus,
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="email"]:focus,
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="number"]:focus,
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="password"]:focus,
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="search"]:focus,
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="tel"]:focus,
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="time"]:focus,
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="text"]:focus,
.bltd--ui.bltd--form .bltd--field.bltd--error input[type="url"]:focus {
  border-color: #dd4b39;
  box-shadow: 0 0 0 .125em  rgba(221, 75, 57, 0.35) ;
}
.bltd--ui.bltd--form textarea.is-invalid,
.bltd--ui.bltd--form select.is-invalid,

.bltd--ui.bltd--form input:not([type]).is-invalid,
.bltd--ui.bltd--form input[type="date"].is-invalid,
.bltd--ui.bltd--form input[type="datetime-local"].is-invalid,
.bltd--ui.bltd--form input[type="email"].is-invalid,
.bltd--ui.bltd--form input[type="number"].is-invalid,
.bltd--ui.bltd--form input[type="password"].is-invalid,
.bltd--ui.bltd--form input[type="search"].is-invalid,
.bltd--ui.bltd--form input[type="tel"].is-invalid,
.bltd--ui.bltd--form input[type="time"].is-invalid,
.bltd--ui.bltd--form input[type="text"].is-invalid,
.bltd--ui.bltd--form input[type="url"].is-invalid,
.bltd--ui.selection.bltd--dropdown.is-invalid,
.bltd--ui.bltd--form .bltd--form-dropdown.is-invalid {
  border-color: #dd4b39;
  box-shadow: 0 0 0 .125em  rgba(221, 75, 57, 0.35) ;
}


.bltd--ui.bltd--form textarea:focus,
.bltd--ui.bltd--form select:focus,
.bltd--ui.bltd--form input:not([type]).is-invalid:focus,
.bltd--ui.bltd--form input[type="date"].is-invalid:focus,
.bltd--ui.bltd--form input[type="datetime-local"].is-invalid:focus,
.bltd--ui.bltd--form input[type="email"].is-invalid:focus,
.bltd--ui.bltd--form input[type="number"].is-invalid:focus,
.bltd--ui.bltd--form input[type="password"].is-invalid:focus,
.bltd--ui.bltd--form input[type="search"].is-invalid:focus,
.bltd--ui.bltd--form input[type="tel"].is-invalid:focus,
.bltd--ui.bltd--form input[type="time"].is-invalid:focus,
.bltd--ui.bltd--form input[type="text"].is-invalid:focus,
.bltd--ui.bltd--form input[type="url"].is-invalid:focus {
  border-color: #dd4b39;
  box-shadow: 0 0 0 .125em  rgba(221, 75, 57, 0.35) ;
}

/* Preserve Native Select Stylings */
.bltd--ui.bltd--form .bltd--field.bltd--error select {
  -webkit-appearance: menulist-button;
}

/*--------------------
        Focus
---------------------*/

.bltd--ui.bltd--form input:not([type]):focus,
.bltd--ui.bltd--form input[type="date"]:focus,
.bltd--ui.bltd--form input[type="datetime-local"]:focus,
.bltd--ui.bltd--form input[type="email"]:focus,
.bltd--ui.bltd--form input[type="number"]:focus,
.bltd--ui.bltd--form input[type="password"]:focus,
.bltd--ui.bltd--form input[type="search"]:focus,
.bltd--ui.bltd--form input[type="tel"]:focus,
.bltd--ui.bltd--form input[type="time"]:focus,
.bltd--ui.bltd--form input[type="text"]:focus,
.bltd--ui.bltd--form input[type="url"]:focus {
  /* color: rgba(0, 0, 0, 0.95);
   border-color: #85B7D9;
   border-radius: 3px;
   background: #FFFFFF;*/
  border-color: var(--base-green-color);
  box-shadow: 0 0 0 .125em var(--base-focus-shadow), .0625em .0625em .0625em .0625em rgba(0, 0, 0, 0.05) inset
}
.bltd--ui.bltd--form textarea:focus {
  /*color: rgba(0, 0, 0, 0.95);
  border-color: #85B7D9;
  border-radius: 3px;
  background: #FFFFFF;
  box-shadow: 0px 0 0 0 rgba(34, 36, 38, 0.35) inset;*/
  border-color: var(--base-green-color);
  box-shadow: 0 0 0 .125em  var(--base-focus-shadow);
  -webkit-appearance: none;
}
.bltd--ui.bltd--form textarea.ng-invalid, .bltd--ui.bltd--form textarea.ng-invalid:focus {
  border-color: #dd4b39;
  box-shadow: 0 0 0 0.125em rgba(221, 75, 57, 0.35);
}

/*------------------
    Dropdown Error
--------------------*/

.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field .bltd--ui.bltd--dropdown,
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field .bltd--ui.bltd--dropdown .bltd--item,
.bltd--ui.bltd--form .bltd--field.bltd--error .bltd--ui.bltd--dropdown,
.bltd--ui.bltd--form .bltd--field.bltd--error .bltd--ui.bltd--dropdown .bltd--text,
.bltd--ui.bltd--form .bltd--field.bltd--error .bltd--ui.bltd--dropdown .bltd--item {

  color: #dd4b39;
}
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field .bltd--ui.bltd--dropdown,
.bltd--ui.bltd--form .bltd--field.bltd--error .bltd--ui.bltd--dropdown {
  border-color: #E0B4B4 !important;
}
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field .bltd--ui.bltd--dropdown:hover,
.bltd--ui.bltd--form .bltd--field.bltd--error .bltd--ui.bltd--dropdown:hover {
  border-color: #E0B4B4 !important;
}
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field .bltd--ui.bltd--dropdown:hover .bltd--menu,
.bltd--ui.bltd--form .bltd--field.bltd--error .bltd--ui.bltd--dropdown:hover .bltd--menu {
  border-color: #E0B4B4;
}
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field .bltd--ui.multiple.selection.bltd--dropdown > .label,
.bltd--ui.bltd--form .bltd--field.bltd--error .bltd--ui.multiple.selection.bltd--dropdown > .label {

  color: #dd4b39;
}

/* Hover */
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field .bltd--ui.bltd--dropdown .bltd--menu .bltd--item:hover,
.bltd--ui.bltd--form .bltd--field.bltd--error .bltd--ui.bltd--dropdown .bltd--menu .bltd--item:hover {
  background-color: #FBE7E7;
}

/* Selected */
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field .bltd--ui.bltd--dropdown .bltd--menu .selected.bltd--item,
.bltd--ui.bltd--form .bltd--field.bltd--error .bltd--ui.bltd--dropdown .bltd--menu .selected.bltd--item {
  background-color: #FBE7E7;
}

/* Active */
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field .bltd--ui.bltd--dropdown .bltd--menu .active.bltd--item,
.bltd--ui.bltd--form .bltd--field.bltd--error .bltd--ui.bltd--dropdown .bltd--menu .active.bltd--item {
  background-color: #FDCFCF !important;
}

/*--------------------
    Checkbox Error
---------------------*/

.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field .checkbox:not(.toggle):not(.slider) label,
.bltd--ui.bltd--form .bltd--field.bltd--error .checkbox:not(.toggle):not(.slider) label,
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field .checkbox:not(.toggle):not(.slider) .box,
.bltd--ui.bltd--form .bltd--field.bltd--error .checkbox:not(.toggle):not(.slider) .box {
  color: #dd4b39;
}
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field .checkbox:not(.toggle):not(.slider) label:before,
.bltd--ui.bltd--form .bltd--field.bltd--error .checkbox:not(.toggle):not(.slider) label:before,
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field .checkbox:not(.toggle):not(.slider) .box:before,
.bltd--ui.bltd--form .bltd--field.bltd--error .checkbox:not(.toggle):not(.slider) .box:before {
  background: #FFF6F6;
  border-color: #E0B4B4;
}
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field .checkbox label:after,
.bltd--ui.bltd--form .bltd--field.bltd--error .checkbox label:after,
.bltd--ui.bltd--form .bltd--fields.bltd--error .bltd--field .checkbox .box:after,
.bltd--ui.bltd--form .bltd--field.bltd--error .checkbox .box:after {
  color: #dd4b39;
}

/*--------------------
       Disabled
---------------------*/

.bltd--ui.bltd--form .disabled.bltd--fields .bltd--field,
.bltd--ui.bltd--form .disabled.bltd--field,
.bltd--ui.bltd--form .bltd--field :disabled {
  pointer-events: none;
  opacity: 0.45;
}
.bltd--ui.bltd--form .bltd--field.disabled label {
  opacity: 0.45;
}
.bltd--ui.bltd--form .bltd--field.disabled :disabled {
  opacity: 1;
}

/*--------------
    Loading
---------------*/

.bltd--ui.loading.bltd--form {
  position: relative;
  cursor: default;
  point-events: none;
  text-shadow: none !important;
  color: transparent !important;
  -webkit-transition: all 0s linear;
  transition: all 0s linear;
  z-index: 100;
}
.bltd--ui.loading.bltd--form:before {
  position: absolute;
  content: '';
  top: 0%;
  left: 0%;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  z-index: 100;
}
.bltd--ui.loading.bltd--form:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -1.5em 0 0 -1.5em;
  width: 3em;
  height: 3em;
  -webkit-animation: form-spin 0.6s linear;
  animation: form-spin 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #767676 rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-width: 0.2em;
  box-shadow: 0px 0px 0px 1px transparent;
  visibility: visible;
  z-index: 101;
}
@-webkit-keyframes form-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes form-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


/*******************************
         Element Types
*******************************/


/*--------------------
     Required Field
---------------------*/

.bltd--ui.bltd--form .required.bltd--fields:not(.grouped) > .bltd--field > label:after,
.bltd--ui.bltd--form .required.bltd--fields.grouped > label:after,
.bltd--ui.bltd--form .required.bltd--field > label:after,
.bltd--ui.bltd--form .required.bltd--fields:not(.grouped) > .bltd--field > .checkbox:after,
.bltd--ui.bltd--form .required.bltd--field > .checkbox:after {
  margin: -0.2em 0 0 0.2em;
  content: '*';
  color: #DB2828;
}
.bltd--ui.bltd--form .required.bltd--fields:not(.grouped) > .bltd--field > label:after,
.bltd--ui.bltd--form .required.bltd--fields.grouped > label:after,
.bltd--ui.bltd--form .required.bltd--field > label:after {
  display: inline-block;
  vertical-align: top;
}
.bltd--ui.bltd--form .required.bltd--fields:not(.grouped) > .bltd--field > .checkbox:after,
.bltd--ui.bltd--form .required.bltd--field > .checkbox:after {
  position: absolute;
  top: 0%;
  left: 100%;
}


/*******************************
           Variations
*******************************/


/*--------------------
    Inverted Colors
---------------------*/

.bltd--ui.bltd--inverted.bltd--form label,
.bltd--ui.bltd--form .bltd--inverted.segment label,
.bltd--ui.bltd--form .bltd--inverted.segment .bltd--ui.checkbox label,
.bltd--ui.bltd--form .bltd--inverted.segment .bltd--ui.checkbox .box,
.bltd--ui.bltd--inverted.bltd--form .bltd--ui.checkbox label,
.bltd--ui.bltd--inverted.bltd--form .bltd--ui.checkbox .box {
  color: rgba(255, 255, 255, 0.9);
}

/* Inverted Field */
.bltd--ui.bltd--inverted.bltd--form input:not([type]),
.bltd--ui.bltd--inverted.bltd--form input[type="date"],
.bltd--ui.bltd--inverted.bltd--form input[type="datetime-local"],
.bltd--ui.bltd--inverted.bltd--form input[type="email"],
.bltd--ui.bltd--inverted.bltd--form input[type="number"],
.bltd--ui.bltd--inverted.bltd--form input[type="password"],
.bltd--ui.bltd--inverted.bltd--form input[type="search"],
.bltd--ui.bltd--inverted.bltd--form input[type="tel"],
.bltd--ui.bltd--inverted.bltd--form input[type="time"],
.bltd--ui.bltd--inverted.bltd--form input[type="text"],
.bltd--ui.bltd--inverted.bltd--form input[type="url"] {
  background: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.1);
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
}

/*--------------------
     Field Groups
---------------------*/


/* Grouped Vertically */
.bltd--ui.bltd--form .grouped.bltd--fields {
  display: block;
  margin: 0 0 1em;
}
.bltd--ui.bltd--form .grouped.bltd--fields:last-child {
  margin-bottom: 0;
}
.bltd--ui.bltd--form .grouped.bltd--fields > label {
  margin: 0 0 3px 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: bold;
  text-transform: none;
}
.bltd--ui.bltd--form .grouped.bltd--fields .bltd--field,
.bltd--ui.bltd--form .grouped.inline.bltd--fields .bltd--field {
  display: block;
  margin: 0.5em 0;
  padding: 0;
}

/*--------------------
        Fields
---------------------*/


/* Split fields */
.bltd--ui.bltd--form .bltd--fields {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.bltd--ui.bltd--form .bltd--fields > .bltd--field {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  padding-left: 0.5em;
  padding-right: 0.5em;

}
.bltd--ui.bltd--form .bltd--fields > .bltd--field:first-child {
  border-left: none;
  box-shadow: none;
}

/* Other Combinations */
.bltd--ui.bltd--form .two.bltd--fields > .bltd--fields,
.bltd--ui.bltd--form .two.bltd--fields > .bltd--field {
  width: 50%;
}
.bltd--ui.bltd--form .three.bltd--fields > .bltd--fields,
.bltd--ui.bltd--form .three.bltd--fields > .bltd--field {
  width: 33.33333333%;
}
.bltd--ui.bltd--form .four.bltd--fields > .bltd--fields,
.bltd--ui.bltd--form .four.bltd--fields > .bltd--field {
  width: 25%;
}
.bltd--ui.bltd--form .five.bltd--fields > .bltd--fields,
.bltd--ui.bltd--form .five.bltd--fields > .bltd--field {
  width: 20%;
}
.bltd--ui.bltd--form .six.bltd--fields > .bltd--fields,
.bltd--ui.bltd--form .six.bltd--fields > .bltd--field {
  width: 16.66666667%;
}
.bltd--ui.bltd--form .seven.bltd--fields > .bltd--fields,
.bltd--ui.bltd--form .seven.bltd--fields > .bltd--field {
  width: 14.28571429%;
}
.bltd--ui.bltd--form .eight.bltd--fields > .bltd--fields,
.bltd--ui.bltd--form .eight.bltd--fields > .bltd--field {
  width: 12.5%;
}
.bltd--ui.bltd--form .nine.bltd--fields > .bltd--fields,
.bltd--ui.bltd--form .nine.bltd--fields > .bltd--field {
  width: 11.11111111%;
}
.bltd--ui.bltd--form .ten.bltd--fields > .bltd--fields,
.bltd--ui.bltd--form .ten.bltd--fields > .bltd--field {
  width: 10%;
}

/* Swap to full width on mobile */
@media only screen and (max-width: 767px) {
  .bltd--ui.bltd--form .bltd--fields {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .bltd--ui.bltd--form .two.bltd--fields > .bltd--fields,
  .bltd--ui.bltd--form .two.bltd--fields > .bltd--field,
  .bltd--ui.bltd--form .three.bltd--fields > .bltd--fields,
  .bltd--ui.bltd--form .three.bltd--fields > .bltd--field,
  .bltd--ui.bltd--form .four.bltd--fields > .bltd--fields,
  .bltd--ui.bltd--form .four.bltd--fields > .bltd--field,
  .bltd--ui.bltd--form .five.bltd--fields > .bltd--fields,
  .bltd--ui.bltd--form .five.bltd--fields > .bltd--field,
  .bltd--ui.bltd--form .six.bltd--fields > .bltd--fields,
  .bltd--ui.bltd--form .six.bltd--fields > .bltd--field,
  .bltd--ui.bltd--form .seven.bltd--fields > .bltd--fields,
  .bltd--ui.bltd--form .seven.bltd--fields > .bltd--field,
  .bltd--ui.bltd--form .eight.bltd--fields > .bltd--fields,
  .bltd--ui.bltd--form .eight.bltd--fields > .bltd--field,
  .bltd--ui.bltd--form .nine.bltd--fields > .bltd--fields,
  .bltd--ui.bltd--form .nine.bltd--fields > .bltd--field,
  .bltd--ui.bltd--form .ten.bltd--fields > .bltd--fields,
  .bltd--ui.bltd--form .ten.bltd--fields > .bltd--field {
    width: 100% !important;
    margin: 0 0 1em;
    padding-left: 0%;
    padding-right: 0%;
  }

  .bltd--ui.bltd--form .fbuttons .button {
    width: 100% !important;
    margin: 0 0 1em;
    padding-left: 0%;
    padding-right: 0%;
  }



}
.bltd--ui.bltd--form .bltd--fields .bltd--field:first-child {
  padding-left: 0%;
}
.bltd--ui.bltd--form .bltd--fields .bltd--field:last-child {
  padding-right: 0%;
}

/* Sizing Combinations */
.bltd--ui.bltd--form .bltd--fields .wide.bltd--field {
  width: 6.25%;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.bltd--ui.bltd--form .bltd--fields .wide.bltd--field:first-child {
  padding-left: 0%;
}
.bltd--ui.bltd--form .bltd--fields .wide.bltd--field:last-child {
  padding-right: 0%;
}
.bltd--ui.bltd--form .one.wide.bltd--field {
  width: 6.25% !important;
}
.bltd--ui.bltd--form .two.wide.bltd--field {
  width: 12.5% !important;
}
.bltd--ui.bltd--form .three.wide.bltd--field {
  width: 18.75% !important;
}
.bltd--ui.bltd--form .four.wide.bltd--field {
  width: 25% !important;
}
.bltd--ui.bltd--form .five.wide.bltd--field {
  width: 31.25% !important;
}
.bltd--ui.bltd--form .six.wide.bltd--field {
  width: 37.5% !important;
}
.bltd--ui.bltd--form .seven.wide.bltd--field {
  width: 43.75% !important;
}
.bltd--ui.bltd--form .eight.wide.bltd--field {
  width: 50% !important;
}
.bltd--ui.bltd--form .nine.wide.bltd--field {
  width: 56.25% !important;
}
.bltd--ui.bltd--form .ten.wide.bltd--field {
  width: 62.5% !important;
}
.bltd--ui.bltd--form .eleven.wide.bltd--field {
  width: 68.75% !important;
}
.bltd--ui.bltd--form .twelve.wide.bltd--field {
  width: 75% !important;
}
.bltd--ui.bltd--form .thirteen.wide.bltd--field {
  width: 81.25% !important;
}
.bltd--ui.bltd--form .fourteen.wide.bltd--field {
  width: 87.5% !important;
}
.bltd--ui.bltd--form .fifteen.wide.bltd--field {
  width: 93.75% !important;
}
.bltd--ui.bltd--form .sixteen.wide.bltd--field {
  width: 100% !important;
}

/* Swap to full width on mobile */
@media only screen and (max-width: 767px) {
  .bltd--ui.bltd--form .two.bltd--fields > .bltd--fields,
  .bltd--ui.bltd--form .two.bltd--fields > .bltd--field,
  .bltd--ui.bltd--form .three.bltd--fields > .bltd--fields,
  .bltd--ui.bltd--form .three.bltd--fields > .bltd--field,
  .bltd--ui.bltd--form .four.bltd--fields > .bltd--fields,
  .bltd--ui.bltd--form .four.bltd--fields > .bltd--field,
  .bltd--ui.bltd--form .five.bltd--fields > .bltd--fields,
  .bltd--ui.bltd--form .five.bltd--fields > .bltd--field,
  .bltd--ui.bltd--form .bltd--fields > .two.wide.bltd--field,
  .bltd--ui.bltd--form .bltd--fields > .three.wide.bltd--field,
  .bltd--ui.bltd--form .bltd--fields > .four.wide.bltd--field,
  .bltd--ui.bltd--form .bltd--fields > .five.wide.bltd--field,
  .bltd--ui.bltd--form .bltd--fields > .six.wide.bltd--field,
  .bltd--ui.bltd--form .bltd--fields > .seven.wide.bltd--field,
  .bltd--ui.bltd--form .bltd--fields > .eight.wide.bltd--field,
  .bltd--ui.bltd--form .bltd--fields > .nine.wide.bltd--field,
  .bltd--ui.bltd--form .bltd--fields > .ten.wide.bltd--field,
  .bltd--ui.bltd--form .bltd--fields > .eleven.wide.bltd--field,
  .bltd--ui.bltd--form .bltd--fields > .twelve.wide.bltd--field,
  .bltd--ui.bltd--form .bltd--fields > .thirteen.wide.bltd--field,
  .bltd--ui.bltd--form .bltd--fields > .fourteen.wide.bltd--field,
  .bltd--ui.bltd--form .bltd--fields > .fifteen.wide.bltd--field,
  .bltd--ui.bltd--form .bltd--fields > .sixteen.wide.bltd--field {
    width: 100% !important;
    margin: 0 0 1em;
    padding-left: 0%;
    padding-right: 0%;
  }
}

/*--------------------
     Equal Width
---------------------*/

.bltd--ui[class*="equal width"].bltd--form .bltd--fields > .bltd--field,
.bltd--ui.bltd--form [class*="equal width"].bltd--fields > .bltd--field {
  width: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

/*--------------------
    Inline Fields
---------------------*/

.bltd--ui.bltd--form .inline.bltd--fields {
  margin: 0 0 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.bltd--ui.bltd--form .inline.bltd--fields .bltd--field {
  margin: 0;
  padding: 0 1em 0 0;
}

/* Inline Label */
.bltd--ui.bltd--form .inline.bltd--fields > label,
.bltd--ui.bltd--form .inline.bltd--fields .bltd--field > label,
.bltd--ui.bltd--form .inline.bltd--fields .bltd--field > p,
.bltd--ui.bltd--form .inline.bltd--field > label,
.bltd--ui.bltd--form .inline.bltd--field > p {
  display: inline-block;
  width: auto;
  margin-top: 0;
  margin-bottom: 0;
  vertical-align: baseline;


  text-transform: none;
}

/* Grouped Inline Label */
.bltd--ui.bltd--form .inline.bltd--fields > label {
  margin: 0.035714em 1em 0 0;
}

/* Inline Input */
.bltd--ui.bltd--form .inline.bltd--fields .bltd--field > input,
.bltd--ui.bltd--form .inline.bltd--fields .bltd--field > select,
.bltd--ui.bltd--form .inline.bltd--field > input,
.bltd--ui.bltd--form .inline.bltd--field > select {
  display: inline-block;
  width: auto;
  margin-top: 0;
  margin-bottom: 0;
  vertical-align: middle;
  font-size: 1em;
}

/* Label */
.bltd--ui.bltd--form .inline.bltd--fields .bltd--field > :first-child,
.bltd--ui.bltd--form .inline.bltd--field > :first-child {
  margin: 0 0.85714286em 0 0;
}
.bltd--ui.bltd--form .inline.bltd--fields .bltd--field > :only-child,
.bltd--ui.bltd--form .inline.bltd--field > :only-child {
  margin: 0;
}

/* Wide */
.bltd--ui.bltd--form .inline.bltd--fields .wide.bltd--field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.bltd--ui.bltd--form .inline.bltd--fields .wide.bltd--field > input,
.bltd--ui.bltd--form .inline.bltd--fields .wide.bltd--field > select {
  width: 100%;
}

/*--------------------
        Sizes
---------------------*/


/* Standard */
.bltd--ui.small.bltd--form {

}

/* Medium */
.bltd--ui.bltd--form {

}

/* Large */
.bltd--ui.large.bltd--form {

}

/* Huge */
.bltd--ui.huge.bltd--form {
  font-size: 2.4rem;
}

.bltd--ui .bltd--icon.bltd--icon--calendar, .bltd--ui .bltd--icon.bltd--icon--graycalendar {
  background-color: #fff;
  cursor: pointer;
  width: 1.25em;
  height: 1.25em;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  margin-top: -0.625em;
  right: 0.625em;
  display: block;
  position: absolute;
}