$footer-gray: #9B9B9B;

@mixin flex-column($fg: 1, $fs: null, $fb: null) {
  $fg-boxflex: $fg;
  -webkit-box-flex: $fg-boxflex;
  -webkit-flex: $fg $fs $fb;
  -moz-box-flex: $fg-boxflex;
  -moz-flex: $fg $fs $fb;
  -ms-flex: $fg $fs $fb;
  flex: $fg $fs $fb;
  max-width: $fb;
}

.bltd--points--list {
  position: relative;
  .bltd--query {
    position: absolute;
    z-index: 2;
    top: 0;
    width: 18%;
  }

  .bltd--row {
    overflow: hidden;
    margin-bottom: 1.5em;
  }

  .bltd--columns {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    .bltd--col {
      .bltd--no-margin {
        margin-top: 0;
      }
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      box-flex: 1;
      -webkit-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      align-content: stretch;
      @include flex-column(0, 0, 25%);
      &.bltd--padding-top {
        position: relative;
        padding-top: 6.5em;
      }

      .bltd--item {
        margin-bottom: 0.125em;
      }

      a {
        color: $base-color;
      }
    }

    .bltd--region {
      color: $footer-gray;
      margin-bottom: .5em;
      margin-top: 1.5em;
      //margin: 1em 0 0.5em;
    }

    form {
      padding: 0.5em 0;
    }
  }

  @media (max-width: 768px) {
    .bltd--query {
      width: 26%;
    }
    .bltd--columns {
      .bltd--col {
        @include flex-column(0, 0, 33%);
      }
    }
  }

  @media (max-width: 559px) {
    .bltd--query {
      width: 43%;
    }
    .bltd--columns {
      .bltd--col {
        @include flex-column(0, 0, 50%);
      }
    }
  }

  @media (max-width: 459px) {
    .bltd--query {
      width: 100%;
    }
    .bltd--columns {
      .bltd--col {
        @include flex-column(0, 0, 100%);
      }
    }
  }
}

footer.bltd--clearfix {
  clear: both;
}

@include media("<=tablet") {
  .bltd--cities {
    .bltd--wrap {
      padding-top: 1.25em;
    }
  }
}