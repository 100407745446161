.bltd--footer {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: -5em 0 0 0;
  position: relative;
  z-index: 1;
  padding: 0;

  .bltd--wrapper {
    padding-bottom: 1.75em;
    color: #9b9b9b;
    font-size: .875em;
    padding-top: 1em;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
  }

  .bltd--menu {
    float: left;
    flex-flow:row wrap;
    .bltd--link {
      color: #9b9b9b;
    }
  }

  .bltd--copyrights {
    float: right;
    text-align: right;
    padding: .75em 0.9375em;
    margin-left: 2em;
    white-space: nowrap;
    p {
      margin-bottom: 0;
    }
  }
}

.bltd--footer {
  margin-top: -5em;
  background: #fff;
  margin-bottom: 0;
}

@include media('<=tablet') {
  footer {
    display:none;
  }
}