.bltd--hamburger-menu {
    background-color: $bg-color-impaired;

    &:before, &:after {
        background-color: $bg-color-impaired;
    }
}

.bltd--is-nav-expanded-active .bltd--hamburger-menu {
    background: none;
}

header {
    &.bltd--header--index {
        background-color: $font-color-impaired;

        .bltd--logo .bltd--logo-link a, .bltd--main-nav .bltd--link {
            color: $bg-color-impaired;
        }

        .bltd--hamburger-menu {
            background-color: $bg-color-impaired;

            &:before, &:after {
                background-color: $bg-color-impaired;
            }
        }

        .bltd--is-nav-expanded-active .bltd--hamburger-menu {
            background: none;
        }
    }

    &.bltd--header--inner {
        background: $font-color-impaired;

        .bltd--logo-link a, .bltd--account .bltd--xs,
        .bltd--main-nav .bltd--link {
            color: $bg-color-impaired;
        }

        .bltd--ui.bltd--dropdown.bltd--item .bltd--menu {
            border: 0.0625em solid #000;
        }
    }

    @include media('>tablet') {
        .bltd--header-wrapper {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .bltd--logo .bltd--logo-link {
        margin: .625em 0;
    }

    .bltd--main-nav {
        margin-top: 0.75em;
    }

    .bltd--account {
        margin-top: 0.75em;
    }
}

.bltd--inverted .bltd--account .bltd--btn .bltd--text:after {
    background-position: -6.9375em -7.75em;
}

.bltd--account .bltd--btn.bltd--btn--stroke.bltd--btn--white.bltd--btn--m {
    color: $bg-color-impaired !important;
    box-shadow: 0 0 0 0.125em $bg-color-impaired inset;
    background-color: $font-color-impaired;
}

.bltd--ui.bltd--secondary.bltd--menu .bltd--link.bltd--item:hover,
.bltd--ui.bltd--secondary.bltd--menu a.bltd--item:hover {
    background: $bg-color-impaired;
    color: $font-color-impaired;
}

.bltd--ui.bltd--dropdown.bltd--inverted > .bltd--dropdown.bltd--icon:before {
    background-position: -6.9375em -7.75em;
}
.bltd--ui.bltd--secondary.bltd--menu .bltd--dropdown.bltd--item > .bltd--menu,
.bltd--ui.bltd--text.bltd--menu .bltd--dropdown.bltd--item > .bltd--menu {
    border: 0.0625em solid $font-color-impaired;
}

.bltd--ui.bltd--dropdown .bltd--menu > .bltd--divider {
    border-color: $font-color-impaired;
 }

.login-popup h3 {
    color: $font-color-impaired;
}

.bltd--with-icon_on:after {
    background-color: $font-color-impaired;
}

@include media('<=tablet') {
    header .bltd--logo .bltd--expand-nav {
        margin: 0.375em 0.375em 0 0em;
    }
    header .bltd--top-flex .bltd--logo .bltd--logo-flex .bltd--expand-nav {
        margin-top: .25em;
    }
    .bltd--booker .bltd--booker--line {
        padding-bottom: 0;
    }
    .bltd--booker .bltd--booker__params .bltd--booker--text {
        padding-right: 1em;
    }
    .bltd--booker .bltd--booker__params > .bltd--dropdown.bltd--icon:before {
        right: 0.1em;
    }
    .bltd--booker .bltd--booker__params {
        padding-left: 30px;
        font-size: 18px;
    }
    .zoom.bltd--icon:before {
        left: 0.4em;
    }


}
