.bltd--ui.header {
  border: none;
  margin: 0 0em 1.2em;
  padding: 0em 0em;

  font-weight: bold;

  text-transform: none;
  color: rgba(0, 0, 0, 0.87);
}
.bltd--ui.header:first-child {
  margin-top: 0
}
.bltd--ui.header:last-child {
  margin-bottom: 0em;
}

/*--------------
   Sub Header
---------------*/

.bltd--ui.header .sub.header {
  display: block;
  font-weight: normal;
  padding: 0em;
  margin: 0em;
  font-size: 1rem;
  line-height: 1.2em;
  color: rgba(0, 0, 0, 0.6);
}

/*--------------
      Icon
---------------*/

.bltd--ui.header > .bltd--icon {
  display: table-cell;
  opacity: 1;
  font-size: 1.5em;
  padding-top: 0.14285em;
  vertical-align: middle;
}

/* With Text Node */
.bltd--ui.header .bltd--icon:only-child {
  display: inline-block;
  padding: 0em;
  margin-right: 0.75rem;
}

/*-------------------
        Image
--------------------*/

.bltd--ui.header > .image,
.bltd--ui.header > img {
  display: inline-block;
  margin-top: 0.14285em;
  width: 2.5em;
  height: auto;
  vertical-align: middle;
}
.bltd--ui.header > .image:only-child,
.bltd--ui.header > img:only-child {
  margin-right: 0.75rem;
}

/*--------------
     Content
---------------*/

.bltd--ui.header .bltd--content {
  display: inline-block;
  vertical-align: top;
}

/* After Image */
.bltd--ui.header > img + .bltd--content,
.bltd--ui.header > .image + .bltd--content {
  padding-left: 0.75rem;
  vertical-align: middle;
}

/* After Icon */
.bltd--ui.header > .bltd--icon + .bltd--content {
  padding-left: 0.75rem;
  display: table-cell;
  vertical-align: middle;
}

/*--------------
 Loose Coupling
---------------*/

.bltd--ui.header .bltd--ui.label {
  font-size: '';
  margin-left: 0.5rem;
  vertical-align: middle;
}

/* Positioning */
.bltd--ui.header + p {
  margin-top: 0em;
}


/*******************************
            Types
*******************************/


/*--------------
     Page
---------------*/

/*h1.bltd--ui.header {
  font-size: 2rem;
}
h2.bltd--ui.header {
  font-size: 1.714rem;
}
h3.bltd--ui.header {
  font-size: 1.28rem;
}
h4.bltd--ui.header {
  font-size: 1.071rem;
}*/
h5.bltd--ui.header {
  font-size: 1rem;
}

/* Sub Header */
h1.bltd--ui.header .sub.header {
  font-size: 1.14285714rem;
}
h2.bltd--ui.header .sub.header {
  font-size: 1.14285714rem;
}
h3.bltd--ui.header .sub.header {
  font-size: 1rem;
}
h4.bltd--ui.header .sub.header {
  font-size: 1rem;
}
h5.bltd--ui.header .sub.header {
  font-size: 0.92857143rem;
}

/*--------------
 Content Heading
---------------*/

.bltd--ui.huge.header {
  min-height: 1em;
  font-size: 2em;
}
.bltd--ui.large.header {
  font-size: 1.714em;
}
.bltd--ui.medium.header {
  font-size: 1.28em;
}
.bltd--ui.small.header {
  font-size: 1.071em;
}
.bltd--ui.tiny.header {
  font-size: 1em;
}

/* Sub Header */
.bltd--ui.huge.header .sub.header {
  font-size: 1.14285714rem;
}
.bltd--ui.large.header .sub.header {
  font-size: 1.14285714rem;
}
.bltd--ui.header .sub.header {
  font-size: 1rem;
}
.bltd--ui.small.header .sub.header {
  font-size: 1rem;
}
.bltd--ui.tiny.header .sub.header {
  font-size: 0.92857143rem;
}

/*--------------
   Sub Heading
---------------*/

.bltd--ui.sub.header {
  padding: 0em;
  margin-bottom: 0.14285714rem;
  font-weight: bold;
  font-size: 0.85714286em;
  text-transform: uppercase;
  color: '';
}
.bltd--ui.small.sub.header {
  font-size: 0.71428571em;
}
.bltd--ui.sub.header {
  font-size: 0.85714286em;
}
.bltd--ui.large.sub.header {
  font-size: 0.92857143em;
}
.bltd--ui.huge.sub.header {
  font-size: 1em;
}

/*******************************
            States
*******************************/

.bltd--ui.disabled.header {
  opacity: 0.45;
}


/*******************************
           Variations
*******************************/



/*-------------------
       Aligned
--------------------*/

.bltd--ui.left.aligned.header {
  text-align: left;
}
.bltd--ui.right.aligned.header {
  text-align: right;
}
.bltd--ui.centered.header,
.bltd--ui.center.aligned.header {
  text-align: center;
}
.bltd--ui.justified.header {
  text-align: justify;
}
.bltd--ui.justified.header:after {
  display: inline-block;
  content: '';
  width: 100%;
}

/*-------------------
       Floated
--------------------*/

.bltd--ui.floated.header,
.bltd--ui[class*="left floated"].header {
  float: left;
  margin-top: 0em;
  margin-right: 0.5em;
}
.bltd--ui[class*="right floated"].header {
  float: right;
  margin-top: 0em;
  margin-left: 0.5em;
}

/*-------------------
       Fittted
--------------------*/

.bltd--ui.fitted.header {
  padding: 0em;
}

/*-------------------
      Dividing
--------------------*/

.bltd--ui.dividing.header {
  padding-bottom: 0.21428571rem;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}
.bltd--ui.dividing.header .sub.header {
  padding-bottom: 0.21428571rem;
}
.bltd--ui.dividing.header .bltd--icon {
  margin-bottom: 0em;
}
.bltd--ui.bltd--inverted.dividing.header {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

/*-------------------
        Block
--------------------*/

.bltd--ui.bltd--block.header {
  background: #F3F4F5;
  padding: 0.71428571rem 1rem;
  box-shadow: none;
  border: 1px solid #D4D4D5;
  border-radius: 0.28571429rem;
}
.bltd--ui.tiny.bltd--block.header {
  font-size: 0.85714286rem;
}
.bltd--ui.small.bltd--block.header {
  font-size: 0.92857143rem;
}
.bltd--ui.bltd--block.header:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
  font-size: 1rem;
}
.bltd--ui.large.bltd--block.header {
  font-size: 1.14285714rem;
}
.bltd--ui.huge.bltd--block.header {
  font-size: 1.42857143rem;
}

/*-------------------
       Attached
--------------------*/

.bltd--ui.attached.header {
  background: #FFFFFF;
  padding: 0.71428571rem 1rem;
  margin-left: -1px;
  margin-right: -1px;
  box-shadow: none;
  border: 1px solid #D4D4D5;
}
.bltd--ui.attached.bltd--block.header {
  background: #F3F4F5;
}
.bltd--ui.attached:not(.top):not(.bottom).header {
  margin-top: 0em;
  margin-bottom: 0em;
  border-top: none;
  border-radius: 0em;
}
.bltd--ui.top.attached.header {
  margin-bottom: 0em;
  border-radius: 0.28571429rem 0.28571429rem 0em 0em;
}
.bltd--ui.bottom.attached.header {
  margin-top: 0em;
  border-top: none;
  border-radius: 0em 0em 0.28571429rem 0.28571429rem;
}

/* Attached Sizes */
.bltd--ui.tiny.attached.header {
  font-size: 0.85714286em;
}
.bltd--ui.small.attached.header {
  font-size: 0.92857143em;
}
.bltd--ui.attached.header:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
  font-size: 1em;
}
.bltd--ui.large.attached.header {
  font-size: 1.14285714em;
}
.bltd--ui.huge.attached.header {
  font-size: 1.42857143em;
}

/*-------------------
        Sizing
--------------------*/

.bltd--ui.header:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
  font-size: 1.28em;
}
