.bltd--ui.bltd--message {
  position: relative;
  margin: 0 .75em 1.5em;
}

@include media('<=tablet') {
  .bltd--ui.bltd--message {
    margin: 0 1.25em 1.5em;
  }
  .bltd--ui.bltd--icon.bltd--message {
    margin-left: 0.75em!important;
  }
}

/*--------------
     Content
---------------*/



/* Paragraph */
.bltd--ui.bltd--message p {

  margin: 0.75em 0em;
}
.bltd--ui.bltd--message p:first-child {
  margin-top: 0em;
}
.bltd--ui.bltd--message p:last-child {
  margin-bottom: 0em;
}
.bltd--ui.bltd--message .header + p {
  margin-top: 0.25em;
}


/* Icon */
.bltd--ui.bltd--message > .bltd--icon {
  margin-right: 0.85em;
}

/* Close Icon */
.bltd--ui.bltd--message > .bltd--close.bltd--icon {
  cursor: pointer;
  position: absolute;
  margin: 0em;
  top: 0.78575em;
  right: 0.5em;
  opacity: 0.7;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
}
.bltd--ui.bltd--message > .bltd--close.bltd--icon:hover {
  opacity: 1;
}

/* First / Last Element */
.bltd--ui.bltd--message > :first-child {
  margin-top: 0em;
}
.bltd--ui.bltd--message > :last-child {
  margin-bottom: 0em;
}



.bltd--ui.bltd--icon.bltd--message {
  position: relative;
  padding-left: 1.75em;
  margin-left: 1.85em;
}
.bltd--ui.bltd--icon.bltd--message > .bltd--icon:not(.bltd--close) {
  display: block;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  line-height: 1;
  vertical-align: middle;
  font-size: 3em;
  position: relative;

}
.bltd--ui.bltd--icon.bltd--message > .bltd--content {
  display: block;
  min-height: 1em;
  font-size: 0.875em;
  line-height: 1.75em;
}

.bltd--message.bltd--icon:before {
  content: '';
  background-image: url("../img/icons/icons.svg");
  background-size: 10.5625em 17.3125em;
  position: absolute;
  top: 0em;
  left: 0;
}

.bltd--message.bltd--icon.warning:before {
  background-position: -7.8125em -14.5625em;
  width: 1.5em;
  height: 1.5em;
}

.bltd--message.bltd--icon.lock:before {
  background-position: -6.9375em -3.8125em;
  top: .25em;
  width: 1em;
  height: 1em;
}


/*

.bltd--message.lock:before {
  background: url("../img/icons/icon_lock_green.png") no-repeat 100% 100% transparent;
  width: 2em;
  height: 2em;
  content: ' ';
  position: absolute;
  left: .5em;
  top: 0;
}
i.bltd--icon.printer:before {
  background: url("../img/icons/icon_lock_green.png") no-repeat 100% 100%, transparent;
  width: 16px;
  height: 16px;
  content: ' ';
  position: absolute;
  top: 0em;
  left: 0;
}*/
