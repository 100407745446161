.bltd--ui.bltd--plist {

  background: #fff;
  border-radius: 0.25em;
  padding: 0 1.25em;

  &.bltd--form-plist {
    padding: 2.2em 6em;
  }

  & > .bltd--plist-item {
    padding: $px15 0;
    border-bottom: $px1 solid rgba(223, 223, 224, 0.5);
    .bltd--plist-item-inner {
      position: relative;
    }
    .bltd--content {
      margin-right: 9.375em;
      overflow: hidden;
    }
    .bltd--action {
      width: 9.375em;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      text-align: right;
    }
    &.bltd--with-icon {
      margin-left: $px60;
    }
    &:last-child {
      border-bottom: none;
    }
    :after {
      content: '';
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }


    .bltd--plist-name {
      margin-bottom: .5em;
      font-size: 1.125em;
      /* font-weight: 400; */
      line-height: 1.25em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .bltd--plist-doc {
      color: #666666;
      font-size: 0.8125em;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }

}

.bltd--plist-edit {
  background: #fff;
  padding: $px20 $px40;
  border: $px2 solid #82b548;
  border-radius: $px4;
  box-shadow: 0 $px2 $px4 rgba(0, 0, 0, 0.08);
  margin: 0 -1.25em;

  .bltd--breadcrumb {
    display: none;
  }

  &.mob {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: $px20;
    margin: 0 0 1.25em 0;
    z-index: 1;
    border: none;
    box-shadow: none;

    .bltd--breadcrumb {
      display: block;
      margin-bottom: 1.25em;
    }
  }
}
.bltd--plist-white {
  background: #fff;
  padding: $px20 $px30 $px40;
  border: $px1 solid rgba(255, 255, 255, 0);
  border-radius: $px4;
  box-shadow: 0 $px1 $px4 rgba(255, 255, 255, 0);
}
.bltd--plist-notedit {
  background: #fff;
  padding: $px25 $px30;
  border: $px1 solid #9b9b9b;
  border-radius: $px4;
  box-shadow: 0 $px1 $px4 rgba(0, 0, 0, 0.08);
}

@include media('<=tablet') {
  .bltd--plist-white {
    padding: 1.25em 0 2.5em;
  }
  .bltd--ui.bltd--plist {
    padding: 0;

    > .bltd--plist-item.bltd--with-icon {
      margin-right: 1.25em;
    }
  }
  .bltd--ui.bltd--plist > .bltd--plist-item .bltd--content {
    margin-right:0;
    overflow: hidden;
    margin-bottom: 1em;
  }
  .bltd--ui.bltd--plist > .bltd--plist-item .bltd--action {
    width: 9.375em;
    position: relative;
    text-align: left;
    /* position: absolute; */
    /* right: 0; */
    /* top: 0; */
    /* bottom: 0; */
    /* text-align: right; */
  }
}
