header.bltd--header--inner.bltd--header--inner-small {
  .login-popup {
    top: 100%;
  }
}

header.bltd--header--inner {
  background: var(--base-green-color);
  --icon-account: #fff;
  margin: 0;
  & .bltd--logo-link {
    a {
      color: #fff;
    }
  }
  .bltd--main-nav {
    .bltd--link {
      color: $base-white-color;
      &:hover {
        color: $base-white-color;
      }
    }
    .bltd--ui.bltd--secondary.bltd--menu .bltd--link.bltd--item:hover, .bltd--ui.bltd--secondary.bltd--menu a.bltd--item:hover {
      color: $base-white-color;
    }
  }
  .bltd--account {
    .bltd--xs {
      color: $base-white-color;
      i {
        background-color: $base-white-color;
      }
    }

    a.bltd--acc-name {
      color: #fff;
    }

    .bltd--ui.bltd--menu .bltd--item.bltd--dropdown {
      position: relative;
    }
  }
  .bltd--ui.bltd--dropdown {
    border-color: #ffffff;
    box-shadow: 0 0 0 1px #fff inset;
    & > .bltd--dropdown.bltd--icon:before {
     /* background-color: $base-white-color;*/
      background-position: -6.9375em -7.75em;
    }
    &.no-border {
      border: none;
      box-shadow: none;
    }

    &.bltd--item .bltd--menu {
      margin-top: 0;
    }
  }

  .login-popup {
    top: 57%;
  }

 /* + .bltd--main_content.routes {
    padding-top: 162px;
  }*/

  @include media("<=768px") {
    /*+ .bltd--main_content.routes {
      padding-top: 109px;
    }*/

    position: relative;

    .bltd--booker {
      //margin: 0 0.5em 0 0.75em;
      margin: 0;
      width: auto;
    }
  }

  &.page {
    .login-popup {
      top: 100%;
    }
  }
}
