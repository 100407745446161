/*.bltd--expand-link {
  color: $base-link-color;

  cursor: pointer;
  position: relative;
  overflow: hidden;

  height: 1.6em;
  display: block;
  text-overflow: ellipsis;
  white-space: normal;

  &:hover {
    color: $base-link-color-hover;
    &:after {
      background-color: $base-link-color-hover;
    }
  }
}

.expand .bltd--expand-link:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}*/

.bltd--routing {

/*  .table-header {
    padding-top: 1.3125em;

  }*/

  //.bltd--wrap {
  //  padding-top: 1.25em;
  //}

  .hnp .table-header {
    padding-top: 0;

  }

  .hnp .bltd--routing-header {
    padding: 1.25em 1.5%;
    border-bottom: $px2 solid #F8F7F0 ;
  }
  .simplified .bltd--routing-header {
    padding: .5em 1.5% !important;
    border-bottom: $px2 solid #F8F7F0 ;
  }
  .bltd--routing-header {
    padding: 0 1.5%;
    margin-bottom: -3px;

    .table-header {
      .sort-btn {
        position: relative !important;
        overflow: visible !important;
        cursor: pointer;

        > span.bltd--ui > span.bltd--title {
          border-bottom: 1px dashed #4A4A4A;
          &:hover {
            border-bottom: none;
          }
        }

        .bltd--hint:hover .bltd--hint-content {
          display: block !important;
        }
      }
    }
  }

  .bltd--hint-content {
    display: none;
    position: absolute;
    font-weight: normal;
    background: #fff;
    top: 100%;
    left: 50%;
    z-index: 200;
    transform: translateX(-50%);
    padding: 15px 20px;
    box-shadow: 0 3px 20px 0px #e4e4e4;
    border: 1px solid #e4e4e4;
    margin-top: 5px;

    &:before, &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &:before {
      border-width: 0 7.5px 6px 7.5px;
      border-color: transparent transparent #e4e4e4 transparent;
      top: -6px;
    }
    &:after {
      border-width: 0 7px 5px 7px;
      border-color: transparent transparent #ffffff transparent;
      top: -5px;
    }

  }

  .bltd--routing__personal {
    .bltd--route--num, .bltd--ticket--number {
      width: 125px;
    }

    .bltd--ticket--name {
      width: 50%;
    }

    .bltd--route--passangers {
      width: 12%;
      text-align: right;
    }

    .bltd--route--cost {
      width: 11%;
    }

    .bltd--route--name {
      width: 30%;
    }

    .bltd--route-row {
      .table-content__item:not(.bltd--route--link) {
        cursor: pointer;
      }
    }

    .bltd--expand-link > span:first-child {
      display: inline-block;
    }

    .bltd--expand-link > span.with-icon_expand {
      left: auto;
      top: 0.15em;
    }

    .bltd--route-extend.bltd--ticket-extend {


      .bltd--loader {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -0.7em;
        margin-top: -0.7em;
      }
    }

    .bltd--route-extend {
      .bltd--route-extend__inner {
        margin: 0;

        .fz14 {
          font-size: .875em;
        }
        .table-content__item {
          vertical-align: top;
         &.nowrap{
           overflow: hidden;
           text-overflow: ellipsis;
           white-space: nowrap;
         }
        }

        .item-row.table-content__row {
          margin: 0.5em 0;
        }
      }
    }
  }

  .bltd--routing__wait__return {
    position: relative;
    width: 100%;
    min-height: 8em;
  }

  .bltd--route--return {
    .bltd--route--num {
      width: 140px;
    }
    .bltd--route--bag {
      width: 120px;
    }
  }

  .bltd--route--num {
    width: 70px;

  }
  .bltd--route--name {
    width: 34%;
    position: relative;

    .bltd--expand-link {
      padding-right: 0!important;
    }
  }
  .bltd--route--dates {
    width: 30%;
    .da-wrapper {
      display: table;
      table-layout: fixed;
      width: 100%;
      position: relative;
      overflow-y: visible;

      .bltd--route-item__depart-time {
        width: 50%;
        display: table-cell;
        overflow: hidden;
        text-overflow: ellipsis;

      }

      .sort {
        //position: relative;
        //margin-left: .25em;
        &:before {
          content: '';
          color: rgba(102, 102, 102, 0.75);
          border-left: .35em solid transparent;
          border-right: .35em solid transparent;
          border-top: .35em solid;
          margin: -0.0625em 0 0 1.75em;
          position: absolute;
          top: 50%;
          height: 0;
          width: 0;

        }
        &.bltd--desc {
          &:before {
            border-bottom: .35em solid  rgba(102, 102, 102, 0.75);
            border-top: 0;
          }
        }
      }

      .bltd--route-item__arrive-time {
        width: 47%;
        padding-left: 3%;
        display: table-cell;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .bltd--route-item__depart-time, .bltd--route-item__arrive-time {
        i {
          padding: 0;
          display: inline-block;
          vertical-align: middle;
          width: 1em;
          height: 1em;

          background: url(../img/icons/icon_clock_small.png) no-repeat 0% 0%;

        }

      }
    }
  }
  .bltd--route--spent-time {
    width: 12%;

    &.sort-btn {
      .sort {

        &:before {
          content: '';
          color: rgba(102, 102, 102, 0.75);
          border-left: .35em solid transparent;
          border-right: .35em solid transparent;
          border-top: .35em solid;
          margin: 0 0 0 0.5em;
          position: absolute;
          top: 30%;
          height: 0;
          width: 0;

        }
        &.bltd--desc {
          &:before {
            border-bottom: .35em solid  rgba(102, 102, 102, 0.75);
            border-top: 0;
          }
        }
      }
    }
  }
  .bltd--route--days {
    width: 12%;
  }
  .bltd--route--link {
    width: 28rem; // fix width
    &.bltd--w180 {
      width: 10em;
      text-align: right;
    }
    &.bltd--w1802 {
      width: 4.25em;
      text-align: right;
    }
    .bltd--ticket-wrapper {
      position: relative;
      min-height: 3.06em;

      .bltd--loader {
        position: absolute;
        top: 50%;
        left: 23%;
        margin: -0.6rem 0 0 0;
      }
    }
    .bltd--ticket-inner {
      margin-right: 18rem;
      text-align: right;
      //overflow: hidden;
      //position: relative;

      p.status-err {
        margin-top: 0;
        white-space: normal;
        line-height: 1.9em;
        text-align: left;
        margin-left: 13px;
        z-index: 100;
        opacity: 0.65;
        position: relative;
      }

      .transit {
        /*overflow: visible;
        text-align: right!important;
        margin-top: 0;
        white-space: normal;
        line-height: 1.45em;
        text-align: left;
        margin-left: 13px;*/

        > span.bltd--title {
          border-bottom: 1px dashed #4a4a4a;
          cursor: pointer;
          &:hover {
            border-bottom: none;

            + .bltd--hint-content  {
              display: block !important;
              width: 260px;
              margin-left: -200px;
              text-align: left;
              -webkit-transform: none;
              transform: none;
            }
          }
        }
      }
    }

    .bltd--ticket-inner.trip-state {
      width: 100%;
      text-align: center!important;
      margin: 0 !important;

      .trip-state-msg {
        color: #DD4B39;
      }
    }

    .bltd--ticket-submit {
      width: 16.5rem;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      text-align: right;

      > .status-error {
        margin-right: 7px;
      }
    }
  }

}

//decor
.bltd--route-over {
  box-shadow: none;
  transition: box-shadow 0.5s;
  -moz-transition: box-shadow 0.5s;
  -webkit-transition: box-shadow .5s;
  .bltd--route.expand & {
    position: absolute;
    top: $px1;
    bottom: $px1;
    left: -$px2;
    right: -$px2;
    background-color: #fff;
    border-radius: 4px;
    border: $px2 solid #82b548;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    z-index: 3;
  }
}

.bltd--route {
  position: relative;
  &:before {
    content: '';
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.65s ease-out;
  }

  &.inactive {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#ececec, 0.45);
      z-index: 100;
      visibility: visible;
      opacity: 1;
    }
  }

  &.expand .bltd--route-content {
    z-index: 5;
  }

  .bltd--route-row {
    border-bottom: $px1 solid #efeff0;
  }

  &:last-child {
    .bltd--route-row {
      border-bottom: none;
    }
  }
  .bltd--route-content {
    position: relative;
    margin: 0 1.5%;
    //overflow: hidden;
    .bltd--route--num {
      line-height: $px19;
    }
  }
  .bltd--route .smaller.bltd--expanded {
    color: $base-link-color;
  }
  .num {
    font-size: $px14;

  }
  .ellipsis {
    margin-bottom: 0;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .bolder {

  }
  .bolder {

    margin-bottom: 0;
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
    &.bltd--route-item__name, &.bltd--route-item__pname {
      font-weight: $font-800;
      position: relative;
    }

  }

  .smaller {
    color: $base-button2-link-color;
    font-size: 0.8125em;
    margin: 0 0 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }
  .bltd--price-val {
    font-weight: $font-800;
  }
  .doc {
    color: #9b9b9b;
  }

}
.bltd--route.expand:last-child {
  .bltd--route-row {
    border-bottom: $px1 solid #efeff0;
  }
}

.with-checkbox .bltd--route:last-child {
  .bltd--route-row {
    border-bottom: $px1 solid #efeff0;
  }
}