
.nav-dropdown {
  background: #0E2E43;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 800px;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 8rem;
  display: none;
  width: 100%;
  z-index: 1400;
  li{
    margin-bottom: .5em;
  }
}
.nav-dropdown.open {
  display: block;
}
.nav-expanded {
  padding: 2rem;
}

.nav-expanded__list, .nav-expanded__list--sub {
  list-style: none;
  margin: 0;
  padding: 0;


  font-style: normal;

}
.nav-expanded__list {
  margin-bottom: 2em;
}

.nav-expanded__list > li, .nav-expanded__list--sub > li {
  margin-bottom: .5em;
}

.nav-expanded__list>li>a, .nav-expanded__list--sub>li>a {
  color: #FFF;
  text-decoration: none;
}
.nav-expanded__list--support p {
  color: #fff;
  font-size: .85em;
}
.nav-expanded__list--sub {

  .bltd--item {
    color: #fff;
  }
  font-style: normal;

  text-transform: none;
}