[ui-booker-popup].bltd--booker-suggest {
	display: block;
}

.bltd--booker-header {
	padding-top: 0;
	margin-bottom: 3.75em;
	margin-left: 1.875em;
	margin-right: 1.875em;
	h1 {
		font-size: 3em;
		line-height: 1.25em;
		font-style: normal;
		text-align: center;
		font-weight: bold;
		text-shadow: 0.0625rem 0.0625rem 0.0625rem rgba(0, 0, 0, 0.25);
		margin-top: 0;
		margin-bottom: 0;
		color: #fff;
	}
	@include media('<=tablet') {
		padding-top: 1.25em;
		margin-bottom: 2.5em;
		h1 {
			font-size: 1.75em;
		}
	}
}

.bltd--booker--page {
	min-height: 420px;
	.bltd--bg {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		overflow: hidden;
		z-index: 0;
		background: linear-gradient(270deg, var(--base-bg-dark), var(--base-bg));
		background-repeat: no-repeat;
		background-size: cover;

		img {
			width: auto;
			height: auto;
			transform: scale(1) translateZ(0px);
		}
	}
	.bltd--wr {
		width: 100%;
		max-width: 1040px;
		padding: 0 2em;
		position: relative;
	}
}

.mobile {
	.bltd--booker {
		.bltd--booker-typeahead-placeholder {
			&.transform {
				display: none;
			}
		}
	}
}

.bltd--booker {
	margin-top: 1.25em;
	width: 100%;

	.bltd--booker-typeahead-placeholder {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		pointer-events: none;
		-webkit-transform: translateY(0px);
		transform: translateY(0);
		-webkit-transform-origin: bottom left;
		transform-origin: bottom left;
		transition-property: transform, color, -webkit-transform;
		transition-duration: 0.4s;
		transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
		height: 3.5em;
		line-height: 3.5em;
		max-width: 85%;
		padding: 0 .5em;
		color: rgb(153, 153, 153);
		&.transform {
			-webkit-transform: scale(0.75) translateY(-2.125em);
			-moz-transform: scale(0.75) translateY(-2.125em);
			-ms-transform: scale(0.75) translateY(-2.125em);
			-o-transform: scale(0.75) translateY(-2.125em);
			color: #fff;
			padding-left: .75em;
			line-height: 1em;
			height: 1em;
		}
	}

	.bltd--booker-typeahead-placeholder.blink {
		transition: color 0.35s linear;
		color: #dd4b39;
	}

	.bltd--booker-item--date {
		.bltd--booker-typeahead-placeholder {
			&.transform {
				-webkit-transform: scale(0.75) translateY(-2.125em) opacity(1);
				-moz-transform: scale(0.75) translateY(-2.125em) opacity(1);
				-ms-transform: scale(0.75) translateY(-2.125em) opacity(1);
				-o-transform: scale(0.75) translateY(-2.125em) opacity(1);
				opacity: 1;
			}
		}
	}

	.no-active {
		.bltd--booker-typeahead {
			//opacity: .5;
		}
	}

	.blink input, input.blink {
		color: #dd4b39;
		&::-webkit-input-placeholder {
			transition: color 0.35s linear;
			color: #dd4b39;
		}
		&::-moz-placeholder {
			transition: color 0.35s linear;
			color: #dd4b39;
		}
		&:-ms-input-placeholder {
			transition: color 0.35s linear;
			color: #dd4b39;
		}
		&:-moz-placeholder {
			transition: color 0.35s linear;
			color: #dd4b39;
		}
	}

	.bltd--booker-container {
		padding: 0;
		position: relative;
		margin: 0 0 2em;
	}

	.bltd--booker-item-inner-rel {
		position: relative;
	}

	.bltd--booker-wrapper {
		background: #fff;
		border: 0.125em solid var(--base-border-green);
		border-radius: 0.25em 0 0 0.25em;
		box-shadow: inset 0 0.125em 0.0625em rgba(0, 0, 0, 0.03);
		@include rem('margin-right', 16.5);
		position: relative;
		border-right: 0;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.bltd--eyebrow-note {
		color: #fff;
		top: -2em;
		position: absolute;
		margin: 0 .75em;
		font-size: .75em;
	}

	.bltd--booker-item-swap, .bltd--booker-item-container {
		-webkit-flex: 1 1 auto;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		width: 33.33%;
		position: relative;
	}

	.bltd--booker-item-swap {
		cursor: pointer;
		position: relative;
		width: 3.75em;
		height: 2.25em;
		&:before {
			content: '';
			background: #efeff0;
			position: absolute;
			width: .0625em;
			height: 100%;
			top: 0;
			left: 50%;
			margin-left: -.0625em;
		}
	}

	.bltd--booker-item-container {
		position: relative;
		margin-bottom: 0;

		&.item--date {
			border-left: .0625em solid #efeff0;
		}
	}

	.bltd--booker-item {
		height: 100%;
		position: relative;
		text-decoration: none;
		width: 100%;

		.bltd--booker-typeahead-clear {
			background: url('../img/icons/icon_clear_gray.png') no-repeat 50% 50%, transparent;
			cursor: pointer;
			display: block;
			width: 16px;
			height: 16px;
			position: absolute;
			right: .685em;
			top: 50%;
			margin-top: -.5em;
			opacity: 0.3;
			z-index: 5;
			&:hover {
				opacity: 1;
			}
		}

		&.bltd--booker-item--date {

			.bltd--booker-typeahead {
				max-width: 100% !important;
				padding: 0 !important;
				margin-right: 0;
				padding-right: 2em;
			}
			button {
				outline: none;
				background: none;
				border: 0;
				width: 100%;
				padding: 0 .5em;
				padding-right: 2em;
				text-align: left;
				span {
					display: block;
					overflow: hidden;
					text-overflow: ellipsis;
					padding: 0 .5em;
					padding-right: 2.5em;
				}

			}
			.bltd--icon {
				background-color: #fff;
				cursor: pointer;
				width: 1.25em;
				height: 1.25em;
				-webkit-border-radius: 100%;
				-moz-border-radius: 100%;
				border-radius: 100%;
				top: 50%;
				margin-top: -0.625em;
				right: 0.625em;
				display: block;
				position: absolute;
			}

		}
		.bltd--booker-item-inner {
			position: relative;
		}
		.ng-invalid-found {
			.bltd--booker-typeahead {
				color: #dd4b39
			}
			.bltd--booker-typeahead[disabled] {
				color: rgba(221, 75, 57, 0.45) !important;
			}
		}
		.bltd--booker-typeahead {
			outline: none;

			white-space: nowrap;
			/* overflow: hidden;*/
			text-overflow: ellipsis;
			background-color: transparent;
			border: 0;

			text-align: left;
			width: 100%;
			height: 2.25em;
			line-height: 2.25em;
			display: block;
			padding: 0 0.55em;
			max-width: 85%;
			button {
				outline: none;
				background: none;
				border: 0;
			}
			&.ng-invalid-not-found-input {
				color: #dd4b39
			}

			&[disabled] {
				color: #ddd;
				&.ng-invalid-not-found-input {
					color: rgba(221, 75, 57, 0.45) !important;
				}
			}
		}

		input.bltd--booker-typeahead[disabled] {
			&::-webkit-input-placeholder, &::-moz-placeholder, &:-moz-placeholder, &:-ms-input-placeholder {
				color: #ddd;
				transition: color 0.35s linear;
			}
		}
	}

	.bltd--booker-submit {
		@include rem('width', 16.5);
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;

		input {
			cursor: pointer;
			background: var(--base-input-bg-green);
			color: #fff;
			width: 100%;
			outline: none;
			height: 100%;
			border: none;
			font-weight: bold;
			border-radius: 0 0.125em 0.125em 0;
			&:active, &:focus {
				background: var(--base-input-bg-green-active);
			}
		}
	}
}

.bltd--booker.bltd--booker--page {
	margin-top: 0;
	padding-bottom: 2.5em;
	position: relative;
	@include media('<=tablet') {
		height: 100% !important;
	}

	.bltd--booker-container {
		padding: 0;
		position: relative;
		margin-bottom: 5.625em;

	}
	.bltd--booker-wrapper {
		border: 0.125em solid #c9c9c5;
		border-right: 0;
	}

	.bltd--eyebrow-note {
		display: none;
	}

	.bltd--booker-item-swap {
		height: 3.5em;
		z-index: 10;
	}

	.bltd--booker-item {
		.bltd--booker-typeahead {
			height: 3.5em;
			line-height: 3.5em;
			position: relative;
			max-width: 85%;
			padding: 0 .5em;
		}

		.bltd--booker-item-inner-rel {
			position: relative;
		}

		.bltd--booker-typeahead-clear {
			background: url('../img/icons/icon_clear_gray.png') no-repeat 50% 50%, transparent;
			cursor: pointer;
			display: block;
			width: 16px;
			height: 16px;
			position: absolute;
			right: .685em;
			top: 50%;
			margin-top: -.5em;
			opacity: 0.3;
			&:hover {
				opacity: 1;
			}
		}
	}

	.bltd--booker-submit {
		input {
			background: var(--booker-submit-light);
			&[disabled] {
				opacity: .85;
			}
			&:hover {
				background: var(--booker-submit-hover);
			}
			&:active {
				background: var(--booker-submit-hover);
			}
			&:focus {
				background: var(--booker-submit-hover);
			}
		}
	}
}

.bltd--booker.bltd--booker--innerpage {
	padding-bottom: 2.5em;
	position: relative;
	max-width: 860px;
	margin: 0 auto;

	.bltd--booker-container {
		padding: 0;
		position: relative;
		margin-bottom: 0em;

	}
	.bltd--booker-wrapper {

		border: 0.125em solid #c9c9c5;
		border-right: 0;
	}
	.bltd--eyebrow-note {
		display: none;
	}
	.bltd--booker-item-swap {
		height: 3.5em;
		z-index: 10;
	}
	.bltd--booker-item {
		.bltd--booker-typeahead {
			height: 3.5em;
			line-height: 3.5em;
			position: relative;
			max-width: 85%;
			padding: 0 .5em;
		}
		.bltd--booker-item-inner-rel {
			position: relative;
		}
		.bltd--booker-typeahead-clear {
			background: url('../img/icons/icon_clear_gray.png') no-repeat 50% 50%, transparent;
			cursor: pointer;
			display: block;
			width: 16px;
			height: 16px;
			position: absolute;
			right: .685em;
			top: 50%;
			margin-top: -.5em;
			opacity: 0.3;
			&:hover {
				opacity: 1;
			}
		}
	}
	.bltd--booker-container {

	}
	.bltd--booker-submit {
		input {
			background: var(--booker-submit-light);
			&[disabled] {
				opacity: .85;
			}
			&:hover {
				background: var(--booker-submit-hover);
			}
			&:active {
				background: var(--booker-submit-hover);
			}
			&:focus {
				background: var(--booker-submit-hover);
			}
		}
	}
}

.bltd--booker-all {
	display: none;
	text-align: center;
	padding: 0.625em 0;
}

.bltd--booker-item--arrow {
	background-color: #fff;
	cursor: pointer;
	width: 1.25em;
	height: 1.25em;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
	top: 50%;
	margin-top: -0.625em;
	right: -0.625em;
	display: block;
	position: absolute;
}

.bltd--booker-suggest {
	border: 1px solid #E4E4E4;
	box-shadow: 0 1em 1em 0 rgba(0, 0, 0, 0.10);
	background-color: #FFF;
	border-top: none;
	margin-top: 2px;
	left: 0;
	position: absolute;
	width: 100%;
	z-index: 1001;
	height: auto;
	max-height: 26.85rem;
	overflow: hidden;
	overflow-y: auto;
	scrollbar-color: var(--base-green-color) #fff; //for ff
	scrollbar-width: thin; //for ff

	&:after {
		clear: both;
		content: '';
		display: table;
	}

	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		background-color: rgba(255, 255, 255, 0);
		-webkit-border-radius: 100px;
	}
	&::-webkit-scrollbar-thumb {
		background: var(--base-green-color);
		-webkit-border-radius: 100px;
	}
	.bltd--booker-suggest-list {
		list-style: none;
		padding-left: 0;
		margin: 0;
	}

	.bltd--booker-suggest-item {
		&.active {
			background: #f4f4f4;
		}

		a {
			&.bltd--icon {
				.bltd--name {
					font-weight: 600;
				}
			}
			&:hover {
				background: #f4f4f4
			}

			display: block;
			font-size: .8125em;
			padding: .75em 1em;
			color: #9b9b9b;
			text-decoration: none;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;

			.bltd--state {
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.bltd--name {
				color: #4a4a4a;
				white-space: nowrap;
				.bltd--match {
					font-weight: bold;
				}
			}
		}
	}
}

.bltd--booker-suggest {

	&.ng-enter {
		transition: .2s linear all;
		opacity: 0;
	}

	&.ng-enter.ng-enter-active {
		opacity: 1;
	}

	&.ng-leave {
		transition: .2s linear all;
		opacity: 1;
	}
	&.ng-leave.ng-leave-active {
		opacity: 0;
	}
}

.prev-search {
	font-size: .8125em;
	padding: .75em 1em;
	color: #9b9b9b;
}
.prev-search p {
	margin-bottom: 0px !important;
}
.child-prev-search {
	padding: 0em !important;
}


.bltd--booker-suggest-popup {

	padding: 1em;
	p {
		margin-bottom: 1em;
	}
	.bltd--suggest {
		position: relative;
		.bltd--suggest-wrapper {
			margin-right: 6.5rem;
			border: $px2 solid #e5e4de;
			border-right: 0;
			border-radius: $px3;
			input {
				width: 100%;
				border: 0;
				outline: 0;
				&.ng-invalid-pattern {
					color: #dd4b39;
					&[disabled] {
						color: rgba(221, 75, 57, 0.45) !important;
					}
				}
				padding: 0.625em 0.9375em;
			}
		}
		.bltd--suggest-submit {
			width: 6.5rem;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			input {
				color: #fff;
				width: 100%;
				outline: none;
				height: 100%;
				border: none;
				font-weight: bold;
				border-radius: 0 0.125em 0.125em 0;
				background: var(--base-green-color);
				cursor: pointer;

				&[disabled] {
					cursor: default;
					opacity: 0.45 !important;
					/*box-shadow: none !important;*/
					pointer-events: none;
				}
			}
		}
	}
}

.bltd--booker__params {
	display: none;
}

@include media('<=tablet') {
	.bltd--booker.bltd--booker--page {

		.bltd--booker-container {
			@include rem('margin-bottom', 7.5);
			padding: 0 1.25em;
		}

		.bltd--wr {
			padding: 0;
		}

		.bltd--booker-wrapper {
			border-radius: 0.25em;
			border: 0.125em solid #c9c9c5;
			margin-right: 0;
			margin-left: 0;
			margin-bottom: 1.25em;
		}
		.bltd--booker-item-container {
			border-bottom: 0.0625em solid #efeff0;
		}
		.bltd--booker-item-swap {
			top: 2.6em;
			height: 1.75em;
			z-index: 10;
		}
		.bltd--booker-submit {
			height: 3.75em;
			position: relative;
			top: auto;
			width: 100%;
			margin: 0;
			input {
				border-radius: 0.125em;
			}
		}
	}
}

@include media('<=tablet') {
	//увеличиваем отсутп сверху в хеадре

	.h2x .bltd--main_content {
		//padding-top: 6.85em
		padding-top: 0;
	}

	.bltd--booker--expanded {
		.h2x .bltd--main_content {
			padding-top: 15.5em;
		}
	}

	.bltd--booker {

		.bltd--booker-compact {
			display: block;
		}
		.bltd--booker-container {
			margin: 0 auto;
		}
		.bltd--booker--line {
			padding-bottom: .625em;
			outline: 0;
		}
		.bltd--booker__params {
			outline: 0;
			position: relative;
			display: block;
			background: var(--base-input-bg-green);
			border-radius: 4px;
			height: 40px;
			line-height: 40px;
			color: #fff;
			font-size: 14px;
			font-weight: bold;
			text-align: left;
			padding-left: 40px;

			> span {
				white-space: nowrap;
				display: inline-block;
				vertical-align: top;
				overflow: hidden;
				max-width: 81px;
				text-overflow: ellipsis;
			}

			.bltd--booker--text {
				white-space: nowrap;
				display: block;
				vertical-align: top;
				overflow: hidden;
				text-overflow: ellipsis;
				padding-right: 2em;
			}
			&--when {
				margin-left: 3px;
			}

			> .bltd--dropdown.bltd--icon:before {
				left: initial;
				right: 0.65em;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		.bltd--booker-wrapper {
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			border-radius: 0.125em;
			margin: 0 0em 0.65em;
			border: .125em solid var(--base-border-green);
		}
		.bltd--eyebrow-note {
			display: none;
		}
		.bltd--booker-item .bltd--booker-typeahead-clear {
			right: .685em;
		}

		.bltd--booker-item-swap {
			position: absolute;
			right: .3250em;
			top: 1.685em;
			z-index: 10;
			width: 1.75em;
			height: 1.25em;
			&:before {
				content: none;
			}
			.bltd--icon--swap {
				-moz-transform: rotate(90deg);
				-ms-transform: rotate(90deg);
				-webkit-transform: rotate(90deg);
				-o-transform: rotate(90deg);
				transform: rotate(90deg);
			}
		}
		.bltd--booker-item-container {
			width: 100%;
			border-bottom: .0625em solid #efeff0;

		}

		.bltd--booker-submit {
			height: 2.5em;
			position: relative;
			margin: 0 0em 0.75em;
			width: auto;

			input {
				border-radius: 0.125em;
			}
		}
		.bltd--booker--inputs {
			display: none;
		}
		&.bltd--booker--expanded {
			.bltd--booker__params {
				display: none;
			}
			.bltd--booker--inputs {
				display: block;
			}
		}

	}
	.bltd--booker-all {
		display: block;
	}
	.bltd--booker-suggest-popup {
		padding: 1em;
		p {
			margin-bottom: 1em;
		}
		.bltd--suggest {
			position: relative;
			overflow: hidden;
			.bltd--suggest-wrapper {
				margin-right: 0;
				margin-bottom: $px50;
				border: 0.125em solid #e5e4de;
				input {

					padding: $px8 0.9375em;
				}
			}
			.bltd--suggest-submit {
				height: $px40;
				top: auto;
				width: 100%;
				input {

					border-radius: 0.125em;
				}
			}
		}
	}
}

@include media("<=460px") {
	.bltd--booker .bltd--booker-container {
		max-width: 100%;
	}

	.bltd--booker-suggest {
		width: 107%;
		margin-left: -3.6%;
	}
}

@include media("<=380px") {
	.bltd--booker-suggest {
		width: 108%;
		margin-left: -4.3%;
	}
}

.bltd--booker-compact {
	.ellipsis {
		text-overflow: ellipsis;
		display: block;
		overflow: hidden;
		white-space: nowrap;
	}
	&:after {
		width: 1em;
		height: 1em;
		display: block;
		content: '';
		background-image: url("../img/icons/s.svg");
		background-size: 1em 1em;
		position: absolute;
		background-position: 0 0;
		top: 50%;
		left: 0.6875em;
		margin-top: -.5em;
	}
	display: none;
	cursor: pointer;
	word-wrap: break-word;
	line-height: 1.25em;
	white-space: normal;
	outline: 0;
	background: var(--base-input-bg-green);
	color: #fff;
	border-radius: 0.25em;
	width: 100%;
	min-width: 0;
	padding: .6875em 2.3125em .5625em 2.3125em;
	position: relative;
	margin-bottom: 0.625em;

	.bltd--text {
		.route-compact-text {
			position: relative;
			margin-right: 5em;
			display: inline-block;
			max-width: 70%;
		}
		.route-compact-date {
			position: absolute;
			right: -5em;
			top: 0;
			white-space: nowrap;
			width: 4em;
			text-align: left;
		}
		display: inline-block;
		position: relative;
		width: 100%;
	}
	.bltd--dropdown.bltd--icon {
		line-height: 1;
		height: 1em;
		padding: 0;
		position: absolute;
		right: 0.9375em;
		top: 50%;
		width: 1em;
		margin-top: -.5em;
		&:before {
			width: 1em;
			height: 1em;
			display: block;
			content: '';
			background-image: url("../img/icons/icons.svg");
			background-size: 10.5625em 17.3125em;
			position: absolute;
			background-position: -6.9375em -7.75em;
			top: 0em;
			left: 0em;
		}
	}

}
