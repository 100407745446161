.bltd--ui.bltd--label {
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  margin: 0em 0.14285714em;
  background-color: #E8E8E8;
  background-image: none;
  padding: 0.5833em 0.833em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: bold;
  border: 0px solid transparent;
  border-radius: 0.28571429rem;
  -webkit-transition: background 0.1s ease;
  transition: background 0.1s ease;
}
.bltd--ui.bltd--label:first-child {
  margin-left: 0em;
}
.bltd--ui.bltd--label:last-child {
  margin-right: 0em;
}

/* Link */
a.bltd--ui.bltd--label {
  cursor: pointer;
}

/* Inside Link */
.bltd--ui.bltd--label > a {
  cursor: pointer;
  color: inherit;
  opacity: 0.5;
  -webkit-transition: 0.1s opacity ease;
  transition: 0.1s opacity ease;
}
.bltd--ui.bltd--label > a:hover {
  opacity: 1;
}

/* Image */
.bltd--ui.bltd--label > img {
  width: auto !important;
  vertical-align: middle;
  height: 2.1666em !important;
}

/* Icon */
.bltd--ui.bltd--label > .bltd--icon {
  width: auto;
  margin: 0em 0.75em 0em 0em;
}

/* Detail */
.bltd--ui.bltd--label > .detail {
  display: inline-block;
  vertical-align: top;
  font-weight: bold;
  margin-left: 1em;
  opacity: 0.8;
}
.bltd--ui.bltd--label > .detail .bltd--icon {
  margin: 0em 0.25em 0em 0em;
}

/* Removable label */
.bltd--ui.bltd--label > .bltd--close.bltd--icon,
.bltd--ui.bltd--label > .bltd--delete.bltd--icon {
  cursor: pointer;
  margin-right: 0em;
  margin-left: 0.5em;
  font-size: 0.92857143em;
  opacity: 0.5;
  -webkit-transition: background 0.1s ease;
  transition: background 0.1s ease;
}
.bltd--ui.bltd--label > .bltd--delete.bltd--icon:hover {
  opacity: 1;
}

/*-------------------
       Group
--------------------*/

.bltd--ui.bltd--labels > .bltd--label {
  margin: 0em 0.5em 0.5em 0em;
}

/*-------------------
       Coupling
--------------------*/

.bltd--ui.header > .bltd--ui.bltd--label {
  margin-top: -0.29165em;
}

/* Remove border radius on attached segment */
.bltd--ui.attached.segment > .bltd--ui.top.left.attached.bltd--label,
.bltd--ui.bottom.attached.segment > .bltd--ui.top.left.attached.bltd--label {
  border-top-left-radius: 0;
}
.bltd--ui.attached.segment > .bltd--ui.top.right.attached.bltd--label,
.bltd--ui.bottom.attached.segment > .bltd--ui.top.right.attached.bltd--label {
  border-top-right-radius: 0;
}
.bltd--ui.top.attached.segment > .bltd--ui.bottom.left.attached.bltd--label {
  border-bottom-left-radius: 0;
}
.bltd--ui.top.attached.segment > .bltd--ui.bottom.right.attached.bltd--label {
  border-bottom-right-radius: 0;
}

/* Padding on next content after a label */
.bltd--ui.top.attached.bltd--label:first-child + :not(.attached) {
  margin-top: 2rem !important;
}
.bltd--ui.bottom.attached.bltd--label:first-child ~ :last-child:not(.attached) {
  margin-top: 0em;
  margin-bottom: 2rem !important;
}


/*******************************
             Types
*******************************/

.bltd--ui.image.bltd--label {
  width: auto !important;
  margin-top: 0em;
  margin-bottom: 0em;
  max-width: 9999px;
  vertical-align: baseline;
  text-transform: none;
  background: #E8E8E8;
  padding: 0.5833em 0.833em 0.5833em 0.5em;
  border-radius: 0.28571429rem;
  box-shadow: none;
}
.bltd--ui.image.bltd--label img {
  display: inline-block;
  vertical-align: top;
  height: 2.1666em;
  margin: -0.5833em 0.5em -0.5833em -0.5em;
  border-radius: 0.28571429rem 0em 0em 0.28571429rem;
}
.bltd--ui.image.bltd--label .detail {
  background: rgba(0, 0, 0, 0.1);
  margin: -0.5833em -0.833em -0.5833em 0.5em;
  padding: 0.5833em 0.833em;
  border-radius: 0em 0.28571429rem 0.28571429rem 0em;
}

/*-------------------
         Tag
--------------------*/

.bltd--ui.bltd--tag.bltd--labels .bltd--label,
.bltd--ui.bltd--tag.bltd--label {
  margin-left: 1em;
  position: relative;
  padding-left: 1.5em;
  padding-right: 1.5em;
  border-radius: 0em 0.28571429rem 0.28571429rem 0em;
  -webkit-transition: none;
  transition: none;
}
.bltd--ui.bltd--tag.bltd--labels .bltd--label:before,
.bltd--ui.bltd--tag.bltd--label:before {
  position: absolute;
  -webkit-transform: translateY(-50%) translateX(50%) rotate(-45deg);
  -ms-transform: translateY(-50%) translateX(50%) rotate(-45deg);
  transform: translateY(-50%) translateX(50%) rotate(-45deg);
  top: 50%;
  right: 100%;
  content: '';
  background-color: inherit;
  background-image: none;
  width: 1.56em;
  height: 1.56em;
  -webkit-transition: none;
  transition: none;
}
.bltd--ui.bltd--tag.bltd--labels .bltd--label:after,
.bltd--ui.bltd--tag.bltd--label:after {
  position: absolute;
  content: '';
  top: 50%;
  left: -0.25em;
  margin-top: -0.25em;
  background-color: #FFFFFF !important;
  width: 0.5em;
  height: 0.5em;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.3);
  border-radius: 500rem;
}

/*-------------------
    Corner Label
--------------------*/

.bltd--ui.corner.bltd--label {
  position: absolute;
  top: 0em;
  right: 0em;
  margin: 0em;
  padding: 0em;
  text-align: center;
  border-color: #E8E8E8;
  width: 4em;
  height: 4em;
  z-index: 1;
  -webkit-transition: border-color 0.1s ease;
  transition: border-color 0.1s ease;
}

/* Icon Label */
.bltd--ui.corner.bltd--label {
  background-color: transparent !important;
}
.bltd--ui.corner.bltd--label:after {
  position: absolute;
  content: "";
  right: 0em;
  top: 0em;
  z-index: -1;
  width: 0em;
  height: 0em;
  background-color: transparent !important;
  border-top: 0em solid transparent;
  border-right: 4em solid transparent;
  border-bottom: 4em solid transparent;
  border-left: 0em solid transparent;
  border-right-color: inherit;
  -webkit-transition: border-color 0.1s ease;
  transition: border-color 0.1s ease;
}
.bltd--ui.corner.bltd--label .bltd--icon {
  cursor: default;
  position: relative;
  top: 0.64285714em;
  left: 0.78571429em;
  font-size: 1.14285714em;
  margin: 0em;
}

/* Left Corner */
.bltd--ui.left.corner.bltd--label,
.bltd--ui.left.corner.bltd--label:after {
  right: auto;
  left: 0em;
}
.bltd--ui.left.corner.bltd--label:after {
  border-top: 4em solid transparent;
  border-right: 4em solid transparent;
  border-bottom: 0em solid transparent;
  border-left: 0em solid transparent;
  border-top-color: inherit;
}
.bltd--ui.left.corner.bltd--label .bltd--icon {
  left: -0.78571429em;
}

/* Segment */
.bltd--ui.segment > .bltd--ui.corner.bltd--label {
  top: -1px;
  right: -1px;
}
.bltd--ui.segment > .bltd--ui.left.corner.bltd--label {
  right: auto;
  left: -1px;
}

/*-------------------
       Ribbon
--------------------*/

.bltd--ui.ribbon.bltd--label {
  position: relative;
  margin: 0em;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  border-radius: 0em 0.28571429rem 0.28571429rem 0em;
  border-color: rgba(0, 0, 0, 0.15);
}
.bltd--ui.ribbon.bltd--label:after {
  position: absolute;
  content: '';
  top: 100%;
  left: 0%;
  background-color: transparent !important;
  border-style: solid;
  border-width: 0em 1.2em 1.2em 0em;
  border-color: transparent;
  border-right-color: inherit;
  width: 0em;
  height: 0em;
}

/* Positioning */
.bltd--ui.ribbon.bltd--label {
  left: calc( -1rem  -  1.2em );
  margin-right: -1.2em;
  padding-left: calc( 1rem  +  1.2em );
  padding-right: 1.2em;
}
.bltd--ui[class*="right ribbon"].bltd--label {
  left: calc(100% +  1rem  +  1.2em );
  padding-left: 1.2em;
  padding-right: calc( 1rem  +  1.2em );
}

/* Right Ribbon */
.bltd--ui[class*="right ribbon"].bltd--label {
  text-align: left;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  border-radius: 0.28571429rem 0em 0em 0.28571429rem;
}
.bltd--ui[class*="right ribbon"].bltd--label:after {
  left: auto;
  right: 0%;
  border-style: solid;
  border-width: 1.2em 1.2em 0em 0em;
  border-color: transparent;
  border-top-color: inherit;
}

/* Inside Table */
.bltd--ui.image > .ribbon.bltd--label,
.bltd--ui.card .image > .ribbon.bltd--label {
  position: absolute;
  top: 1rem;
}
.bltd--ui.card .image > .bltd--ui.ribbon.bltd--label,
.bltd--ui.image > .bltd--ui.ribbon.bltd--label {
  left: calc( 0.05rem  -  1.2em );
}
.bltd--ui.card .image > .bltd--ui[class*="right ribbon"].bltd--label,
.bltd--ui.image > .bltd--ui[class*="right ribbon"].bltd--label {
  left: calc(100% +  -0.05rem  +  1.2em );
  padding-left: 0.833em;
}

/* Inside Table */
.bltd--ui.table td > .bltd--ui.ribbon.bltd--label {
  left: calc( -0.71428571em  -  1.2em );
}
.bltd--ui.table td > .bltd--ui[class*="right ribbon"].bltd--label {
  left: calc(100% +  0.71428571em  +  1.2em );
  padding-left: 0.833em;
}

/*-------------------
      Attached
--------------------*/

.bltd--ui[class*="top attached"].bltd--label,
.bltd--ui.attached.bltd--label {
  width: 100%;
  position: absolute;
  margin: 0em;
  top: 0em;
  left: 0em;
  padding: 0.75em 1em;
  border-radius: 0.21428571rem 0.21428571rem 0em 0em;
}
.bltd--ui[class*="bottom attached"].bltd--label {
  top: auto;
  bottom: 0em;
  border-radius: 0em 0em 0.21428571rem 0.21428571rem;
}
.bltd--ui[class*="top left attached"].bltd--label {
  width: auto;
  margin-top: 0em !important;
  border-radius: 0.21428571rem 0em 0.28571429rem 0em;
}
.bltd--ui[class*="top right attached"].bltd--label {
  width: auto;
  left: auto;
  right: 0em;
  border-radius: 0em 0.21428571rem 0em 0.28571429rem;
}
.bltd--ui[class*="bottom left attached"].bltd--label {
  width: auto;
  top: auto;
  bottom: 0em;
  border-radius: 0em 0.28571429rem 0em 0.21428571rem;
}
.bltd--ui[class*="bottom right attached"].bltd--label {
  top: auto;
  bottom: 0em;
  left: auto;
  right: 0em;
  width: auto;
  border-radius: 0.28571429rem 0em 0.21428571rem 0em;
}


/*******************************
             States
*******************************/


/*-------------------
      Disabled
--------------------*/

.bltd--ui.bltd--label.disabled {
  opacity: 0.5;
}

/*-------------------
        Hover
--------------------*/

a.bltd--ui.bltd--labels .bltd--label:hover,
a.bltd--ui.bltd--label:hover {
  background-color: #E0E0E0;
  border-color: #E0E0E0;
  background-image: none;
  color: rgba(0, 0, 0, 0.8);
}
.bltd--ui.bltd--labels a.bltd--label:hover:before,
a.bltd--ui.bltd--label:hover:before {
  color: rgba(0, 0, 0, 0.8);
}

/*-------------------
        Active
--------------------*/

.bltd--ui.active.bltd--label {
  background-color: #D0D0D0;
  border-color: #D0D0D0;
  background-image: none;
  color: rgba(0, 0, 0, 0.95);
}
.bltd--ui.active.bltd--label:before {
  background-color: #D0D0D0;
  background-image: none;
  color: rgba(0, 0, 0, 0.95);
}

/*-------------------
     Active Hover
--------------------*/

a.bltd--ui.bltd--labels .active.bltd--label:hover,
a.bltd--ui.active.bltd--label:hover {
  background-color: #C8C8C8;
  border-color: #C8C8C8;
  background-image: none;
  color: rgba(0, 0, 0, 0.95);
}
.bltd--ui.bltd--labels a.active.bltd--label:ActiveHover:before,
a.bltd--ui.active.bltd--label:ActiveHover:before {
  background-color: #C8C8C8;
  background-image: none;
  color: rgba(0, 0, 0, 0.95);
}

/*-------------------
      Visible
--------------------*/

.bltd--ui.bltd--labels.visible .bltd--label,
.bltd--ui.bltd--label.visible {
  display: inline-block !important;
}

/*-------------------
      Hidden
--------------------*/

.bltd--ui.bltd--labels.hidden .bltd--label,
.bltd--ui.bltd--label.hidden {
  display: none !important;
}


/*******************************
           Variations
*******************************/


/*-------------------
       Colors
--------------------*/


/*--- Red ---*/

.bltd--ui.red.bltd--labels .bltd--label,
.bltd--ui.red.bltd--label {
  background-color: #DB2828 !important;
  border-color: #DB2828 !important;
  color: #FFFFFF !important;
}

/* Link */
.bltd--ui.red.bltd--labels .bltd--label:hover,
a.bltd--ui.red.bltd--label:hover {
  background-color: #d01919 !important;
  border-color: #d01919 !important;
  color: #FFFFFF !important;
}

/* Corner */
.bltd--ui.red.corner.bltd--label,
.bltd--ui.red.corner.bltd--label:hover {
  background-color: transparent !important;
}

/* Ribbon */
.bltd--ui.red.ribbon.bltd--label {
  border-color: #b21e1e !important;
}

/* Basic */
.bltd--ui.basic.red.bltd--label {
  background-color: #FFFFFF !important;
  color: #DB2828 !important;
  border-color: #DB2828 !important;
}
.bltd--ui.basic.red.bltd--labels a.bltd--label:hover,
a.bltd--ui.basic.red.bltd--label:hover {
  background-color: #FFFFFF !important;
  color: #d01919 !important;
  border-color: #d01919 !important;
}

/*--- Orange ---*/

.bltd--ui.orange.bltd--labels .bltd--label,
.bltd--ui.orange.bltd--label {
  background-color: #F2711C !important;
  border-color: #F2711C !important;
  color: #FFFFFF !important;
}

/* Link */
.bltd--ui.orange.bltd--labels .bltd--label:hover,
a.bltd--ui.orange.bltd--label:hover {
  background-color: #f26202 !important;
  border-color: #f26202 !important;
  color: #FFFFFF !important;
}

/* Corner */
.bltd--ui.orange.corner.bltd--label,
.bltd--ui.orange.corner.bltd--label:hover {
  background-color: transparent !important;
}

/* Ribbon */
.bltd--ui.orange.ribbon.bltd--label {
  border-color: #cf590c !important;
}

/* Basic */
.bltd--ui.basic.orange.bltd--label {
  background-color: #FFFFFF !important;
  color: #F2711C !important;
  border-color: #F2711C !important;
}
.bltd--ui.basic.orange.bltd--labels a.bltd--label:hover,
a.bltd--ui.basic.orange.bltd--label:hover {
  background-color: #FFFFFF !important;
  color: #f26202 !important;
  border-color: #f26202 !important;
}

/*--- Yellow ---*/

.bltd--ui.yellow.bltd--labels .bltd--label,
.bltd--ui.yellow.bltd--label {
  background-color: #FBBD08 !important;
  border-color: #FBBD08 !important;
  color: #FFFFFF !important;
}

/* Link */
.bltd--ui.yellow.bltd--labels .bltd--label:hover,
a.bltd--ui.yellow.bltd--label:hover {
  background-color: #eaae00 !important;
  border-color: #eaae00 !important;
  color: #FFFFFF !important;
}

/* Corner */
.bltd--ui.yellow.corner.bltd--label,
.bltd--ui.yellow.corner.bltd--label:hover {
  background-color: transparent !important;
}

/* Ribbon */
.bltd--ui.yellow.ribbon.bltd--label {
  border-color: #cd9903 !important;
}

/* Basic */
.bltd--ui.basic.yellow.bltd--label {
  background-color: #FFFFFF !important;
  color: #FBBD08 !important;
  border-color: #FBBD08 !important;
}
.bltd--ui.basic.yellow.bltd--labels a.bltd--label:hover,
a.bltd--ui.basic.yellow.bltd--label:hover {
  background-color: #FFFFFF !important;
  color: #eaae00 !important;
  border-color: #eaae00 !important;
}

/*--- Olive ---*/

.bltd--ui.olive.bltd--labels .bltd--label,
.bltd--ui.olive.bltd--label {
  background-color: #B5CC18 !important;
  border-color: #B5CC18 !important;
  color: #FFFFFF !important;
}

/* Link */
.bltd--ui.olive.bltd--labels .bltd--label:hover,
a.bltd--ui.olive.bltd--label:hover {
  background-color: #a7bd0d !important;
  border-color: #a7bd0d !important;
  color: #FFFFFF !important;
}

/* Corner */
.bltd--ui.olive.corner.bltd--label,
.bltd--ui.olive.corner.bltd--label:hover {
  background-color: transparent !important;
}

/* Ribbon */
.bltd--ui.olive.ribbon.bltd--label {
  border-color: #198f35 !important;
}

/* Basic */
.bltd--ui.basic.olive.bltd--label {
  background-color: #FFFFFF !important;
  color: #B5CC18 !important;
  border-color: #B5CC18 !important;
}
.bltd--ui.basic.olive.bltd--labels a.bltd--label:hover,
a.bltd--ui.basic.olive.bltd--label:hover {
  background-color: #FFFFFF !important;
  color: #a7bd0d !important;
  border-color: #a7bd0d !important;
}

/*--- Green ---*/

.bltd--ui.bltd--green.bltd--labels .bltd--label,
.bltd--ui.bltd--green.bltd--label {
  background-color: #21BA45 !important;
  border-color: #21BA45 !important;
  color: #FFFFFF !important;
}

/* Link */
.bltd--ui.bltd--green.bltd--labels .bltd--label:hover,
a.bltd--ui.bltd--green.bltd--label:hover {
  background-color: #16ab39 !important;
  border-color: #16ab39 !important;
  color: #FFFFFF !important;
}

/* Corner */
.bltd--ui.bltd--green.corner.bltd--label,
.bltd--ui.bltd--green.corner.bltd--label:hover {
  background-color: transparent !important;
}

/* Ribbon */
.bltd--ui.bltd--green.ribbon.bltd--label {
  border-color: #198f35 !important;
}

/* Basic */
.bltd--ui.basic.bltd--green.bltd--label {
  background-color: #FFFFFF !important;
  color: #21BA45 !important;
  border-color: #21BA45 !important;
}
.bltd--ui.basic.bltd--green.bltd--labels a.bltd--label:hover,
a.bltd--ui.basic.bltd--green.bltd--label:hover {
  background-color: #FFFFFF !important;
  color: #16ab39 !important;
  border-color: #16ab39 !important;
}

/*--- Teal ---*/

.bltd--ui.teal.bltd--labels .bltd--label,
.bltd--ui.teal.bltd--label {
  background-color: #00B5AD !important;
  border-color: #00B5AD !important;
  color: #FFFFFF !important;
}

/* Link */
.bltd--ui.teal.bltd--labels .bltd--label:hover,
a.bltd--ui.teal.bltd--label:hover {
  background-color: #009c95 !important;
  border-color: #009c95 !important;
  color: #FFFFFF !important;
}

/* Corner */
.bltd--ui.teal.corner.bltd--label,
.bltd--ui.teal.corner.bltd--label:hover {
  background-color: transparent !important;
}

/* Ribbon */
.bltd--ui.teal.ribbon.bltd--label {
  border-color: #00827c !important;
}

/* Basic */
.bltd--ui.basic.teal.bltd--label {
  background-color: #FFFFFF !important;
  color: #00B5AD !important;
  border-color: #00B5AD !important;
}
.bltd--ui.basic.teal.bltd--labels a.bltd--label:hover,
a.bltd--ui.basic.teal.bltd--label:hover {
  background-color: #FFFFFF !important;
  color: #009c95 !important;
  border-color: #009c95 !important;
}

/*--- Blue ---*/

.bltd--ui.blue.bltd--labels .bltd--label,
.bltd--ui.blue.bltd--label {
  background-color: #2185D0 !important;
  border-color: #2185D0 !important;
  color: #FFFFFF !important;
}

/* Link */
.bltd--ui.blue.bltd--labels .bltd--label:hover,
a.bltd--ui.blue.bltd--label:hover {
  background-color: #1678c2 !important;
  border-color: #1678c2 !important;
  color: #FFFFFF !important;
}

/* Corner */
.bltd--ui.blue.corner.bltd--label,
.bltd--ui.blue.corner.bltd--label:hover {
  background-color: transparent !important;
}

/* Ribbon */
.bltd--ui.blue.ribbon.bltd--label {
  border-color: #1a69a4 !important;
}

/* Basic */
.bltd--ui.basic.blue.bltd--label {
  background-color: #FFFFFF !important;
  color: #2185D0 !important;
  border-color: #2185D0 !important;
}
.bltd--ui.basic.blue.bltd--labels a.bltd--label:hover,
a.bltd--ui.basic.blue.bltd--label:hover {
  background-color: #FFFFFF !important;
  color: #1678c2 !important;
  border-color: #1678c2 !important;
}

/*--- Violet ---*/

.bltd--ui.violet.bltd--labels .bltd--label,
.bltd--ui.violet.bltd--label {
  background-color: #6435C9 !important;
  border-color: #6435C9 !important;
  color: #FFFFFF !important;
}

/* Link */
.bltd--ui.violet.bltd--labels .bltd--label:hover,
a.bltd--ui.violet.bltd--label:hover {
  background-color: #5829bb !important;
  border-color: #5829bb !important;
  color: #FFFFFF !important;
}

/* Corner */
.bltd--ui.violet.corner.bltd--label,
.bltd--ui.violet.corner.bltd--label:hover {
  background-color: transparent !important;
}

/* Ribbon */
.bltd--ui.violet.ribbon.bltd--label {
  border-color: #502aa1 !important;
}

/* Basic */
.bltd--ui.basic.violet.bltd--label {
  background-color: #FFFFFF !important;
  color: #6435C9 !important;
  border-color: #6435C9 !important;
}
.bltd--ui.basic.violet.bltd--labels a.bltd--label:hover,
a.bltd--ui.basic.violet.bltd--label:hover {
  background-color: #FFFFFF !important;
  color: #5829bb !important;
  border-color: #5829bb !important;
}

/*--- Purple ---*/

.bltd--ui.purple.bltd--labels .bltd--label,
.bltd--ui.purple.bltd--label {
  background-color: #A333C8 !important;
  border-color: #A333C8 !important;
  color: #FFFFFF !important;
}

/* Link */
.bltd--ui.purple.bltd--labels .bltd--label:hover,
a.bltd--ui.purple.bltd--label:hover {
  background-color: #9627ba !important;
  border-color: #9627ba !important;
  color: #FFFFFF !important;
}

/* Corner */
.bltd--ui.purple.corner.bltd--label,
.bltd--ui.purple.corner.bltd--label:hover {
  background-color: transparent !important;
}

/* Ribbon */
.bltd--ui.purple.ribbon.bltd--label {
  border-color: #82299f !important;
}

/* Basic */
.bltd--ui.basic.purple.bltd--label {
  background-color: #FFFFFF !important;
  color: #A333C8 !important;
  border-color: #A333C8 !important;
}
.bltd--ui.basic.purple.bltd--labels a.bltd--label:hover,
a.bltd--ui.basic.purple.bltd--label:hover {
  background-color: #FFFFFF !important;
  color: #9627ba !important;
  border-color: #9627ba !important;
}

/*--- Pink ---*/

.bltd--ui.pink.bltd--labels .bltd--label,
.bltd--ui.pink.bltd--label {
  background-color: #E03997 !important;
  border-color: #E03997 !important;
  color: #FFFFFF !important;
}

/* Link */
.bltd--ui.pink.bltd--labels .bltd--label:hover,
a.bltd--ui.pink.bltd--label:hover {
  background-color: #e61a8d !important;
  border-color: #e61a8d !important;
  color: #FFFFFF !important;
}

/* Corner */
.bltd--ui.pink.corner.bltd--label,
.bltd--ui.pink.corner.bltd--label:hover {
  background-color: transparent !important;
}

/* Ribbon */
.bltd--ui.pink.ribbon.bltd--label {
  border-color: #c71f7e !important;
}

/* Basic */
.bltd--ui.basic.pink.bltd--label {
  background-color: #FFFFFF !important;
  color: #E03997 !important;
  border-color: #E03997 !important;
}
.bltd--ui.basic.pink.bltd--labels a.bltd--label:hover,
a.bltd--ui.basic.pink.bltd--label:hover {
  background-color: #FFFFFF !important;
  color: #e61a8d !important;
  border-color: #e61a8d !important;
}

/*--- Brown ---*/

.bltd--ui.brown.bltd--labels .bltd--label,
.bltd--ui.brown.bltd--label {
  background-color: #A5673F !important;
  border-color: #A5673F !important;
  color: #FFFFFF !important;
}

/* Link */
.bltd--ui.brown.bltd--labels .bltd--label:hover,
a.bltd--ui.brown.bltd--label:hover {
  background-color: #975b33 !important;
  border-color: #975b33 !important;
  color: #FFFFFF !important;
}

/* Corner */
.bltd--ui.brown.corner.bltd--label,
.bltd--ui.brown.corner.bltd--label:hover {
  background-color: transparent !important;
}

/* Ribbon */
.bltd--ui.brown.ribbon.bltd--label {
  border-color: #805031 !important;
}

/* Basic */
.bltd--ui.basic.brown.bltd--label {
  background-color: #FFFFFF !important;
  color: #A5673F !important;
  border-color: #A5673F !important;
}
.bltd--ui.basic.brown.bltd--labels a.bltd--label:hover,
a.bltd--ui.basic.brown.bltd--label:hover {
  background-color: #FFFFFF !important;
  color: #975b33 !important;
  border-color: #975b33 !important;
}

/*--- Grey ---*/

.bltd--ui.grey.bltd--labels .bltd--label,
.bltd--ui.grey.bltd--label {
  background-color: #767676 !important;
  border-color: #767676 !important;
  color: #FFFFFF !important;
}

/* Link */
.bltd--ui.grey.bltd--labels .bltd--label:hover,
a.bltd--ui.grey.bltd--label:hover {
  background-color: #838383 !important;
  border-color: #838383 !important;
  color: #FFFFFF !important;
}

/* Corner */
.bltd--ui.grey.corner.bltd--label,
.bltd--ui.grey.corner.bltd--label:hover {
  background-color: transparent !important;
}

/* Ribbon */
.bltd--ui.grey.ribbon.bltd--label {
  border-color: #805031 !important;
}

/* Basic */
.bltd--ui.basic.grey.bltd--label {
  background-color: #FFFFFF !important;
  color: #767676 !important;
  border-color: #767676 !important;
}
.bltd--ui.basic.grey.bltd--labels a.bltd--label:hover,
a.bltd--ui.basic.grey.bltd--label:hover {
  background-color: #FFFFFF !important;
  color: #838383 !important;
  border-color: #838383 !important;
}

/*--- Black ---*/

.bltd--ui.black.bltd--labels .bltd--label,
.bltd--ui.black.bltd--label {
  background-color: #1B1C1D !important;
  border-color: #1B1C1D !important;
  color: #FFFFFF !important;
}

/* Link */
.bltd--ui.black.bltd--labels .bltd--label:hover,
a.bltd--ui.black.bltd--label:hover {
  background-color: #27292a !important;
  border-color: #27292a !important;
  color: #FFFFFF !important;
}

/* Corner */
.bltd--ui.black.corner.bltd--label,
.bltd--ui.black.corner.bltd--label:hover {
  background-color: transparent !important;
}

/* Ribbon */
.bltd--ui.black.ribbon.bltd--label {
  border-color: #805031 !important;
}

/* Basic */
.bltd--ui.basic.black.bltd--label {
  background-color: #FFFFFF !important;
  color: #1B1C1D !important;
  border-color: #1B1C1D !important;
}
.bltd--ui.basic.black.bltd--labels a.bltd--label:hover,
a.bltd--ui.basic.black.bltd--label:hover {
  background-color: #FFFFFF !important;
  color: #27292a !important;
  border-color: #27292a !important;
}

/*-------------------
        Basic
--------------------*/

.bltd--ui.basic.bltd--label {
  background: none #FFFFFF;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
}

/* Link */
a.bltd--ui.basic.bltd--label:hover {
  text-decoration: none;
  background: none #FFFFFF;
  color: #1e70bf;
  box-shadow: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: none;
}

/* Pointing */
.bltd--ui.basic.bltd--pointing.bltd--label:before {
  border-color: inherit;
}

/*-------------------
       Fluid
--------------------*/

.bltd--ui.bltd--label.fluid,
.bltd--ui.fluid.bltd--labels > .bltd--label {
  width: 100%;
  box-sizing: border-box;
}

/*-------------------
       Inverted
--------------------*/

.bltd--ui.bltd--inverted.bltd--labels .bltd--label,
.bltd--ui.bltd--inverted.bltd--label {
  color: rgba(255, 255, 255, 0.9) !important;
}

/*-------------------
     Horizontal
--------------------*/

.bltd--ui.horizontal.bltd--labels .bltd--label,
.bltd--ui.horizontal.bltd--label {
  margin: 0em 0.5em 0em 0em;
  padding: 0.4em 0.833em;
  min-width: 3em;
  text-align: center;
}

/*-------------------
       Circular
--------------------*/

.bltd--ui.circular.bltd--labels .bltd--label,
.bltd--ui.circular.bltd--label {
  min-width: 2em;
  min-height: 2em;
  padding: 0.5em !important;
  line-height: 1em;
  text-align: center;
  border-radius: 500rem;
}
.bltd--ui.empty.circular.bltd--labels .bltd--label,
.bltd--ui.empty.circular.bltd--label {
  min-width: 0em;
  min-height: 0em;
  overflow: hidden;
  width: 0.5em;
  height: 0.5em;
  vertical-align: baseline;
}

/*-------------------
       Pointing
--------------------*/

.bltd--ui.bltd--pointing.bltd--label {
  position: relative;
}
.bltd--ui.attached.bltd--pointing.bltd--label {
  position: absolute;
}
.bltd--ui.bltd--pointing.bltd--label:before {
  background-color: inherit;
  background-image: inherit;
  border-width: none;
  border-style: solid;
  border-color: inherit;
}

/* Arrow */
.bltd--ui.bltd--pointing.bltd--label:before {
  position: absolute;
  content: '';
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background-image: none;
  z-index: 2;
  width: 0.6666em;
  height: 0.6666em;
  -webkit-transition: background 0.1s ease;
  transition: background 0.1s ease;
}

/*--- Above ---*/

.bltd--ui.bltd--pointing.bltd--label,
.bltd--ui[class*="pointing above"].bltd--label {
  margin-top: 1em;
}
.bltd--ui.bltd--pointing.bltd--label:before,
.bltd--ui[class*="pointing above"].bltd--label:before {
  border-width: 1px 0px 0px 1px;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  top: 0%;
  left: 50%;
}

/*--- Below ---*/

.bltd--ui[class*="bottom pointing"].bltd--label,
.bltd--ui[class*="pointing below"].bltd--label {
  margin-top: 0em;
  margin-bottom: 1em;
}
.bltd--ui[class*="bottom pointing"].bltd--label:before,
.bltd--ui[class*="pointing below"].bltd--label:before {
  border-width: 0px 1px 1px 0px;
  top: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  top: 100%;
  left: 50%;
}

/*--- Left ---*/

.bltd--ui[class*="left pointing"].bltd--label {
  margin-top: 0em;
  margin-left: 0.6666em;
}
.bltd--ui[class*="left pointing"].bltd--label:before {
  border-width: 0px 0px 1px 1px;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  bottom: auto;
  right: auto;
  top: 50%;
  left: 0em;
}

/*--- Right ---*/

.bltd--ui[class*="right pointing"].bltd--label {
  margin-top: 0em;
  margin-right: 0.6666em;
}
.bltd--ui[class*="right pointing"].bltd--label:before {
  border-width: 1px 1px 0px 0px;
  -webkit-transform: translateX(50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(50%) translateY(-50%) rotate(45deg);
  transform: translateX(50%) translateY(-50%) rotate(45deg);
  top: 50%;
  right: 0%;
  bottom: auto;
  left: auto;
}

/* Basic Pointing */

/*--- Above ---*/

.bltd--ui.basic.bltd--pointing.bltd--label:before,
.bltd--ui.basic[class*="pointing above"].bltd--label:before {
  margin-top: -1px;
}

/*--- Below ---*/

.bltd--ui.basic[class*="bottom pointing"].bltd--label:before,
.bltd--ui.basic[class*="pointing below"].bltd--label:before {
  bottom: auto;
  top: 100%;
  margin-top: 1px;
}

/*--- Left ---*/

.bltd--ui.basic[class*="left pointing"].bltd--label:before {
  top: 50%;
  left: -1px;
}

/*--- Right ---*/

.bltd--ui.basic[class*="right pointing"].bltd--label:before {
  top: 50%;
  right: -1px;
}

/*------------------
   Floating Label
-------------------*/

.bltd--ui.floating.bltd--label {
  position: absolute;
  z-index: 100;
  top: -1em;
  left: 100%;
  margin: 0em 0em 0em -1.5em !important;
}

/*-------------------
        Sizes
--------------------*/

.bltd--ui.mini.bltd--labels .bltd--label,
.bltd--ui.mini.bltd--label {
  font-size: 0.64285714rem;
}
.bltd--ui.tiny.bltd--labels .bltd--label,
.bltd--ui.tiny.bltd--label {
  font-size: 0.71428571rem;
}
.bltd--ui.small.bltd--labels .bltd--label,
.bltd--ui.small.bltd--label {
  font-size: 0.78571429rem;
}
.bltd--ui.bltd--labels .bltd--label,
.bltd--ui.bltd--label {
  font-size: 1em;
}
.bltd--ui.large.bltd--labels .bltd--label,
.bltd--ui.large.bltd--label {
  font-size: 1rem;
}
.bltd--ui.big.bltd--labels .bltd--label,
.bltd--ui.big.bltd--label {
  font-size: 1.28571429rem;
}
.bltd--ui.huge.bltd--labels .bltd--label,
.bltd--ui.huge.bltd--label {
  font-size: 1.42857143rem;
}
.bltd--ui.massive.bltd--labels .bltd--label,
.bltd--ui.massive.bltd--label {
  font-size: 1.71428571rem;
}


/*******************************
         Theme Overrides
*******************************/


