.bltd--ui-nav-menu .bltd--ui-nav-link .bltd--ui-nav-link__state.bltd--ui-nav-link__state--is-active {
    border-color: $font-color-impaired;
    color: $link-color-impaired;
}

.bltd--ui-nav-menu .bltd--ui-nav-link:hover {
    a {
        border-color: $font-color-impaired;
    }
}

.bltd--ui-nav-menu a {
    text-decoration: none;
}

input.bltd--input-typeahead {
    border: 0.0625em solid $font-color-impaired;
    color: $font-color-impaired;
    box-shadow: none;
}

.bltd--input-suggest .bltd--input-suggest-item a .bltd--name {
    color: $font-color-impaired;
}

.bltd--input-suggest .bltd--input-suggest-item:hover {
    background: $bg-color-impaired;
    .bltd--name {
        text-decoration: underline;
    }
}

.bltd--ticket--list .bltd--ticket--tr .bltd--ticket--td .bltd--ticket--next-line {
    color: $font-color-impaired;
}

.bltd--input-suggest::-webkit-scrollbar-thumb {
    background: $font-color-impaired;
}

@include media('<=tablet') {
    .bltd--page--ticket .bltd--rlist {
        .bltd--tr .bltd--td.bltd--route--date .bltd--main-line, .bltd--td.bltd--route--cost .bltd--main-line {
            color: $font-color-impaired;
        }

        .bltd--expand .bltd--tr--expand .bltd--tr--expand-heading .bltd--expand--back {
            background: $bg-color-impaired;
        }
    }

    .bltd--heading .bltd--heading--breadcrumb .bltd--title:before,
    .bltd--rlist .bltd--expand .bltd--tr--expand .bltd--tr--expand-heading .bltd--expand--back .bltd--expand--nav:before {
        filter: brightness(0) saturate(100%);
    }

    .bltd--rlist .bltd--expand .bltd--tr--expand .bltd--tr--expand-heading .bltd--expand--back {
        background: $bg-color-impaired;

        .bltd--expand--nav {
            color: $font-color-impaired;
        }
    }

    .bltd--page--profile .bltd--form .bltd--form--footer {
        padding: 0 1.25em;
    }
}

.bltd--page--ticket .bltd--rlist .bltd--rlist--header {
    border-bottom: 0.125em solid $font-color-impaired;
}
