.bltd--booker-header h1 {
    text-shadow: none;
    color: $font-color-impaired;
    font-size: 2.5em;
}

.bltd--booker {
    .bltd--booker-typeahead-placeholder, .bltd--booker-typeahead-placeholder.transform,
    .bltd--booker-item .bltd--booker-typeahead.ng-invalid-not-found-input, .bltd--eyebrow-note {
        color: $font-color-impaired;
    }

    .bltd--booker-submit input {
        background-color: $font-color-impaired;
        border: 0.125em solid $bg-color-impaired;

        &:hover {
            text-decoration: underline;
        }
    }

    &.bltd--booker--page {
        .bltd--booker-wrapper {
            border: 0.125em solid $font-color-impaired;
        }
        .bltd--booker-submit input {
            background-color: $font-color-impaired;
            border: 0.125em solid $font-color-impaired;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .bltd--booker-wrapper {
        border: 0.125em solid $bg-color-impaired;
        @include media('>tablet') {
            margin-right: 19rem;
        }
    }

    @include media('>tablet') {
        .bltd--booker-submit {
            width: 19rem;
        }
    }

    .bltd--booker-container {
        margin-bottom: 1em;
    }
}

.bltd--booker-suggest {
    border: 0.0625em solid $font-color-impaired !important;
    border-top: none !important;
    scrollbar-color: $font-color-impaired $bg-color-impaired;

    &::-webkit-scrollbar-thumb {
        background: $font-color-impaired;
    }

    .bltd--booker-suggest-item a .bltd--name {
        color: $font-color-impaired;
        font-weight: bold;
    }

    .bltd--booker-suggest-item a {
        color: $font-color-impaired;
    }
}

@include media('<=tablet') {
    .bltd--booker-suggest {
        border-top: 0.0625em solid #000 !important;
    }

    .bltd--booker-header {
        margin-left: 0.7em;
        margin-right: 0.7em;

        h1 {
            font-size: 1.5em;
        }
    }
}

.bltd--booker-suggest-popup .bltd--suggest .bltd--suggest-submit input {
    background-color: $font-color-impaired;
}

.bltd--booker-suggest-popup .bltd--suggest .bltd--suggest-wrapper {
    border-color: $font-color-impaired;
    border-right: 0.125em solid $font-color-impaired;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
 }

.bltd--booker .bltd--booker__params {
    background: $bg-color-impaired;
    color: $font-color-impaired;
    border: 0.125em solid $font-color-impaired;
}

#bltd--full-clndr {
    .bltd--clndr-grid .bltd--days .day.active .bltd--day-number, .bltd--clndr-grid .bltd--days .empty.active .bltd--day-number {
        background: $font-color-impaired;
        border-color: $font-color-impaired;
    }
    .bltd--clndr-grid .bltd--days .day.event .bltd--day-number, .bltd--clndr-grid .bltd--days .empty.event .bltd--day-number {
        border-color: $font-color-impaired;
    }
    .bltd--clndr-controls {
        color: $font-color-impaired;
    }
    .bltd--clndr-grid .bltd--days-of-the-week .bltd--header-day.week-end {
        color: $font-color-impaired;
        font-weight: bold;
    }
}

.bltd--calendar-error span {
    color: $font-color-impaired;
    font-weight: bold;
}

.bltd--booker--page .bltd--wr {
    padding: 0;
}

.bltd--booker .bltd--booker-item .bltd--booker-typeahead-clear,
.bltd--booker.bltd--booker--page .bltd--booker-item .bltd--booker-typeahead-clear {
    width: 1em;
    height: 1em;
}

.bltd--route--filter ul.bltd--block-list li.bltd--selected .bltd--name:after,
.bltd--route--sorting ul.bltd--block-list li.bltd--selected .bltd--name:after {
    filter: brightness(0) saturate(100%);
}
