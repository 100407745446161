.expand .route-extend {
  display: block;
}

body.expand .expand {
  &.route {
    position: static;

    .route-content {
      position: static;
    }
  }
}

@include media("<=tablet") {
  .expand .route-extend {
    display: block;
    position: fixed;
    top: 0;
    background: #fff;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    overflow: scroll;

    &.extend-loading {
      .route-extend__inner.bltd--error {
        opacity: 0;
        height: 200px;
      }
      .route-extend__inner {
        height: 250px;
        > .bltd--column {
          display: none;
        }
      }
    }

    .route-extend__inner > .bltd--column {
      .bltd--map {
        min-height: 175px;
        margin-bottom: 0;
        position: relative;
      }
    }

    .bltd--calendar-loading img {
      top: 6%;
    }
  }
}
