/*-------------------
       Agency
--------------------*/
.agency.round {
  //border-top-left-radius: 25px;
  //border-top-right-radius: 25px
}

ul.ivh-treeview {
  list-style-type: none;
  line-height: 40px;
}

ul.ivh-treeview .ivh-treeview-checkbox {
  vertical-align: middle;
}

.ui-personal-agency-form .bltd--is-loading{
  width: 100%;
  height: 100%;
  position: absolute;
  background: white;
  opacity: 0.45;
  z-index: 100;
}
.ui-personal-agency-form .bltd--is-loading .loader-giff{
  position: absolute;
  margin-left: 43%;
  margin-top: -50px;
  top: 50%;
  left: 0px;
}

.bltd--agency-promo {
  background-color: #639925;
  padding-left: 20px;
  margin-top: 20px;
  padding-top: 40px;
  padding-bottom: 20px;
}