//@extend-elements
//original selectors
//.custom-select ul, .custom-select li
%extend_1 {
  margin: 0;
  padding: 0;
  list-style: none;
}

//original selectors
//.custom-select, .custom-select ul
%extend_2 {
  position: relative;
  top: 0;
  left: 0;
}

//original selectors
//.custom-select .custom-text, .custom-select.disabled span.custom-text:hover
%extend_3 {
  overflow: hidden;
  border: 1px solid #ddd;
  height: 30px;
  line-height: 28px;
  padding-left: 10px;
  padding-right: 11px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 3px;
}


.wrapper--routing {
  font-size: 1.6rem;
}
.pretty_form {
  font-size: 1.6rem;
  background: #f8f7f5;
  margin: 10px -10px 0;
  padding: 25px 30px;
  position: relative;
  h3 {
    margin-bottom: 1em;
  }
  fieldset {
    border: 0;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 1.5em;
    padding-bottom: 35px;
    &:last-child {
      border: 0;
      margin-bottom: 0!important;
      padding-bottom: 0!important;
    }
    h4 {
      margin-bottom: 1em;
    }
  }

  .form_item {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
      .example:last-child {
        margin-bottom: -5px;
      }
    }
    input {
      font-size: 100%;
      vertical-align: middle;
      &.long {
        //Instead of the line below you could use @include box-sizing($bs)
        box-sizing: border-box;
        width: 100%;
      }
      &.middle {
        width: 15em;
      }
      &.short {
        width: 5em;
      }
    }
    &.long {
      input {
        //Instead of the line below you could use @include box-sizing($bs)
        box-sizing: border-box;
        width: 100%;
      }
      textarea {
        //Instead of the line below you could use @include box-sizing($bs)
        box-sizing: border-box;
        width: 100%;
      }
      .bltd--inner {
        //Instead of the line below you could use @include box-sizing($bs)
        box-sizing: border-box;
        width: 100%;
      }
    }

    &.w3 {
      .bltd--inner {
        box-sizing: border-box;
        width: 100%;
      }
    }
    textarea {
      margin-bottom: .3em;
      vertical-align: top;
      &.long {
        //Instead of the line below you could use @include box-sizing($bs)
        box-sizing: border-box;
        width: 100%;
      }
      &.middle {
        width: 15em;
      }
    }


    .bltd--inner {
      display: inline-block;
      position: relative;
    }

    label {
      /* display: inline-block; */
      line-height: 1.2;
      margin: 0 0 .2em 0;
      /* width: 10em; */
      /* font-size: 100%; */
      vertical-align: middle;
      display: block;
      font-weight: $font-800;
    }
    select {
      font-size: 100%;
      vertical-align: middle;
    }

  }


  .flex {
    width: 100%;
    border-radius: 0;
    display: table;
  /*  display: flex;
    table-layout: fixed;
    position: relative;
    justify-content: space-between;
    flex-basis: 33%;*/

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1em;
    .form_item {
      display: table-cell;
      display: inline-flex;
      overflow: hidden;
      vertical-align: top;
      box-sizing: border-box;
      padding-right: 1em;

    /*  -webkit-box-flex: 1;
      -webkit-flex: 1 ;
      -ms-flex: 1;
      flex: 1 ;*/
      flex:  1 auto;

      &:last-child {
        padding-right: 0;
      }
      input{
        width: 100%;
        box-sizing: border-box;

      }
    }



  }
}
.webkit {
  .pretty_form .form_item textarea {
    margin-bottom: .5em;
  }
  .custom-select li {
    padding-right: 37px;
  }
}
.pretty_select {
  vertical-align: top;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all .4s ease 0s;
  &.hidden {
    left: -99999em;
    top: -99999em;
    position: absolute;
  }
  input {
    background: transparent;
  }
  &:hover {
    background-color: #f2f1ef;
  }
}
.select-list {
  background: #fff;
  border: 1px solid #dedede;
  color: #4d4d4d;
  position: absolute;
  left: 0;
  margin-top: -1px;
  min-width: 100px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 3px;
  ul {
    width: 100%!important;
  }
}
.custom-select {
  display: inline-block;
  margin: -2px .5em .5em 0;
  max-width: 100%;
  position: relative;
  text-align: left;
  vertical-align: top;
  z-index: 1;
  @extend %extend_2;
  * {
    //Instead of the line below you could use @include user-select($select)
    user-select: none;
  }
  ul {
    @extend %extend_1;
    @extend %extend_2;
  }
  li {
    @extend %extend_1;
    background: #fff;
    border-bottom: 1px solid #e7e7e7;
    cursor: pointer;
    line-height: 30px;
    min-height: 30px;
    padding: 0 36px 0 10px;
    white-space: nowrap;
    &:last-child {
      border: 0;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0 0 3px 3px;
    }
    &:hover {
      background: #fef1e7;
    }
    &.disabled {
      color: #ccc;
      cursor: default;
    }
    &:first-child {
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 3px 3px 0 0;
    }
    &.selected {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 13px;
        height: 15px;
        margin-top: -8px;
        top: 50%;
        right: 12px;
      }
    }
  }
  .custom-text {
    background: #fff;
    color: #000;
    cursor: pointer;
    display: inline-block;
    max-width: 100%;
    min-width: 40px;
    overflow: hidden;
    vertical-align: middle;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all .4s ease 0s;
    position: relative;
    @extend %extend_3;
    &:before {
      content: '';
      position: absolute;
      width: 8px;
      height: 5px;
      margin-top: -3px;
      top: 50%;
      right: 7px;
    }
    &:hover {
      background: #fef1e7;
    }
  }
  &.combobox {
    position: absolute;
    .select-list {
      margin-top: 34px;
    }
    .custom-text {
      display: none;
      &:after {
        display: none;
      }
    }
    .disabled {
      display: none;
    }
  }
  &.disabled {
    opacity: .5!important;
    span.custom-text:hover {
      @extend %extend_3;
    }
  }
  .hover {
    color: #fff;
  }
  &.active {
    z-index: 10;
    .custom-text {
      background-color: #fefdeb;
      &:before {
        width: 8px;
        height: 5px;
      }
    }
  }
  &.open_list {
    z-index: 10;
    .custom-text {
      background-color: #fefdeb;
      &:before {
        width: 8px;
        height: 5px;
      }
    }
  }
  .select-scroll {
    position: absolute;
    right: 2px;
    top: 0;
    width: 8px;
    z-index: 2;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 3px;
    .scroll-drag {
      display: block;
      position: absolute;
      left: 1px;
      cursor: pointer;
      top: 0;
      width: 6px;
      background-color: #ddd;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 3px;
      &.ondrag {
      }
    }
  }
}
.macos {
  &.webkit {
    .custom-select li {
      padding-right: 44px;
    }
    &.safari .custom-select li {
      padding-right: 54px;
    }
  }
  &.firefox .custom-select li {
    padding-right: 34px;
  }
}
.custom-scroll {
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: top .5s ease .5s;
  overflow: hidden;
}
.bltd--columns .bltd--column {
  float: left;
}
.ie7-8 .pretty_form fieldset {
  border-bottom: 1px solid #e7e7e7;
}
.ie7 .pretty_form {
  .description {
    display: inline;
    zoom: 1;
  }
  .form_item {
    .bltd--inner {
      display: inline;
      zoom: 1;
    }
    .flex {
      display: inline;
      zoom: 1;
    }
    label {
      display: inline;
      zoom: 1;
    }
  }
}
.done select {
  display: block;
  left: -9999em;
  position: absolute;
  visibility: hidden;
}
.desktop .pretty_select {
  border: 1px solid #000;
  cursor: pointer;
}
.helper .custom-select {
  margin-right: 0;
  width: 100%;
}
.opera .custom-select *::selection {
  color: inherit;
}
.safari .custom-select .custom-text {
  padding-right: 22px;
}