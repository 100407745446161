.bltd--rlist {
    .bltd--rlist--content .bltd--rlist--content-route.bltd--expand .bltd--rlist--overlay {
        border-color: $font-color-impaired;
    }

    .bltd--expand-link {
        color: $link-color-impaired;
    }

    .bltd--tr {
        border-bottom-color: $font-color-impaired;

        .bltd--td {
            @include media('>tablet') {
                padding-left: .3%;
                padding-right: .3%;

                &:first-child {
                    padding-left: 0;
                }
            }

            .bltd--next-line {
                color: $font-color-impaired;
            }
        }
    }

    @include media('>tablet') {
        .bltd--route--link {
            width: 26rem;

            .bltd--route--link-inner {
                margin-right: 15rem;

                .bltd--main-line {
                    text-align: center;
                }
            }
        }

        .bltd--route--days {
            width: 15%;
        }

        .bltd--route--num {
            width: 90px;
        }

        .bltd--td--error {
            font-size: 0.8em;
            word-break: break-all;
            overflow: visible;
        }

        .bltd--rlist--content .bltd--rlist--content-route .bltd--ctn {
            margin-left: .5%;
            margin-right: .5%;
        }

        .bltd--rlist--header .bltd--rlist--header-wrapper .bltd--th {
            padding-left: .3%;
            padding-right: .3%;
        }
    }
}

.bltd--switcher {
    a {
        text-decoration: none;
    }

    .bltd--tab .bltd--date, .bltd--tab .bltd--counter {
        color: $font-color-impaired;
    }
    .bltd--tab.bltd--tab_pressed, .bltd--tab.bltd--hover, .bltd--tab:not(.tabFilter):hover {
        background-color: $bg-color-impaired;
        color: $font-color-impaired;
        border-bottom: 0.125em solid $font-color-impaired;
    }

    .bltd--tab.bltd--tab_pressed .bltd--date:not(.filterDate),
    .bltd--tab.bltd--hover .bltd--date:not(.filterDate),
    .bltd--tab:not(.tabFilter):hover .bltd--date:not(.filterDate) {
        color: $font-color-impaired;
    }

}

.expand--filter {
    color: $font-color-impaired;
}

.bltd--dropdown-menu.bltd--dropdown-menu--is-open {
    border: 0.0625em solid $font-color-impaired;
}

.bltd--route--filter ul.bltd--block-list li .bltd--name .bltd--text,
.bltd--route--sorting ul.bltd--block-list li .bltd--name .bltd--text {
    font-weight: bold;
}

.bltd--route--filter ul.bltd--block-list li .bltd--state,
.bltd--route--sorting ul.bltd--block-list li .bltd--state {
    color: $font-color-impaired;
}

.bltd--sorting {
    background: $bg-color-impaired;
}

.bltd--ui-progress-bar {
    background-color: $font-color-impaired;
}

.bltd--rlist .bltd--tr--expand .bltd--tr--expand-ctn .bltd--column.bltd--column--waypoint {
    .bltd--point {
        &.bltd--active .bltd--location--destination:after,
        &.bltd--inner .bltd--location--destination:after, .bltd--location--destination:after {
            border-color: $font-color-impaired;
        }
        &:before {
            background: $font-color-impaired;
        }
        &.bltd--inactive:before, &.bltd--inactive .bltd--subl:before, &.bltd--end:before {
            background: #d7d6d2;
        }
        &.bltd--inactive .bltd--location--destination:after {
            border-color: #d7d6d2;
        }
    }
    .bltd--shortlist {
        &.bltd--inner .bltd--location--destination:before {
            background: $font-color-impaired;
        }
        .bltd--location--destination .bltd--location--destination__inner .bltd--location:last-child:after {
            border-color: $font-color-impaired;
        }
        &:before {
            background: repeating-linear-gradient(180deg, #000, #000 3px, transparent 3px, transparent 6px);
        }
        &.bltd--inactive .bltd--location--destination__inner .bltd--location:last-child:after {
            border-color: #d7d6d2;
        }
    }
}

@include media('<=tablet') {
    .bltd--sorting .bltd--dropdown-toggle {
        border: 0.0625em solid $font-color-impaired;
        color: $font-color-impaired;
    }

    .bltd--route-notes {
        border-bottom: 0.0625em solid $font-color-impaired;
    }

    .bltd--page--ticket .bltd--rlist .bltd--tr .bltd--td.bltd--route--date,
    .bltd--page--ticket .bltd--rlist .bltd--tr .bltd--td.bltd--route--cost {
        width: 100%;
        text-align: left;
    }

    .bltd--dropdown-menu.bltd--dropdown-menu--is-open {
        left: auto !important;
    }

}

.bltd--rlist--content-route .bltd--btn--is-rarr.bltd--btn--green {
    padding-left: .4em;
    padding-right: .4em;
}

.bltd--dropdown-menu.bltd--dropdown-menu--is-open {
    width: 15em !important;
}

@include media('>tablet') {
    .bltd--page--ticket .bltd--rlist {
        .bltd--route--num {
            width: 120px;
        }
        .bltd--route--passangers {
            text-align: center;
        }
        .bltd--route--link {
            width: 17rem;
        }

        .bltd--route--date-payed {
            display: none !important;
        }

        .bltd--tr .bltd--td {
            padding-left: .3%;
            padding-right: .3%;
        }

        .bltd--route--name {
            width: 26%;
        }
    }
}

.bltd--ticket--list {
    .bltd--ticket--num {
        width: 175px;
        max-width: 175px;
    }
    .bltd--ticket--tr .bltd--ticket--td {
        padding-left: .1%;
        padding-right: .1%;
    }
    .bltd--ticket--name {
        width: 37%;
    }
    .bltd--ticket--tax {
        width: 20%;
    }
    .bltd--ticket--cost {
        display: none !important;
    }
    .bltd--ticket--bag {
        width: 10%;
    }
}