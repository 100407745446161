$red: #ff5a45;
$blue: #71bbd2;
$dark-blue: #3883a3;
$dark-green: #69a776;
$light-green: #97ce7f;
$dark-gray: #414141;
$light-gray: #ebebeb;
$yellow: #ddca9a;

#bltd--full-clndr {
  width: 15em;
  background-color: #fff;
  margin: 0 auto;
  border: 0.0625em solid #e4e4e4;
  box-shadow: 0 0.625em 0.625em rgba(0, 0, 0, 0.1);
  border-top: 0;
  &.bltd--routes-clndr {
    border: none;
    box-shadow: none;
    overflow: hidden;
  }
  .bltd--clndr {
    margin: 0 0.9375em;
    position: relative;
    padding: 1.0625em 0 0;
    cursor: default;
  }
  .bltd--clndr-all {
    text-align: left;
    padding: 1em 1.125em;
    line-height: 1.5;
    border-bottom: 0.0625em solid rgba(223, 223, 224, 0.5);
  }
  .bltd--clndr-controls {
    line-height: 1;
    padding: 0;
    text-align: center;
    color: #4a4a4a;
    font-weight: 600;
    .bltd--current-month {
      font-size: .875em;
    }
    .bltd--clndr-previous-button, .bltd--clndr-next-button {
      display: block;
      background-image: url("../img/icons/icons.svg");
      background-size: 10.5625em 17.3125em;
      width: 1em;
      height: 1em;
      text-indent: -9999px;
      cursor: pointer;
      &.inactive {
        opacity: 0.3;
        cursor: default;
        &:hover {
          opacity: 0.3;
        }
      }

      &:hover {
        opacity: 0.5;
      }
    }

    .bltd--clndr-previous-button {
      float: left;
      background-position: -9.5625em -6.4375em;
    }

    .bltd--clndr-next-button {
      float: right;
      background-position: -8.25em -6.4375em;
    }
  }

  .bltd--clndr-grid {
    padding: 0.5em 0;
    .bltd--days-of-the-week {
      width: 100%;
      .bltd--header-day {
        float: left;
        width: 14.2857%;
        cursor: pointer;
        margin: 0;
        padding: 0;
        border: none;
        text-align: center;
        padding: 0;
        font-size: .75em;
        padding-bottom: 14.2857%;
        &.week-end {
          color: #dd4b39;
        }
        .bltd--header-day-num {
          height: 2.5em;
          width: 2.5em;
          line-height: 2.5em;
          display: block;
          position: absolute;
        }
      }
    }

    .bltd--days {
      width: 100%;
      .day, .empty {
        float: left;
        width: 14.2857%;
        cursor: pointer;
        margin: 0;
        padding: 0;
        border: none;
        text-align: center;
        font-size: .75em;
        padding-bottom: 15.2857%;
        .bltd--day-number {
          height: 2.5em;
          width: 2.5em;
          line-height: 2.5em;
          display: block;
          border-radius: $px4;
          position: absolute;
        }

        &.event {
          .bltd--day-number {
            background: #FFFFFF;
            color: #4a4a4a;
            border: 0.085em solid var(--base-green-color);
            font-weight: $font-800;
          }
        }

        &.adjacent-month {
          .bltd--day-number {
            opacity: 0.3;
          }
        }
        &.select {
          .bltd--day-number {
            background: var(--calendar-bg-select);
            border: 0;
          }
          &:hover {
            .bltd--day-number {
              background: var(--base-green-color);
            }
          }
        }
        &.active {
          .bltd--day-number {
            background: var(--base-green-color);
            color: #fff;
            border: 0.085em solid var(--base-green-color);
          }
          &:hover {
            .bltd--day-number {
              background: var(--base-green-color);
            }
          }
        }
        &:hover {
          .bltd--day-number {
            background: #eee;
          }
        }
        &.in-range {
          .bltd--day-number {
            border-radius: 0;
            border-left: 0;
            border-right: 0;
          }
          &.start {
            .bltd--day-number {
              border-radius: 0.25em 0 0 0.25em;
              border-left: 0.085em solid var(--base-green-color);
            }
          }
          &.end {
            .bltd--day-number {
              border-radius: 0 0.25em 0.25em 0;
              border-right: 0.085em solid var(--base-green-color);
            }
          }
        }
      }

      .today {
        .bltd--day-number {
          color: var(--base-green-color);
        }

      }
    }
  }
}

.bltd--calendar-route {
  overflow: hidden;
}

.bltd--calendar-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#fff, 0.7);
  z-index: 10;

  .bltd--global--loader {
    position: relative;
    height: 100px;
    width: auto;
    .bltd--spinner-wrap {
      width: 200px;
      margin-top: auto;
      .bltd--text {
        font-size: .85em;
        line-height: 1.3;
      }
    }
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;

    + p {
      position: absolute;
      bottom: 10%;
      left: 0;
      color: #666666;
      font-size: 0.8125em;
      text-align: center;
      line-height: 150%;
      display: block;
      width: 100%;
    }
  }
}

.bltd--calendar-error {
  padding: 0 0 0.75em 0;
  text-align: center;
  span {
    font-size: 0.75em;
    color: #dd4b39;
  }
}

[date-picker-wrapper], .bltd--date-picker-wrapper {
  position: absolute;
  top: 100%;
  z-index: 10;
  display: block;
  width: 100%;
  margin-top: 2px;
}

#bltd--full-clndr {
  position: relative;
}

.ticket-submit {
  [date-picker-wrapper] {
    width: 240px;
    right: 0;

    #bltd--full-clndr {
      border: 0.0625em solid #e4e4e4;
    }
  }
}

#full-clndr .bltd--clndr .bltd--row {
  display: block;
  margin-right: 0;
  margin-left: 0;
}

@include media("<=tablet") {
  .route-extend .cal {
    [date-picker-wrapper] {
      top: inherit;
      margin-top: 0;
      width: 90%;
    }
  }
}
@include media("<=tablet") {
  /*.booker {
    #bltd--full-clndr {
      width: 100%;
      !* margin-left: -4%;*!

    }
  }
  .route--calendar-append {
    .bltd--dropdown-menu {
      width: 100%;
    }
    #bltd--full-clndr {
      width: 100%;
     !* margin-left: -4%;*!

    }
  }*/
}
/*

@include media("<=460px") {
  #bltd--full-clndr {
    width: 107%;
    margin-left: -4%;

    &.bltd--routes-clndr {
      margin-left: -15px;
      border: 1px solid #eee;
    }
  }
}

@include media("<=380px") {
  #bltd--full-clndr {
    width: 108%;
    margin-left: -4.5%;
  }
}
*/
#full-clndr .bltd--clndr .bltd--row {
  display: block;
  margin-right: 0;
  margin-left: 0;
}
