.bltd--singup--standalone {
  max-width: 30em;
  padding: 0 6em;
  margin: 0 auto;

  .bltd--heading--form h4 {
    margin: 0;
    padding: 0;
    font-size: 1.125em;
    font-weight: 600;
    text-align: center;
  }
  .bltd--mb{
    margin-bottom: .5em;
  }
  .bltd--mbb{
    margin-bottom: 1em;
  }
  .bltd--login-links {
    font-size: 0.8125em;
    text-align: center;
  }

  .bltd--reset-password {
  /*  margin-top:0;
    text-align: left;
    font-size: 1em;*/
  }
}

.agreement {
  font-size: 0.875em;
}
.expand-form {
  padding: 2rem;
  border: .125em solid #82b548;
  background: #fff;
  border-radius: 3px;
}

@include media("<=tablet") {
  .bltd--wrapper--personal > .bltd--wrap {
    padding-left: 0;
    padding-right: 0;
  }
  .bltd--singup--standalone {
    padding: 0 3em;
  }
}

.return-order-invoice {
  &__info {
    > p {
      line-height: 2em;
    }
  }

  &__header {
    padding-bottom: 0;
  }

  &__tickets {
    .bltd--item:first-child {
      margin-top: 1.2em;
    }
  }
}

