@include media('<=tablet') {

  header {
    .bltd--header-wrapper {
      margin: 0 0.625em;
    }


    .bltd--logo {
      margin-right: 0.9375em;
      & .bltd--logo-link {
        margin: 0.625em 0;
        a {
          font-size: 1.75em;
          line-height: 1.45em;
        }
      }
      & .bltd--expand-nav {
        display: block;
        margin: 1.375em 1.375em 0 .75em;
        height: 100%;

        & a {
          color: #fff;
          width: 1em;
          height: 60px;
        }
      }
    }

    .bltd--main-nav {
      display: none;
    }

    .bltd--account {
      margin-top: 0.625em;

      .bltd--ui.bltd--menu .bltd--item.bltd--dropdown {
        padding: .7em 0.35em .7em 0.8em;
        /*box-shadow: 0 0 0 2px #CDCDCD inset;*/
      }

      .bltd--ui.bltd--menu .bltd--item > i.bltd--dropdown.bltd--icon {
        margin: 0;
        &:before {
          position: relative;
        }
      }
    }
  }

  .bltd--ui.bltd--simple.active.bltd--dropdown > .bltd--menu, .bltd--ui.bltd--simple.bltd--dropdown:hover > .bltd--menu {
    margin-top: -8px;
  }
  .login-popup {
    padding: 2.25em 0.625em;
    display: block;
    .fake {
      display:none;

    }

    max-width: none;
    box-shadow: none;
    &:before {
      content: none;

    }
    .social-links {
      margin-bottom: 1em;
    }
    .bltd--h {
      margin-bottom: 1.1875em;
    }
    h3 {
      font-size: $px22;
      font-weight: 400;
      line-height: $px26;
      color: #4A4A4A;

    }
    .bltd--login-links {
      margin-top: 1em;
      text-align:  left;
    }
    .bltd--login-links  a {
      font-size: $px14;

    }

  }
}

