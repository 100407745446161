html,
body {
	width: 100%;
	height: 100%;
	background: #fff;
}

body:not(.agency) {
	background: var(--base-body-color);
}

html {
	overflow-y: auto;
}

#bltd--page {
	position: relative;
	height: 100%;
}

#bdo--page {
	position: relative;
	height: 100%;
}

body > div[ng-app='bdo'] {
	width: 100%;
	height: 100%;
	background: #fff;
}

.bltd--booker.bltd--booker--page {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
}

.bltd--max-width {
	max-width: 1280px;
	margin: 0 auto;
}

.bltd--flex-spacer {
	-webkit-flex: 1;
	flex: 1;
}

.balance-item {
	font-weight: bold;
}

#bltd--layout {
	position: relative;
	background: var(--base-body-color);
	min-height: 100%;
	padding-bottom: 80px;
	#canvas-pdf-wrapper{
		margin: auto;
	}
}

footer.bltd--index {
	border-top: 0;
}

.white #bltd--layout, #bltd--layout.white, .agency #bltd--layout.index {
	background: #fff;
}

.bltd--layout--view {
	height: 100%;
}

.bltd--notfound-container {
	background: var(--base-body-color);
	padding: 30px 20px;
	&.bltd--text-error > h3 {
		font-weight: 600;
		color: #DD4B39;
	}
}

.bltd--main_content {
	box-sizing: border-box;
	padding-top: 4.875em;
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

.h1x .bltd--main_content {
	padding-top: 0em;
}

.h2x .bltd--main_content {
	padding-top: 10.625em;
}

.bltd--main_content {
	margin: 0 auto;
}

.bltd--wrapper {
	position: relative;
}

.bltd--wrap {
	padding: 2.1875em 1.85em;
	&.bltd--wrap--main {
		padding: 0 $px20 $px20;
	}
	&-hidden {
		display: none;
	}
	&:after {
		clear: both;
		content: '';
		display: table;
	}
}

.bltd--heading {
	position: relative;
	background: #fff;
	width: 100%;
	margin: 0;
	max-width: 100%;
	.bltd--heading--wrapper {
		padding: 2.5em 1.825em 1.25em;
		position: relative;
		background: #fff;
		width: 100%;
		max-width: 1280px;
		margin: 0 auto;
		&.bltd--heading--breadcrumb {
			padding: 1.875em 1.825em;
		}
		.bltd--title--additional {
			margin-top: .5em;
			font-size: .875em;
		}
	}
	.bltd--heading--content {
		position: relative;
		.bltd--heading--action {
			position: absolute;
			top: 0%;
			right: 0;
		}
	}

	h1, h2, h3, h4, h5 {
		line-height: 1;
	}

	.bltd--breadcrumb {
		margin-bottom: 1em;
	}
	.bltd--breadcrumb a {
		font-size: 0.875em;
	}
}

.bltd--heading--sub {
	.bltd--heading--wrapper {
		padding-bottom: 1.5625em;
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		margin-bottom: 0;
	}
}

.sub-header {
	h1,
	h2,
	h3,
	h4,
	h5 {
		margin-bottom: 0;
	}
	margin-bottom: 1.25em;
	margin-top: 1em;
}

.wih {
	display: none !important;
}

.bltd--web-hidden {
	display: none !important;
}

.ih {
	display: none;
}

@include media('<=tablet') {
	.bltd--heading {
		.bltd--heading--wrapper {
			padding: 1.25em 1.25em;
			&.bltd--heading--breadcrumb {
				padding: 1.25em 1.25em;
			}
		}
		.bltd--heading--breadcrumb {
			.bltd--title {
				position: relative;
				color: #4a4a4a;
				display: block;
				padding-left: 1.625em;
				font-weight: 600;
				&::before {
					content: '';
					position: absolute;
					height: 9px;
					width: 9px;
					transform: rotate(51deg) skewX(10deg);
					border: 2px solid var(--base-link-color);
					border-top: none;
					border-right: none;
					top: 5px;
					left: 8px;
				}
			}
		}

		.bltd--title {
			font-size: 1.25em;

			font-weight: $font-600;
			padding-bottom: 0;
			margin-bottom: 0;

		}
		.bltd--breadcrumb {
			display: none;
		}
	}

	.sub-header {
		margin: 0 1.25em 0.625em;
		padding-top: 0.625em;
		h1, h2, h3, h4, h5 {
			margin-bottom: 0;
		}
		h2 {
			font-weight: $font-800;
			font-size: 1.125em;
			margin-bottom: 1em;
		}
	}
	.bltd--wrap {
		padding-left: 1.25em;
		padding-right: 1.25em;
		&.bltd--wrap--main {
			padding: 0 1.25em;
		}
	}
	.bltd--wrapper {
		padding: 0 0.625em;
		position: relative;
	}
	.bltd--mobile-hidden {
		display: none !important;;
	}
	.ih {
		display: inline-block;
	}
	.bltd--web-hidden {
		display: block !important;
	}
	.wih {
		display: inline !important;
	}
	.bltd--main_content {
		margin-top: 0;
		padding-bottom: 0;
		padding-top: 60px;
		&.content_personal, &.content-order {
			padding-top: 0;
		}
	}
}

a.bltd--top-btn {
	opacity: 0;
	visibility: hidden;
	position: fixed;
	bottom: 1em;
	right: 1em;
	z-index: 100;
	width: 25px;
	height: 25px;
	background-color: var(--base-green-color);
	color: #FFFFFF;
	text-indent: -999999px;
	border-radius: 2px;
	will-change: opacity;
	transition: all 0.45s linear;
	&.showed {
		opacity: 1;
		visibility: visible;
		transition: all 0.45s linear;
	}
	&.hidden {
		opacity: 0;
		visibility: hidden;
		transition: all 0.45s linear;
	}
	&:hover {
		background-color: var(--base-green-color);
	}
	&:active {
		background-color: var(--base-green-color);
	}
	&:before {
		transform: rotate(180deg);
		left: 50%;
		top: 50%;
		margin-left: -0.5em;
		margin-top: -0.5em;
	}
}

.bltd--loader-full-page {
	background: rgba(255, 255, 255, 0.6) none repeat scroll 0 0;
	height: 100%;
	position: fixed;
	width: 100%;
	z-index: 1401;
	overflow: hidden;
	.bltd--loader.big {
		position: fixed;
		left: 50%;
		top: 50%;
	}
}

.no-scroll {
	overflow: hidden;
	height: 100%;
}

//test
.bltd--ui-nav-menu {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 0.125em;
	.bltd--ui-nav-link {
		.bltd--ui-nav-link__state {
			display: block;
			border-bottom-color: transparent;
			border-bottom-style: solid;
			border-radius: 0em;
			padding: 1.25em 0 .875em 0;
			margin: 0 2.5em -2px 0;
			border-bottom-width: 2px;
			-webkit-transition: color 0.1s ease;
			transition: color 0.1s ease;
			&.bltd--ui-nav-link__state--is-active {
				background-color: transparent;
				box-shadow: none;
				border-color: var(--base-link-color);
				font-weight: 600;
				color: #4A4A4A;
			}
		}
		.bltd--logout-link{
			font-size: 15px;
			color: var(--base-green-color);
			border: 1px solid;
			border-radius: .1875em;
			top: 1.25em;
			right: 0;
			position: absolute;
			padding: .5em .75em;
		}
	}
}

@include media("<=tablet") {
	#bltd--layout {
		padding-bottom: 0;
		&.bltd--index {
			background: linear-gradient(270deg, var(--base-green-bg-dark), var(--base-green-bg));
			background-repeat: no-repeat;
			background-size: cover;
			height: 100%;

			.bltd--main_content {
				height: 100%;
				& > div:first-child {
					height: 100%;
				}
			}
		}
		&.bltd--bg--gray{
			background: #F3F3F3;
		}
	}
}

.bltd--page--text {
	a.anchor {
		display: block;
		position: relative;
		top: -90px;
		visibility: hidden;
	}
	.bltd--text--content {
		background: #fff;
		border-radius: 0.25em;
		padding: 2.25em 6em;
	}
	dl, ol, ul {
		list-style: none;
		margin-top: 0;
		padding-left: 0
	}
	/*ol {
	  list-style: decimal inside
	}

	ul {
	  list-style: disc inside;
	  margin-bottom: 20px;
	}
	dl dl,dl ol,dl ul,ol dl,ol ol,ol ul,ul dl,ul ol,ul ul {

	  margin: 1.5rem 0 1.5rem 3.0rem
	}

	dd, dt, li {
	  margin-bottom: .25em;
	}

	p {
	  margin-bottom: 1em;
	}
	h1,  h2,  h3,  h4,  h5 {
	  font-weight: 400;
	}
	h1 {
	  margin-bottom: 0.625em;
	}
	h2 {
	  margin-bottom: 0.625em;
	}
	h3 {
	  margin-bottom: 20px;
	}
	h4 {
	  margin-bottom: 20px;
	}
	h5 {
	  margin-bottom: 20px;
	}

	p ~ h1 {
	  margin-top: 0.5em;
	}
	p ~ h2 {
	  margin-top: 0.5em;
	}*/

	p {
		margin-bottom: 1em;
		line-height: 1.5em;
	}

	h1, h2, h3, h4, h5 {
		margin-top: 1em;
		font-weight: normal;
		line-height: 1.2
	}

	h1 {

		margin: 0 0 1em;
		line-height: 1.1
	}

	h1 + p {
		margin-top: -0.5em
	}

	h2 {

		margin-bottom: .5em
	}

	h3 {

		margin-bottom: .75em
	}

	h4 {

		margin-bottom: .3em
	}

	h5 {
		font-size: 100%;
		margin-bottom: .5em
	}

	h2:first-child, h3:first-child, h4:first-child, h5:first-child, h1 + h2, h2 + h3, h3 + h4, h4 + h5 {
		margin-top: 0
	}

	h2 + h3, h3 + h4 {
		margin-top: .8em
	}

	menu, ul, ol {
		list-style-type: none
	}

	ul, ol {
		margin-bottom: 1em;
		counter-reset: item
	}

	ul li, ol li {
		margin-bottom: .5em
	}

	ul ul, ul ol, ol ul, ol ol {
		margin-bottom: .5em;
		margin-top: .5em;
	}

	ul p, ol p {
		margin-bottom: .5em
	}

	ul > li {
		margin-bottom: .5em;
		padding-left: 1.3em
	}

	ul > li:before {
		content: '\2022\a0';
		float: left;
		margin-left: -1.3em
	}

	ol > li {
		margin-left: 1.6em
	}

	ol > li:before {
		font-size: 87%;
		position: absolute;
		margin: .2em 0 0 -1.7em;
		content: counter(item) ".";
		counter-increment: item;
		color: #888
	}

	p + ul, p + ol {
		margin-top: -0.5em
	}

	ul p + ul, ol p + ul, ul p + ol, ol p + ol {
		margin-top: -0.2em
	}

	h2 + ul, h3 + ul, h4 + ul {
		margin-top: 1em
	}

	sup, sub {
		position: relative;
		top: -0.5em;
		font-size: 75%;
		vertical-align: baseline
	}
}

@include media("<=tablet") {

	.bltd--page--text {
		.bltd--wrap {
			padding: 0;
		}
		.bltd--text--content {
			padding: .5em 1.25em;
		}

		h1, h2, h3, h4, h5 {
			font-weight: 600;
		}
		h1 {
			font-size: 1.25em;
			font-weight: 600;
			margin-bottom: 0.625em;
		}
		h2 {
			font-size: 1.25em;
			margin-bottom: 0.625em;
		}
		h3 {
			font-size: 1.25em;
			margin-bottom: 20px;
		}
		h4 {
			font-size: 1.25em;
			margin-bottom: 20px;
		}
		h5 {
			font-size: 1.25em;
			margin-bottom: 20px;
		}

	}
}

.bltd--message--text {

	a.anchor {
		display: block;
		position: relative;
		top: -90px;
		visibility: hidden;
	}
	.bltd--text--content {
		background: #fff;

		border-radius: 0.25em;
		padding: 2.25em 6em;
	}
	/*ul, li {
	  @include bare-list;
	}*/
	dl, ol, ul {
		list-style: none;
		margin-top: 0;
		padding-left: 0
	}
	/*ol {
	  list-style: decimal inside
	}

	ul {
	  list-style: disc inside;
	  margin-bottom: 20px;
	}
	dl dl,dl ol,dl ul,ol dl,ol ol,ol ul,ul dl,ul ol,ul ul {

	  margin: 1.5rem 0 1.5rem 3.0rem
	}

	dd, dt, li {
	  margin-bottom: .25em;
	}

	p {
	  margin-bottom: 1em;
	}
	h1,  h2,  h3,  h4,  h5 {
	  font-weight: 400;
	}
	h1 {
	  margin-bottom: 0.625em;
	}
	h2 {
	  margin-bottom: 0.625em;
	}
	h3 {
	  margin-bottom: 20px;
	}
	h4 {
	  margin-bottom: 20px;
	}
	h5 {
	  margin-bottom: 20px;
	}

	p ~ h1 {
	  margin-top: 0.5em;
	}
	p ~ h2 {
	  margin-top: 0.5em;
	}*/

	p {
		margin-bottom: 1em;
		line-height: 1.5em;
	}

	h1, h2, h3, h4, h5 {
		margin-top: 1em;
		font-weight: normal;
		line-height: 1.2
	}

	h1 {

		margin: 0 0 1em;
		line-height: 1.1
	}

	h1 + p {
		margin-top: -0.5em
	}

	h2 {

		margin-bottom: .5em
	}

	h3 {

		margin-bottom: .2em
	}

	h4 {

		margin-bottom: .3em
	}

	h5 {
		font-size: 100%;
		margin-bottom: .5em
	}

	h2:first-child, h3:first-child, h4:first-child, h5:first-child, h1 + h2, h2 + h3, h3 + h4, h4 + h5 {
		margin-top: 0
	}

	h2 + h3, h3 + h4 {
		margin-top: .8em
	}

	menu, ul, ol {
		list-style-type: none
	}

	ul, ol {
		margin-bottom: 1em;
		counter-reset: item
	}

	ul li, ol li {
		margin-bottom: .5em
	}

	ul ul, ul ol, ol ul, ol ol {
		margin-bottom: .5em;
		margin-top: .5em;
	}

	ul p, ol p {
		margin-bottom: .5em
	}

	ul > li {
		margin-bottom: .5em;
		padding-left: 1.3em
	}

	ul > li:before {
		content: '\2022\a0';
		float: left;
		margin-left: -1.3em
	}

	ol > li {
		margin-left: 1.6em
	}

	ol > li:before {
		font-size: 87%;
		position: absolute;
		margin: .2em 0 0 -1.7em;
		content: counter(item) ".";
		counter-increment: item;
		color: #888
	}

	p + ul, p + ol {
		margin-top: -0.5em
	}

	ul p + ul, ol p + ul, ul p + ol, ol p + ol {
		margin-top: -0.2em
	}

	h2 + ul, h3 + ul, h4 + ul {
		margin-top: 1em
	}

	sup, sub {
		position: relative;
		top: -0.5em;
		font-size: 75%;
		vertical-align: baseline
	}
}

.bltd--message--text {
	.text--link {
		font-size: 1.125em;
		font-weight: 600;
		margin: 0 0 1.05em 0;
		border-bottom: 1px dashed;
		display: inline-block;
		cursor: pointer;
	}
	.bltd--text {
		display: none;
	}
	&.bltd--expanded {
		.bltd--text {
			display: block;
		}
	}

}

@include media("<=tablet") {
	.bltd--message--text {
		.bltd--wrap {
			padding: 0;
		}
		.bltd--text--content {
			padding: 0;
			padding-left: 1.25em;
			padding-right: 1.25em;
		}

		h1, h2, h3, h4, h5 {
			font-weight: 600;
		}
		h1 {
			font-size: 1.25em;
			font-weight: 600;
			margin-bottom: 0.625em;
		}
		h2 {
			font-size: 1.25em;
			margin-bottom: 0.625em;
		}
		h3 {
			font-size: 1.25em;
			margin-bottom: 20px;
		}
		h4 {
			font-size: 1.25em;
			margin-bottom: 20px;
		}
		h5 {
			font-size: 1.25em;
			margin-bottom: 20px;
		}

	}
}