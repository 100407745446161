.bltd{
	&--data-table-container{
		padding: 0 6em 3.25em;
	}
	&--data-table{
		width: 100%;
		border-collapse: collapse;
		&-tfoot{
			font-weight: 600;
			color: #4A4A4A;
		}
		&-return{
			color: #DD4B39;
		}
		&-result{
			font-weight: 600;
			color: #4A4A4A;
			td:last-child{
				font-size: 1.2em;
			}
		}
		&-thead{
			border-top: 1px solid #e5e4de;
			background: #f9f9f8;
		}
		&-th{
			font-weight: 600;
			color: #4A4A4A;
			text-align: center;
		}
		&-td, &-th{
			border-bottom: 1px solid #e5e4de;
			border-left: 1px solid #e5e4de;
			padding: 8px;
			&:first-child{
				border-left: none;
			}
		}
		&-td{
			&:first-child{
				text-align: right;
			}
		}
	}
}