// All Elements
*,
*:before,
*:after {
  background: transparent;
  color: #000;
  box-shadow: none;
  text-shadow: none;
}

// Links
a, a:visited { text-decoration: underline; }
a[href]:after { content: " (" attr(href) ")"; }
a[href^="#"]:after, a[href^="javascript:"]:after { content: ""; }

// Code and Blockquotes
pre, blockquote { page-break-inside: avoid; }

// Tables
thead { display: table-header-group; }
tr { page-break-inside: avoid; }

// Images
img { page-break-inside: avoid; max-width: 100%;}

// Typography
p, h2, h3 { orphans: 3; widows: 3; }
h2, h3 { page-break-after: avoid; }
abbr[title]:after { content: " (" attr(title) ")"; }