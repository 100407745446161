.bltd--body{
  height: 100%;
}

.bltd--promo {
  .bltd--promo-item {
    line-height: 1.4;
    min-height: 7rem;
    position: relative;
    float: left;
    width: 33%;
    &:first-child {
      .bltd--block {
        margin-left: 0;
      }
    }
    .bltd--block {
      position: relative;
      padding-left: 4.8125em;
      margin-left: 2.5em;
    }
    p {
      color: #FFFFFF;
      font-size: 1.125em;
      line-height: 1.5em;
      text-shadow: 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.25);
    }
    .bltd--image_container {
      position: absolute;
      left: 0;
      @include rem('width', 5.8);
      @include rem('height', 5.8);
      text-align: center;

      i {
        display: block;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-size: contain;
        &.bltd--i1 {
          background: url('../img/icon_1.svg') no-repeat 0 0 scroll;
          background-size: contain;
        }
        &.bltd--i2 {
          background: url('../img/icon_2.svg') no-repeat 0 0 scroll;
          background-size: contain;
        }
        &.bltd--i3 {
          background: url('../img/icon_3.svg') no-repeat 0 0 scroll;
          background-size: contain;
        }
      }
    }
  }
}

@include media('<=tablet') {
  .bltd--promo {
    .bltd--promo-item {
      float: none;
      width: auto;
      margin-bottom: 2rem;
      padding: 0 1.25em;
      .bltd--block {
        position: relative;
        @include rem('margin-left', 0);
      }
      p {
        color: #FFFFFF;
        @include rem('font-size', 2rem);
        @include rem('line-height', 2.4rem);
        text-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.25);
      }
      .bltd--image_container {
        @include rem('width', 5.4);
        @include rem('height', 5.4);
      }
    }
  }
}

.popular {
  h3 {
    text-align: center;
    margin-bottom: 1.5em;
    font-size: 1.25em;
    line-height: 1.5;
  }
  .bltd--columns {
    margin-right: -5%;
    .bltd--column {
      float: left;
      width: 20%;
      margin-right: 5%;
    }
  }
}

.popular-wrapper {
  width: 100%;
  overflow: hidden;
}

@include media('<=tablet') {
  .popular {
    .bltd--columns {
      width: 100%;

      .bltd--column {
        display: none;
        float: none;
        width: 100%;
        &:nth-child(1) {
          display: block;
        }
      }

    }
  }
}

.popular-route {
  .bltd--t {
    padding-bottom: 1.5em;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    background-image: none;
    display: table;
    table-layout: fixed;
    position: relative;
    .c {
      display: table-cell;
      font-size: .85em;

      overflow: hidden;
      text-overflow: ellipsis;

      vertical-align: top;
      box-sizing: border-box;
      width: 80%;
    }
    .bltd--r {
      text-align: right;
      font-size: .75em;
      width: auto;
    }
  }

  .rubl {
    color: #9B9B9B;
  }
}
