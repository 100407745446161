// ==========================================================================
// Variables
// ==========================================================================
$base-green-color:                              #82b548;
$base-green-background-color:                   #82b548;
$base-green-background-color-hover:             #79a943;
$base-gray-border:                              #CDCDCD;

$base-white-color:                              #ffffff;
$base-white-color-hover:                        #ffffff;

$base-button-link-background:                   #82b548;
$base-button-link-background-hover:             #79a943;

$base-button2-link-color:                       #666666;
$base-button2-link-color-hover:                 #000;

$base-background-color:                         #fff;

$base-green-bg:                                 #8fc354;
$base-green-bg-dark:                            #467d06;
$base-border-green:                             #74a240;
$base-input-bg-green:                           #589117;
$base-input-bg-green-active:                    #3d6510;

$booker-submit-light:                           #B46CC1;
$booker-submit-dark:                            #783683;
$booker-submit-hover:                           #4B1255;

// Global Typography
$base-background-color:                         #fff;
$base-font-family:                              "Open Sans", Helvetica, Arial, sans-serif;
$base-font-size:                                10;
$base-line-height:                              15;
$base-font-weight:                              400;
$base-color:                                    #4A4A4A;
$base-font-color:                               #4A4A4A;
$base-link-color:                               #3F88CC;
$base-link-color-hover:                         #2859CC;
$white:                                         #fff;
// Font Weights
$font-100:                                      100;
$font-200:                                      200;
$font-300:                                      300;
$font-400:                                      400;
$font-500:                                      500;
$font-600:                                      600;
$font-700:                                      700;
$font-800:                                      $font-600;//800;
$font-900:                                      900;
$border-radius:                                 0.25em;
// Headings
$heading-font-family:                           "Open Sans", Helvetica, Arial, sans-serif;

$h1-font-size:                                  34;
$h1-line-height:                                50;
$h1-font-weight:                                $font-400;
$h1-color:                                       $base-color;

$h2-font-size:                                  32;
$h2-line-height:                                20;
$h2-font-weight:                                $font-400;
$h2-color:                                     #666;

$h3-font-size:                                  22;
$h3-line-height:                                28;
$h3-font-weight:                                $font-400;
$h3-color:                                      $base-color;

$h4-font-size:                                  20;
$h4-line-height:                                30;
$h4-font-weight:                                $font-300;
$h4-color:                                      $base-color;

$h5-font-size:                                  16;
$h5-line-height:                                22;
$h5-font-weight:                                $font-400;
$h5-color:                                      $base-color;

$h6-font-size:                                  14;
$h6-line-height:                                20;
$h6-font-weight:                                $font-400;
$h6-color:                                      $base-color;

// Blockquotes
$base-blockquote-font-family:                   "Open Sans", Helvetica, Arial, sans-serif;
$base-blockquote-font-size:                     22;
$base-blockquote-line-height:                   28;
$base-blockquote-color:                         #424242;
$base-blockquote-cite-color:                    #777;

// Code
$base-code-font-family:                         "Open Sans", Helvetica, Arial, sans-serif;
$base-code-font-size:                           13;
$base-code-line-height:                         18;
$base-code-color:                               #272727;
$base-code-background-color:                    #f1f1f1;
$base-code-border-color:                        #d7d7d7;

// Forms
$base-placeholder-color:                        #999;

// Grid Containers
$container-mobile:                              100%;
$container-tablet:                              720px;
$container-desktop:                             960px;
$container-hd:                                  1120px;

// Responsive Breakpoints
$breakpoint-tablet:                             ($container-tablet + 20);
$breakpoint-desktop:                            ($container-desktop + 20);
$breakpoint-hd:                                 ($container-hd + 20);

$emSize   : 14px;


/*-------------------
       Em Sizes
--------------------*/
$miniSize        : (10 / 14);
$tinySize        : (12 / 14);
$smallSize       : (13 / 14);
$mediumSize      : (14 / 14);
$largeSize       : (16 / 14);
$bigSize         : (18 / 14);
$hugeSize        : (20 / 14);
$massiveSize     : (24 / 14);
/*
  This rounds $size values to the closest pixel then expresses that value in (r)em.
  This ensures all size values round to exact pixels
*/
/*$mini            : unit( round($miniSize * $emSize) / $emSize, rem);
$tiny            : unit( round($tinySize * $emSize) / $emSize, rem);
$small           : unit( round($smallSize * $emSize) / $emSize, rem);
$medium          : unit( round($mediumSize * $emSize) / $emSize, rem);
$large           : unit( round($largeSize * $emSize) / $emSize, rem);
$big             : unit( round($bigSize * $emSize) / $emSize, rem);
$huge            : unit( round($hugeSize * $emSize) / $emSize, rem);
$massive         : unit( round($massiveSize * $emSize) / $emSize, rem);

*//* em *//*
$relativeMini    : unit( round($miniSize * $emSize) / $emSize, em);
$relativeTiny    : unit( round($tinySize * $emSize) / $emSize, em);
$relativeSmall   : unit( round($smallSize * $emSize) / $emSize, em);
$relativeMedium  : unit( round($mediumSize * $emSize) / $emSize, em);
$relativeLarge   : unit( round($largeSize * $emSize) / $emSize, em);
$relativeBig     : unit( round($bigSize * $emSize) / $emSize, em);
$relativeHuge    : unit( round($hugeSize * $emSize) / $emSize, em);
$relativeMassive : unit( round($massiveSize * $emSize) / $emSize, em);

*//* rem *//*
$absoluteMini    : unit( round($miniSize * $emSize) / $emSize, rem);
$absoluteTiny    : unit( round($tinySize * $emSize) / $emSize, rem);
$absoluteSmall   : unit( round($smallSize * $emSize) / $emSize, rem);
$absoluteMedium  : unit( round($mediumSize * $emSize) / $emSize, rem);
$absoluteLarge   : unit( round($largeSize * $emSize) / $emSize, rem);
$absoluteBig     : unit( round($bigSize * $emSize) / $emSize, rem);
$absoluteHuge    : unit( round($hugeSize * $emSize) / $emSize, rem);
$absoluteMassive : unit( round($massiveSize * $emSize) / $emSize, rem);

$relative1px  : unit( (1 / $emSize), em);
$relative2px  : unit( (2 / $emSize), em);
$relative3px  : unit( (3 / $emSize), em);
$relative4px  : unit( (4 / $emSize), em);
$relative5px  : unit( (5 / $emSize), em);
$relative6px  : unit( (6 / $emSize), em);
$relative7px  : unit( (7 / $emSize), em);
$relative8px  : unit( (8 / $emSize), em);
$relative9px  : unit( (9 / $emSize), em);
$relative10px : unit( (10 / $emSize), em);
$relative11px : unit( (11 / $emSize), em);
$relative12px : unit( (12 / $emSize), em);
$relative13px : unit( (13 / $emSize), em);
$relative14px : unit( (14 / $emSize), em);*/

/* Responsive */
$mobileBreakpoint            : 320px;
$tabletBreakpoint            : 768px;
$computerBreakpoint          : 992px;
$largeMonitorBreakpoint      : 1200px;
$widescreenMonitorBreakpoint : 1920px;

$largestMobileScreen : ($tabletBreakpoint - 1px);
$largestTabletScreen : ($computerBreakpoint - 1px);
$largestSmallMonitor : ($largeMonitorBreakpoint - 1px);
$largestLargeMonitor : ($widescreenMonitorBreakpoint - 1px);
/* Columns */

$columnCount: 16;
$oneWide        : (1 / $columnCount * 100%);
$twoWide        : (2 / $columnCount * 100%);
$threeWide      : (3 / $columnCount * 100%);
$fourWide       : (4 / $columnCount * 100%);
$fiveWide       : (5 / $columnCount * 100%);
$sixWide        : (6 / $columnCount * 100%);
$sevenWide      : (7 / $columnCount * 100%);
$eightWide      : (8 / $columnCount * 100%);
$nineWide       : (9 / $columnCount * 100%);
$tenWide        : (10 / $columnCount * 100%);
$elevenWide     : (11 / $columnCount * 100%);
$twelveWide     : (12 / $columnCount * 100%);
$thirteenWide   : (13 / $columnCount * 100%);
$fourteenWide   : (14 / $columnCount * 100%);
$fifteenWide    : (15 / $columnCount * 100%);
$sixteenWide    : (16 / $columnCount * 100%);

$oneColumn      : (1 / 1 * 100%);
$twoColumn      : (1 / 2 * 100%);
$threeColumn    : (1 / 3 * 100%);
$fourColumn     : (1 / 4 * 100%);
$fiveColumn     : (1 / 5 * 100%);
$sixColumn      : (1 / 6 * 100%);
$sevenColumn    : (1 / 7 * 100%);
$eightColumn    : (1 / 8 * 100%);
$nineColumn     : (1 / 9 * 100%);
$tenColumn      : (1 / 10 * 100%);
$elevenColumn   : (1 / 11 * 100%);
$twelveColumn   : (1 / 12 * 100%);
$thirteenColumn : (1 / 13 * 100%);
$fourteenColumn : (1 / 14 * 100%);
$fifteenColumn  : (1 / 15 * 100%);
$sixteenColumn  : (1 / 16 * 100%);

$relativeBorderRadius: 4px;
$absoluteBorderRadius: 4px;
$defaultDuration : 0.1s;
$defaultEasing   : ease;
$defaultBorderRadius: $absoluteBorderRadius;
$disabledOpacity: 0.3;
$borderColor               : rgba(34, 36, 38, 0.15);
$negativeColor           : #FF4136;
$negativeBackgroundColor : #FFF6F6;
$negativeColorHover   : saturate(darken($negativeColor, 5), 10);
$negativeBorderColor     : #E0B4B4;
$negativeTextColor       : #9F3A38;
$selectedTextColor           : rgba(0, 0, 0, 0.95);
$selectedBorderColor       : rgba(34, 36, 38, 0.35);
$formErrorColor: $negativeTextColor;
$formErrorBorder: $negativeBorderColor;

$inputColor: $base-font-color;
/*******************************
             Form
*******************************/

/*-------------------
       Elements
--------------------*/


/* Text */
$paragraphMargin: 1em 0em;

/* Field */
$fieldMargin: 0em 0em 1em;

/* Form Label */

$labelFontSize: 1em;
$labelTextTransform: uppercase;

$groupedLabelTextTransform: none;

/* Input */
$inputHorizontalPadding: 0.5em;
$inputBackground: transparent;
$inputBorder: none;
$inputBorderRadius: 0em;
$inputBoxShadow: none;

$inputVerticalPadding   : 0.5em;
$inputLineHeight: 1.2142em;


$textAreaPadding: 1em;
$textAreaBackground: transparent;
$textAreaFocusBackground: #EEEEEE;
$textAreaBorder: 1px solid #DDDDDD;

/* Divider */
$dividerMargin: 1em 0em;

/* Validation Prompt */
$validationMargin: 0em 0em 0em 1em;
$validationArrowOffset: -0.3em;

/*-------------------
        States
--------------------*/

/* Disabled */

/* Focus */
$inputFocusPointerSize: 0px;
$inputErrorPointerSize: 0px;

/* Dropdown Error */
$dropdownErrorHoverBackground: #FFF2F2;
$dropdownErrorActiveBackground: #FDCFCF;

/* Focused Error */
$inputErrorFocusBackground: $negativeBackgroundColor;
$inputErrorFocusColor: $negativeColorHover;
$inputErrorFocusBorder: $negativeBorderColor;
$inputErrorFocusBoxShadow: $inputErrorPointerSize 0em 0em 0em $negativeColorHover inset;

/* Placeholder */
$inputPlaceholderColor: lighten($inputColor, 55);
$inputPlaceholderFocusColor: lighten($inputColor, 35);
$inputErrorPlaceholderColor: lighten($formErrorColor, 10);
$inputErrorPlaceholderFocusColor: lighten($formErrorColor, 5);

/* Loading */
$formLoaderDimmerColor: rgba(255, 255, 255, 0.6);
$formLoaderPath: "${imagePath}/loader-large.gif";
$formLoaderPosition: 50% 50%;

/* (x) Wide Field */
$gutterWidth: 1.5em;

/* Form */
$gutterWidth: 1em;
$rowDistance: 1em;

/* Text */
$paragraphMargin: $rowDistance 0em;

/* Field */
$fieldMargin: 0em 0em $rowDistance;

/* Form Label */
$labelDistance: 4px;
$labelMargin: 0em 0em $labelDistance 0em;
$labelFontSize: .75em;
$labelFontWeight: bold;
$labelTextTransform: none;
$labelColor: $base-font-color;

/* Input */
$inputFont: $base-font-family;
$inputWidth: 100%;
$inputFontSize: 1em;
$inputPadding: ($inputVerticalPadding + ((1em - $inputLineHeight) / 2)) $inputHorizontalPadding;
$inputBorder: 1px solid $borderColor;
$inputBorderRadius: $absoluteBorderRadius;
$inputColor: $base-font-color;
$inputTransition:
color $defaultDuration $defaultEasing,
border-color $defaultDuration $defaultEasing
;
$inputBoxShadow: 0em 0em 0em 0em transparent inset;

/* Select */
$selectBackground: $white;
$selectBorderRadius: $inputBorderRadius;
$selectBorder: $inputBorder;
$selectPadding: 0.62em $inputHorizontalPadding;
$selectBoxShadow: $inputBoxShadow;
$selectTransition: $inputTransition;
$selectColor: $inputColor;

/* Text Area */
$textAreaFontSize: $inputFontSize;
$textAreaPadding: $inputVerticalPadding $inputHorizontalPadding;
$textAreaHeight: 12em;
$textAreaResize: vertical;
$textAreaLineHeight: 1.2857;
$textAreaMinHeight: 8em;
$textAreaMaxHeight: 24em;
$textAreaBackground: $inputBackground;
$textAreaBorder: $inputBorder;
$textAreaFontSize: $inputFontSize;
$textAreaTransition: $inputTransition;

/* Checkbox */
$checkboxVerticalAlign: top;
$checkboxLabelFontSize: 1em;
$checkboxLabelTextTransform: $labelTextTransform;

/* Inline Validation Prompt */
$promptBackground: $white;
$promptBorderColor: $formErrorBorder;
$promptBorder: 1px solid $promptBorderColor;
$promptTextColor: $formErrorColor;
$inlinePromptMargin: -0.25em 0em -0.5em 0.5em;
$inlinePromptBorderWidth: 1px;

/*-------------------
        States
--------------------*/

/* Focus */
$inputFocusPointerSize: 0px;

/* Input Focus */
$inputFocusBackground: $inputBackground;
$inputFocusBorderColor: #85B7D9;
$inputFocusColor: $selectedTextColor;
$inputFocusBoxShadow: $inputFocusPointerSize 0em 0em 0em $selectedBorderColor inset;
$inputFocusBorderRadius: $inputBorderRadius;

/* Text Area Focus */
$textAreaFocusBackground: $inputFocusBackground;
$textAreaFocusBorderColor: $inputFocusBorderColor;
$textAreaFocusColor: $inputFocusColor;
$textAreaFocusBoxShadow: $inputFocusBoxShadow;
$textAreaFocusBorderRadius: $inputFocusBorderRadius;

/* Disabled */
$disabledLabelOpacity: $disabledOpacity;

/* Errored Input */
$formErrorColor: $negativeTextColor;
$formErrorBorder: $negativeBorderColor;
$formErrorBackground: $negativeBackgroundColor;

/* AutoFill */
$inputAutoFillBackground: #FFFFF0;
$inputAutoFillBorder: #E5DFA1;
$inputAutoFillFocusBackground: $inputAutoFillBackground;
$inputAutoFillFocusBorder: #D5C315;
$inputAutoFillErrorBackground: #FFFAF0;
$inputAutoFillErrorBorder: #E0B4B4;


/* Input Error */
$inputErrorBorderRadius: '';
$inputErrorBoxShadow: none;

/* Dropdown Error */
$dropdownErrorHoverBackground: #FBE7E7;
$dropdownErrorSelectedBackground: $dropdownErrorHoverBackground;
$dropdownErrorActiveBackground: #FDCFCF;
$dropdownErrorLabelBackground: #EACBCB;
$dropdownErrorLabelColor: $negativeTextColor;

/* Focused Error */
$inputErrorFocusBackground: $negativeBackgroundColor;
$inputErrorFocusColor: $negativeTextColor;
$inputErrorFocusBorder: $negativeBorderColor;
$inputErrorFocusBoxShadow: none;

/* Placeholder */
$inputPlaceholderColor: lighten($inputColor, 55);
$inputPlaceholderFocusColor: lighten($inputColor, 35);

/* Placeholder Error */
$inputErrorPlaceholderColor: lighten($formErrorColor, 40);
$inputErrorPlaceholderFocusColor: lighten($formErrorColor, 30);

/* Loading Dimmer */
$loaderDimmerColor: rgba(255, 255, 255, 0.8);
$loaderDimmerZIndex: 100;

/* Loading Spinner */
$loaderSize: 3em;
$loaderLineZIndex: 101;

/*-------------------
        Types
--------------------*/

/* Required */
$requiredContent: '*';
$requiredColor: $negativeColor;
$requiredVerticalOffset: -0.2em;
$requiredDistance: 0.2em;
$requiredMargin: $requiredVerticalOffset 0em 0em $requiredDistance;

/* Inverted */
$invertedInputBackground: $inputBackground;
$invertedInputBorderColor:  rgba(255, 255, 255, 0.1);
$invertedInputBoxShadow: $inputBoxShadow;
$invertedInputColor: $inputColor;
$invertedLabelColor: rgba(255, 255, 255, 0.9);
$invertedInputBoxShadow: none;

/*-------------------
      Variations
--------------------*/

/* Grouped Fields */
$groupedMargin: $fieldMargin;
$groupedFieldMargin: 0.5em 0em;

$groupedLabelDistance: $labelDistance;
$groupedLabelColor: $labelColor;
$groupedLabelMargin: $labelMargin;
$groupedLabelFontSize: $labelFontSize;
$groupedLabelFontWeight: $labelFontWeight;
$groupedLabelTextTransform: $labelTextTransform;


/* Inline */
$inlineInputSize: 1em;

$inlineLabelDistance: 1em;
$inlineLabelColor: $labelColor;
$inlineLabelFontSize: $labelFontSize;
$inlineLabelFontWeight: $labelFontWeight;
$inlineLabelTextTransform: $labelTextTransform;

$groupedInlineLabelMargin: 0.035714em 1em 0em 0em;

/*-------------------
       Groups
--------------------*/

$inlineFieldsMargin: 0em 1em 0em 0em;

$px1 : (1px/16px * 1em);
$px2 : (2px/16px * 1em);
$px3 : (3px/16px * 1em);
$px4 : (4px/16px * 1em);
$px8 : (10px/16px * 1em);
$px10 : (10px/16px * 1em);
$px12 : (12px/16px * 1em);
$px13 : (13px/16px * 1em);
$px14 : (14px/16px * 1em);
$px15 : (15px/16px * 1em);
$px16 : (16px/16px * 1em);
$px18 : (18px/16px * 1em);
$px19 : (19px/16px * 1em);
$px20 : (20px/16px * 1em);
$px25 : (22px/16px * 1em);
$px22 : (25px/16px * 1em);
$px26 : (26px/16px * 1em);
$px28 : (28px/16px * 1em);
$px30 : (30px/16px * 1em);
$px34 : (34px/16px * 1em);
$px40 : (40px/16px * 1em);
$px50 : (50px/16px * 1em);
$px60: (60px/16px * 1em);
$px80 : (80px/16px * 1em);
$px180 : (180px/16px * 1em);
