
.bltd--hamburger-menu {
  @include transition-ease(0.3s);
  background-color: #fff;
  display: block;
  height: 0.125em;
  left: 50%;
  margin-left: -50%;
  position: absolute;
  top: 50%;
  width: 100%;
  & .nav-copy {
    margin-top: 10px;
    display: block;
  }
  &:after, &:before {
    @include transition-ease(0.3s);
    background-color: #fff;
    content: ' ';
    display: block;
    height: 0.125em;
    left: 50%;
    margin-left: -50%;
    margin-top: 0;
    position: absolute;
    top: -0.3125em;;
    width: 100%;
  }
  &:after {
    top: 0.3125em;
  }
}
header.bltd--header--index {
  .bltd--is-nav-expanded-active {
    .bltd--hamburger-menu {
      background: none;
    }
  }
  .bltd--hamburger-menu {
    background-color: var(--base-green-color);
    &:after, &:before {
      background-color: var(--base-green-color);
    }

  }
}


.bltd--is-nav-expanded-active {
  .bltd--hamburger-menu {
    background: none;
    &:after, &:before {
      top: 0
    }
    &:after {
      @include rotate(45)

    }
    &:before {
      @include rotate(-45)

    }
  }

}
