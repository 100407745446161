//test
.bltd--modal-payment {
	min-height: 200px;
	.bltd--switcher {
		margin-bottom: 1.5em;
	}
}

.iframe {
	header {
		position: relative;
		width: 100%;
		z-index: 1400;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}
	.h2x .bltd--main_content {
		padding-top: 0em;
	}

	.bltd--main_content {
		margin: 0 auto;
		padding-top: 0em;
	}
}

.bltd--relative--loader_container {
	min-height: 100px;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	left: 0;
	right: 0;

	.bltd--relative--loader {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 999;
		left: 0;
		right: 0;
		bottom: 0;
		&.bltd--no-text {
			.bltd--spinner-wrap {
				margin-top: 0;
			}
		}
	}
}

.bltd--global--loader_container {
	min-height: 400px
}

.bltd--global--loader {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	left: 0;
	right: 0;
	bottom: 0;
	&.bltd--absolute {
		position: absolute;
	}
	&.bltd--no-text {
		.bltd--spinner-wrap {
			margin-top: 0;
		}
	}
	.bltd--loader.bltd--big {
		left: 50%;
		top: 50%;
		margin-left: -6px;
		margin-top: -6px;
	}
	&.bltd--global--loader_nonblock {
		position: absolute;
		.bltd--spinner-bg {
			display: none;
		}
		.bltd--spinner-wrap {
			margin-top: 0;
		}
		.bltd--global--loader_container {
			position: relative;
			min-height: 400px
		}
	}
	&.bltd--global--loader_relative {
		position: absolute;
	}
}

.bltd--page--route {
	.bltd--workspace {
		background: #F8F7F0;
	}
}

.bltd--heading {
	.bltd--heading--wrapper {
		margin-bottom: 2.5em;
	}
}

.bltd--heading--menu {
	position: relative;
	background: #fff;
	width: 100%;
	.bltd--heading--menu-wrapper {
		padding: 0 1.825em;
		position: relative;
		background: #fff;
		width: 100%;
		max-width: 1280px;
		margin: 0 auto;
	}
}

@include media("<=tablet") {
	.bltd--heading--menu {
		display: none;
	}
}

.bltd--switcher {
	.bltd--tab {
		display: inline-block;
		padding: 0.625em 1.25em 0.25em;
		@include border-radius(.25em .25em 0 0);
		.bltd--date {
			margin-bottom: 0.25em;
			color: var(--base-link-color);
		}
		.bltd--counter {
			font-size: 0.8125em;
			color: #666;
			font-weight: normal;
		}
		&.bltd--tab_pressed, &.bltd--hover, &:not(.tabFilter):hover {
			cursor: pointer;
			background-color: #f8f7f0;
			color: var(--base-font-color);

			.bltd--date:not(.filterDate) {
				color: var(--base-font-color);
			}

			&.bltd--tab_pressed {
				font-weight: bold;
			}
		}
	}
}

.bltd--rlist {
	background: none;
	border-radius: 0.25em;
	margin-bottom: 1em;

	.bltd--rlist--header {
		padding: 0 1.5%;
		margin-top: .25em;

		.bltd--rlist--header-wrapper {
			width: 100%;
			border-radius: 0;
			box-shadow: none;
			background-image: none;
			display: table;
			table-layout: fixed;
			position: relative;
			padding-bottom: .625em;
			.bltd--th {
				display: table-cell;
				padding: 0 1%;
				vertical-align: top;
				box-sizing: border-box;
				white-space: nowrap;
				font-size: .8125em;
				font-weight: 600;
				overflow: hidden;
				text-overflow: ellipsis;
				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
			}
		}
	}

	.bltd--route--num {
		width: 70px;
	}
	.bltd--route--name {
		width: 34%;
		position: relative;
	}
	.bltd--route--timestamp {
		width: 30%;
		.bltd--inner {
			display: table;
			table-layout: fixed;
			width: 100%;
			position: relative;
			overflow-y: visible;

			.bltd--department {
				width: 50%;
				display: table-cell;
				overflow: hidden;
				text-overflow: ellipsis;

				.bltd--main-line {
					font-weight: bold;
				}
			}
			.bltd--arrival {
				width: 47%;
				padding-left: 3%;
				display: table-cell;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.bltd--route--spent-time {
		width: 12%;
	}
	.bltd--route--days {
		width: 12%;
	}
	.bltd--td {
		&.bltd--route--link {
			.bltd--route--link-wrapper {
				min-height: 2.5em; /*875*/
			}
			.bltd--route--link-inner {
				min-height: 2.5em;
			}
		}
	}

	.bltd--route--link {
		.bltd--loader {
			position: absolute;
			top: 1em;
			left: 0;
			right: 0;
		}
		width: 28rem;
		.bltd--route--link-wrapper {
			position: relative;
		}
		.bltd--route--link-inner {
			margin-right: 16.5rem;
			text-align: right;
			position: relative;
			.bltd--ellipsis-loader {
				&:after {
					overflow: hidden;
					display: inline-block;
					vertical-align: bottom;
					-webkit-animation: ellipsis steps(4, end) 900ms infinite;
					animation: ellipsis steps(4, end) 900ms infinite;
					content: "\2026"; /* ascii code for the ellipsis character */
					width: 0px;
				}
				@keyframes ellipsis {
					to {
						width: 20px;
					}
				}
				@-webkit-keyframes ellipsis {
					to {
						width: 20px;
					}
				}
			}
			.bltd--transit {
				overflow: visible;
				text-align: right;
				margin-top: 0;
				white-space: normal;

				.bltd--lnk {
					border-bottom: 1px dashed #666666;
					line-height: 1.15em;
					display: inline-block;
					cursor: pointer;
				}
			}
		}
		.bltd--route--link-submit {
			//width: 16.5rem;
			position: absolute;
			right: 0;
			top: 0; /*.1875em*/
			bottom: 0;
			text-align: right;
		}
	}

	.bltd--td--error {
		margin-top: 0;
		white-space: normal;
		line-height: 23px;
		text-align: right;
		z-index: 100;
		opacity: 0.65;
		position: relative;
		height: 2.5em;
		overflow: hidden;
		padding-right: 10px;
		span {
			text-align: left;
			display: block;
			overflow: hidden;
			margin-top: -0.25em;
		}
	}

	.bltd--icon--time {
		position: relative;
		width: 1em;
		height: 1em;
		display: inline-block;
		vertical-align: middle;
		margin-top: 3px;
		margin-left: .25em;
		font-size: 16px;
		&:after {
			content: '';
			background-image: url(../img/icons/icons.svg);
			background-size: 10.5625em 17.3125em;
			position: absolute;
			width: 1em;
			height: 1em;
			background-position: -6.75em -15.625em;
			top: 0;
			left: 0;
		}
	}

	.bltd--sortable {
		cursor: pointer;

		.bltd--l {
			display: inline-block;
			line-height: 19px;
		}
		.bltd--lnk {
			border-bottom: 1px dashed #4A4A4A;
		}
		.bltd--sort {
			position: relative;
			&:before {
				content: '';
				color: rgba(102, 102, 102, 0.75);
				border-left: .35em solid transparent;
				border-right: .35em solid transparent;
				border-top: .35em solid;
				margin: -0.25em 0 0 0.5em;
				position: absolute;
				top: 50%;
				height: 0;
				width: 0;
				right: 0;
			}
			&.bltd--desc {
				&:before {
					border-bottom: 0.35em solid rgba(102, 102, 102, 0.75);
					border-top: 0;
				}
			}
		}
		.bltd--w {
			position: relative;
			white-space: nowrap;
			overflow: hidden;
			.bltd--txt {
				text-overflow: ellipsis;
				white-space: nowrap;
				display: block;
				overflow: hidden;
				margin-right: 1em;
			}
			.bltd--ico {
				position: absolute;
				left: 100%;
				top: .25em;
				width: 1em;
				height: 1.2em;
				margin-left: -1em;
				z-index: 100;
			}
		}
	}

	.bltd--rlist--content {
		background: #fff;
		padding: 0;
		box-sizing: border-box;
		position: relative;

		.bltd--rlist--content-route {
			position: relative;

			.bltd--rlist--overlay {
				box-shadow: none;
				transition: box-shadow 0.5s;
			}

			&.bltd--expand {
				.bltd--rlist--overlay {
					position: absolute;
					top: -0.125em;
					bottom: -0.125em;
					left: -0.125em;
					right: -0.125em;
					background-color: #fff;
					border-radius: 4px;
					border: 0.125em solid var(--base-green-color);
					box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
					z-index: 3;
				}
				.bltd--account--edit{
					opacity: 0;
				}
				.bltd--ctn {
					z-index: 5;
				}
			}

			.bltd--ctn {
				position: relative;
				margin: 0 1.5%;
			}
			&:before {
				content: ' ';
				visibility: hidden;
				opacity: 0;
				-webkit-transition: opacity 0.65s ease-out;
				transition: opacity 0.65s ease-out;
			}
		}
	}

	.bltd--tr {
		display: table;
		table-layout: fixed;
		width: 100%;
		position: relative;
		border-bottom: none;
		border-bottom: 0.0625em solid #efeff0;
		.bltd--td {
			&:first-child {
				padding-left: 0;
			}
			&:last-child {
				padding-right: 0;
			}
			padding: 1.125em 1%;
			box-sizing: border-box;
			display: table-cell;
			//vertical-align: middle;

			.bltd--main-line {
				//line-height: 1;
				font-weight: $font-400;
				margin-bottom: .125em;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&.bltd--route--name {
				.bltd--main-line {
					font-weight: 600;
				}
			}
			.bltd--next-line {
				color: #666;
				font-size: 0.8125em;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.bltd--expand-link {
		&.bltd--expand--decorator {
			display: block;
		}
		color: var(--base-link-color);
		cursor: pointer;
		position: relative;
		overflow: hidden;
		display: block;
		text-overflow: ellipsis;
		white-space: normal;
		.bltd--txt {
			text-overflow: ellipsis;
			white-space: normal;
			display: block;
			overflow: hidden;
			max-width: 80%;
		}
		.bltd--ico {
			position: absolute;
			left: 82%;
			top: 0;
			width: 1em;
			height: 1em;
			background: #fff;
		}
	}

	.bltd--icon--expand {
		&:after {
			content: '';
			position: absolute;
			height: 6px;
			width: 6px;
			transform: rotate(40deg) skewX(-10deg);
			border: 2px solid var(--base-link-color);
			border-top: none;
			border-left: none;
			bottom: 1px;
			left: 1px;
		}
	}

	.bltd--price-val {
		font-weight: $font-600;
	}

	.bltd--expand {
		.bltd--tr--expand {
			display: block;
			min-height: 100px;
			.bltd--error{
				color: #dd4b39;
				padding: 20px 0 0;
				text-align: center;
			}
		}
	}
	.bltd--tr--expand {
		display: none;
		.bltd--heading--sub {
			display: none;
		}
		&.bltd--expand-loading {
			.bltd--tr--expand-ctn {
				> .bltd--column {
					display: none;
				}
			}
			.bltd--tr--expand-ctn.bltd--error {
				opacity: 0;
				height: 250px;
			}
		}
		.bltd--tr--expand-ctn {
			display: table;
			table-layout: fixed;
			width: 100%;
			position: relative;
			margin: 0;

			&.bltd--error {
				padding: 1.125em 1.25em;
			}

			.bltd--column {
				width: auto;
				overflow: hidden;
				padding: 0%;
				box-sizing: border-box;
				display: table-cell;
				vertical-align: top;

				&.bltd--column--waypoint {
					width: 34%;
					padding: 1.25em 0;
					padding-right: 2.5em;
					.bltd--heading {
						display: none;
					}

					.bltd--point {
						.bltd--more {
							color: #666666;
							margin: 0;
							margin-left: 15%;
							margin-top: 1em;
							margin-bottom: 1em;

							span { /**/
								font-size: 0.8125em;
								padding: 0;
								margin: 0;
								display: block;
								font-style: italic;
								cursor: pointer;
							}
						}
						&:last-child {
							&:before {
								content: none;
							}
						}
						&:before {
							content: ' ';
							width: 0.125em;
							display: block;
							position: absolute;
							top: 1.3125em;
							bottom: -1.125em;
							left: 15%;
							margin-left: -0.0625em;
							background: var(--route-way-color);
						}
						&.bltd--end:before {
							background: #d7d6d2;
						}
						clear: both;
						margin-bottom: 1em;
						max-width: 64rem;
						min-height: 2em;
						position: relative;

						&:after {
							clear: both;
							display: block;
							content: '';
						}

						.bltd--time--destination {
							float: left;
							width: 15%;
						}
						.bltd--end .bltd--location--destination:after {
							border-color: var(--route-way-color);
						}
						.bltd--location--destination {
							float: left;
							width: 70%;
							position: relative;

							.bltd--location--destination__inner {
								margin-left: 2em;
								width: 99%;
								overflow: hidden;
								text-overflow: ellipsis;
							}

							.bltd--region {
								font-weight: normal;
							}
							&:after {
								background: #fff;
								width: 0.75em;
								height: 0.75em;
								@include border-radius(100%);
								content: ' ';
								top: 0.3125em;
								display: block;
								position: absolute;
								left: -0.375em;
								border: 3px solid var(--route-way-color);
							}
						}
						&.bltd--inner {
							.bltd--location--destination {
								&:before {
									background: #bbb;
								}
								&:after {
									border-color: var(--route-way-color);
								}
							}
						}
						&.bltd--active {
							.bltd--location {
								font-weight: $font-600;
							}
							.bltd--location--destination:after {
								border-color: var(--route-way-color);
							}
						}
						&.bltd--end .bltd--location {
							font-weight: 600;
						}
						&.bltd--inactive, &.bltd--inactive .bltd--subl {
							color: #d7d6d2;
							&:before {
								background: #d7d6d2;
							}
						}
						&.bltd--inactive {
							.bltd--location {
								&--destination {
									&:after {
										border-color: #d7d6d2;
									}
									&:before {
										background: #d7d6d2;
									}
								}
							}
						}

						.bltd--time, .bltd--location {
							font-size: .875em;
						}
						.bltd--location {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						.bltd--subl {
							font-size: 0.8125em;
							color: #666;
							margin: 0;
						}
					}

					.bltd--shortlist {
						cursor: pointer;
						min-height: unset;

						&:before {
							background: repeating-linear-gradient(180deg, var(--route-way-color), var(--route-way-color) 3px, transparent 3px, transparent 6px);
							bottom: 1.125em;
						}

						.bltd--location--destination {
							margin-left: 30%;

							&:before {
								content: ' ';
								width: 0.125em;
								display: block;
								position: absolute;
								bottom: -1.125em;
								margin-left: -0.0625em;
								background: #d7d6d2;
								height: 1.125em;
							}

							.bltd--location--destination__inner {
								.bltd--location {
									white-space: normal;

									& + .bltd--location:before {
										content: " – ";
									}

									&:last-child {
										&:after {
											background: #fff;
											width: 0.85714em;
											height: 0.85714em;
											@include border-radius(100%);
											content: ' ';
											bottom: 0.25em;
											display: block;
											position: absolute;
											left: -0.42858em;
											border: 3px solid var(--route-way-color);
										}
									}
								}
							}
						}

						&.bltd--inactive {
							&:before {
								background: repeating-linear-gradient(180deg, #d7d6d2, #d7d6d2 3px, transparent 3px, transparent 6px);
							}

							.bltd--location--destination__inner .bltd--location:last-child:after {
								border-color: #d7d6d2;
							}
						}

						&.bltd--inner .bltd--location--destination:before {
							background: var(--route-way-color);
						}
					}
				}
			}
		}

		.bltd--tr--expand-heading {
			display: none;
		}
	}
}

.bltd--page--route {
	.bltd--wrap {
		padding: 1.25em 1.85em;
		position: relative;
	}
}

@include media("<=tablet") {
	.bltd--switcher {
		display: none;
	}

	.bltd--page--route {
		.bltd--wrap {
			padding: 0;
		}
		.bltd--main--heading {
			display: none;
		}
	}

	.bltd--rlist {
		margin-bottom: 0;
		.bltd--rlist--content {
			background: none;
			//overflow: hidden;
			.bltd--rlist--content-route:nth-child(odd) {
				background-color: #f9f9f9;
			}
			.bltd--rlist--content-route {
				background: #fff;
				&.bltd--expand {
					.bltd--ctn {
						z-index: 2000;
					}
				}
				.bltd--ctn {
					margin: 0;
					padding: 0;
				}
			}
		}
		.bltd--rlist--header {
			display: none;
		}

		.bltd--tr {
			display: block;
			.bltd--td {
				padding: 1.125em 1.25em;
				box-sizing: border-box;
				display: block;
				vertical-align: middle;
				width: 100%;

				&:first-child {
					padding-left: 1.25em;
					padding-top: 0;
				}

				&:last-child {
					padding-right: 1.25em;
					padding-top: 0;
				}

				&.bltd--route--num {
					display: none !important;
				}

				&.bltd--route--timestamp {
					padding-top: 0;

					.bltd--inner {
						&:after {
							content: ' ';
							width: auto;
							height: 0.5em;
							background: url(../img/icons/route-arrow.svg) no-repeat 100% 0;
							display: block;
							position: absolute;
							top: 0;
							margin-top: 0.375em;
							right: 5rem;
							left: 5rem;
						}
						&.bltd--with-date--right {
							&.bltd--with-date--left {
								&:after {
									right: 8em;
									left: 8em;
								}
							}
							&:after {
								right: 8em;
							}
						}
						&.bltd--with-date--left {
							&:after {
								right: 4em;
								left: 8em;
							}
						}
					}
					.bltd--arrival {
						width: 50%;
						padding-left: 0;
						text-align: right;
					}
				}
				&.bltd--route--spent-time {
					display: none !important;
				}
				&.bltd--route--link {
					.bltd--route--link-inner {
						text-align: left;
						.transit {
							text-align: left;
						}
					}
				}
				&.bltd--route--name {
					.bltd--main-line {
						cursor: pointer;
						position: relative;
						padding-right: 1em;
						&:after {
							width: 1em;
							height: 1em;
							display: block;
							content: '';
							background-image: url(../img/icons/icons.svg);
							background-size: 10.5625em 17.3125em;
							background-position: -8.25em -6.4375em;
							top: 0;
							right: 0;
							position: absolute;
						}
					}
				}
			}

		}

		.bltd--expand {
			.bltd--tr--expand {
				display: block;
				position: fixed;
				top: 0;
				background: #fff;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2000;
				overflow-y: auto;

				.bltd--tr {
					margin-bottom: 0;
				}
				.bltd--tr--expand-heading {
					display: block;

					.bltd--expand--back {
						font-size: 1.25em;
						padding: 1em /*0.9375em*/
						.5em;
						background: #F8F7F0;
						margin-bottom: 1em;
						.bltd--expand--nav {
							position: relative;
							color: #4a4a4a;
							display: block;
							padding-left: 1.625em;
							font-weight: $font-600;
							&:before {
								width: 1em;
								height: 1em;
								display: block;
								content: '';
								background-image: url(../img/icons/icons.svg);
								background-size: 10.5625em 17.3125em;
								background-position: -6.9375em -9.0625em;
								top: 0;
								left: 0;
								position: absolute;
							}
						}
					}

					.bltd--expand--detail {
						position: relative;
						padding: 0;
						.bltd--td {
							padding-top: 0;
						}
					}
				}

				.bltd--tr--expand-ctn {
					display: block;
					width: 100%;
					position: relative;
					margin: 0;

					&.bltd--error {
						padding: 1.125em 1.25em;
					}

					.bltd--column {
						width: 100%;
						overflow: hidden;
						padding: 0;
						box-sizing: border-box;
						display: block;
						vertical-align: top;

						&.bltd--column--waypoint {
							width: 100%;
							padding: 1.125em 1.25em;

							.bltd--heading {
								display: block;
								.bltd--heading--wrapper {
									margin-bottom: 1.5em;
									h2 {
										font-size: 1.125em;
										font-weight: 600;
									}
								}
							}

							.bltd--point {
								clear: both;
								margin-bottom: 1em;
								max-width: 90%;
								min-height: 2em;
								position: relative;
								&:after {
									clear: both;
									display: block;
									content: '';
								}

								&:before {
									left: 30%;
								}

								.bltd--time--destination {
									float: left;
									width: 30%;
								}

								.bltd--location--destination {
									.bltd--location--destination__inner {
										width: 100%;
									}
								}
							}
						}
					}
				}
				.bltd--route--calendar-append {
					.bltd--dropdown {
						width: 100%;
					}
				}
				.bltd--route--name {
					.bltd--main-line {
						padding-right: 0;
						&:after {
							content: none;
						}
					}
				}

				.bltd--route--departure-append, .bltd--route--arrival-append, .bltd--route--duration-append {
					.bltd--main-line {

						font-size: .875em;
					}
				}
			}
			.bltd--heading--sub {
				display: block;
				padding: 0;
				.bltd--heading--wrapper {
					padding-bottom: 1.5625em;
					h2 {
						font-size: 1.125em;
						font-weight: 600;
					}
				}
			}
		}

		.bltd--tr--expand {
			display: none;
		}

		.bltd--ltime {
			color: #9b9b9b;
			padding-left: .5em;
		}
	}
}

.bltd--fontsize14 {
	font-size: 0.875em;
}

.bltd--page--ticket {
	.bltd--pblock {
		margin-bottom: 1.875em;
	}
	.bltd--nmargin {
		margin: 0 !important;
	}
	.bltd--wrap {
		padding: 1.25em 1.85em;
		padding-top: 2.5em;
	}

	.bltd--heading--sub {
		padding: 0;
		position: relative;
		background: none;
		.bltd--heading--wrapper {
			h2 {
				margin: 0
			}
		}
	}

	.bltd--workspace {
		background: #F8F7F0;
	}
	.bltd--rlist {
		background: #fff;
		border-radius: 0.25em;
		.bltd--tr {
			.bltd--td {
				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
				padding: .625em 1%;

			}
		}

		.bltd--rlist--header {

			border-bottom: 0.125em solid #F8F7F0;
			.bltd--rlist--header-wrapper {
				padding-top: .625em;
			}
		}

		.bltd--route--num {
			width: 110px;
			.bltd--fontsize14 {
				font-size: 0.875em;
			}

		}
		.bltd--route--name {
			width: 27%;

			//padding-right: 0 !important;
		}

		.bltd--route--passangers {
			text-align: right;
			width: 5%;
		}
		.bltd--route--date-payed {
			width: 155px;
		}
		.bltd--route--date {
			width: 155px;
		}
		.bltd--route--cost {
			width: 8%;
			text-align: right;
		}
		.bltd--route--status {
			width: 12%;
			//text-align: right;
		}
		.bltd--route--link {
			width: 15rem;
		}
	}

	.bltd--tr--expand-ctn {
		.bltd--heading--sub {
			display: none;
		}
	}

	.bltd--additional {
		margin-top: 1.25em;
	}
}

.bltd--ticket--list {
	background: none;

	.bltd--ticket--list-content {
		background: #fff;
		padding: 0;
		box-sizing: border-box;
		position: relative;

		.bltd--ticket {
			position: relative;

			.bltd--ticket--ctn {
				position: relative;
				margin: 0;

			}
			&:before {
				content: ' ';
				visibility: hidden;
				opacity: 0;
				-webkit-transition: opacity 0.65s ease-out;
				transition: opacity 0.65s ease-out;
			}
		}
	}

	.bltd--ticket--tr {
		display: table;
		table-layout: fixed;
		width: 100%;
		position: relative;
		border-bottom: none;
		margin-bottom: .5em;
		.bltd--ticket--td {
			&:first-child {
				padding-left: 0;
			}
			&:last-child {
				padding-right: 0;
			}
			padding: 1.125em 1%;
			box-sizing: border-box;
			display: table-cell;
			vertical-align: middle;

			.bltd--ticket--main-line {
				font-weight: $font-400;
				margin-bottom: 0;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				@include media("<=tablet"){
					white-space: normal;
				}
			}

			.bltd--ticket--next-line {
				color: #666;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				&.mbb {
					margin: 0.5em 0;
				}
			}

			&.bltd--ticket--name {
				.bltd--ticket--main-line, .bltd--ticket--next-line {
					line-height: 1.5;
				}
			}
			.bltd--ticket--main-line, .bltd--ticket--next-line {
				font-size: .875em;
				.red {
					color: #dd4b39;
				}
			}

		}
	}

	.bltd--ticket--num {
		width: 120px;
		max-width: 120px;
	}
	.bltd--ticket--name {
		width: 40%;
		.bltd--ticket--main-line, .bltd--ticket--next-line {
			line-height: 1.5;

		}
		.bltd--ticket--main-line {
			font-weight: $font-600;
		}
	}

	.bltd--ticket--passangers {
		width: 9%;
	}
	.bltd--ticket--tax {
		width: 12%;
	}
	.bltd--ticket--seat {
		width: 8%;
	}
	.bltd--ticket--bag {
		width: 8%;
		text-align: right;
	}
	.bltd--ticket--cost {
		width: 8%;
		text-align: right;
	}
}

@include media("<=tablet") {
	.bltd--ticket--list {
		background: none;

		.bltd--ticket--tr {
			display: block;
			width: 100%;
			position: relative;
			border-bottom: none;
			margin-bottom: .5em;
			.bltd--ticket--td {
				width: 100%;
				padding: 0 0 1.125em;
				box-sizing: border-box;
				display: block;
				vertical-align: middle;
				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
				&.bltd--ticket--num {
					display: none;
				}
				&.bltd--ticket--name {
					width: 100%;
					.bltd--ticket--main-line {
						font-weight: $font-600;
						margin-bottom: .5em;
					}
				}

				&.bltd--ticket--passangers, &.bltd--ticket--tax, &.bltd--ticket--seat, &.bltd--ticket--bag, &.bltd--ticket--cost {
					display: none;
				}

				.bltd--ticket--next-line {
					line-height: 1.5;
					font-size: .8125em;
					white-space: normal;
				}
				.bltd--ticket--main-line {
					font-size: .875em;
				}
			}
		}
	}

	.bltd--page--ticket {
		.bltd--wrap {
			padding-left: 0;
			padding-right: 0;
			padding-top: 1.25em;
		}
		.bltd--additional{
			padding: 0 1.25em;
		}
		.bltd--heading--sub {
			display: block;
			position: relative;
			background: none;
			padding: 0 1.25em;

			.bltd--heading--wrapper {
				padding-bottom: 1.25em;
				h2 {
					font-weight: 600;
					font-size: 1.125em;
					margin: 0
				}
			}
		}

		.bltd--rlist {
			.bltd--tr {
				display: block;
				overflow: hidden;
				padding-top: .65em;
				padding-bottom: .65em;
				.bltd--td {
					&:first-child {
						padding: .5em 1.25em .625em;
					}
					&:last-child {
						padding: 0 1.25em;
					}
					padding: 0 1.25em .625em;
					box-sizing: border-box;
					display: block;
					vertical-align: middle;
					width: 100%;

					&.bltd--route--num {
						display: block !important;
						.bltd--main-line {
							.bltd--fontsize14 {
								font-size: 1em;
								font-weight: 600;
							}
							margin-bottom: 0;
						}
						.bltd--next-line {
							display: none;
						}
					}
					&.bltd--route--name {
						.bltd--main-line {
							cursor: pointer;
							position: relative;
							padding-right: 1em;
							&:after {
								width: 1em;
								height: 1em;
								display: block;
								content: '';
								background-image: url(../img/icons/icons.svg);
								background-size: 10.5625em 17.3125em;
								background-position: -8.25em -6.4375em;
								top: 0;
								right: 0;
								position: absolute;
							}
						}
					}

					&.bltd--route--passangers, &.bltd--route--status, &.bltd--route--date-payed, &.bltd--route--link {
						display: none;
					}

					&.bltd--route--date {
						display: block;
						width: 50%;
						float: left;
						.bltd--main-line {
							color: #666;
							font-size: 0.8125em;
							overflow: visible;
						}
					}
					&.bltd--route--cost {
						display: block;
						width: 50%;
						float: left;
						text-align: right;
						.bltd--main-line {
							color: #666;
							font-size: 0.8125em;
							.bltd--price-val {
								font-weight: 400;
							}
						}
					}
					&.bltd--route--timestamp {
						.bltd--inner {
							&:after {
								content: ' ';
								width: auto;
								height: 0.5em;
								background: url(../img/icons/route-arrow.svg) no-repeat 100% 0;
								display: block;
								position: absolute;
								top: 0;
								margin-top: 0.25em;
								right: 5rem;
								left: 5rem;
							}
							&.with-date {
								&:after {
									right: 8em;
									left: 8em;
								}
							}
						}
						.bltd--arrival {
							width: 50%;
							padding-left: 0;
							text-align: right;
						}
					}
					&.bltd--route--spent-time {
						display: none !important;
					}
					&.bltd--route--link {
						display: none;
					}
				}
			}
		}

		.bltd--rlist {
			.bltd--expand {
				.bltd--tr--expand {
					display: block;
					position: fixed;
					top: 0;
					background: #fff;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 2000;
					overflow: scroll;
					.bltd--main-line {
						.bltd--b {
							font-weight: 600;
						}
					}
					.bltd--tr {
						margin-bottom: 0;
						padding: 0;
					}
					.bltd--tr--expand-heading {
						display: block;
						.bltd--expand--back {
							font-size: 1.25em;
							padding: 1em /*0.9375em*/
							.5em;
							background: #F8F7F0;
							margin-bottom: 1em;
							.bltd--expand--nav {
								position: relative;
								color: #4a4a4a;
								display: block;
								padding-left: 1.625em;
								font-weight: $font-600;
								&:before {
									width: 1em;
									height: 1em;
									display: block;
									content: '';
									background-image: url(../img/icons/icons.svg);
									background-size: 10.5625em 17.3125em;
									background-position: -6.9375em -9.0625em;
									top: 0;
									left: 0;
									position: absolute;
								}
							}
						}

						.bltd--expand--detail {
							position: relative;
							padding: 0;
							border-bottom: 1px solid #efeff0;
							.bltd--td {
								padding: 0 1.25em 1.125em;

							}
						}
					}

					.bltd--tr--expand-ctn {
						display: block;
						width: 100%;
						position: relative;
						margin: 0;

						&.bltd--error {
							padding: 1.125em 1.25em;
						}

						.bltd--heading--sub {
							display: block;
							padding: 0;

							.bltd--heading--wrapper {
								padding-bottom: 1.5625em;
								h2 {
									font-size: 1.125em;
									font-weight: 600;
								}
							}
						}
						.bltd--column {
							width: 100%;
							overflow: hidden;
							box-sizing: border-box;
							display: block;
							vertical-align: top;
							padding: 1.125em 1.25em;

							&.bltd--column--ticket {
								padding-bottom: 0;
							}
							&.bltd--column--calc {
								padding-top: 0;
							}
							&.bltd--column--button {
								background-color: #f3f3f3;
								text-align: center;
								padding-bottom: .875em;
								.bltd--field {
									&:first-child {
										margin-bottom: .5em;
									}
								}
							}
						}
					}

					.bltd--route--name {
						.bltd--main-line {
							padding-right: 0;
							&:after {
								content: none;
							}
						}
					}
				}
			}

			.bltd--tr--expand {
				display: none;
			}

			.bltd--ltime {
				color: #9b9b9b;
				padding-left: .5em;
			}
			.bltd--route--calc {
				list-style: none;
				margin: 0;
				padding: 0;
				li {
					display: block;
					margin-bottom: .875em;
					overflow: hidden;
					.bltd--t {
						font-size: .875em;
						float: left;
					}
					.bltd--cost {
						font-size: .875em;
						float: right;
					}
					&.bltd--total-cost {
						margin-bottom: .875em;
						font-weight: $font-600;
						font-size: 1em;
						padding: 0;
						.bltd--t {
							font-size: 1em;
						}
						.bltd--cost {
							font-size: 1em;
							float: right;
						}
					}
				}
			}
			.bltd--bankid {
				font-size: .8125em;
			}
		}
	}
}

.un-returned {
	margin: 1em 0;
	p {
		margin-bottom: .85em;
	}
	.un-ticket {
		margin-bottom: .5em;
		.num {
			font-weight: 600;
		}
	}
}

.bltd--page--return {
	.bltd--wrap {
		padding: 1.25em 1.85em;
	}

	.bltd--notice {
		position: relative;
		padding-left: 2.25em;
		margin-left: .875em;
		padding-right: 1.5%;
		/* width: 100%; */
		margin-bottom: 1.5625em;
		&:before {
			content: '';
			background-image: url(../img/icons/icons.svg);
			background-size: 10.5625em 17.3125em;
			position: absolute;
			top: 0;
			left: 0;
			background-position: -7.8125em -14.5625em;
			width: 1.5em;
			height: 1.5em;
		}
		& > .bltd--content {
			display: block;
			min-height: 1em;
			font-size: 0.875em;
			line-height: 1.75em;
		}
	}
	.bltd--heading--sub {
		padding: 0;
		position: relative;
		background: none;
		.bltd--heading--wrapper {
			margin-bottom: 1.25em;
			h2 {
				margin: 0
			}
		}
	}
	.bltd--table-footer {
		padding: 1.25em 1.5%;
	}
	.bltd--table-fields {
		display: table;
		table-layout: fixed;
		width: 100%;
		.bltd--field {
			width: 50%;
			display: table-cell;
			.button-group {
				white-space: nowrap;
			}
			&:first-child {
				text-align: left;
			}
			&:last-child {
				width: 60%;
				text-align: right;
			}
		}
	}
	.bltd--price-val {
		&.old {
			font-weight: 400;
			/* text-decoration: line-through; */
			color: #9b9b9b;
			position: relative;
			display: inline-block;
		}
	}
	.bltd--rlist {
		background: #fff;
		border-radius: 0.25em;

		.single--line {
			.bltd--main-line {
				margin-bottom: 0;
			}
		}
		.bltd--tr {
			.bltd--td {
				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
				padding: .625em 1%;
			}
		}
		.total {
			margin: 1.25em 1.5% 1em;
			text-align: right;
		}
		.bltd--rlist--header {

			border-bottom: 0.125em solid #F8F7F0;
			.bltd--rlist--header-wrapper {
				padding-top: .625em;
			}
		}
		.bltd--route--checkbox {
			width: 25px;
			max-width: 25px;

		}
		.bltd--route--num {
			max-width: 130px;
			width: 130px;

		}
		.bltd--route--name {
			width: 24%;
			.bltd--next-line {
				color: #9b9b9b;
			}
		}

		.bltd--route--cost {
			width: 12%;
			text-align: right;
		}

		.bltd--route--kind {
			width: 12%;
		}
		.bltd--route--seat {
			width: 8%;
		}
		.bltd--route--bag {
			width: 8%;
		}
	}
}

@include media("<=tablet") {
	.bltd--main_content {
		&.bltd--page--return {
			padding-top: 0;
		}
	}
	.bltd--page--return {

		.bltd--wrap {
			padding: 0;
			padding-top: 1.25em;
		}
		.bltd--rlist {
			background: #fff;
			border-radius: 0;

			.bltd--rlist--content-route {
				margin-bottom: 0em;
				&:first-child {
					padding-top: .5em;
				}
				.bltd--ctn {
					padding: 0 1.25em;
				}
			}
			.bltd--tr {
				padding-left: 2em;
				padding-bottom: .5em;
				overflow: hidden;
				margin-bottom: 0;
				.bltd--td {
					padding: .625em 1%;
					&:first-child {
						padding-left: 0;
					}
					&:last-child {
						padding-right: 0;
					}

					&.bltd--route--checkbox {
						position: absolute;
						top: 0.625em;
						left: 0;
						padding: 0 !important;
					}
					&.bltd--route--num {
						display: block !important;
						width: 100%;
						max-width: 100%;
						padding-bottom: .25em;
						.bltd--main-line {
							.bltd--fontsize14 {
								font-size: 1em;
							}
							margin-bottom: 0;
						}
					}
					&.bltd--route--name {
						width: 100%;
					}

					&.bltd--route--cost {
						//width: 33%;
						float: right;
					}
					&.bltd--route--kind, &.bltd--route--seat {
						float: left;
					}
					&.bltd--route--cost, &.bltd--route--kind, &.bltd--route--seat {
						font-size: 0.875em;
						width: 33%;
					}
					&.bltd--route--bag {
						display: none;
					}
				}
			}
			.bltd--total {
				margin: 1.25em 0 1em;
				text-align: right;
				padding: 0 1.25em;
			}
			.bltd--table-footer {
				padding: 0 1.25em 1.25em;
			}
		}
		.bltd--agreement {
			margin-bottom: 1.25em;
		}
		.bltd--table-fields {
			display: block;
			width: 100%;
			.bltd--field {
				width: 100%;
				display: block;
				&:first-child {
					width: 100%;
					text-align: left;
				}
				&:last-child {
					width: 100%;
					text-align: left;
				}
			}
		}
		.bltd--mobile-flex {
			display: flex;
			flex-flow: column;
			.o2 {
				order: 2;
			}
			.o3 {
				order: 3;
			}
			.o4 {
				order: 4;
			}

			.bltd--btn {
				margin-bottom: .5em;
				line-height: 2.5em;
				&:first-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

.bltd--page--account-list {
	.bltd--wrap {
		padding: 2.5em 1.85em 1.25em;
	}
	.bltd--notice {
		position: relative;
		//padding-left: 2.25em;
		margin-left: .875em;
		margin-bottom: 1.5625em;
		display: flex;
		align-items: center;

		& > .bltd--content {
			display: block;
			/* line-height: 1.333; */
			min-height: 1em;
			font-size: 0.875em;
			line-height: 1.75em;
			padding-left: 20px;
		}
	}
	.bltd--heading--sub {
		padding: 0;
		position: relative;
		background: none;
		.bltd--heading--wrapper {
			margin-bottom: 1.25em;
			h2 {
				margin: 0
			}
		}
	}
	.bltd--form--content {
		padding-top: 1.125em;
	}

	.bltd--form--footer {
		padding: 1.125em 0;
	}
	.bltd--account--form {
		padding: 0em 1.25em;
		border: 0.125em solid var(--base-green-color);
		background: #fff;
		border-radius: 0.25em;
		box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.08);

		.bltd--account--form-heading {
			display: none;
		}

	}

	.bltd--rlist {

		.bltd--account--name {
			width: 100%;
			.bltd--gender {
				margin-left: 3.75em;
				position: relative;
				&:after {
					content: '';
					background-image: url(../img/icons/icons.svg);
					background-size: 10.5625em 17.3125em;
					position: absolute;

					background-position: 0em -14.75em;
					width: 2.5em;
					height: 2.5em;
					top: 0;
					left: -3.75em;
					border-radius: 100%;
				}
			}
		}
		.bltd--account--edit {
			width: 9.375em;
			position: relative;
		}

	}

	.bltd--rlist {
		.bltd--expand {
			.bltd--tr--expand {
				display: block;
			}
		}
		.bltd--tr--expand {

			display: none;

		}
	}

}

@include media('<=tablet') {
	.bltd--page--account-list {
		.bltd--wrap {
			padding: 0;
			padding-top: 1.25em;
		}
		.bltd--form--content {
			padding: 1.125em 1.25em;
		}
		.bltd--form--footer {
			background-color: #f3f3f3;
			text-align: center;
			padding: 1.125em 1.25em;
		}
		.bltd--account--list {
			.bltd--tr {
				margin-left: 0;
				.bltd--wr {
					display: block;
				}
				.bltd--td {
					&:first-child {
						padding: 1.125em 1.25em;
					}
					&.bltd--account--name {
						display: block;
						width: 100%;

						.bltd--gender {
							margin-left: 0;
							position: relative;
							&:after {
								content: none;
							}
						}
					}
					&.bltd--account--edit {
						display: block;
						width: 100%;
					}
				}
			}
		}

		.bltd--account--form {
			padding: 0;
			border: 0;
			background: #fff;
			border-radius: 0;
			box-shadow: none;
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2000;
			overflow-y: auto;

			.bltd--account--form-heading {
				display: block;

				.bltd--expand--back {
					font-size: 1.25em;
					padding: 1em /*0.9375em*/
					.5em;
					background: #F8F7F0;
					margin-bottom: 1em;
					.bltd--expand--nav {
						position: relative;
						color: #4a4a4a;
						display: block;
						padding-left: 1.625em;
						font-weight: $font-600;
						&:before {
							width: 1em;
							height: 1em;
							display: block;
							content: '';
							background-image: url(../img/icons/icons.svg);
							background-size: 10.5625em 17.3125em;
							background-position: -6.9375em -9.0625em;
							top: 0;
							left: 0;
							position: absolute;
						}
					}
				}
			}
			.bltd--form--content {
				background: #fff;
			}
		}

		.bltd--list-footer {
			.bltd--btn {
				width: 100%;
			}
		}
	}
}

.bltd--page--profile {
	.bltd--wrap {
		padding: 1.25em 1.85em;
		padding-top: 2.5em;
	}

	.bltd--form {
		background: #fff;
		border-radius: 0.25em;
		margin-bottom: 1.875em;
		.bltd--form--wrapper {
			position: relative;
			padding: 2.25em 0;
		}
		.bltd--form--wrapper-half {
			max-width: 25em;
			padding: 0 6em;
		}
		.bltd--form--footer {
			padding: 0 6em;
		}
		.bltd--heading--form {
			display: block;
			padding: 0;
		}
	}
}

.bltd--page--profile, .bltd--page--agency{
	.bltd--heading--wrapper {
		padding-bottom: 1.5625em;
	}
	h4 {
		margin: 0;
		padding: 0;
		font-size: 1.125em;
		font-weight: 600;
	}
}

.bltd--page--report, .bltd--modal-move-order{
	.bltd--booker-item{
		.bltd--booker-typeahead{
			height: 40px;
			line-height: 40px;
			position: relative;
			border-radius: 4px;
			button{
				outline: none;
				background: none;
				width: 100%;
				white-space: nowrap;
				display: block;
				height: 100%;
				text-align: left;
				text-overflow: ellipsis;
				border-radius: 4px;
				border: 1px solid #e5e4de;
			}
			//&.ng-invalid{
			//	button{
			//		border-color: #dd4b39;
			//		box-shadow: 0 0 0 0.125em rgba(221, 75, 57, 0.35);
			//	}
			//}
			box-shadow: 0 0 0 1px #e5e4de;
		}
		&.bltd--booker-item--date button span{
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: 0 .2em;
			padding-right: 2.5em;
			line-height: 1em;
		}
	}
	.bltd--booker-typeahead-placeholder{
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		pointer-events: none;
		transform: translateY(0);
		transform-origin: bottom left;
		transition-property: transform, color;
		transition-duration: 0.4s;
		transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
		max-width: 85%;
		padding: 0 .5em;
		color: #999;
		&.transform{
			display: none;
		}
	}
}

.bltd--page--report{
	.bltd--form--wrapper-half {
		max-width: none!important;
		.bltd--field{
			max-width: 100%;
			width: auto;
			min-width: 168px;
			display: inline-block;
			margin-right: 30px;
			vertical-align: top;
		}
	}
}

.bltd--agents{
	.bltd--form--wrapper{
		position: relative;
	}
	.bltd--labels{
		.bltd--field{
			margin: 0 20px 0 0!important;
		}
	}
	.bltd--field{
		display: inline-block;
		max-width: 200px;
		margin: 0 20px 20px 0!important;
		vertical-align: bottom;
		label{
			margin-bottom: 15px;
		}
		.message-error{
			display: none;
		}
		input{
			&.ng-invalid{
				border-color: #dd4b39;
				box-shadow: 0 0 0 0.125em rgba(221, 75, 57, 0.35)
			}
		}
	}
	.bltd--error.bltd--msg{
		margin: 0 0 20px!important;
	}
	fieldset{
		margin: 0;
		padding-left: 1px;
	}
	.bltd--agent-link{
		display: inline-block;
		line-height: 40px;
		margin-right: 20px;
	}
	.bltd--icon--info{
		height: 1.2em;
		width: 1.2em;
		border-radius: 50%;
		border: 1px solid #adadad;
		color: #adadad;
		cursor: pointer;
		display: inline-block;
		margin: -2px 0 0 6px;
		vertical-align: middle;
		font-size: 15px;
		line-height: 1em;
		padding: 1px 5px;
	}
}

.bltd--modal-move-order{
	.bltd--form .bltd--field{
		margin: 10px 0;
		display: inline-block;
	}
}

.bltd--table-fields {
	display: table;
	table-layout: fixed;
	width: 100%;
	.bltd--field {
		width: 50%;
		display: table-cell;
		.button-group {
			white-space: nowrap;
		}
		&:first-child {
			text-align: left;
		}
		&:last-child {
			width: 60%;
			text-align: right;
		}
	}
}

@include media('<=tablet') {
	.bltd--page--profile {

		.bltd--wrap {
			padding: 0;
			padding-top: 1.25em;
		}

		.bltd--form {
			background: #fff;
			border-radius: 0;
			margin-bottom: 0;
			.bltd--form--wrapper {
				position: relative;
				padding: 1.25em 0;
			}
			.bltd--form--wrapper-half {
				padding: 0 1.25em;
			}
		}
	}
}

@include media('<=tablet') {
	.bltd--table-fields {
		display: block;
		table-layout: fixed;
		width: 100%;
		.bltd--field {
			width: 100%;
			display: block;
			margin-bottom: 1em;
			.bltd--button-group {
				white-space: nowrap;
			}
			&:first-child {
				text-align: left;
				margin-bottom: 1em;
			}
			&:last-child {
				width: 100%;
				text-align: left;
				display: block;
				margin-bottom: 0;
				.bltd--btn {
					margin-bottom: .5em;
				}
			}
		}

		.bltd--btn {
			width: 100%;
			margin-bottom: .5em;
			line-height: 2.5em;
			display: block;
		}
	}
}

.bltd--form--footer {
	padding: 0 1.25em 1.25em;
}

.bltd--base-modal {
    .bltd--text {
        margin: 20px 0;

        p {
            margin-bottom: 14px;
        }
    }

    .bltd--base-modal-footer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

.bltd--payment--list {
	.bltd--note--warning {

		color: #dd4b39 !important;
		margin: 0em 0 1em 0;
	}
	.bltd--agree {
		margin-bottom: 1.875em;
	}
	.bltd--heading {
		margin: 0 0 1em;
		.bltd--title {
			font-size: 3.2rem;
			color: #666;
			font-weight: 400;
			line-height: 1.1;
		}
	}
	.bltd--action {
		margin-top: 1em;
	}
	.bltd--rlist {

		.bltd--tr {
			.bltd--td {
				.bltd--bold {
					font-weight: 600;
				}
				.bltd--main-line {
					white-space: normal;

				}
				.bltd--next-line {
					white-space: normal;
					line-height: 1.33;
				}
			}
		}

		.bltd--payment--method {

			margin-left: 2em;
			display: inline-block;
			vertical-align: middle;
		}
		label {
			display: inline-block;
			vertical-align: middle;
			white-space: nowrap;
			input {
				display: inline-block;
				vertical-align: middle;
			}
		}
		.bltd--payment--name {
			width: 100%;
			.bltd--payment--icon {
				margin-left: 220px;
				position: relative;
				&:after {
					content: '';
					background-repeat: no-repeat;
					text-align: center;
					position: absolute;
					width: 200px;
					height: 2.5em;
					top: 0;
					left: -220px;
					/* border-radius: 100%; */
				}

				&.bltd--icon-sber_equairing,
				&.bltd--icon-bash_sber_equiring_rur,
				&.bltd--icon-sber_kassa38_rur,
				&.bltd--icon-volzhskiy_sber_equiring_rur,
				&.bltd--icon-gars_sber_equiring_rur,
				&.bltd--icon-yakutsk_sber_equiring_rur,
				&.bltd--icon-gorizont_sber_equiring_rur,
				&.bltd--icon-tavda_sber_equiring_rur,
				&.bltd--icon-surgut_sber_equiring_rur,
				&.bltd--icon-rossosh_sber_equiring_rur,
				&.bltd--icon-itt_sber_equiring_rur,
				&.bltd--icon-tatflot_sber_equiring_rur,
				&.bltd--icon-avudm_sber_equiring_rur,
				&.bltd--icon-alapaevsk_sber_equiring_rur,
				&.bltd--icon-kalach_sber_equiring_rur,
				&.bltd--icon-kotovo_sber_equiring_rur,
				&.bltd--icon-kirov_sber_equiring_rur,
				&.bltd--icon-revda_sber_equiring_rur,
				&.bltd--icon-omskoblauto_sber_equiring_rur,
				&.bltd--icon-uralsk_ecom_equiring_kzt {
					&:after {
						top: .45em;
						background-image: url(../img/payments/sber.png);
					}

				}
				&.bltd--icon-rfi_equairing, &.bltd--icon-rfi_celphone {
					&:after {
						background-image: url(../img/payments/rfi.png);
					}

				}
				&.bltd--icon-rfi_equairing_rur, &.bltd--icon-rfi_celphone_rur {
					&:after {
						background-image: url(../img/payments/rfi.png);
					}

				}
				&.bltd--icon-test_cavsemey_equaring_kzt,
				&.bltd--icon-cavsemey_equaring_kzt {
					 &:after {
						 background-image: url(../img/payments/alfa_bank.png);
					 }

				}
				&.bltd--icon-uralsk_equiring_kzt,
				&.bltd--icon-semey_equiring_kzt {
					&:after {
						background-image: url(../img/payments/kaz.png);
					}

				}
				&.bltd--icon-itt_balance_rur {
					&:after {
						background-image: url(../img/payments/biletdo_payment_uee.png);
					}

				}
				&.bltd--icon-itt_sber_equiring_rur_pay, &.bltd--icon-sber_equairing_pay {
					&:after {
						background-image: url(../img/payments/sberpay_icon.png);
						background-size: contain;
					}

				}
			}
		}
	}
}

@include media('<=tablet') {
	.bltd--payment--list {
		.bltd--heading {
			margin: 1.25em 0 2em;

		}
		.bltd--action {
			margin-top: 1em;
			text-align: center;
		}
		.bltd--rlist {
			margin-bottom: 1.875em;

			.bltd--tr {
				.bltd--td {
					&:last-child {
						padding: 1.125em 1.25em 1.125em .875em;
					}
					.bltd--main-line {
						white-space: normal;
						line-height: 1.33;
					}
					.bltd--next-line {
						white-space: normal;
						line-height: 1.33;
					}
				}
			}

			.bltd--payment--method {
				margin-left: 0.5em;
				display: inline-block;
			}
			.bltd--payment--name {
				width: 100%;
				.bltd--payment--icon {
					margin-left: 0;
					position: relative;
					&:after {
						content: none;
					}
				}
			}
		}
	}
}

.bltd--page--text .bltd--select--variant ul > li {
	margin-bottom: 0;
	padding-left: 0;
}

.bltd--page--text .bltd--select--variant ul > li:before {
	content: none;
	float: left;
	margin-left: -1.3em;
}

.bltd--select--variant {
	width: 560px;
	margin-bottom: 1em;

	input {
		font-family: "Open Sans", Helvetica, Arial, sans-serif;
		margin: 0;
		outline: none;
		-webkit-appearance: none;
		tap-highlight-color: rgba(255, 255, 255, 0);
		height: 2.5em;
		padding: 0em 0.5em;
		font-size: 1em;
		background: #FFFFFF;
		border: 1px solid #e5e4de;
		color: #4a4a4a;
		border-radius: 3px;
		box-shadow: 0 0 0 1px #e5e4de;
		transition: color 0.1s ease, border-color 0.1s ease;
		width: 100%;

	}
}

/*.bltd--btn--m {
	line-height: 1.5em !important;
	font-size: 1em;
	font-weight: 600;
	padding: 0.5em 0.5em !important;
}*/

@include media('<=tablet') {
	.bltd--select--variant {
		width: 260px;
	}
}
