/* jquery ui */

.ui-widget {

}

.ui-widget-content {
  background: #FFFFFF;
  color: #0E2E43;
  border: 2px solid #E4E4E4;
  border-top: none;
  box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, 0.10);
}

.ui-menu .ui-menu-item {
  padding: 0;
  margin: 0;
  background: #ffffff;
  border: none;
  white-space: nowrap;
}

.ui-widget-content li a {
  display: block;
  width: 21rem;
  padding: 1rem 1rem 1.3rem 1.5rem;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 400;
  color: #C0C0C0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui-widget-content li a span {
  color: #4A4A4A;
}

.ui-widget-content li a:hover, .ui-state-focus a {
  color: #4A4A4A;
  background: #F4F4F4;
}

.ui-corner-all {
  border-radius: 0;
}

.ui-widget-header {
  background: none;
  border: none;
  font-weight: 400;
}

.ui-datepicker {
  padding: 0;
  border: $px1 solid #e4e4e4;
  box-shadow: 0 $px10 $px10 rgba(0, 0, 0, 0.1);
  /*width: 15em;*/
}
.ui-datepicker .ui-datepicker-calendar {
  padding: 0.5em 0.9375em;
  border-spacing: 0;
}
.ui-datepicker .ui-datepicker-calendar-error {
  padding: $px15 $px25;
  text-align: center;
  span {
    font-size: $px12;
    color: #dd4b39;
  }
}
.ui-datepicker .all-days {
  padding: 1em $px18;
  border-bottom: $px1 solid rgba(223, 223, 224, 0.50);
}

.ui-datepicker .all-days a {
  font-size: $px14;
  line-height: $px18;
  color: #3F88CC;
  font-weight: $font-800;
}

.ui-datepicker .all-days a:hover {
  color: #2859CC;
}

.ui-datepicker .all-days a:active {
  color: #173ACC;
}

.ui-datepicker .ui-datepicker-header {
  margin: 1.0625em 0.9375em 0;
  position: relative;
  padding: 0;
}

.ui-datepicker .ui-datepicker-title {
  margin-bottom: 1em;
  font-size: $px14;
  line-height: $px20;
  padding: 0;
  text-align: center;
  color: #4a4a4a;
  font-weight: $font-800;
}

.ui-datepicker .ui-datepicker-prev {
  left: 0;
}

.ui-datepicker .ui-datepicker-next {
  right: 0;
}

.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover {
  top: 0;
}

.ui-datepicker .ui-datepicker-prev-hover {
  left: 0;
}

.ui-datepicker .ui-datepicker-next-hover {
  right: 0;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
  border: none;
  background: rgba(102, 102, 102, 0.05);
  font-weight: 400;
  color: transparent;
}

.ui-state-hover a, .ui-state-hover a:hover, .ui-state-hover a:link, .ui-state-hover a:visited, .ui-state-focus a, .ui-state-focus a:hover, .ui-state-focus a:link, .ui-state-focus a:visited {
  color: transparent;
  text-decoration: none;
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 0;
  width: 1em;
  height:  1em;
  text-align: center;
  cursor: pointer;
}

.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
  display: block;
  text-indent: -9999px;

  background-image: url("../img/icons/icons.svg");
  background-size: 10.5625em 17.3125em;
  position: absolute;
  width: 1em;
  height: 1em;
  top: 0;
  left: 0;
}

.ui-datepicker .ui-datepicker-prev span {
  background-position: -9.5625em -6.4375em;


}

.ui-datepicker .ui-datepicker-next span {
  background-position: -8.25em -6.4375em;

}

.ui-datepicker th {
  padding:0;
  text-align: center;
  font-weight: 400;
  border: 0;
  font-size: $px12;


  color: #666;
  text-transform: lowercase;
}
.ui-datepicker th span {

  width: 2.5em;
  height: 2.5em;
  line-height: 2.45em;
  display: block;
}
.ui-datepicker th.ui-datepicker-week-end {
  color: #dd4b39;
}

.ui-datepicker td {
  margin: 0;
  padding: 0;
  border: none;
  text-align: center;
  padding: 0;

  font-size: .75em;

  padding-bottom: 0.25em;
}

.ui-datepicker td span, .ui-datepicker td a {
  display: block;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  text-decoration: none;

  padding: 0;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  border: 1px solid transparent;
  background: none;
  font-weight: 400;
  border-radius: 0.3em;
  color: #4a4a4a;
  /* margin: 0.1rem; */
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  display: block;
}
.ui-state-default.ui-state-disable, .ui-widget-content .ui-state-default.ui-state-disable, .ui-widget-header .ui-state-default.ui-state-disable
 {
  color: #cecece;
}
.ui-widget-content .ui-datepicker-current-day a {
  background: #82B548;
  color: #FFFFFF;

}

.ui-datepicker-today.ui-datepicker-current-day a, .ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight, .ui-state-default:hover, .ui-widget-content .ui-state-default:hover, .ui-widget-header .ui-state-default:hover {
  background: #FFFFFF;
  color: #4a4a4a;
  border: 0.085em solid #82B548;
  border-radius: 0.3em;
  font-weight: $font-800;
}

.ui-state-selected, .ui-widget-content .ui-state-selected{
  background: #82B548;
  color: #fff;

}
.ui-state-selected:hover, .ui-widget-content .ui-state-selected:hover  {
  background: #FFFFFF;
  color: #4a4a4a;
  border-color: #82B548;

}
.rounded .ui-datepicker-today.ui-datepicker-current-day a,
.rounded .ui-state-highlight,
.rounded .ui-widget-content .ui-state-highlight,
.rounded .ui-widget-header .ui-state-highlight,
.rounded .ui-state-default:hover,
.rounded .ui-widget-content .ui-state-default:hover,
.rounded .ui-widget-header .ui-state-default:hover,
.rounded .ui-state-selected,
.rounded .ui-state-selected:hover{
  border-radius: 100%;
}